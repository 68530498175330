const _decode = new RegExp("http[s]?://cf\.c\.ooyala\.com/(.*)/(.*)");

export default class OoyalaPlayerUtils {
  /**
  * Extracts video Id from url
  * @param {string}		url		    - The url extracts from
  * @returns {string}
  */
  static getVideoId(url) {
    if (url) {
      let matches = decodeURIComponent(url).match(_decode);

      if (matches != null) {
        return matches[1];
      }
    }

    return null;
  }

  /**
   * Return true if is valid defence video URL
   * @returns {boolean}
   */
  static isValidVideoLink(url: string) {
    if (!url) {
      return false;
    }

    var regexp = new RegExp("^(http[s]?://)?video.(defence|navy|army|airforce).gov.au/play/([^#]*).*");
    return url.toLowerCase().trim().match(regexp);
  }
}
import moment from 'moment';

import AppDelegate				from '../AppDelegate';

const DATE_TIME_STANDARD_PARSE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_TIME_STANDARD_RENDER_FORMAT = 'D MMM YYYY [at] HH:mm';
export const DATE_STANDARD_RENDER_FORMAT = 'D MMM YYYY';

export default class DateTimeUtils {

	static renderDate = (date) => {
		return date && date.clone().tz("Australia/Sydney").format(DATE_STANDARD_RENDER_FORMAT);
	}
	static renderDateTime = (date) => {
		return date && moment(date).utcOffset('+0000', true).tz("Australia/Sydney").format(DATE_TIME_STANDARD_RENDER_FORMAT);
	}
	/**
	 * Creates a date from a ISO string with format "YYYY-MM-DDTHH:mm:ss". Null returnd if invalid.
	 * @param {string} 	dateString		- The iso date string to parse
	 * @returns {Date|null}
	 */
	static dateFromISOString (dateString) {
		if (dateString && dateString.endsWith("Z")) {
			var m = moment.tz(dateString, "YYYYMMDDTHHmmssZ", AppDelegate.config.serverTimeZone);    
		} else {
			var m = moment.tz(dateString, "YYYY-MM-DDTHH:mm:ss", AppDelegate.config.serverTimeZone);
		}
		if(m.isValid()) {
			return m;
		}
		return null;
	}

	static stringFromDate(date, format = "DD/MM/YYYY")
	{
		return moment(date).format(format);
	}

	/**
	 * Takes an ISO 8601 time and returns a string representing how long ago the date represents.
	 * @param time
	 * @returns {*}
	 */
	static fuzzyDateTime(time) {

		var date;

		if(time._isAMomentObject){
			date = time;
		} else {
			date = moment(time, 'YYYY-MM-DDTHH:mm:ss');
		}

		if(!date.isValid()) {
			return;
		}

		var n = moment(),
			diffSeconds = n.diff(date, 'seconds'),
			dayDiff = n.diff(date, 'days');

		return dayDiff === 0 && (
				diffSeconds < 60 && 'Just now' ||
				diffSeconds < 120 && '1 minute ago' ||
				diffSeconds < 3600 && Math.floor( diffSeconds / 60 ) + ' minutes ago' ||
				diffSeconds < 7200 && '1 hour ago' ||
				diffSeconds < 86400 && Math.floor( diffSeconds / 3600 ) + ' hours ago') ||
				dayDiff === 1 && 'Yesterday' ||
				dayDiff < 7 && dayDiff +  ' days ago' ||
				dayDiff < 31 && Math.ceil( dayDiff / 7 ) + ' weeks ago' ||
				date.format(DATE_TIME_STANDARD_RENDER_FORMAT);
	}


	/**
	 * Returns a fuzzy date time capped up to a date range difference
	 * @param {Date|moment}		time		- The date to convert
	 * @param {int}				maxDays		- The maximum number of days to cap at
	 * @returns {string}
	 */
	static fuzzyDateTimeCapped(time, maxDays = 1) {
		time = moment.tz(time.format(), AppDelegate.config.serverTimeZone);

		// If greater than or equal to capped days, return the date as a string
		if(this.diff(moment(), time, 'days') >= maxDays){
			return time.format(DATE_TIME_STANDARD_RENDER_FORMAT);
		}

		// else return a fuzzy date
		return this.fuzzyDateTime(time);

	}

	/**
	 * Returns a formatted Absolute Date/Time
	 */
	static getAbsoluteDateTimeFormatted(time) {
		return time.format(DATE_TIME_STANDARD_RENDER_FORMAT);
	}


	/**
	 * Calculates the difference between two dates
	 * @param {date|moment}		n1		- The first date
	 * @param {date|moment}		n2		- The second date to compare
	 * @param {string}			unit	- The unit used to compare, eg seconds, days, months, years etc
	 * @returns {int}
	 */
	static diff(n1, n2, unit = 'seconds') {

		if(!n1._isAMomentObject){ n1 = moment(n1, DATE_TIME_STANDARD_PARSE_FORMAT); }
		if(!n2._isAMomentObject){ n2 = moment(n2, DATE_TIME_STANDARD_PARSE_FORMAT); }

		if(!n1.isValid() || !n2.isValid()) {
			throw new Error('Cannot compare. Unexpected date object.');
		}

		return n1.diff(n2, unit);
	}
}
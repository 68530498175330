import React, {
    FC,
}                           from "react";
import _	                from 'lodash';

import {RecentActivityList, IRecentActivityListProps}   from "./RecentActivities";

interface IRecentActivitiesModuleProps {
    isOpen: boolean;
    activityList: Array<IRecentActivityListProps>
}

export const RecentActivitiesModule : FC<IRecentActivitiesModuleProps> = (props: IRecentActivitiesModuleProps) => {
    return (
        <RecentActivityList isOpen={props.isOpen} recentActivityList={props.activityList}/>
    )
}
import React 				from 'react';
import PropTypes            from 'prop-types';
import moment               from "moment";

function getSelectedMonths(fromMonth, toMonth) {
    let selectedMonths = new Set();

    if (typeof(fromMonth) == "number") {
        if (typeof(toMonth) == "number" && toMonth < fromMonth) {
            toMonth += 12;
        }

        for (let i = fromMonth; i <= (toMonth || fromMonth); ++i) {
            selectedMonths.add(i % 12);
        }
    }

    return selectedMonths;
}

function getSelectableMonths(selectedMonths, allSelectedMonths) {
    let selectableMonths = new Set();

    for (let i = 0; i < 12; ++i) {
        if (!allSelectedMonths.has(i) || selectedMonths.has(i)) {
            selectableMonths.add(i);
        }
    }

    return selectableMonths;
}

class PatternOfService extends React.Component {
	constructor(props) {
        super(props);

        let selectedMonths = getSelectedMonths(props.item.fromMonth, props.item.toMonth);
        let selectableMonths = getSelectableMonths(selectedMonths, props.allSelectedMonths);
        this.state = {
            id: props.item.id,
            fromMonth: props.item.fromMonth,
            toMonth: props.item.toMonth,
            type: props.item.type,
            days: props.item.days,
            weeks: props.item.weeks,
            weekDays: props.item.weekDays,
            weekends: props.item.weekends,
            fullDay: props.item.fullDay,
            dayTime: props.item.dayTime,
            afterHours: props.item.afterHours,
            blockOfDays: props.item.blockOfDays,
            selectedMonths,
            selectableMonths,
            maxDays: 0,
            isLookingForWork: props.item.isLookingForWork,
            comments: props.item.comments ? props.item.comments : ""
        }
    }

    componentWillReceiveProps(props) {
        let selectedMonths = getSelectedMonths(props.item.fromMonth, props.item.toMonth);
        let selectableMonths = getSelectableMonths(selectedMonths, props.allSelectedMonths);

        this.setState({
            id: props.item.id,
            fromMonth: props.item.fromMonth,
            toMonth: props.item.toMonth,
            type: props.item.type,
            days: props.item.days,
            weeks: props.item.weeks,
            weekDays: props.item.weekDays,
            weekends: props.item.weekends,
            fullDay: props.item.fullDay,
            dayTime: props.item.dayTime,
            afterHours: props.item.afterHours,
            blockOfDays: props.item.blockOfDays != 0 ? props.item.blockOfDays : 20,
            selectedMonths,
            selectableMonths,
            isLookingForWork: props.item.isLookingForWork,
            comments: props.item.comments ? props.item.comments : ""
        });
    }

    emit() {
        let selectedMonths = getSelectedMonths(this.state.fromMonth, this.state.toMonth);
        let validation = {
            monthsInvalid: typeof(this.state.fromMonth) != "number",
            typeInvalid: typeof(this.state.type) != "number",
            daysInvalid: this.state.type === 0 && typeof(this.state.days) != "number",
            weeksInvalid: this.state.type === 1 && typeof(this.state.weeks) != "number",
            fullDayInvalid: this.state.type === 0 && typeof(this.state.fullDay) != "boolean",
            weekInvalid: false,
            timeInvalid: false,
            blockOfDaysInvalid: this.state.type === 2 && (this.state.blockOfDays === "" || isNaN(this.state.blockOfDays) || this.state.blockOfDays < 0),
        }

        this.setState({
            selectedMonths,
            validation
        });

        if (this.props.onChange) {
            this.props.onChange({
                id: this.state.id,
                fromMonth: this.state.fromMonth,
                toMonth: this.state.toMonth,
                type: this.state.type,
                days: this.state.days,
                weeks: this.state.weeks,
                weekDays: this.state.weekDays,
                weekends: this.state.weekends,
                fullDay: this.state.fullDay,
                dayTime: this.state.dayTime,
                afterHours: this.state.afterHours,
                blockOfDays: this.state.blockOfDays == 0 ? 20 : this.state.blockOfDays,
                selectedMonths: [...selectedMonths],
                validation,
                isLookingForWork: this.state.isLookingForWork,
                comments: this.state.comments
            });
        }

        var days = 0;
        if (!this.state.toMonth) {
            days = new Date(new Date().getFullYear(), this.state.fromMonth + 1, 0).getDate()
        } else {
            for (var i = this.state.fromMonth; i <= this.state.toMonth ; i++) {
                days = days + new Date(new Date().getFullYear(), i + 1, 0).getDate()
            }
        }

        this.setState({
            maxDays: days
        })
    }

    onChangeFromMonth = (e) => {
        let fromMonth = e.target.value && parseInt(e.target.value);
        let toMonth = this.state.toMonth;

        if (typeof(fromMonth) == "number") {
            if (typeof(toMonth) == "number") {
                let valid = true;

                for (let i = fromMonth; i < 12; ++i) {
                    if (!this.state.selectableMonths.has(i)) {
                        toMonth = null;
                        break;
                    }
                }
            }
        } else {
            toMonth = null;
        }

		this.setState({
            fromMonth,
            toMonth,
            blockOfDays: 0
        }, this.emit);
    }
    
    onChangeToMonth = (e) => {
		this.setState({
            toMonth: e.target.value && parseInt(e.target.value),
            blockOfDays: 0
        }, this.emit);
    }

    onChangeType = (e) => {
		this.setState({
			type: e.target.value && parseInt(e.target.value)
		}, this.emit);
    }

    onChangeDays = (e) => {
        this.setState({
            days: e.target.value && parseInt(e.target.value)
        }, this.emit);
        if (e.target.value && parseInt(e.target.value) > 2) {
            this.setState({
                weekDays: true
            })
        }
    }

    onChangeLookingForWork = (e) => {
        this.setState({
            isLookingForWork: e.target.checked
        }, this.emit);
    }


    onChangeWeekDays = (e) => {
        this.setState({
            weekDays: e.target.checked
        }, this.emit);
    }

    onChangeWeekends = (e) => {
        this.setState({
            weekends: e.target.checked
        }, this.emit);
    }

    onChangeFullDay = (e) => {
        this.setState({
            fullDay: e.target.value == 1 ? true : (e.target.value == 0 ? false : null)
        }, this.emit);
    }

    onChangeDayTime = (e) => {
        this.setState({
            dayTime: e.target.checked
        }, this.emit);
    }

    onChangeAfterHours = (e) => {
        this.setState({
            afterHours: e.target.checked
        }, this.emit);
    }

    onChangeWeeks = (e) => {
        this.setState({
            weeks: e.target.value && parseInt(e.target.value)
        }, this.emit);
    }

    onChangeBlockOfDays = (e) => {
        this.setState({
            blockOfDays: e.target.value
        }, this.emit);
    }

    onChangeComments = (e) => {
        this.setState({
            comments: e.target.value
        }, this.emit);
    }

    onDeleteItem = (e) => {
        if (this.props.onDelete) {
            this.props.onDelete({
                id: this.state.id
            });
        }
    }

    fromMonths() {
        return moment.months()
            .map((month, index) => {
                return {
                    label: month,
                    value: index
                };
            })
            .filter((value, index) => this.state.selectableMonths.has(index));
    }

    toMonths() {
        let prevAdded = null;
        let nextYearAdded = null;
        let allToMonths = true;

        for (let index = this.state.fromMonth; index < 12; ++index) {
            if (!this.state.selectableMonths.has(index)) {
                allToMonths = false;
                break;
            }
        }

        return moment.months()
            .map((month, index) => {
                return {
                    label: month,
                    value: index
                };
            })
            .filter((value, index) => {
                if (this.state.fromMonth !== null) {
                    if (index >= this.state.fromMonth) {
                        if (this.state.selectableMonths.has(index)) {
                            if (prevAdded === null || prevAdded === index - 1) {
                                prevAdded = index;
                                return true;
                            }
                        }
                    } else if (allToMonths) {
                        if (this.state.selectableMonths.has(index)) {
                            if (nextYearAdded === null || nextYearAdded === index - 1) {
                                nextYearAdded = index;
                                return true;
                            }
                        }
                    }
                }

                return false;
            })
            .sort((left, right) => {
                let lValue = left.value;
                let rValue = right.value;

                if (typeof(this.state.fromMonth) == "number") {
                    if (lValue < this.state.fromMonth) {
                        lValue += 12;
                    }

                    if (rValue < this.state.fromMonth) {
                        rValue += 12;
                    }
                }

                return lValue < rValue ? -1 : 0;
            });
    }

    formGroupClassName(valid) {
        return "form-group" + (!valid ? " has-error" : "");
    }

    maxLengthCheck = (object) => {
        if (object.target.value > this.state.maxDays) {
            object.target.value = this.state.maxDays
        }
        if (object.target.value < 0) {
            object.target.value = 0
        }
    }

    render() {
        let monthsInvalid = this.props.validate && typeof(this.state.fromMonth) != "number";
        let typeInvalid = this.props.validate && typeof(this.state.type) != "number";
        let daysInvalid = this.props.validate && this.state.type === 0 && typeof(this.state.days) != "number";
        let weeksInvalid = this.props.validate && this.state.type === 1 && typeof(this.state.weeks) != "number";
        let fullDayInvalid = this.props.validate && this.state.type === 0 && typeof(this.state.fullDay) != "boolean";
        let weekInvalid = this.props.validate && false;
        let timeInvalid = this.props.validate && false;
        let blockOfDaysInvalid = this.props.validate && this.state.type === 2 && (this.state.blockOfDays === "" || isNaN(this.state.blockOfDays) || parseInt(this.state.blockOfDays) <= 0);

        return (
            <div>
                <div className={this.formGroupClassName(!monthsInvalid)}>
                    <div className="small-16 large-16" style={{paddingBottom: "15px"}}>
                        <div>
                            <label className="small-16 large-4 control-label">I am currently looking for work </label>
                            <input name="IsLookingForWork" id="IsLookingForWork" className="" type="checkbox" style={{marginTop: "10px", marginLeft: "12px"}} checked={!!this.state.isLookingForWork} onChange={this.onChangeLookingForWork}/>
                            <a className="btn btn-basic large-10" style={{float:"right"}} role="Button" onClick={this.onDeleteItem}><span className="fn-icon fn-icon-delete-sign-lg fn-icon-hover-primary"></span></a>
                        </div>
                    </div>
                    <label className="small-16 large-4 columns control-label" htmlFor="FromMonth">Available from <abbr className="req" title="required">*</abbr></label>
                    <div className="small-16 large-6 columns inline-fields">
                        <div className="large-7 columns no-padding">
                            <select name="FromMonth" id="FromMonth" className="form-control" onChange={this.onChangeFromMonth}>
                                <option value="">Please select</option>
                                { this.fromMonths().map((month) => {
                                    return <option value={month.value} selected={this.state.fromMonth == month.value}>{month.label}</option>

                                })}
                            </select>
                        </div>
                        <label className="small-16 large-1 columns control-label" htmlFor="EndMonth">To</label>
                        <div className="large-7 columns no-padding">
                            <select name="EndMonth" id="EndMonth" className="form-control" onChange={this.onChangeToMonth} disabled={typeof(this.state.fromMonth) != "number"}>
                                <option value="">Please select</option>
                                { this.toMonths().map((month, index) => {
                                    return <option value={month.value} selected={this.state.toMonth == month.value}>{month.label}</option>

                                })}
                            </select>
                        </div>
                    </div>
                    <div className="small-16 large-2 columns end">
                        <div className="form-control-static">
                            <span className="italicized">{ this.state.selectedMonths.size } month{ this.state.selectedMonths.size != 1 && "s" }</span>
                        </div>
                    </div>
                </div>
                <div className={this.formGroupClassName(!typeInvalid)}>
                    <label className="small-16 large-4 columns control-label" htmlFor="Type">I'm available for <abbr className="req" title="required">*</abbr></label>
                    <div className="small-16 large-6 columns end">
                        <select name="Type" id="Type" className="form-control" onChange={this.onChangeType}>
                            <option value="">Please select</option>
                            <option value="0" selected={this.state.type === 0}>Days per week</option>
                            <option value="1" selected={this.state.type === 1}>Weeks per month</option>
                            <option value="2" selected={this.state.type === 2}>Block of days</option>
                        </select>
                    </div>
                    { this.state.type === 1 &&
                        <div className="italicized">
                            Each week = 5 days full time Mon-Fri, Sat-Sun, 07:00-18:00, after 18:00
                        </div>
                    }
                </div>
                { this.state.type === 0 &&
                    <div>
                        <div className={this.formGroupClassName(!daysInvalid)}>
                            <label className="small-16 large-4 columns control-label" htmlFor={this.state.id + "-days"}>How many days per week <abbr className="req" title="required">*</abbr></label>
                            <div className="small-16 large-9 columns end">
                                <div className="input-height">
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-days"} value="1" checked={this.state.days == 1} onChange={this.onChangeDays} />1 day</label>
                                    </div>
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-days"} value="2" checked={this.state.days == 2} onChange={this.onChangeDays} />2 days</label>
                                    </div>
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-days"} value="3" checked={this.state.days == 3} onChange={this.onChangeDays} />3 days</label>
                                    </div>
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-days"} value="4" checked={this.state.days == 4} onChange={this.onChangeDays} />4 days</label>
                                    </div>
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-days"} value="5" checked={this.state.days == 5} onChange={this.onChangeDays} /><span className="tiny">5 days (full week)</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={this.formGroupClassName(!weekInvalid)}>
                            <label className="small-16 large-4 columns control-label">Days you're available</label>
                            <div className="small-16 large-9 columns end">
                                <div className="input-height">
                                    <div className="checkbox-inline">
                                        <label><input type="checkbox" checked={!!this.state.weekDays} onChange={this.onChangeWeekDays}/> Week days</label>
                                    </div>
                                    <div className="checkbox-inline">
                                        <label><input type="checkbox" checked={this.state.weekends} onChange={this.onChangeWeekends}/> Weekends</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={this.formGroupClassName(!fullDayInvalid)}>
                            <label className="small-16 large-4 columns control-label" htmlFor={this.state.id + "-fullDay"}>Shifts you're available <abbr className="req" title="required">*</abbr></label>
                            <div className="small-16 large-9 columns end">
                                <div className="input-height">
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-fullDay"} value="0" checked={this.state.fullDay === false} onChange={this.onChangeFullDay}/>Part days</label>
                                    </div>
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-fullDay"} value="1" checked={this.state.fullDay === true} onChange={this.onChangeFullDay}/>Full days</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={this.formGroupClassName(!timeInvalid)}>
                            <label className="small-16 large-4 columns control-label">Times you're available</label>
                            <div className="small-16 large-9 columns end">
                                <div className="input-height">
                                    <div className="checkbox-inline">
                                        <label><input type="checkbox" checked={!!this.state.dayTime} onChange={this.onChangeDayTime}/> 07:00 - 18:00</label>
                                    </div>
                                    <div className="checkbox-inline">
                                        <label><input type="checkbox" checked={!!this.state.afterHours} onChange={this.onChangeAfterHours}/> After 18:00</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                { this.state.type === 1 &&
                    <div>
                        <div className={this.formGroupClassName(!weeksInvalid)}>
                            <label className="small-16 large-4 columns control-label" htmlFor={this.state.id + "-weeks"}>How many weeks per month <abbr className="req" title="required">*</abbr></label>
                            <div className="small-16 large-9 columns end">
                                <div className="input-height">
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-weeks"} value="1" checked={this.state.weeks == 1} onChange={this.onChangeWeeks} />1 week</label>
                                    </div>
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-weeks"} value="2" checked={this.state.weeks == 2} onChange={this.onChangeWeeks} />2 weeks</label>
                                    </div>
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-weeks"} value="3" checked={this.state.weeks == 3} onChange={this.onChangeWeeks} />3 weeks</label>
                                    </div>
                                    <div className="radio-inline">
                                        <label><input type="radio" name={this.state.id + "-weeks"} value="4" checked={this.state.weeks == 4} onChange={this.onChangeWeeks} />4 weeks <span className="tiny">(full month)</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                { this.state.type == 2 &&
                    <div className={this.formGroupClassName(!blockOfDaysInvalid)}>
                        <label className="small-16 large-4 columns control-label" htmlFor={this.state.id + "-blockOfDays"}>How many days are you available for <abbr className="req" title="required">*</abbr></label>
                        <div className="small-16 large-2 columns end">
                            <input type="number" className="form-control" name={this.state.id + "-days"} min={1} max={this.state.maxDays} value={this.state.blockOfDays != 0 ? this.state.blockOfDays : 20} onChange={this.onChangeBlockOfDays} onInput={this.maxLengthCheck} />
                        </div>
                    </div>
                }
                <div className="small-16 large-16">
                    <label className="small-16 large-4 columns control-label">Comments </label>
                    <textarea className="small-16 large-6" style={{marginLeft:"5px", marginBottom: "20px"}} value={this.state.comments} rows="5" cols="40" onChange={this.onChangeComments} spellCheck="true"></textarea>
                </div>
            </div>
        )
    }
}

PatternOfService.defaultProps = {
    validate: false
};

PatternOfService.propTypes = {
    id:         PropTypes.string,
    validate:   PropTypes.bool,
    onChange:	PropTypes.func,
    onDelete:	PropTypes.func,
};

export default PatternOfService;
import React 				from 'react';
import Modal        		from '../../../core/components/modal/Modal';
import BtnClose     		from '../../../core/components/BtnClose';
import DateTimeUtils 		from '../../../core/utils/DateTimeUtils';
import AckStatus			from './AckStatus';
import PromptActions		from '../../prompt/actions/PromptActions';
import PostSvc              from '../../../services/PostSvc';


enum PostType {
    Group = 3,
    Event = 5,
    Job = 21,
    News = 22,
}
const maxTry = 5;

class PostAnalytics extends React.Component {

	constructor(props) {
		super(props);

        let postType = PostType.Event
        switch (this.props.post.type) {
            case 3:
                postType = PostType.Group
                break
            case 21:
                postType = PostType.Job
                break
            case 22:
                postType = PostType.News
                break
            default:
                postType = PostType.Event
                break
        }

		this.state = {
            active: false,            
            post: props.post,
            postType,
            isError: false,
            tryCount: 0
		};
    }

    componentDidMount() {
        this.refreshInfo()
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    clearTimer(){        
        if (this.timer) {
			clearInterval(this.timer);
            this.timer = null;
            this.setState({
                tryCount: 0
            })
		}
    }

    refreshInfo(e){
        const isGroupOrEvent = this.state.postType == PostType.Group || this.state.postType == PostType.Event
        //Refresh post info if modal opened and user has permission

        if (this.state.active && !this.state.isError && this.state.tryCount <= maxTry){
            PostSvc
				.getPostInfo(this.props.post.id)
                .promise
				.then((response) => {
                    if (response)
					{
                        let post = this.state.post;
                        if (isGroupOrEvent) {
                            post.numberOfReads = response.ReadCount;
                            post.acks.count = response.AckCount;
                            if (post.discussion?.totalComments) {
                                post.discussion.totalComments = response.CommentCount;
                            }
                            if (post.photos && response.Files) {
                                post.photos.forEach(photo => {
                                    for(var i =0; i < response.Files.length; i++) {
                                        if (photo.id == response.Files[i].FileId) {
                                            photo.allViews = response.Files[i].NumberOfAllViews,
                                            photo.thirtyDaysViews = response.Files[i].NumberOfLastThirtyDaysViews
                                        }
                                    }
                                })
                            }
                        } else {
                            post.numberOfReads = response.ReadCount;
                            this.setState({
                                post: post,
                                isError: false
                            });
                        }
                        this.setState({
						    post: post,
                            isError: false
					    });
                    } else {
                        this.setState((prevState, _) => ({
                            tryCount: prevState.tryCount + 1
                        }))
                    }
				})
				.catch(err => {
					PromptActions.displayFriendlyErrorPrompt(err);
					this.setState({
						isError: true
					});                    
				});
        }
    }

    _handleModalCloseRequest(event) {
        event.preventDefault();

        if (this.state.active) {
			this.setState({ active: false });
		}

        this.clearTimer();
    }

    _handleModalOpenRequest(event) {
        event.preventDefault();

        if (!this.state.active) {
			this.setState({ active: true }, () => this.refreshInfo(null));
		}

        if (!this.timer)
        {
            this.timer = setInterval( this.refreshInfo.bind(this), 3000);
        }
    }

    _renderSeconds(t) {
		if (t == null) return ''
		if (isNaN(t) || t == Infinity) return '-:--';
		t = Math.floor(t)
		t = Math.max(0, t)
		var h = Math.floor(t / 3600);
		var m = Math.floor(t / 60) % 60;
		var s = (t % 60);
		return (h ? h + ':' : '') + (h ? ('0' + m).slice(-2) : m) + ':' + ('0' + s).slice(-2);
	}

    _viewsTextForItem(item) : string[] {
        switch (item?.fileType?.toUpperCase()) {
            case 'AUDIO':
                return ['listens', 'Listens']
            case 'VIDEO':
                return ['plays', 'Plays']
            default:
                return ['views', 'Views']
        }
    }

    _modalView() {
        const postType = this.state.postType
        const isGroupOrEvent = postType == PostType.Group || postType == PostType.Event
        let targetPost = 'Group'
        switch (postType) {
            case PostType.Group:
                targetPost = 'Event'
                break
            case PostType.News:
            case PostType.Job:
                targetPost = 'Title'
                break
        }
        let postBody = this.state.post.body ? this.state.post.body : "N/A";
        if (postBody && postBody.length > 449) {
            postBody = postBody.slice(0, 449).trim() + "..."
        }
        if (this.state.post.photos) {
            var videos = this.state.post.photos;
        }
        return(
            <Modal onRequestsClose={this._handleModalCloseRequest.bind(this)} active={this.state.active} size='default'>
                <div className='modal-header'>
                    <BtnClose onClick={this._handleModalCloseRequest.bind(this)} />
                    <h2 className='modal-title'>Post Info</h2>
                </div>
                <div className={`modal-content padding-0`}>
                    <div className="row margin-0 d-flex row-to-colum" style={{height:'100%'}}>     
                        <div className="small-16 large-8 columns padding-4 bg-powder-blue">
                            <div className="stats-row">
                                <h4>Subject</h4>
                                <p>{this.state.post.subject ? this.state.post.subject : "N/A"}</p>
                            </div>
                            <div className="stats-row">
                                <h4>Posted on</h4>
                                <p>{this.state.post.dateCreated ? DateTimeUtils.stringFromDate(this.state.post.dateCreated, "D MMM YYYY HH:mm") : "N/A"}</p>
                            </div>
                            <div className="stats-row">
                                <h4>Posted by</h4>
                                <a href={this.state.post.author.url ? this.state.post.author.url : null}>{this.state.post.author.name ? this.state.post.author.name : "N/A"}</a>
                            </div>
                            <div className="stats-row">
                                <h4>{targetPost}</h4>
                                <a href={this.state.post.target.url ? this.state.post.target.url : null}>{this.state.post.target.name ? this.state.post.target.name : "N/A"}</a>
                            </div>
                            <div className="stats-row">
                                <p className="font-size-12" style={{wordBreak: 'break-all'}} dangerouslySetInnerHTML={{__html:postBody}}></p>
                            </div>
                        </div>
                        <div className="small-16 large-8 columns padding-4">
                            <div className="stats-row">
                                <h3>POST INFO</h3>
                            </div>
                            <div className="stats-row">
                                <h4>Opens</h4>
                                <p>{this.state.post.numberOfReads}</p>
                            </div>
                            { isGroupOrEvent && 
                                <div className="stats-row">
                                    <h4>ACKs</h4>
                                    <p>
                                        {
                                            this.state.post.acks && this.state.post.acks.canAck ?
                                            <AckStatus key={this.state.post.acks ? this.state.post.acks.count : -1} 
                                                acks={this.state.post.acks} itemId={this.state.post.id} itemType='Post' showAckers={true} showAckersCountOnly={true} />
                                            :this.state.post.acks?.count
                                        }
                                    </p>
                                </div>
                            }
                            { isGroupOrEvent && 
                                <div className="stats-row">
                                    <h4>Comments</h4>
                                    <p>{this.state.post.discussion?.totalComments ?? 0}</p>
                                </div>
                            }
                            { isGroupOrEvent && videos && videos.length > 0 ? this.showTitle() : null}
                            <div style={{flex: "1 1 auto", overflowY: 'auto', height: '180px'}}>
                            {
                                isGroupOrEvent && this.state.post.photos ?
                                    this.state.post.photos.map((value, index) => {
                                        const duration = this._renderSeconds(value.duration);
                                        const [_views, _Views] = this._viewsTextForItem(value)
                                        return ( (value.fileType == 'VIDEO' || value.fileType == 'IMAGE' || value.fileType == 'AUDIO') ? 
                                            <div className="stats-row padding-0" key={index}>
                                                <div className="split" style={{width: "90px", height: "90px"}}>
                                                    {(value.fileType == 'AUDIO') ? 
                                                        <div style={{position:'relative', width: "80px", height: "80px"}} className="icon-container">
                                                            <span className="file-icon icon-file-icon-audio" aria-hidden="true" />
                                                            { value.duration &&
                                                                <span className="analytics-duration">{duration}</span>
                                                            }
                                                        </div>
                                                        :
                                                        <div style={{position:'relative'}}>
                                                            <img src={`/Services/GetImage.ashx?mw=80&id=${value.fileType == "VIDEO" ? value.thumbnailFileId : value.id}`} style={{width: "80px", height: "80px"}} />
                                                            { value.duration &&
                                                                <span className="analytics-duration">{duration}</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className="split border-right-1">
                                                    <h4>{_Views} in last 30 days</h4>
                                                    <span className="view-count">{value.thirtyDaysViews == null ? "0" : value.thirtyDaysViews}</span>
                                                </div>
                                                <div className="split">
                                                    <h4>All {_views}</h4>
                                                    <span className="view-count">{value.allViews == null ? "0" : value.allViews}</span>
                                                </div>
                                            </div>
                                            : null
                                        )
                                    })
                                : null
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-actions">
                    <button role="button" className="btn btn-default" onClick={this._handleModalCloseRequest.bind(this)}>Close</button>
                </div>
            </Modal>
        );
    }

    //Hide the Media Engagement title if no media items are videos
    showTitle() {
        return (
                <div className="stats-row border-0">
                    <h4>Media engagement</h4>
                </div>
        )
    }


    render() {
		return (
            <>
                <a href="#" role="button" title="See post analytics" onClick={this._handleModalOpenRequest.bind(this)}><span className="fn-icon fn-icon-primary fn-analytics-info theme-background"></span><span className="sr-only">Info</span></a>
                {this.state.active ? this._modalView() : null} 
            </>
		);
	}
}

export default PostAnalytics;

import React, {
    FC,
    MouseEventHandler,
} from "react";
import _ from 'lodash';

import { FileCategory } from "../../modules/fileexplorer/actions/FileExplorerActions";
import FilesLinksUtils from "../utils/FilesLinksUtils";

interface ILinkedFilesFolders {
    links?: any[],
    onRemoveLink?: (link, e) => MouseEventHandler<HTMLAnchorElement>,
    hideRemoveLink?: boolean,
}

export const LinkedFilesFolders: FC<ILinkedFilesFolders> = (props: ILinkedFilesFolders) => {

    return (
        <div className="create-post__links">
            {_.map(props.links, (link, i: number) => {
                let linkType;

                if (link.FileId) {
                    linkType = "file";
                } else if (link.Category == FileCategory.MEDIA) {
                    linkType = "album";
                } else {
                    linkType = "folder";
                }

                return <div key={link.FileId || link.FolderId} className={"post-link-item " + (linkType === "File" ? FilesLinksUtils.renderFileClass(link.FileType) : "link-type_" + linkType)}>
                    <p><span className={`fn-icon fn-icon-${linkType}`}></span>
                        <span className="title"> {link.Title} </span>
                        { ! props.hideRemoveLink &&
                            <a href="#" className="fn-icon fn-icon-delete" onClick={() => props.onRemoveLink(link, this)}>
                            <span className="sr-only">Remove</span></a>
                        }
                        </p>
                    <p>{link.Description}</p>
                </div>;
            })}

        </div>
    );
}

export default LinkedFilesFolders;
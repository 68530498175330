
import React from "react";
import _ from "lodash";
import C3Chart from "../../../C3Chart";
import GroupReportActions from "../actions/GroupReportActions";
import GroupReportStore from "../stores/GroupReportStore";
import { GroupReportState } from "../stores/GroupReportStore";
import { GroupReport } from "../models/GroupReport";
import GroupReportChartCompare from "../components/GroupReportChartCompare";
import "./compare.css";


class GroupReportChart extends React.Component {

  _storeChangeBinding: () => void;
  state: any;

  componentDidMount() {
    const report = this.props.reportData;
    report.left = null;
    report.right = null;
    this.setState(report);
  }

  componentWillReceiveProps(newProps) {
    const left = this.state != null ? this.state.left : null;
    const right = this.state != null ? this.state.right : null;
    this.setState({
      series: {
        'x': ['x'].concat(newProps.reportData.Dates),
        'Posts': ['Posts'].concat(newProps.reportData.Posts),
        'Comments': ['Comments'].concat(newProps.reportData.Comments),
        'Acks': ['Acks'].concat(newProps.reportData.Acks),
        'Events': ['Events'].concat(newProps.reportData.Events),
        'File downloads': ['File downloads'].concat(newProps.reportData.FileDownloads),
        'File uploads': ['File uploads'].concat(newProps.reportData.FileUploads),
        'Members': ['Members'].concat(newProps.reportData.Members),
        'Join requests': ['Join requests'].concat(newProps.reportData.JoinRequests),
        'Page views': ['Page views'].concat(newProps.reportData.PageViews)
      },
      left: left,
      right: right
    });
  }

  updateChartSeries(axes) {
    let state = this.state;
    state.left = axes.left;
    state.right = axes.right;
    this.setState(state);
  }

  setState(state) {
    let result = {
      series: state.series,
      left: state.left,
      right: state.right,
      data: {
        x: 'x',
        columns: [],
        axes: {}
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d'
          }
        },
        y: {
          show: false,
          min: 0,
          padding: { bottom: 0 },
          label: {
            text: 'Posts',
            position: 'outer-middle'
          }
        },
        y2: {
          show: false,
          label: {
            text: 'None',
            position: 'outer-middle'
          },
          min: 0,
          padding: { bottom: 0 }
        }
      }
    };

    if (result.left != 'None' && result.series != null && result.series[result.left] != null) {
      result.axis.y.show = true;
      result.axis.y.label.text = state.left;
      result.data.axes[state.left] = 'y';
      result.data.columns.push(result.series['x']);
      result.data.columns.push(result.series[result.left]);
    }
    if (result.right != 'None' && result.series != null && result.series[result.right] != null) {
      result.axis.y2.show = true;
      result.axis.y2.label.text = this.state.right;
      result.data.axes[result.right] = 'y2';
      result.data.columns.push(result.series[result.right]);
    }

    super.setState(result);
  }

  render() {
    if (null == this.state) return null;
    return <div className="panel">
                <div className="panel-body">
                    <h2>Compare</h2>
                    <p>
                    Compare specific statistics with others to find out if there are any useful patterns.
                    </p>
                    <GroupReportChartCompare onChange={axes => this.updateChartSeries(axes)} />
                </div>
                {this.state.left == null ? null : <div className="panel-body">
                        <C3Chart data={this.state.data} axis={this.state.axis} />
                    </div>}
			</div>;
  }
}

export default GroupReportChart;
import Promise						from 'bluebird';

import Action						from '../../../core/models/Action';
import AppDispatcher				from '../../../core/dispatcher/AppDispatcher';
import FileExplorerConstants		from '../constants/FileExplorerConstants';
import FolderSvc, {FolderSortOrder} from '../../../services/FolderSvc';
import {Enum} 						from '../../../core/utils/LanguageUtils';
import APIError						from '../../../core/models/APIError';

export const FileExplorerSort = Enum.create({

	FILENAME_AZ: 0,

	FILENAME_ZA: 1,

	MOST_RECENT: 2,

	OLDEST_FIRST: 3
});

export const FileExplorerType = Enum.create({

	EVENT: 'Event',

	GROUP: 'Group'

});

export const FileCategory = Enum.create({
	DOCUMENT: 'Document',
	MEDIA: 'Media'
});

export const FileCategoryType = Enum.create({
	DOCUMENT: 0,
	MEDIA: 1
});

export const FileType = Enum.create({
	UNKNOWN: 0,
	WORD: 1,
	EXCEL: 2,
	PDF: 3,
	POWER_POINT: 4,
	IMAGE: 5,
	CSV: 6,
	VIDEO: 7,
	URL: 8,
	AUDIO: 9
});

var loadReq = null;

class FileExplorerActions {

	/**
	 * Loads a folders files with filtering
	 * @param {int}								id			- The folder id
	 * @param {FileExplorerType|string}			type		- The folder type
	 * @param {FileCategory|string}				category	- The file category
	 * @param {string|null}						query		- Filter files that match this or null for all
	 * @param {int}								page		- The page number to load
	 * @param {FileExplorerSort|int}			sortBy		- The sort order of the results
	 */
	static loadFolder(id, type, category, folderId = '00000000-0000-0000-0000-000000000000', viewUnarchivable = false, query = null, page = 1, sortBy = FileExplorerSort.MOST_RECENT) {

		let sort,
			desc = false;

		switch(sortBy){
			case FileExplorerSort.FILENAME_AZ:
				sort = FolderSortOrder.FILENAME;
				desc = false;
				break;

			case FileExplorerSort.FILENAME_ZA:
				sort = FolderSortOrder.FILENAME;
				desc = true;
				break;

			case FileExplorerSort.MOST_RECENT:
				sort = FolderSortOrder.UPLOADEDDATE;
				desc = true;
				break;

			default:
			case FileExplorerSort.OLDEST_FIRST:
				sort = FolderSortOrder.UPLOADEDDATE;
				desc = false;
				break;
		}

		if(loadReq && loadReq.abort) {
			loadReq.abort();
		}


		return new Promise((resolve, reject) => {

			loadReq = FolderSvc.find(id, type, category, folderId, viewUnarchivable, query, {
				page: page,
				sortDesc: desc,
				sortBy: sort
			});

			loadReq.promise
					.then((response) => {

						response.category = category;
						AppDispatcher.dispatch(new Action(FileExplorerConstants.FOLDER_LOADED, response));

						loadReq = null;

						resolve();
					})
					.catch(() => {
						loadReq = null;
						reject();
					});

		});


	}

	/**
	 * Un-links a file
	 * @param {File}			file				- The file to unlink
	 * @param {string|number}	relatedItemId		- The related id (eg Group or Event id)
	 * @param {FileCategory|string}				category	- The file category
	 * @param {string}			relatedItemType		- The related type (eg Group or Event)
	 * @param {boolean}			canUnarchive		- Set to true if can be unarchived
	 * @returns {Promise}
	 */
	static unlinkFile(file, relatedItemId, relatedItemType, category, canUnarchive, isArchived) {

		return new Promise((resolve, reject) => {

			FolderSvc.unLink(file.id, relatedItemId, relatedItemType, canUnarchive).promise.then(() => {
				file.category = category;
				file.canUnarchive = canUnarchive;
				file.isArchived = isArchived;
				resolve(new Action(FileExplorerConstants.FILE_UNLINKED, file));
			}).catch(error => {
				reject(error);
			});

		});
	}

	static updateFile(selectedFolderId, file, relatedItemId, relatedItemType, category, title, description, folderId, parentFolderId, folderTitle, replaceFromFileId, viewUnarchivable, isAccessRestricted, accessUsers)
	{
		return new Promise((resolve, reject) => {

			FolderSvc.update(selectedFolderId, file.id, relatedItemId, relatedItemType, category, title, description, folderId, parentFolderId, folderTitle, replaceFromFileId, viewUnarchivable, isAccessRestricted, accessUsers).promise.then((response) => {
				if (!folderTitle)
				{
					file.title = title;
					file.description = description;
					file.folderId = folderId;
					file.category = category;
					file.isUnarchive = viewUnarchivable;
					file.isAccessRestricted = isAccessRestricted;
					resolve(new Action(FileExplorerConstants.FILE_UPDATED, file));
				}
				else
				{
					response.category = category;
					resolve(new Action(FileExplorerConstants.FOLDER_LOADED, response));
				}
			}).catch(error => {
				reject(error);
			});

		});
	}

	static createFolder(folderId, relatedItemId, relatedItemType, category, parentFolderId, name) {

		return new Promise((resolve, reject) => {

			loadReq = FolderSvc.createFolder(folderId, relatedItemId, relatedItemType, category, parentFolderId, name);

			loadReq.promise
					.then((response) => {
						response.category = category;
						AppDispatcher.dispatch(new Action(FileExplorerConstants.FOLDER_LOADED, response));

						loadReq = null;

						resolve();
					})
					.catch(error => {
						loadReq = null;
						reject(error);
					});

		});


	}

    static unlinkFolder(selectedfolderId, relatedItemId, relatedItemType, category, folderId, canUnarchive) {

		return new Promise((resolve, reject) => {

			loadReq = FolderSvc.unlinkFolder(selectedfolderId, relatedItemId, relatedItemType, category, folderId, canUnarchive);

			loadReq.promise
					.then((response) => {

						response.category = category;
						AppDispatcher.dispatch(new Action(FileExplorerConstants.FOLDER_LOADED, response));

						loadReq = null;
						resolve(response.folderId);
					})
					.catch(() => {
						loadReq = null;
						reject();
					});

		});


	}

	static updateFolder(selectedfolderId, relatedItemId, relatedItemType, category, folderId, parentFolderId, title, isAccessRestricted, accessUsers)
	{
		return new Promise((resolve, reject) => {

			FolderSvc.updateFolder(selectedfolderId, relatedItemId, relatedItemType, category, folderId, parentFolderId, title, isAccessRestricted, accessUsers).promise.then((response) => {
				response.category = category;
				resolve(new Action(FileExplorerConstants.FOLDER_LOADED, response));
			}).catch(error => {
				reject(error);
			});

		});
	}

	/**	
	 * Verifies a file is virus free
	 * @param {File}			file				- The file to unlink
	 * @returns {Promise}
	 */
	static verifyFileVirusFree(file)
	{
		return new Promise((resolve, reject) => {
			FolderSvc.verifyFileVirusFree(file.id).promise.then((response) => {
				resolve(new Action(FileExplorerConstants.FILE_DOWNLOADFILE, { file: file, url: response }));
			}).catch(error => {
				if (error.virusName)
				{
					AppDispatcher.dispatch(new Action(FileExplorerConstants.FILE_VIRUSFOUND, { file: file, error: error }));
				}
				reject(new APIError(error.message));
			});
		});
	}

	static uploadFileUrl(url, title, folderId, id, type, category, description, thumbnailUrl, notify) {
		return new Promise((resolve, reject) => {
			FolderSvc.uploadFileUrl(url, title, folderId, type, id, category, thumbnailUrl, description, notify).promise.then(() => {
				resolve();
			}).catch(error => {
				reject(error);
			});

		});
	}

	static sendFileUploadNotification(fileIds, relatedItemId, relatedItemType)
	{
		return new Promise((resolve, reject) => {
			FolderSvc.sendFileUploadNotification(fileIds, relatedItemId, relatedItemType).promise.then((response) => {
				resolve();
			}).catch(error => {
				reject(error);
			});

		});
	}

	static videoStreamUrl(id) {
		return new Promise((resolve, reject) => {
			FolderSvc.videoStreamUrl(id).promise.then((response) => {
				resolve(response.StreamUrl);
			}).catch(error => {
				reject(error);
			});

		});
	}

	/**
	 * clearAllUnarchivable
	 * @param {string}					relatedItemType		- The related type (eg Group or Event)
	 * @param {string|number}			relatedItemId		- The related id (eg Group or Event id)
	 * @param {FileCategory|string}		category			- The file category
	 * @returns {Promise}
	 */
	static clearAllUnarchivable(relatedItemType, relatedItemId, category) {
		return new Promise((resolve, reject) => {
			FolderSvc.clearAllUnarchivable(relatedItemId, relatedItemType, category).promise.then(() => {
				resolve();
			}).catch(error => {
				reject(error);
			});

		});
	}}

export default FileExplorerActions;

import React 				from 'react';
import moment               from "moment";

interface Props {
    id: string;
    title: string;
    checked: boolean;
    onToggle: Function;
    fileType: string;
    dateUploaded: string;
}

interface State {
}

export default class BtnUploadFile extends React.Component<Props, State> {
	constructor(props) {
        super(props);

        this.state = {
        };
    }

    _onClick = () => {
        this.props?.onToggle(this.props.id, this.props.fileType, this.props.title, this.props.dateUploaded);
    }

    render() {
        return <div className="folder-file"><label><input type="checkbox" onClick={ this._onClick } defaultChecked={this.props.checked} /> { this.props.title }</label></div>
    }
}

import Promise						from 'bluebird';
import Action						from '../../../core/models/Action';
import AppDispatcher				from '../../../core/dispatcher/AppDispatcher';
import CrawlerConstants				from '../constants/CrawlerConstants';
import CrawlerSvc					from '../../../services/CrawlerSvc';
import APIError						from '../../../core/models/APIError';

class CrawlerActions {
	static crawl(url) {		
		return new Promise((resolve, reject) => {
			let matches = url.match(CrawlerActions._extract);
			let extract = null;
	
			var id = matches !== null ? matches[3] : null;

			if (id) {
				CrawlerSvc.crawlDefenceVideo(id).promise.then((response) => {
					AppDispatcher.dispatch(new Action(CrawlerConstants.CRAWLER_CRAWLED, response));
				}).catch(error => {
				}).finally(err => {
					resolve();
				});
			}
			else {
				resolve();
			}
		});
	}

	static reset() {
		AppDispatcher.dispatch(new Action(CrawlerConstants.CRAWLER_RESET));
	}
}

CrawlerActions._extract = new RegExp("^(http[s]?://)?video.(defence|navy|army|airforce).gov.au/play/([^#]*).*");

export default CrawlerActions;

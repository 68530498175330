import React, {
    FC,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";

import _	from 'lodash';

import {
    AccountStatus,
    SearchQueryInputType,
    SearchType,
    PermanentUserType,
    performSearchVariables,
    performSearch_search_UsersResult,
    performSearch_search_UsersResult_users,
} from "../../../../types/graphqlTypes";

import Localisation		from "../../../core/resources/Localisation";
import PromptActions from "../../prompt/actions/PromptActions";
import { useLazyQuery } from "@apollo/client";
import SvgIcon from '../../../icons';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const performSearchGql = require("../../../../gql/performSearch.gql");

interface IUserSelectorProps {
    selectedUsers: Array<any>,
    groupId: string | null,
    eventId: number | null,
    onEmit: any,
    label: string,
    placeholder: string
}

export const UserSelector : FC<IUserSelectorProps> = (props: IUserSelectorProps) => {
    const keywordRef = useRef<HTMLInputElement>(null);
    const [search, { loading, data, error, networkStatus, fetchMore }] = useLazyQuery(performSearchGql);
    const [selectedUsers, setSelectedUsers ] = useState(props.selectedUsers);
    const searchResult: performSearch_search_UsersResult | null = keywordRef?.current?.value ? data?.search : null;
    const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
    const thisRef = useRef();

    const performSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const keywords = e?.target?.value;

        if (keywords) {
            const query: SearchQueryInputType = {
                queryType: SearchType.RECIPIENT,
                excludeIds: selectedUsers.map(i => i.id),
                userQuery: {
                    group: props.groupId,
                    event: props.eventId,
                    firstOrLastName: keywords,
                    notAccountStatus: AccountStatus.DEACTIVATED
                },
            };
            const page = null;
            const variables: performSearchVariables = {
                query,
                page
            };

            search({
                variables
            });
        }
    }, 500);

    const loadMore = () => {
        fetchMore({
            variables: {
                page: {
                    pageAfter: searchResult.pageInfo.endCursor
                },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult || !fetchMoreResult.search || (fetchMoreResult.search.users && fetchMoreResult.search.users.length === 0)) {
                    return prev;
                }

                return Object.assign({}, prev, {
                    search: {
                        __typename: fetchMoreResult.search.__typename,
                        pageInfo: fetchMoreResult.search.pageInfo,
                        users: [...prev.search.users, ...fetchMoreResult.search.users]
                    }
                })
            },
        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);
        });
    }

    const onClose = useCallback((event) => {
        const localNode = thisRef.current;
        let source = event.target;

        while (source.parentNode) {
            if (source === localNode) {
                return;
            }
            source = source.parentNode;
        }

        const keywords = keywordRef.current.value;
        
        keywordRef.current.value = "";

        if (keywords) {
            forceUpdate();
            event.stopPropagation();
        }
    }, []);

    const keyUp = ((event) => {
        if (event.keyCode === 27) {
            const keywords = keywordRef.current.value;
            
            keywordRef.current.value = "";
    
            if (keywords) {
                forceUpdate();
                event.stopPropagation();
            }
        } else if (!keywordRef.current.value) {
            forceUpdate();
        }
    });

    const emit = () => {
        props?.onEmit(selectedUsers);
    }

    useEffect(() => {
        window.document.addEventListener("mousedown", onClose)
    
        return function cleanup() {
            window.document.removeEventListener("mousedown", onClose)
        }
    })
      
    const hasNextPage = searchResult?.pageInfo?.hasNextPage == true;

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        // When there is an error, we stop infinite loading.
        // It can be reactivated by setting "error" state as undefined.
        disabled: !!error,
        // `rootMargin` is passed to `IntersectionObserver`.
        // We can use it to trigger 'onLoadMore' when the sentry comes near to become
        // visible, instead of becoming fully visible on the screen.
        rootMargin: '0px 0px 400px 0px',
    });

    const addUser = (event, user) => {
        event.stopPropagation();

        setSelectedUsers([...selectedUsers, ...[user]].sort((l, r) => l.name < r.name ? -1 : 0));
        keywordRef?.current?.focus();
    }

    const deleteUser = (event, id) => {
        event.preventDefault();
        setSelectedUsers(selectedUsers.filter(g => g.id != id));
        blur();
    }

    const renderPermanentUserType = (permanentUserType: PermanentUserType | null) => {
        switch (permanentUserType)
        {
        case PermanentUserType.CONTRACTOR:
            return " (CONTR)";
        case PermanentUserType.OTHER_GOV:
            return " (OTHER)";
        case PermanentUserType.FORCE_NET_SUPPORT:
            return " (SUPPORT)";
        default:
            return "";
        }
    }
    
    const renderUser = (user: performSearch_search_UsersResult_users) => {
        return <div key={user.id}>{user.fullName}{renderPermanentUserType(user.permanentUserType)}</div>
    }

    useEffect(emit, [selectedUsers]);

    const foundUsers = searchResult?.users?.filter(g => !selectedUsers.some(s => s.id == g.id));

    return <div ref={thisRef}>
        <div className="search">
            <input ref={keywordRef} type="text" className="form-control" placeholder={props.placeholder} onChange={performSearch} onKeyUp={keyUp}></input>
            { loading && foundUsers == null && <div className="loading">Loading…</div> }
            { !loading && foundUsers != null && foundUsers.length == 0 && 
                <div className="noResults">
                    <strong>No Results</strong><br/>
                    Your search did not find any results
                </div> }
            { error && <div>{ Localisation.localisedStringFor('ErrorGenericMsg') }</div>}
            {
                foundUsers != null
                    ? <div className="searchResults" ref={ rootRef }>
                        {
                            foundUsers.map(g => {
                                return (
                                    <div key={ g.id } className="searchResult" onClick={(event) => addUser(event, g)}>
                                        { renderUser(g) }
                                    </div>
                                );
                            })
                        }
                        {
                            loading || hasNextPage && <div ref={sentryRef} key="loadMore" className="searchResult">
                                Loading...
                            </div>

                        }
                    </div>
                    : null
            }
        </div>
        <div className="selected-users-label">{props.label}:</div>
        <div className="selected-users">
            { selectedUsers?.map(g => {
                return (
                    <div key={ g.id } className="selected-user">
                        <span className="tools"><a href="#" onClick={(event) => deleteUser(event, g.id)}><SvgIcon icon="icon-cross" width={16} height={16}/></a></span>
                        { renderUser(g) }
                    </div>
                );
            })
            }
        </div>
        { !selectedUsers || selectedUsers.length == 0 && <div>None</div> }
    </div>
}

import _						from 'lodash';

import AppDelegate				from '../core/AppDelegate';
import HttpAuthenticatedSvc		from './HttpAuthenticatedSvc';
import Group					from '../core/models/Group';


/**
 * Group Service
 */
class GroupSvc {

	/**
	 * Find
	 * Query user service to find groups based on criteria
	 *
	 * @param {string} 		query 										- A portion of the group name to search for.
	 * @param {object} 		[options]									- Filter options
	 * @param {string} 		[options.includeHiddenGroups]				- Default value is false. If true, includes hidden groups in the result set.
	 * @param {string} 		[options.includeAllMembersOnlyGroups]		- Default value is false. If true, includes MembersOnlyGroups in the result set.
	 * @returns {{promise: Promise, abort: Function}}
	 */
	find (query, options) {

		var req,
			endpoint = `${AppDelegate.appSettings.apiUrl}/GroupsService.asmx/FindGroups`,
			payload = {
				q: query,
				options: null
			};

			if(options) {
				payload.options = {
					IncludeHiddenGroups: 			options.includeHiddenGroups || false,
					IncludeAllMembersOnlyGroups:	options.includeAllMembersOnlyGroups || false,
					FilterCategories:               options.filterCategories || [],
					GroupTypes:						options.groupTypes || [],
					Services:						options.services,
					HasMemberOnly:					options.hasMemberOnly,
					SearchWordsOnNameOnly:			options.searchWordsOnNameOnly,
					CommanderOnly:					options.commanderOnly,
				};
			}

			let promise = new Promise((resolve, reject) => {

				req = HttpAuthenticatedSvc.post(endpoint, payload, function (response) {

					// Map the results into an array of class Group
					let groups = _.map(response, (g) => {return Group.GroupFromObject(g);});

					// return the results
					resolve(groups);

				}, function (err) {
					reject({reason: err.message});
				});

			});

		return {
			promise: promise,
			abort: () => {
				req.abort();
			}
		};
	}

	loadReport(groupId, startDate, endDate)
	{
		var req,
			endpoint = `${AppDelegate.appSettings.apiUrl}/GroupsService.asmx/GroupReport`,
			payload = {
				groupId: groupId,
				startDate: startDate,
				endDate: endDate,
			};

			let promise = new Promise((resolve, reject) => {

				req = HttpAuthenticatedSvc.post(endpoint, payload, function (response) {

					// return the results without translation
					resolve(response);

				}, function (err) {
					reject({reason: err.message});
				});

			});

		return {
			promise: promise,
			abort: () => {
				req.abort();
			}
		};

	}
}

export default new GroupSvc();

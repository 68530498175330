import React from "react"
import { Button, Conversation, Loader, Mailbox } from "./Components"

let test = []

class InboxPanel extends React.Component {
    constructor(props) {
        super(props)
    }

    _onScroll(e) {
        if (!this.props.loading && this.props.hasMoreConversations && e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
            this.props._more()
        }
    }

    _renderConversations() {
        if (!this.props.conversations) return null
        return this.props.conversations.map((item, i, arr) => {
            let type =
                this.props.selected && this.props.selected.Id == item.Id
                    ? `${item.type} active`
                    : item.type
            type += item.IsArchived ? " archived" : ""

            let unread =
                this.props.selected && this.props.selected.Id == item.Id ? 0 : item.NewCount

            return (
                <Conversation
                    _archive={this.props._archive}
                    _open={this.props._open}
                    archived={item.IsArchived}
                    content={item.Subject}
                    key={item.Id}
                    id={item.Id}
                    image={item.ImageUrl}
                    important={item.IsImportant}
                    item={item}
                    people={[...item.OtherParticipants, ...item.HiddenParticipants]}
                    subject={item.Subject}
                    type={type}
                    unread={unread}
                    draft={item.isDraft}
                />
            )
        })
    }

    render() {
        return (
            <div className={`columns small-16 large-7 ${ this.props.hideMobile ? "slid-left" : ""}`} id="msgInbox">
                <div className="panel sideBar">
                    <div className="header">
                        <h1>
                            <span className="title">Messages</span>
                        </h1>
                        { this.props.canSendMessage && 
                        <Button
                            action={this.props._toggle}
                            primaryClass="btn-new-message"
                            btnClass="newMessage"
                            btnText="New message"
                        />
                        }
                    </div>
                    <Mailbox
                        _getSearch={this.props._getSearch}
                        _selectMailBox={this.props._selectMailBox}
                        loading={this.props.loadingMail}
                        mailbox={!this.props.searchText ? this.props.mailbox : "search"}
                        unread={this.props.unread}
                        important={this.props.important}
                        archived={this.props.archived}
                        draft={this.props.draft}
                        searchText={this.props.searchText}
                    />

                    {this.props.searchText ? (
                        <div
                            id="mailbox-search-txt"
                            className="mailbox-search-results-text"
                            style={{ display: "block" }}>
                            Showing search results for '{this.props.searchText}' for all messages
                        </div>
                    ) : null}

                    <div
                        id="friendsList"
                        className="messageFriends"
                        style={{ height: "642px" }}
                        onScroll={this._onScroll.bind(this)}>
                        {!this.props.loading ? (
                            <span>
                                <div className="messages-all">
                                    <ul id="conversationItems" className="page posts">
                                        {this._renderConversations()}
                                    </ul>
                                </div>
                                <div
                                    id="noConversationsMsg"
                                    style={{
                                        display: `${
                                            this.props.conversations &&
                                            this.props.conversations.length === 0
                                                ? "block"
                                                : "none"
                                        }`,
                                    }}>
                                    Folder Empty
                                </div>
                            </span>
                        ) : (
                            <Loader show={this.props.loading} />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

InboxPanel.defaultProps = {
    hideMobile: false
}

export default InboxPanel


import React from "react";
import _ from "lodash";
import GroupReportHeatmap from "./GroupReportHeatmap";
import PieChart from "./PieChart";
import "./significantactivity.css";

class GroupReportSignificantActivity extends React.Component {

  renderMostPopular(post: PostCommentActivity | null | undefined) {
    if (post == null) return null;
    return <div className="significant-panel">
                <div>
                    <h3>Most popular post</h3>
                    <span className='info'>{post.Date} | {post.AckCount} ACKs | {post.CommentCount} Comments</span>
                    <br />
                    {post.Message}
                </div>
            </div>;
  }

  renderMostActivity(post: PostCommentActivity | null | undefined) {
    if (post == null) return null;
    return <div className="significant-panel">
                <div>
                    <h3>Active discussion</h3>
                    <span className='info'>{post.Date} | {post.CommentCount} Comments | {post.CommentAckCount} Comment ACKs</span>
                    <br />
                    {post.Message}
                </div>
            </div>;
  }

  renderJoinRequests(joins: PageViewDetail | null | undefined) {
    if (joins == null) return null;
    return <div className="significant-panel">
                <div>
                    <h3>Join requests</h3>
                    <span className='info'>{joins.Title}</span>
                    <br />
                    +{joins.Count} join requests
                </div>
            </div>;
  }

  render() {
    if (this.props.reportData == null) return null;

    const any_activity = (this.props.reportData.MostPopularPost != null || this.props.reportData.MostJoinRequests != null || this.props.reportData.MostActivePost != null);

    return <div className="panel">
                <div className="panel-body report-significant">
                    <h2>Significant activity</h2>
                    {any_activity ? <p>
                        The activity that had the largest impact during this period.
                        </p> : <p>
                        No significant activity during this period.
                        </p>}
                    {any_activity ? <div className="significant-panels row">
                            {this.renderMostPopular(this.props.reportData.MostPopularPost)}
                            {this.renderJoinRequests(this.props.reportData.MostJoinRequests)}
                            {this.renderMostActivity(this.props.reportData.MostActivePost)}
                        </div> : null}
                </div>
			</div>;
  }
}

export default GroupReportSignificantActivity;
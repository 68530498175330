import React, {
    FC,
    useState,
    useRef,
    useEffect,
} from "react";
import _ from 'lodash';
import SvgIcon from '../../../icons';
import {
    useMutation,
} from "@apollo/client";

import { TopicControls } from "././TopicControls";

const forumsGql = require('../../../../gql/forums.gql');

import {
    forumResult_topics,
    FORUM_UPDATE_TOPIC_STATEVariables,
} from "../../../../types/graphqlTypes";

import PromptActions from "../../prompt/actions/PromptActions";

interface TopicAdminProps {
    forumId: string,
    topicResults: forumResult_topics[],
    updateTopicResults: (e) => void,
}

export const TopicAdmin: FC<TopicAdminProps> = (props: TopicAdminProps) => {
    const [updateForumTopicState] = useMutation(forumsGql.FORUM_UPDATE_TOPIC_STATE);
    const topicControls = useRef(null);
    const [showTopicControls, setShowTopicControls] = useState(false);
    const onClickTopicHeader = () => setShowTopicControls(!showTopicControls);
    const [showAddTopic, setShowAddTopic] = useState(false);
    const [enableTopicDeletion, setEnableTopicDeletion] = useState(false);
    const addTopicToggle = useRef(null);
    const addTopicRef = useRef(null);
    const topicNameInput = useRef(null);
    const [newTopicTitle, setNewTopicTitle] = useState("");

    const updateEnableTopicDeletion = () => {
        var enabledTopics = props.topicResults?.filter(topicResult => topicResult.topic?.isEnabled);

        if (enabledTopics && enabledTopics.length > 1) {
            // enable topic deletion
            setEnableTopicDeletion(true);
        } else {
            setEnableTopicDeletion(false);
        }
    }

    useEffect(() => {
        if (showAddTopic && topicNameInput.current) {
            // focus input
            topicNameInput.current.focus();
        }
    }, [showAddTopic]);

    useEffect(() => {
        updateEnableTopicDeletion();
    }, [props.topicResults]);

    const onAddTopic = () => {
        setShowAddTopic(!showAddTopic);
    }

    const onChangeTitle = (e) => {
        setNewTopicTitle(e.target.value);
    }

    const saveNewTopic = (e) => {
        e.preventDefault();
        addTopic();
        setShowAddTopic(false);
        setNewTopicTitle("");
    }

    const addTopic = () => {
        updateForumTopicState({
            variables: {
                topicId: null,
                forumId: props.forumId,
                isEnabled: true,
                title: newTopicTitle
            } as FORUM_UPDATE_TOPIC_STATEVariables

        }).then((savedTopic) => {
            const errors = savedTopic?.data?.saveForumTopic?.errors;

            if (errors && errors.length > 0) {
                throw errors[0];
            }

            // update state
            var newResult = {
                totalDiscussionCount: 0,
                topic: {
                    topicId: savedTopic.data.updateForumTopicState.topicId,
                    forumId: props.forumId,
                    isEnabled: true,
                    title: savedTopic.data.updateForumTopicState.title
                }
            } as forumResult_topics;
            var topicResultsNew = [...props.topicResults, newResult];

            props.updateTopicResults(topicResultsNew);

        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);
            console.log(err);
        }).finally();
    }

    const updateTopic = (topicResult, newTitle, isEnabled) => {
        if (topicResult) {
            updateForumTopicState({
                variables: {
                    topicId: topicResult.topic.topicId,
                    forumId: props.forumId,
                    isEnabled: isEnabled,
                    title: newTitle
                } as FORUM_UPDATE_TOPIC_STATEVariables

            }).then((savedTopic) => {
                const errors = savedTopic?.data?.saveForumTopic?.errors;

                if (errors && errors.length > 0) {
                    throw errors[0];
                }

                // update topic state
                var topicResultsNew = props.topicResults.map(r => {
                    if (r.topic) {
                        if (r.topic.topicId == savedTopic.data.updateForumTopicState.topicId) {
                            var newTopicResult = {
                                ...r,
                                topic: { ...r.topic, title: newTitle, isEnabled: isEnabled }
                            };
                            return newTopicResult;
                        }
                    }
                    return r;
                });
                props.updateTopicResults(topicResultsNew);
            }).catch(err => {
                PromptActions.displayFriendlyErrorPrompt(err);
                console.log(err);
            }).finally();
        }
    }

    return (
        <div className="topic-admin">
            <div className={`fn-header toggle-switch ${showTopicControls ? 'active' : 'inactive'}`} onClick={onClickTopicHeader}>
                <span>Topic Setup</span>
                <span className="fn-float-right">
                    {showTopicControls && <SvgIcon icon="icon-arrow-up" width="13" height="20" />}
                    {!showTopicControls && <SvgIcon icon="icon-arrow-down" width="13" height="20" />}
                </span>
            </div>

            <div ref={topicControls} className={`full-width fn-section-right menu-toggle ${showTopicControls ? 'active' : 'inactive'}`}>
                <div className="form-horizontal fn-flex">
                    <div className="row fn-section-body full-width">
                        <ul>
                            {props.topicResults && props.topicResults.map((topicResult) => (topicResult.topic && topicResult.topic.isEnabled) &&
                                <li key={topicResult.topic.topicId}>
                                    <TopicControls topicResult={topicResult} updateTopic={updateTopic} allowDelete={enableTopicDeletion} /></li>)}

                            <li ref={addTopicToggle} className={`menu-toggle ${showAddTopic ? 'inactive' : 'active'}`}>
                                <a onClick={onAddTopic}><span className="fn-icon fn-icon-plus fn-icon-primary"></span> Add topic</a>
                            </li>
                            <li ref={addTopicRef} className={`menu-toggle ${showAddTopic ? 'active' : 'inactive'}`}>
                                <form method="post" name="addTopic" onSubmit={saveNewTopic}>
                                    <input ref={topicNameInput}
                                        placeholder="Topic title"
                                        name="title"
                                        type="text"
                                        maxLength={100}
                                        className="form-control"
                                        onChange={onChangeTitle}
                                        spellCheck={true}
                                        value={newTopicTitle}
                                    />
                                    <button className="btn btn-primary" type="submit">Add</button>
                                    <button className="close" type="reset" onClick={onAddTopic}><SvgIcon icon="icon-delete" width="8" height="8" /></button>
                                </form></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopicAdmin;
import React from 'react'
import RichTextEditor from './RichTextEditor'


enum RichTextEditorMode {
    ActivityPost = "ActivityPost",
    EventDescription = "EventDescription",
    GroupDescription = "GroupDescription",
    InviteToForceNet = "InviteToForceNet",
    Message = "Message",
}

interface RichTextEditorModuleProps {
    mode: RichTextEditorMode,
    spellcheck: boolean | undefined,
    valueElement: string,
    userPreference: object
}

interface RichTextEditorModuleState {
    value: string,
}

class RichTextEditorModule extends React.Component<RichTextEditorModuleProps, RichTextEditorModuleState> {

    constructor (props) {
        super(props)

        this.el = document.getElementById(props.valueElement) as HTMLInputElement
        if(this.el == null){
            throw `RichTextEditorModule can not find an input element by the name $(props.valueElement)`
        }
        this.state = {
            value: this.el.value,
        }
    }

    el: HTMLInputElement

    _onValueChange = (value: string, empty: boolean) => {
        this.el.value = value
    }

    render() {
        return (
            <RichTextEditor
                mode={this.props.mode}
                readOnly={this.props.readOnly}
                spellcheck={this.props.spellcheck}
                value={this.state.value}
                onValueChange={this._onValueChange}
                userPreference={this.props.userPreference}
            />
        )
    }
}

export default RichTextEditorModule

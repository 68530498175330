import React from "react"
import { Button, Discussion, Forward, Reply } from "./Components"
import WindowEventUtils		from '../../../core/utils/WindowEventUtils';
import Localisation								from '../../../core/resources/Localisation';
import ToolTip									from '../../../core/components/ToolTip';
import ImgUtils					                from '../../../core/utils/ImgUtils';
import SvgIcon                                  from '../../../icons';

class Message extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            reply: false,
            replySingle: false,
            forward: false,
            max: 5,
            maxHidden: 5,
            headerHeight: 90
        }
    }

    _showReply = () => {
        this.refs.messages.scrollTop = 0;
        this.setState({reply: true, replySingle: false, forward: false})
    }

    _hideReply = () => {
        this.setState({reply: false, replySingle: false})
    }

    _showForward = () => {
        this.refs.messages.scrollTop = 0;
        this.setState({forward: true, replySingle: false, reply: false })
        setTimeout(() => document.getElementById('forward_email').focus(), 10);
    }

    _hideForward = () => {
        this.setState({forward: false})
    }

    _showReplySingle = () => {
        this.refs.messages.scrollTop = 0;
        this.setState({replySingle: true, reply: false, forward: false})
    }

    _renderDiscussions() {
        return (
            <ul key="discussions" ref="discussions" id="discussion" className="row-space-top">
                {this.props.message &&
                this.props.message.DataCollection &&
                this.props.message.DataCollection.length > 0
                    ? this.props.message.DataCollection.map((item, i, arr) => {
                          return (
                              <Discussion
                                  name={item.Author.Name}
                                  key={i}
                                  permanentUserType={item.Author.PermanentUserType}
                                  url={item.Author.ProfileUrl}
                                  image={ImgUtils.srcWithSizeParams(60, 60, item.Author.Avatar)}
                                  time={item.DateCreated}
                                  content={item.Body}
                                  read={item.ReceiptsRead}
                                  unread={item.ReceiptsUnread}
                                  conversationId={this.props.conversation.Id}
                              />
                          )
                      })
                    : null}
            </ul>
        )
    }

	_handleShowMore(group, e) {		
		if (e && e.preventDefault){
			e.preventDefault();
		}

		this.setState({
			max: this.state.max == Number.MAX_SAFE_INTEGER ? 5 : Number.MAX_SAFE_INTEGER
		})		
    }
    
    _renderMore() {
        if (!this.props.conversation) return null
        if (this.props.conversation.OtherParticipants.length <= this.state.max && this.state.max != Number.MAX_SAFE_INTEGER) return null
        if (this.state.replySingle) return null;
        return (
            <a href="#" className="more-participants" onClick={ this._handleShowMore.bind(this) }>
                {this.state.max != Number.MAX_SAFE_INTEGER ? `and ${this.props.conversation.OtherParticipants.length - this.state.max} more ...` : "Hide recipients"}
            </a>
        )
    }

    get hasParticipants(): boolean {
        return this.props.conversation?.OtherParticipants?.length > 0;
    }

    _renderParticipants() {
        if (!this.props.conversation) return null;

        let toUsers = this.state.replySingle ? [this.props.conversation.CreatedByUser] : this.props.conversation.OtherParticipants;

        return toUsers.map((item, i, participants) => {
            var designator = null;
            if (item.PermanentUserType == 1 || item.PermanentUserType == 2) {
                designator = (<span className="designator small-text">{(item.PermanentUserType == 1 ? '(CONTR)' : (item.PermanentUserType == 2 ? '(O/GOV)' : ''))}</span>);
            }

            if (item.Status === 0) {
                return (
                    <span key={i}>
                        <a
                            href={item.ProfileUrl}
                            className={`listName ${i >= this.state.max ? "js-hidden hiddenName" : ""}`}>
                            {item.Name}
                        </a>
                        { i < this.state.max && designator }
                        { i < this.state.max && i !== participants.length - 1 ? ", " : " "}
                    </span>
                )
            }

            return <ToolTip key={i} pos="n" tip={Localisation.localisedStringFor('MessageDeactivatedUser')}>
                <a className={`listName ${i > this.state.max ? "js-hidden hiddenName" : ""} user-deactivated`}>{`${item.Name}${i !== participants.length - 1 ? "," : ""}\u00A0`}
                </a>
                </ToolTip>
        })
    }

	_handleShowMoreHidden = (group, e) => {
		if (e && e.preventDefault){
			e.preventDefault();
		}

		this.setState({
			maxHidden: this.state.maxHidden == Number.MAX_SAFE_INTEGER ? 5 : Number.MAX_SAFE_INTEGER
		})		
    }

    _renderMoreHidden() {
        if (!this.props.conversation) return null
        if (this.props.conversation.HiddenParticipants.length <= this.state.maxHidden && this.state.maxHidden != Number.MAX_SAFE_INTEGER) return null
        return (
            <a href="#" className="more-participants" onClick={ this._handleShowMoreHidden }>
                {this.state.maxHidden != Number.MAX_SAFE_INTEGER ? `and ${this.props.conversation.HiddenParticipants.length - this.state.maxHidden} more ...` : "Hide recipients"}
            </a>
        )
    }

    get hasHiddenParticipants(): boolean {
        return this.props.conversation?.HiddenParticipants?.length > 0;
    }

    _renderHiddenParticipants() {
        if (!this.props.conversation) return null
        return this.props.conversation.HiddenParticipants.map((item, i, participants) => {
            var designator = null;
            if (item.PermanentUserType == 1 || item.PermanentUserType == 2) {
                designator = (<span className="designator small-text">{(item.PermanentUserType == 1 ? '(CONTR)' : (item.PermanentUserType == 2 ? '(O/GOV)' : ''))}</span>);
            }

            if (item.Status === 0) {
                return (
                    <span key={i}>
                        <a
                            href={item.ProfileUrl}
                            className={`listName ${i > this.state.maxHidden ? "js-hidden hiddenName" : ""}`}>
                            {item.Name}
                        </a>
                        { i <= this.state.maxHidden && designator }
                        { i <= this.state.maxHidden && i !== participants.length - 1 ? ", " : " "}
                    </span>
                )
            }

            return <ToolTip key={i} pos="n" tip={Localisation.localisedStringFor('MessageDeactivatedUser')}>
                <a className={`listName ${i > this.state.maxHidden ? "js-hidden hiddenName" : ""} user-deactivated`}>{`${item.Name}${i !== participants.length - 1 ? "," : ""}\u00A0`}
                </a>
                </ToolTip>
        })
    }

    _selection() {
        return this.props.message &&
            this.props.message.DataCollection &&
            this.props.message.DataCollection.length > 0
            ? "none"
            : "block"
    }

    initialLoadMore() {
        if (this.props.hasMoreMessages && this.refs.discussions.clientHeight + this.refs.recipients.clientHeight < this.refs.messages.clientHeight) {
            this.props._getConversationMessages();
        }
    }

    componentDidUpdate(prev) {
        if (this.props.conversation && this.props.conversation.initialLoad) {
            this.props._conversationLoaded();
            this.setState({ forward: false, max: 5, reply: false, replySingle: false });

            if (this.props.infiniteScroll) {
                this.refs.messages.scrollTop = 0;
                this.initialLoadMore();
            }

            if (this.state.headerHeight != this.refs.header.clientHeight) {
                this.setState({ headerHeight: this.refs.header.clientHeight });
            }
        }
    }

	/**
	 * Handle the  scroll event
	 * @private
	 */
	_onMessagesScroll() {
		if (this.props.infiniteScroll && this.refs.messages.clientHeight != this.refs.messages.scrollHeight && (this.refs.messages.scrollTop + this.refs.messages.clientHeight >= this.refs.messages.scrollHeight)) {
			if (this.props.loading) {
				return;
            }

            if (this.props.hasMoreMessages) {
                this.props._getConversationMessages();
            }
		}
    }

    get canReplySingle(): boolean {
        return (this.props.conversation.IsOriginalSender && (this.props.conversation.OtherParticipants.length == 1 || this.props.conversation.HiddenParticipants.length == 1))
            || !this.props.conversation.IsOriginalSender
            || this.props.conversation.IsHiddenRecipient;
    }

    get canReplyAll(): boolean {
        return (!this.props.conversation.IsHiddenRecipient && this.props.conversation.OtherParticipants.length > 1)
            || (this.props.conversation.IsOriginalSender
                    && (this.props.conversation.HiddenParticipants.length > 1 || this.props.conversation.OtherParticipants.length > 1));
    }

    render() {
        return (
            <div ref="conversation" id="postMessage">
                {this.props.message && this.props.message.DataCollection ? (
                    <div ref="header" className="header">
                        <div className="clearfix fn-flex">
                            <h2 dir="auto" style={{ paddingRight: "10px"}}>
                                {this.props.conversation.Subject}
                            </h2>
                            <div className="btn-fixed-section">
                            { this.props.canSendMessage && !this.state.forward &&
                            <button
                                className="btn btn-fixed btn-white"
                                onClick={this._showForward}
                            ><SvgIcon icon="icon-forw-ard" width={20} height={20} /> Forward</button>
                            }
                            { this.props.canSendMessage && this.canReplySingle && !this.state.replySingle &&
                            <button
                                className="btn btn-fixed btn-white"
                                onClick={this._showReplySingle}
                            ><SvgIcon icon="icon-reply" width={20} height={20} /> Reply</button>
                            }
                            { this.props.canSendMessage && this.canReplyAll && !this.state.reply &&
                            <button
                                className="btn btn-fixed btn-white"
                                onClick={this._showReply}
                            ><SvgIcon icon="icon-reply-all" width={20} height={20} /> Reply all</button>
                            }
                            </div>
                            <a
                                id="messages-skip-link"
                                className="visuallyhidden focusable"
                                href="#">
                                Back to Messages
                            </a>
                        </div>
                    </div>
                ) : null}
                <div ref="messages" className={`body ${this.props.loading ? "loading" : ""}`} onScroll={this._onMessagesScroll.bind(this)}>
                    <div id="noSelectionMessage" style={{ display: this._selection() }}>
                        No messages selected
                    </div>
                        { [
	                    <div key="recipients" ref="recipients" id="recipients" className="recipientList">
                                { this.hasParticipants && !this.state.forward && <div>
                                    <span className="recepients-header">To</span>
                                    <span className="recipients-list">
                                        {this._renderParticipants()}
                                        {this._renderMore()}
                                    </span>
                                    </div>
                                }
                                { this.hasHiddenParticipants && <div>
                                    <span className="recepients-header">Bcc</span>
                                    <span className="recipients-list">
                                        {this._renderHiddenParticipants()}
                                        {this._renderMoreHidden()}
                                    </span>
                                    </div>
                                }
                            </div>,
                            <div key="reply" ref="reply">
                                {(this.state.reply || this.state.replySingle) && <Reply
                                        sendReply={this.props._reply}
                                        hideReply={this._hideReply}
                                        replySingle={this.state.replySingle}
                                        show={this.state.reply || this.state.replySingle}
                                        conversationId={this.props.conversation.Id}
                                        userPreference={this.props.userPreference}
                                    />}
                            </div>,
                            <div key="forward" ref="forward">
                                {this.state.forward && <Forward
                                    sendForward={this.props._forward}
                                    hideForward={this._hideForward}
                                    show={this.state.forward}
                                    conversationId={this.props.conversation.Id}
                                    userPreference={this.props.userPreference}
                                />}
                            </div>,
                            this._renderDiscussions(),
                            this.props.hasMoreMessages && !this.props.infiniteScroll ? <button role="button" className="btn btn-primary btn-block mobile-small-16" onClick={this.props._getConversationMessages.bind(this)} key="more">Load more messages</button> : null
                        ]
                    }
                </div>
            </div>
        )
    }
}

Message.defaultProps = {
    infiniteScroll: true
}

export default Message

import React from 'react'
import BtnClose from "./BtnClose"
import Modal from "./modal/Modal"

interface SmsPriceAlertProps {
    onConfirm: () => void,
}

interface SmsPriceAlertState {
    isActive: boolean,
}

class SmsPriceAlert extends React.Component<SmsPriceAlertProps, SmsPriceAlertState> {

	constructor(props) {
		super(props);

        this.state = {
            isActive: false,
        }
	}

    show = () => {
        this.setState({
            isActive: true
        })
    }

    _handleModalCloseRequest = () => {
        this.setState({
            isActive: false
        })
    }

    _onConfirm = (e) => {
        this.props.onConfirm()
        this.setState({
            isActive: false
        })
    }

	render() {
        return (
            <Modal onRequestsClose={this._handleModalCloseRequest} active={this.state.isActive} size="default">
                <div className="modal-header">
                    <BtnClose onClick={this._handleModalCloseRequest} />
                    <h2 className="modal-title">Confirm SMS</h2>
                </div>
                <div className="modal-content">
                    <p>The use of SMS comes at a per message cost to Defence and therefore it is only to be used for critical operational matters, including recalls or matters of personal safety and security. Costs of using SMS messaging outside of these guidelines may result in the cost of transmission being passed on.</p>
                </div>
                <div className="modal-actions">
                    <button role="button" className="btn btn-primary" onClick={this._onConfirm}>
                        Confirm
                    </button>
                    <button role="button" className="btn btn-default" onClick={this._handleModalCloseRequest}>
                        Cancel
                    </button>
                </div>
            </Modal>
        )
    }
}

export default SmsPriceAlert;

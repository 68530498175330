import * as React from "react";

function SvgIconChevronCircleRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 28"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.203 21.797l7.094-7.094a.99.99 0 000-1.406l-7.094-7.094a.99.99 0 00-1.406 0L8.203 7.797a.99.99 0 000 1.406L13 14l-4.797 4.797a.99.99 0 000 1.406l1.594 1.594a.99.99 0 001.406 0zM24 14c0 6.625-5.375 12-12 12S0 20.625 0 14 5.375 2 12 2s12 5.375 12 12z"
      />
    </svg>
  );
}

export default SvgIconChevronCircleRight;

import {
    useEffect
} from 'react';
import folderSvc from '../../../services/FolderSvc'

type ViewMediaItemImageImage = {
    url: string | null,
}
type ViewMediaItemImageFile = {
    id: string,
    fileType: string,
    thumbnailFileId: string | null,
    image: ViewMediaItemImageImage | null,
    url: string,
}
type ViewMediaItemImageProps = {
    file: ViewMediaItemImageFile,
    containerId: string,
}
	
function _imageUrl(photo: ViewMediaItemImageFile) {
    if (photo.image && photo.image.url) {
        return photo.image.url
    } else if (photo.fileType == 'VIDEO') {
        return '/Services/GetImage.ashx?id=' + photo.thumbnailFileId
    }
    return photo.url
}

function ViewMediaItemImage(props: ViewMediaItemImageProps) {

    useEffect(() => {
        folderSvc.createVideoPlayLogEntry(props.file.id, props.containerId)
            .promise
            .then(_ => {})
    }, [])

    if (props.file.fileType != 'IMAGE') return null

    return (
        <img src={_imageUrl(props.file)} />
    )
}

export default ViewMediaItemImage

import React, {
    FC,
    useState,
    useEffect,
    useRef,
    useCallback,
} from "react";

import {
    performSearch_search_GroupsResult,
    SearchQueryInputType,
    SearchType,
    performSearchVariables,
} from "../../../../types/graphqlTypes";

import Localisation		from "../../../core/resources/Localisation";
import PromptActions from "../../prompt/actions/PromptActions";
import { useLazyQuery } from "@apollo/client";
import Pluralize from "../../../vendor/typescript-pluralize/index";
import SvgIcon from '../../../icons';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const performSearchGql = require("../../../../gql/performSearch.gql");

const makePlural = (word: string, count: number) => count != 1 ? Pluralize.plural(word) : word;

interface IGroupSelectorProps {
    selectedGroups: Array<any>,
    types: Array<string>,
    onEmit: any
}

export const GroupSelector : FC<IGroupSelectorProps> = (props: IGroupSelectorProps) => {
    const keywordRef = useRef<HTMLInputElement>(null);
    const [search, { loading, data, error, networkStatus, fetchMore }] = useLazyQuery(performSearchGql);
    const [selectedGroups, setSelectedGroups ] = useState(props.selectedGroups);
    const searchResult: performSearch_search_GroupsResult | null = keywordRef?.current?.value ? data?.search : null;
    const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
    const thisRef = useRef();
    const performSearch = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const keywords = e?.target?.value;

        if (keywords) {
            const query: SearchQueryInputType = {
                keywords,
                queryType: SearchType.GROUP,
                types: props.types,
            };
            const page = null;
            const variables: performSearchVariables = {
                query,
                page
            };

            search({
                variables
            });
        }
    }, 500);

    const isSelected = (groupId) => {
        return selectedGroups.filter(group => group.groupId == groupId).length > 0;
    }

    const loadMore = () => {
        fetchMore({
            variables: {
                page: {
                    pageAfter: searchResult.pageInfo.endCursor
                },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult || !fetchMoreResult.search || (fetchMoreResult.search.groups && fetchMoreResult.search.groups.length === 0)) {
                    return prev;
                }

                return Object.assign({}, prev, {
                    search: {
                        __typename: fetchMoreResult.search.__typename,
                        pageInfo: fetchMoreResult.search.pageInfo,
                        groups: [...prev.search.groups, ...fetchMoreResult.search.groups]
                    }
                })
            },
        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);
        });
    }

    const onClose = useCallback((event) => {
        const localNode = thisRef.current;
        let source = event.target;

        while (source.parentNode) {
            if (source === localNode) {
                return;
            }
            source = source.parentNode;
        }

        const keywords = keywordRef.current.value;
        
        keywordRef.current.value = "";

        if (keywords) {
            forceUpdate();
            event.stopPropagation();
        }
    }, []);

    const keyUp = ((event) => {
       
            if (event.keyCode === 27) {
                const keywords = keywordRef.current.value;

                keywordRef.current.value = "";

                if (keywords) {
                    forceUpdate();
                    event.stopPropagation();
                }
            } else if (!keywordRef.current.value) {
                forceUpdate();
            }
        
    });

    const emit = () => {
        props?.onEmit(selectedGroups);
    }

    useEffect(() => {
        window.document.addEventListener("mousedown", onClose)
    
        return function cleanup() {
            window.document.removeEventListener("mousedown", onClose)
        }
    })
      
    const hasNextPage = searchResult?.pageInfo?.hasNextPage == true;

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        // When there is an error, we stop infinite loading.
        // It can be reactivated by setting "error" state as undefined.
        disabled: !!error,
        // `rootMargin` is passed to `IntersectionObserver`.
        // We can use it to trigger 'onLoadMore' when the sentry comes near to become
        // visible, instead of becoming fully visible on the screen.
        rootMargin: '0px 0px 400px 0px',
    });

    const toggleGroup = (event, group) => {
        if (isSelected(group.groupId)) {
            deleteGroup(event,group.groupId);
        } else {
            addGroup(event, group);
        }
    }

    const addGroup = (event, group) => {
        event.stopPropagation();

        setSelectedGroups([...selectedGroups, ...[group]].sort((l, r) => l.name < r.name ? -1 : 0));
        keywordRef?.current?.focus();
    }

    const deleteGroup = (e, groupId) => {
        e.preventDefault();
        setSelectedGroups(selectedGroups.filter(g => g.groupId != groupId));
        blur();
    }

    useEffect(emit, [selectedGroups]);

    const foundGroups = searchResult?.groups;

    const  convertToPlain = (html) =>{

        // Create a new div element
        var tempDivElement = document.createElement("div");

        // Set the HTML content with the given value
        tempDivElement.innerHTML = html;

        // Retrieve the text property of the element 
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }


      
    return <div ref={thisRef}>
        <div className="search">
            <input ref={keywordRef} type="text" className="form-control" placeholder="Search for a group" onKeyPress={(e) => { if (e.charCode === 13) { e.preventDefault() } }}   onChange={performSearch} onKeyUp={keyUp}></input>
            { loading && foundGroups == null && <div className="loading">Loading…</div> }
            { !loading && foundGroups != null && foundGroups.length == 0 && 
                <div className="noResults">
                    <strong>No Results</strong><br/>
                    Your search did not find any results
                </div> }
            { error && <div>{ Localisation.localisedStringFor('ErrorGenericMsg') }</div>}
            {
                foundGroups != null
                    ? <ul className="searchResults group-lists" ref={ rootRef }>
                        {
                            foundGroups.map(g => {
                                var groupClassName = "searchResult";
                                if (isSelected(g?.groupId)) groupClassName += " selected-group";
                                return (
                                    <li key={ g.groupId } className={groupClassName} onClick={(event) => toggleGroup(event, g)}>
                                        <div>
                                            { isSelected(g?.groupId) && <strong><a>Added to list</a> </strong>}
                                            <strong>{ g.name } </strong>
                                            ({ g.numberOfMembers } { makePlural("member", g.numberOfMembers) })
                                            <span className="description">{ convertToPlain(g.rawDescription) }</span>
                                        </div>
                                        { isSelected(g.groupId) &&
                                            <a><SvgIcon icon="icon-cross" width={20} height={20} /></a>
                                        }
                                        { !isSelected(g.groupId) &&
                                            <span className="add"><SvgIcon icon="icon-cross" width={20} height={20} /></span>
                                        }
                                    </li>
                                );
                            })
                        }
                        {
                            loading || hasNextPage && <div ref={sentryRef} key="loadMore" className="searchResult">
                                Loading...
                            </div>

                        }
                    </ul>
                    : null
            }
        </div>
        <div>Selected groups:</div>
        <div className="selected-groups">
            { selectedGroups?.map(g => {
                return (
                    <div key={ g.groupId } className="selected-group">
                        <span className="tools"><a href="#" onClick={(event) => deleteGroup(event,g.groupId)}><SvgIcon icon="icon-cross" width={16} height={16}/></a></span>
                        <div><h3>{ g.name }</h3></div>
                        { g.primaryAdmin && <div><strong>Primary admin:</strong> { g.primaryAdmin.fullName } ({ g.primaryAdmin.rank?.title })</div> }
                        <div className="description">{convertToPlain( g.rawDescription) }</div>
                        <div><strong>{ g.numberOfMembers } { makePlural("member", g.numberOfMembers) }</strong></div>
                    </div>
                );
            })
            }
        </div>
        { !selectedGroups || selectedGroups.length == 0 && <div>None</div> }
    </div>
   
}

import React from 'react'
import folderSvc from '../../../services/FolderSvc'
import AudioPlayer from 'react-audio-player'
import './ViewMediaItemAudio.css'

enum AudioState {
    Stopped,
    Playing,
    Paused,
}
class ViewMediaItemAudio extends React.Component {

    state = {
        state: AudioState.Stopped,
        currentTime: 0,
        duration: Infinity,
        volume: 0.5,
        showVolume: false
    }

    audioPlayer = React.createRef<AudioPlayer>()
    isPlayed = false

    isPlaying () {
        return this.state.state == AudioState.Playing ? true : false
    }

    _play = (e) => {
        e.preventDefault()
        const audioEl = this.audioPlayer.current.audioEl.current
        audioEl.play()
    }

    _pause = (e) => {
        e.preventDefault()
        const audioEl = this.audioPlayer.current.audioEl.current
        audioEl.pause()
    }

    _rewind = (e) => {
        e.preventDefault()
        const audioEl = this.audioPlayer.current.audioEl.current
        const currentTime = Math.max(0, audioEl.currentTime - 5)
        this._seek(currentTime)
    }

    _forward = (e) => {
        e.preventDefault()
        const audioEl = this.audioPlayer.current.audioEl.current
        const currentTime = Math.min(audioEl.duration, audioEl.currentTime + 5)
        this._seek(currentTime)
    }

    _seek = (currentTime: number) => {
        const audioEl = this.audioPlayer.current.audioEl.current
        audioEl.currentTime = currentTime
        this.setState({ currentTime })
    }

    _volume(v: number) {
        const volume = Math.min(1, Math.max(0, v))
        this.setState({ volume })
    }

    _onLoadedMetadata = (e) => {
        const { duration, volume } = e.srcElement
        this.setState({ duration, volume })
    }

    _onPlay = (e) => {
        this.setState({ state: AudioState.Playing })

        if (this.isPlayed) return;
        return new Promise((resolve, reject) => {
            folderSvc.createVideoPlayLogEntry(this.props.file.id, this.props.containerId)
                .promise
                .then((response) => {
                    this.isPlayed = true;
                    resolve(response);
                })
        })
    };

    _onEnded = (e) => {
        this.setState({
            state: AudioState.Stopped,
            currentTime: this.state.duration,
        })
    }

    _onPause = (e) => {
        this.setState({ state: AudioState.Paused })
    }

    _onListen = (currentTime) => {
        this.setState({ currentTime })
    }

    _renderSeconds(t: number) {
        if (isNaN(t) || t == Infinity) return '-:--';
        t = Math.floor(t)
        var h = Math.floor(t / 3600);
        var m = Math.floor(t / 60) % 60;
        var s = (t % 60);
        return (h ? h + ':' : '') + (h ? ('0' + m).slice(-2) : m) + ':' + ('0' + s).slice(-2);
    }

    _onSliderChange = (e) => {
        this._seek(e.target.value)
    }

    _onVolumeChange = (e) => {
        this._volume(e.target.value/100)
    }

    _showVolume = (e) => {
        e?.preventDefault()
        this.setState({ showVolume: true })
    }

    _hideVolume = (e) => {
        e?.preventDefault()
        this.setState({ showVolume: false })
    }

    render() {
        if (this.props.file.fileType != 'AUDIO') return null

        let url = `/Services/GetImage.ashx?id=${this.props.file.id}`
        const isPlaying = AudioState.Playing == this.state.state
        const showProgress = Infinity != this.state.duration
        if (this.props.file.videoStreamUrl) {
            url = this.props.file.videoStreamUrl
        }

        return (
            <div className="audio-player">
                <div className="icon-container audio-icon-container">
                    <span className="file-icon icon-file-icon-audio" aria-hidden="true">
                    </span>
                </div>
                <div className='player-container'>
                    <div className="file-title">
                        <span>{this.props.file.title}</span>
                    </div>
                    <AudioPlayer
                        ref={this.audioPlayer}
                        volume={this.state.volume}
                        src={url}
                        autoPlay={this.props.autoplay}
                        controls={true}
                        onPlay={this._onPlay}
                        onEnded={this._onEnded}
                        onPause={this._onPause}
                        onListen={this._onListen}
                        listenInterval={1000}
                        onLoadedMetadata={this._onLoadedMetadata}
                    />
                    <div className='audioProgress'>
                        {showProgress &&
                            <div className='audioProgressSlider'>
                                <progress value={this.state.currentTime} max={this.state.duration} />
                                <input type='range' onChange={this._onSliderChange} min={0} max={this.state.duration} />
                            </div>
                        }
                        <div className='audioVolume'>
                            {this.state.showVolume ?
                                <>
                                    <a href="#" onClick={this._hideVolume}><span className='sr-only'>Volume</span></a>
                                    <div className='volumeSlider'>
                                        <input type='range' min={0} max={100} value={100 * this.state.volume} onChange={this._onVolumeChange} />
                                    </div>
                                </>
                                :
                                <a href="#" onClick={this._showVolume}><span className='sr-only'>Volume</span></a>
                            }
                        </div>
                        <div className='audioDuration'>
                            {this._renderSeconds(this.state.currentTime)}
                            <span aria-hidden='true'> / </span><span className='sr-only'>of</span>
                            {this._renderSeconds(this.state.duration)}
                        </div>
                    </div>
                    <div className='audioControls'>
                        <a href="#" onClick={this._rewind} className='rewind'><span className='sr-only'>Rewind</span></a>
                        {(!isPlaying) && <a href="#" onClick={this._play} className='play'><span className='sr-only'>Play</span></a>}
                        {isPlaying && <a href="#" onClick={this._pause} className='pause'><span className='sr-only'>Pause</span></a>}
                        <a href="#" onClick={this._forward} className='forward'><span className='sr-only'>Forward</span></a>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewMediaItemAudio

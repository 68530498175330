import _ from "lodash";
import { AuditLogFactory } from "./AuditLog";

export default class AuditLogCollection {
  constructor() {
    /**
     * The results of this audit search
     * @type {Array}
     */
    this.auditLogs = [];
  }

  static AuditLogCollectionFromObject(o) {
    let auditLogCollection = new AuditLogCollection();

    let auditLogs = null;
    if (o) {
      auditLogCollection.auditLogs = _.map(o, u => {
        return AuditLogFactory.auditLogFromOject(u);
      });
    }

    return auditLogCollection;
  }
}

import React, {
    FC,
    useEffect,
    useState,
} from "react";
import _ from 'lodash';

const forumsGql = require('../../../../gql/forums.gql');

import {
    forumResult_topics,
    forumResult_discussions,
} from "../../../../types/graphqlTypes";

import SvgIcon from '../../../icons';
import { Discussion } from "./Discussion";

interface ITopicProps {
    topicResult: forumResult_topics,
    collapseAllTopics: boolean,
    expandAllTopics: boolean,
    onSelectTopic: (topicResult: forumResult_topics) => void,
    onSelectDiscussion: (discussionResult: forumResult_discussions, topicResult: forumResult_topics) => void,
    forumId: string,
    onCancel: () => void,
    discussionResults: forumResult_discussions[],
    updateTopicAccordionState: (topicId, accordionState) => void,
}

export const Topic: FC<ITopicProps> = (props: ITopicProps) => {
    const maxDiscussions = 5;
    const [showDiscussions, setShowDiscussions] = useState(props.topicResult.accordionState);

    useEffect(() => {
        if (props.collapseAllTopics) {
            setShowDiscussions(false);
        } else if (props.expandAllTopics) {
            setShowDiscussions(true);
        }
    }, [props.collapseAllTopics, props.expandAllTopics]);

    const toggleShowDiscussions = (e) => {
        e.preventDefault();
        props.updateTopicAccordionState(props.topicResult.topic?.topicId, !showDiscussions);
        setShowDiscussions(!showDiscussions);
    }
    const selectTopic = (e) => {
        e.preventDefault();
        props.onSelectTopic(props.topicResult);
    }

    let sortedDiscussionResults = props.discussionResults ? [...props.discussionResults].sort(
        function (a, b) {
            if (a.lastActivityDate && b.lastActivityDate) {
                if (a.lastActivityDate < b.lastActivityDate) { return 1; }
                if (a.lastActivityDate > b.lastActivityDate) { return -1; }
            }
            return 0;
        }
    ) : null;

    return (
        <div className="discussion-topic">
            <div className="heading">
                <div className="fn-service-border-colour topic-header">
                    <div className="row">
                        <div className="small-16 columns">
                            <div className="fn-flex-space-between">
                                <h2 dir="auto"><a onClick={selectTopic}>{props.topicResult.topic?.title}</a></h2>
                                <div className="toggle" onClick={toggleShowDiscussions}>
                                    <SvgIcon icon={`${showDiscussions ? "icon-arrow-up" : "icon-arrow-down"}`} width={16} height={16} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`threads${showDiscussions ? '' : ' closed'}`}>
                {sortedDiscussionResults && sortedDiscussionResults.map((discussionResult, index) => <Discussion
                    onSelectTopic={props.onSelectTopic}
                    onSelectDiscussion={props.onSelectDiscussion}
                    showTopic={false}
                    key={discussionResult.discussion.discussionId}
                    discussionResult={discussionResult}
                    topicResult={props.topicResult} />)}
                { props.topicResult.totalDiscussionCount > maxDiscussions &&
                <div className="thread">
                    <div className="fn-flex">
                        <div className="form-horizontal">
                            <div className="row">
                                <div className="small-16 large-12 columns">
                                    <div className="fn-flex-space-between">
                                        <a onClick={selectTopic}>See all {props.topicResult.totalDiscussionCount} discussions</a>
                                    </div>
                                </div>
                            </div>
                        </div>                  
                    </div>
                </div>
                }
            </div>
        </div>
    )
}


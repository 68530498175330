import React from "react"
import { InboxPanel, MessagePanel } from "./components/Components"
import MessageSvc from "../../services/MessageSvc"
import PromptActions from "../prompt/actions/PromptActions"
import { UserFactory } from '../../core/models/User';
import { MessagePostAction } from "./models/MessageActions";
const service = new MessageSvc()

class MessagesModule extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            archived: 0,
            conversation: null,
            conversations: [],
            important: 0,
            lastState: null,
            loading: true,
            loadingMail: true,
            loadingMessage: false,
            loadingMore: false,
            mailbox: "my-inbox",
            mode: "message",
            message: null,
            searchText: "",
            selected: null,
            unread: 0,
            draft: 0,
            hideMobileMenu: false,
            title: "???",
            conversationId: null
        }
    }

    _selectMailBox(mailbox) {
        this._getConversations(mailbox, this.state.searchText)
        this.setState({ mailbox, loading: true, searchText: "" })
    }

    _toggle(action) {
        if ((this.state.initialUsers && this.state.initialUsers.length > 0 && !this.state.conversation) || this.props.body) {
            this.navigateBack();
            return;
        }

        switch (this.state.mode)
        {
            case "new":
                this.setState({
                    mode: "message",
                    hideMobileMenu: false,
                    isCreateSeparateConversations: false
                });                
                break;
            case "draft":
                if (this.state.conversation && action === MessagePostAction.DELETE){
                    this.setState({loadingMessage:true})
                    service
                    .deleteDraftInboxMessage(this.state.conversation.Id)
                    .promise.then(response => {
                        this.setState({
                            loadingMessage:false,
                            hideMobileMenu: true,
                            initialUsers: null,
                            isCreateSeparateConversations: false,
                            conversation: null,
                            isDraft: false
                        });
                        this._getMailbox();
                        this._selectMailBox("my-inbox-draft");
                    })
                    .catch();
                    this.setState({
                        mode: "message",
                        hideMobileMenu: false,                        
                        isCreateSeparateConversations: false
                    }); 
                } else {
                    this.setState({mode: "new",hideMobileMenu: false,isCreateSeparateConversations: false, conversation: null, initialUsers: null});  
                }      
                break;
            default:
                this.setState({
                    mode: "new",
                    hideMobileMenu: true,
                    initialUsers: null,
                    isCreateSeparateConversations: false,
                    conversation: null,
                    isDraft: false,
                    title: "New Message"
                })
        } 
    }

    navigateBack() {
        if (this.props.noNavigateback) {
            this.props.onRequestsClose(true);
            return;
        }
        // Use returnUrl if provided in favour of back/go(-1) as user could navigate out of sequence. Does not apply to people finder.
        if (this.props.returnUrl) {
            window.location = this.props.returnUrl;
        } else if (window.history.length > 1) {
            window.history.back();
        } else {
            window.location = "/membershome";
        }
    }

    _new(submit, body, subject, recipientList, bccRecipientList, isImportant, isReplyable, sendSms, isDraft, draftConversationId) {
        if (this.state.mode == "new" || this.state.mode == "draft") {
            if (submit) {
                const recipients = recipientList.map(i => i.id).join();
                const bccRecipients = bccRecipientList.map(i => i.id).join();

                this.setState({loadingMessage:true})
                service
                    .create(body, subject, recipients, bccRecipients, isImportant, isReplyable, sendSms, true, isDraft, draftConversationId)
                    .promise.then(response => {
                        if ((this.state.initialUsers && this.state.initialUsers.length > 0 && !draftConversationId) || this.props.body) {
                            this.navigateBack();
                            return;
                        }
                        let mailFolderId = isDraft ? "my-inbox-draft" : null;                      
                        this._getConversations(mailFolderId, null, response.DataCollection.ConversationId, response.DataCollection.ConversationIds)
                        // Refresh Mailbox if either saving a draft or post a draft...
                        if (isDraft || (!isDraft && draftConversationId)){
                            this._getMailbox();
                            //if (isDraft){
                                //this._selectMailBox("my-inbox-draft");
                            //}                          
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            loadingMessage: false
                        })
                        PromptActions.displayFriendlyErrorPrompt(error)
                    });
            }
        } else {
            this.setState({
                mode: "new",
            })
        }
    }

    _reply(conversationId, body, replySingle, callback) {
        this.setState({loadingMessage:true})
        service
            .reply(conversationId, body, true, replySingle)
            .promise.then(response => {
                this._getConversations(null, null, conversationId)
            })
            .catch((error) => {
                this.setState({loadingMessage:false})
                PromptActions.displayFriendlyErrorPrompt(error)
            });
    }

    _archive(id, archived) {
        let isUnread = false;
        let isImportant = false;

        if (this.state.conversation && id == this.state.conversation.Id) {
            this.setState({ loading: true, conversation: null })
        } else {
            let conversation = this.state.conversations.find(i => i.Id == id);

            isUnread = conversation && conversation.NewCount > 0;
            isImportant = conversation && conversation.IsImportant;

            this.setState({ loading: true})
        }        
        service
            .archive(id, !archived)
            .promise.then(response => {
                this._selectMailBox(this.state.mailbox);

                if (isUnread) {
                    if (archived) {
                        this.setState({
                            important: Math.max(0, this.state.important + (isImportant ? 1 : 0)),
                            unread: Math.max(0, this.state.unread + 1),
                            archived: Math.max(0, this.state.archived - 1)
                        });
                    } else {
                        this.setState({
                            important: Math.max(0, this.state.important - (isImportant ? 1 : 0)),
                            unread: Math.max(0, this.state.unread - 1),
                            archived: Math.max(0, this.state.archived + 1)
                        });
                    }
                }
            })
            .catch()
    }

    _forward(conversationId, email, body) {
        this.setState({ loading: true })
        service
            .email(conversationId, email, body)
            .promise.then(response => {
                this.setState({ loading: false })
            })
            .catch((error) => {
                this.setState({
                    loadingMessage:false,
                    loading: false,
                })
                PromptActions.displayFriendlyErrorPrompt(error)
            });
    }

    _open(e, item) {
        if (e) {
            e.stopPropagation()
        }

        let conversation = this.state.conversations.find(i => i.Id == item.Id);

        if (conversation) {
            if (conversation.NewCount > 0) {
                if (conversation.IsArchived) {
                    this.setState({
                        archived: Math.max(0, this.state.archived - 1)
                    })
                } else if (conversation.IsImportant) {
                    this.setState({
                        important: Math.max(0, this.state.important - 1),
                        unread: Math.max(0, this.state.unread - 1)
                    })
                } else {
                    this.setState({
                        unread: Math.max(0, this.state.unread - 1)
                    })
                }

                conversation.NewCount = 0;
            }
        }

        this.setState({
            selected: item,
            mode: "message",
            loadingMessage: true,
            conversations: this.state.conversations
        });

        this._getMessage(item);
        this._hideMobileMenu();
    }

    _getMailbox(searchTerm = null, conversationId = null) {
        service
            .mailbox(searchTerm, conversationId)
            .promise.then(response => {
                let folders = response.Folders.filter(i => {
                    return i.Name === "Inbox"
                })
                let archived = response.Folders.filter(i => {
                    return i.Name === "Archived"
                })
                let important = folders[0].Folders.filter(i => {
                    return i.Name === "Important"
                })
                let unread = folders[0].Folders.filter(i => {
                    return i.Name === "Unread"
                })
                let draft = response.Folders.filter(i => {
                    return i.Name === "Draft"
                })               

                let selected = null;
                if (conversationId != null) {
                    selected = response.Conversations.Conversations && response.Conversations.Conversations.length > 0 && response.Conversations.Conversations[0]
                }

                this.setState({
                    archived: archived[0].UnreadCount,
                    conversations: response.Conversations ? response.Conversations.Conversations : [],
                    hasMoreConversations: response.Conversations ? response.Conversations.HasMoreMessages : false,
                    lastState: response.Conversations ? response.Conversations.State : null,                    
                    important: important[0].UnreadCount,
                    loading: false,
                    loadingMail: false,
                    unread: unread[0].UnreadCount,
                    draft: draft[0].TotalCount,
                    selected
                })

                if (selected) {
                    this._open(null, selected);
                }
            })
            .catch()
    }

    _getSearch(searchText) {
        this.setState({ loading: true })
        this._getConversations(null, searchText)
    }

    _conversationLoaded() {
        let conversation = this.state.conversation;
        let selected = this.state.selected;

        conversation.initialLoad = false;
        selected.initialLoad = false;

        this.setState({
            selected,
            conversation
        })
    }

    _getMessage(item) {
        this.setState({ loadingMessage: true })
        service
            .listMessages(item.Id.toString(), 1)
            .promise.then(response => {
                let message = response;
                let dataCollection = message.DataCollection;
                message.DataCollection = [];
                item.initialLoad = true;

                this.setState({
                    selected: item,
                    conversation: item,
                    message,
                    mode: item.IsDraft ? "draft" : "message",
                    loadingMessage: false,
                    page: 1,
                    hasMoreMessages: response.PagingData.PageSize < response.PagingData.RecordCount,
                    title: item.IsDraft ? "draft" : "message",
                    initialUsers: item && item.IsDraft ? item.OtherParticipants.map((u) => {
                        return UserFactory.userFromObject(u);
                    }) : [],
                    body: item ? item.Body : ''
                })

                message.DataCollection = dataCollection;
                this.setState({
                    message
                });
            })
            .catch()
    }

    _getConversationMessages(e) {
		if (e && e.preventDefault){
			e.preventDefault();
		}

        this.setState({ loadingMessage: true })
        let hasMoreMessages = this.state.page * this.state.message.PagingData.PageSize < this.state.message.PagingData.RecordCount;

        if (hasMoreMessages) {
            service
            .listMessages(this.state.selected.Id.toString(), this.state.page + 1)
            .promise.then(response => {
                let message = this.state.message;

                message.DataCollection = [... message.DataCollection, ...response.DataCollection];

                this.setState({
                    message: message,
                    loadingMessage: false,
                    page: this.state.page + 1,
                    hasMoreMessages: (this.state.page + 1) * response.PagingData.PageSize < response.PagingData.RecordCount
                })
            })
            .catch()
        }

        this.setState({ hasMoreMessages: hasMoreMessages })
    }

    _getConversations(mailbox = null, searchTerm = null, selectedId = null, selectedIds = null) {
        service
            .listConversations(null, searchTerm, null, mailbox)
            .promise.then(response => {
                let conversation = null;

                if (selectedId) {
                    response.Conversations.some(c => {
                        if (c.Id === selectedId.toString()) {
                            conversation = c;
                            return true;
                        }

                        return false;
                    })
                } else if (selectedIds) {
                    response.Conversations.some(c => {
                        if (selectedIds.some(id => id.toString() == c.Id)) {
                            conversation = c;
                            return true;
                        }

                        return false;
                    })
                }

                if (conversation){
                    this._getMessage(conversation)
                } else {
                    if (this.state.mode == "draft"){
                        this.setState({mode: "message"}) 
                    }
                }

                this.setState({
                    conversation,
                    conversations: response.Conversations,
                    loading: false,
                    loadingMail: false,
                    searchText: searchTerm,
                    lastState: response.State,
                    hasMoreConversations: response.HasMoreMessages,
                    initialUsers: []
                })
            })
            .catch()
    }

    _more() {
        if (!this.state.loadingMore) {
            this.setState({
                loadingMore: true,
            })
            let searchText = this.state.searchText ? this.state.searchText : null
            service
                .listConversations(this.state.lastState, searchText, null, this.state.mailbox)
                .promise.then(response => {
                    let conversations = this.state.conversations.concat(response.Conversations)
                    this.setState({
                        conversations,
                        loading: false,
                        loadingMore: false,
                        lastState: response.State,
                        hasMoreConversations: response.HasMoreMessages
                    })
                })
                .catch()
        }
    }

    _deleteDraftInboxMessage(draftConversationId) {
        this.setState({ loading: true })
        service
            .deleteDraftInboxMessage(draftConversationId)
            .promise.then(response => {
                this.setState({ loading: false })
            })
            .catch((error) => {
                this.setState({
                    loadingMessage:false,
                    loading: false,
                })
                PromptActions.displayFriendlyErrorPrompt(error)
            });
    }

    _hideMobileMenu() {
        this.setState({ hideMobileMenu: true });
    }

    _showMobileMenu() {
        this.setState({ hideMobileMenu: false });
    }

    componentDidMount() {
        let searchTerm = null;
        let conversationId = null;

        if (this.props.initialData) {
            let initialData = JSON.parse(this.props.initialData);

            if (initialData && initialData.FocusConversation) {
                conversationId = initialData.FocusConversation;
            }
        }

        this._getMailbox(searchTerm, conversationId);

        if (this.props.pre) {
            let initialUsers = JSON.parse(this.props.pre);

            if (initialUsers) {
                this.setState({
                    mode: "new",
                    initialUsers: initialUsers.map((u) => {
                        return UserFactory.userFromObject(u);
                    })
                });
            }
        }

        if (this.props.body) {
            this.setState({
                mode: "new"
            });
        }

        this.setState({
            isCreateSeparateConversations: this.props.isCreateSeparateConversations,
        })
    }

	/**
	 * Handle the scroll event
	 * @private
	 */
	_onMobileRowsScroll() {
		if (this.props.infiniteScroll && this.refs.mobilerows.clientHeight != this.refs.mobilerows.scrollHeight && (this.refs.mobilerows.scrollTop + this.refs.mobilerows.clientHeight >= this.refs.mobilerows.scrollHeight)) {
			if (this.state.loadingMessage) {
				return;
            }
            
            if (this.state.hasMoreMessages) {
                this._getConversationMessages();
            }
		}
    }

    render() {
        return (
            <div id="primary" style={{ display: "block" }}>
                <div ref="mobilerows" className="row mobile-rows" style={{ overflowX: "hidden" }} onScroll={this._onMobileRowsScroll.bind(this)}>                    
                        { ((!this.state.initialUsers || this.state.initialUsers.length == 0 || this.state.body) && (!this.props.body || this.props.body.length == 0)) && !this.props.showModal && <InboxPanel
                        _archive={this._archive.bind(this)}
                        _getSearch={this._getSearch.bind(this)}
                        _more={this._more.bind(this)}
                        _open={this._open.bind(this)}
                        _selectMailBox={this._selectMailBox.bind(this)}
                        _toggle={this._toggle.bind(this)}
                        archived={this.state.archived}
                        conversations={this.state.conversations}
                        important={this.state.important}
                        loading={this.state.loading}
                        loadingMail={this.state.loadingMail}
                        loadingMore={this.state.loadingMore}
                        mailbox={this.state.mailbox}
                        searchText={this.state.searchText}
                        selected={this.state.selected}
                        unread={this.state.unread}
                        draft={this.state.draft}
                        hideMobile={this.state.hideMobileMenu}
                        hasMoreConversations={this.state.hasMoreConversations}
                        userId={this.state.userId}
                        canSendMessage={this.props.canSendMessage}
                    /> }
                    <MessagePanel
                        _forward={this._forward.bind(this)}
                        _new={this._new.bind(this)}
                        _reply={this._reply.bind(this)}
                        _toggle={this._toggle.bind(this)}
                        conversation={this.state.conversation}
                        loading={this.state.loadingMessage}
                        message={this.state.message}
                        mode={this.state.mode}
                        initialUsers={this.state.initialUsers}
                        selected={this.state.selected}
                        _getConversationMessages={this._getConversationMessages.bind(this)}
                        page={this.state.page}
                        hasMoreMessages={this.state.hasMoreMessages}
                        hideMobile={this.state.hideMobileMenu}
                        _showMobileMenu={this._showMobileMenu.bind(this)}
                        infiniteScroll={this.props.infiniteScroll}
                        canSendMessage={this.props.canSendMessage}
                        canSendSms={this.props.canSendSms}
                        isCreateSeparateConversations={this.state.isCreateSeparateConversations}
                        canCreateSeparateConversations={this.props.canCreateSeparateConversations}
                        maxSeparateConversations={this.props.maxSeparateConversations}
                        separateConversationCount={this.props.separateConversationCount}
                        _conversationLoaded={this._conversationLoaded.bind(this)}
                        userPreference={this.props.userPreference}
                        noNavigateback={this.props.noNavigateback}
                        showModal={this.props.showModal}
                        onRequestsClose={this.props.onRequestsClose}
                        body={this.props.body} 
                        title={this.state.title} 
                    />
                </div>
            </div>
        )
    }
}

MessagesModule.defaultProps = {
    infiniteScroll: true,
	canSendMessage: true,
    canSendSms: false,
    pre: null,
    initialData: null,
    returnUrl: null
}

export default MessagesModule

import { Quill } from 'react-quill';
import * as Parchment from "parchment";

const BlockEmbed = Quill.import('blots/block/embed') as typeof Parchment.default.Embed;

export class QuoteBlot extends BlockEmbed {
  constructor(domNode, blotData) {
    super(domNode);

  }

  static create(blotData): Node {
    let node = super.create();

    node.dataset.replyId = blotData.replyId;
    node.dataset.personName = blotData.personName;
    node.dataset.createdDate = blotData.createdDate;
    node.dataset.replyContent = blotData.replyContent;

    node.setAttribute('class', 'quote-in-post fn-flex');

    let template = document.createElement('template');
    template.innerHTML = `<blockquote><div class="row"><div class="small-8 columns"><span class="name">${blotData.personName}</span></div><div class="small-8 columns"><span class="date">${blotData.createdDate}</span></div><div class="small-16 columns">${blotData.replyContent}</div></div></blockquote>`;
    template.content.childNodes.forEach(element => {
      node.appendChild(element);
    });
    node.setAttribute('contenteditable', false);

    return node;
  }

  static value(domNode) {
    return {
      replyId: domNode.dataset.replyId,
      personName: domNode.dataset.personName,
      createdDate: domNode.dataset.createdDate,
      replyContent: domNode.dataset.replyContent,
    };
  }

}
QuoteBlot.blotName = 'quote-in-post';
QuoteBlot.tagName = 'figure';
QuoteBlot.className = 'quote-in-post';; // 'quote-in-post fn-flex';

import * as React from "react";

function SvgIconReply(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 42 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M25.5 5.251c.219.091.436.18.655.278 2.893 1.293 5.257 2.97 7.051 4.959h3.457v15.705H10.481v-4.369l-5.239-3.526V31.43h36.656V5.251zm-9.818 8.998c3.446 0 5.624.168 7.054.338 8.751 1.04 11.04 6.531 11.04 6.531 0-13.379-18.088-14.768-18.088-14.768V.001L0 10.559l15.682 10.558z"
      />
    </svg>
  );
}

export default SvgIconReply;

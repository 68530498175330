import React, {
    FC,
    MouseEventHandler,
    useState,
    useEffect,
} from "react";
import _ from 'lodash';
import {
    association,
    forumReplyResult,
} from "../../../../types/graphqlTypes";
import { UserRightsType } from "../model/UserRightsType";
import RichTextEditor, { RichTextEditorMode } from "../../../core/components/richtext/RichTextEditor";
import { ConfirmationModal } from "./ConfirmationModal";
import DisplayFileList from "./DisplayFileList";
import ForumUpload from "./ForumUpload";
import AssociationUtils from "./AssociationUtils";
import AppDelegate from "../../../core/AppDelegate";
import BtnUpload from "../../../core/components/BtnUpload";
import { FileFactory } from "../../../core/models/File";
import Localisation from "../../../core/resources/Localisation";
import { ForumAssociationInput } from "../../../../types/graphqlTypes";
import { AssociationType } from "../model/Enums";
import AttachedPhotosFiles from "../../../core/components/AttachedPhotosFiles";
import FileHeader from "../model/FileHeader";
import UrlUtils from "../../../core/utils/UrlUtils";
import { FileType } from '../model/Enums';
import PromptActions from "../../prompt/actions/PromptActions";

interface IUpdateReplyProps {
    discussionReplyResult: forumReplyResult,
    title: string,
    onUpdateReply: (content: string, associations: association[], reason?: string) => void,
    onRequestClose: () => void,
    groupId: string,
    onClick: (e) => MouseEventHandler<HTMLAnchorElement>,
}

const isNumber = (n: string | number | undefined): boolean =>
    n !== undefined && !isNaN(parseFloat(String(n))) && isFinite(Number(n));

export const UpdateReply: FC<IUpdateReplyProps> = (props: IUpdateReplyProps) => {
    const [content, setContent] = useState(props.discussionReplyResult.reply.content);
    const [displayEditModal, setDisplayEditModal] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [associations, setAssociations] = useState(props.discussionReplyResult ? props.discussionReplyResult.associations : null);

    const [isActive, setIsActive] = useState(false);
    const isFile = (association: ForumAssociationInput) => association.associationType == AssociationType.AttachedFile ||
        association.associationType == AssociationType.AttachedPhoto ||
        association.associationType == AssociationType.EmbedlyResult;
    const isLink = (association: ForumAssociationInput) => association.associationType == AssociationType.GroupDocumentFolder ||
        association.associationType == AssociationType.GroupDocument ||
        association.associationType == AssociationType.GroupMediaFolder ||
        association.associationType == AssociationType.GroupMedia;
    const [attachedPhotos, setAttachedPhotos] = useState(null);
    const [links, setLinks] = useState([]);
    const [files, setFiles] = useState([]);

    var contentChanged = false;
    var filesChanged = false;

    const userPreference = {
        ShowRteToolbarWide: true,
        ShowRteToolbarNarrow: false
    }

    useEffect(() => {
        let files = [];
        let index = 0;
        _.forEach(props?.discussionReplyResult?.associations, a =>{
            var itemType = isNumber(a.itemType) ? FileType[Number(a.itemType)] : a.itemType;
            if (itemType == "Image"){
                let imgUrl = '/Services/GetImage.ashx?id=' + a.associatedItemId;
                UrlUtils.toDataURL(
                    imgUrl, 
                    (dataUrl) => {
                      let base64String = dataUrl.replace(/^data:image\/(png|jpg|bmp|jpeg|gif);base64,/,"");
                      let blob = UrlUtils.b64toBlob(base64String);
                      let objectUrl = URL.createObjectURL(blob);
                      files.push(new FileHeader(a.associatedItemId, a.displayName, itemType, {url:imgUrl}, objectUrl, index++));  
                      setFiles(files);                  
                    }, '');
            }else{
                files.push(new FileHeader(a.associatedItemId, a.displayName, itemType, null, null, index++));
                setFiles(files); 
            }
        });

        setAttachedPhotos(_.filter(props?.discussionReplyResult?.associations, isFile).map(association => AssociationUtils.buildFile(association)));
        setLinks(_.filter(props?.discussionReplyResult?.associations, isLink).map(association => AssociationUtils.buildLink(association)));
    }, []);

    const onChangeContent = (value, e) => {
        setContent(value);
        contentChanged = (value !== props.discussionReplyResult.reply.content);
        setIsSaveEnabled(filesChanged || contentChanged);
    }

    const onSaveChanges = (e) => {
        e.preventDefault();

        if (props.discussionReplyResult.userRights != UserRightsType.Author) {
            setDisplayEditModal(true);
        } else {
            updateReply();
        }
    }

    const onFilesChanged = (files: any[], links: any[]) => {
        let validFiles = _.filter(files,(file) => file.error === undefined);
        setAssociations(AssociationUtils.getAssociationInputs(validFiles, links));
        filesChanged = true;
        setIsSaveEnabled(true);
    }

    const updateReply = () => {
        props.onUpdateReply(content, associations);
        props.onRequestClose();
    }

    const onEditModalConfirm = () => {
        updateReply();
        setDisplayEditModal(false);
        props.onRequestClose();
    }

    const onEditModalClose = (e) => {
        setDisplayEditModal(false);
        props.onRequestClose();
    }

    function _onFileUploadFailed(err) {
        PromptActions.displayFriendlyErrorPrompt(err);
    }

    function _onFileUploadClose() {
        setIsActive(false);

    }

    function _onFileUploaded(result, domFiles) {

        let file = FileFactory.fileFromObject(result);

        if (file == null) {
            PromptActions.displayPrompt(Localisation.localisedStringFor('Error'), Localisation.localisedStringFor('ErrorUnexpectedResponse'));
            return;
        }

        let photos = [...attachedPhotos, file].sort((left, right) => {
            let l = domFiles.findIndex(i => i.id == left.id);
            let r = domFiles.findIndex(i => i.id == right.id);

            return l < r ? -1 : 0;
        });

        setAttachedPhotos(photos);
    }

    const onUpdateFileList = (f) => {
        setFiles(f);
        setAttachedPhotos(_.filter(attachedPhotos, photo => _.some(f, file => photo.id == file.id)));
        onFilesChanged(f, links);
    }

    const onUpdateLinks = (e) => {
        setLinks(e);
        onFilesChanged(files, e);        
    }

    function showUploadModal(e): MouseEventHandler<HTMLAnchorElement> {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setIsActive(true);
        return e;
    }


    return (
        <div>
            {props.discussionReplyResult && (props.discussionReplyResult.userRights != UserRightsType.Author) &&
                <ConfirmationModal displayModal={displayEditModal} heading="Edit reply"
                    reasonMessage="Reason for editing this reply"
                    confirmMessage="Are you sure you want to update this reply?"
                    userRights={props.discussionReplyResult.userRights} title={props.title}
                    onConfirm={onEditModalConfirm} onRequestClose={onEditModalClose} />
            }
            <div className="inline-edit form-fields create-post __active">
                <div className="form-fields">
                    <RichTextEditor value={content} onValueChange={onChangeContent} userPreference={userPreference}
                        readOnly={false} spellcheck={true} mode={RichTextEditorMode.ActivityPost} />
                </div>
               {(attachedPhotos && attachedPhotos.length > 0) &&
                    <div className="form-field create-post__inputs">
                        <AttachedPhotosFiles files={attachedPhotos} onClick={showUploadModal} />
                    </div>
                }
                <div className="form-field">
                    <div className="buttons">
                        <div className="inline">
                            <BtnUpload url={AppDelegate.appSettings.fileUploadUrl} btnStyle={null}
                                                title="Add/Edit File" label="Add/Edit File" className="create-post__types-type" btnIconStyle="fn-icon fn-icon-upload"
                                                allowMultiple={true} allowRemove={true} active={isActive} amountLimit={10}
                                                category='Group'
                                                selectedGroupOrEvent={props.groupId}
                                                canAddExisting={true}
                                                showNotify={false}
                                                updateFileList={onUpdateFileList}
                                                uploadFormData={[{ name: 'Type', value: 'GroupPost' }]}
                                                links={links}
                                                files={files}
                                                onUpdateLinks={onUpdateLinks}
                                                attachedPhotos={attachedPhotos}
                                                onFileUploadCompleteImmediate={_onFileUploaded}
                                                onFileUploadClose={_onFileUploadClose}
                                                onFileUploadFailed={_onFileUploadFailed}>
                                                <span className="fn-icon fn-icon-upload"></span>Add/Edit File
                                            </BtnUpload>
                            &nbsp;
                            <button className="btn btn-default" onClick={props.onRequestClose}>Cancel</button>
                            &nbsp;
                            <button className="btn btn-small btn-primary" disabled={!isSaveEnabled}
                                onClick={onSaveChanges}>Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import Modal 			from '../core/components/modal/Modal';
import BtnClose			from '../core/components/BtnClose';
import _	from 'lodash';


export default class ModalConfirmWithReasonTrigger
{
    constructor(ele, props)
    {
        this.node = ele
        this.props = props;
        
        this.wrapper = document.createElement('span');
        this.node.parentNode.insertBefore(this.wrapper, this.node);
        this.node.parentNode.removeChild(this.node);
        this.wrapper.appendChild(this.node);

        this.wrapperListener = this._click.bind(this);
        if (this.wrapper.addEventListener)
        {
            this.wrapper.addEventListener('click', this.wrapperListener, true);
        }
        else
        {
            this.wrapper.attachEvent('onclick', this.wrapperListener);
        }
    }

    _click(e)
    {
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        if (!this.dialog) {
            this.dialogNode = document.createElement('div');
            this.dialogNode.setAttribute('style', 'display: none');
            document.body.appendChild(this.dialogNode);
            this.dialog = ReactDOM.render(this._renderComponent(), this.dialogNode)
        }
        this.dialog.unSuspend();

        return false;
    }

    _showModal()
    {
		this.dialog.unSuspend();
	}

    _closeModal()
    {
		this.dialog.suspend();
    }

    _confirmClick(reason)
    {
        let el = document.getElementById(this.props.reasonResult);
        el.value = reason;

        let mouseEvent;

        try
        {
            mouseEvent = new MouseEvent("click", {
                "view": window,
                "bubbles": false,
                "cancelable": false
            });
            this.node.dispatchEvent(mouseEvent);
        }
        catch (e)
        {
            if ('createEvent' in document)
            {
                mouseEvent = document.createEvent('MouseEvent');
                mouseEvent.initMouseEvent("click", false, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                this.node.dispatchEvent(mouseEvent);
            }
            else
            {
                // IE8 hack
                eval(this.node.pathname);
            }
        }
    }

    _renderComponent()
    {
        return (
            <ModalConfirmWithReasonTriggerComponent heading={this.props.heading} message={this.props.message} reasons={this.props.reasons} otherReason={this.props.otherReason} onConfirm={this._confirmClick.bind(this)} />
        )
    }
}

class ModalConfirmWithReasonTriggerComponent extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            display: false,
            valid: false,
            selectedReason: null,
            otherReason: '',
            expanded: false,
            disabled: props.disabled === true
        };
    }
    
    suspend()
    {
        this.setState({
            display: false
        })
    }
    
    unSuspend()
    {
        this.setState({
            display: true
        })
    }

    confirm(e)
    {
        e.preventDefault();
        this.props.onConfirm(this.getReason(this.state));
    }
    
    handleRadioChange(e)
    {
        this.setState({
            selectedReason: e.target.value
        });
    }
    
    handleTextChange(e)
    {
        this.setState({
            otherReason: e.target.value
        });
    }

    handleKeyPress(e)
    {
        if (e.key == 'Enter') 
        {
            e.preventDefault();
            this.confirm(e);
        }
    }

    getReason(state)
    {
        if (state.selectedReason == null) return '';
        if (state.selectedReason != 'Other') return state.selectedReason;
        if (state.otherReason == null) return '';
        if (state.otherReason.trim() == '') return '';
        return state.otherReason.trim();
    }

    checkValid(state)
    {
        return this.getReason(state) != '';
    }

    render()
    {
        var reasons = this.props.reasons;
        if (this.props.otherReason) {
            reasons = reasons.concat(['Other']);
        }

        return (
            <Modal active={this.state.display} onRequestsClose={this.suspend.bind(this)} hostInAspNetForm={true}>
                <div className='modal-header' style={{height: 'auto'}}>
                    <BtnClose onClick={this.suspend.bind(this)} />
                    <h2 className='modal-title'>{this.props.heading}</h2>
                </div>
                <div className='modal-content' dangerouslySetInnerHTML={{ __html: '<br/>' + this.props.message }} />
                <div className='modal-content form-group'>
                    {_.map(reasons, r => {
                        return (
                            <div className='radio' style={{border: 'none'}}>
                                <label>
                                    <input type='radio' checked={r == this.state.selectedReason} value={r} onChange={this.handleRadioChange.bind(this)} />
                                    {r}
                                </label>
                            </div>
                        )
                    })}
                    <div style={{minHeight: '35px'}}>
                        { this.state.selectedReason == 'Other' ?
                            <input type='text' value={this.state.otherReason} onChange={this.handleTextChange.bind(this)} onKeyPress={this.handleKeyPress.bind(this)} className='form-control' spellCheck="true" />
                        : null }
                    </div>
                </div>
                <div className='modal-actions'>
                    <button role="button" className="btn btn-secondary confirm" onClick={this.confirm.bind(this)} disabled={!this.checkValid(this.state)}>OK</button>
                    <button role="button" className="btn btn-tertiary cancel" onClick={this.suspend.bind(this)}>Cancel</button>
                </div>
            </Modal>
        )
    }
}

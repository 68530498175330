import React, {
    FC,
    useEffect,
    useState,
} from "react";
import PromptActions from "../../prompt/actions/PromptActions";
import Localisation from "../../../core/resources/Localisation";
import SvgIcon              from '../../../icons';
import { GroupSummary } from './GroupSummary';
import { GroupSelector } from "../../../modules/activityfeed/components/GroupSelector";
type CallbackFunctionSave = (item: any) => void
type CallbackFunctionDelete = (id:string) => void
type CallbackFunctionEdit = (edit: boolean) => void
type CallbackFunctionCancelNew = () => void
interface IGroupListProps {
    groupList: object,
    onsave: CallbackFunctionSave,
    ondelete: CallbackFunctionDelete,
    oncancelnew: CallbackFunctionCancelNew,
    types: Array<string>,
    edit: boolean,
    isnew: boolean,
    setEdit: CallbackFunctionEdit
}
export const GroupList : FC<IGroupListProps> = (props: IGroupListProps) => {
    const [name, setName] =  useState("") ;
    const [editSelectedGroups, setEditSelectedGroups] =  useState([]) ;
    const [isSetProp, setisSetProp] =  useState(false) ;
    const onClickEdit = (e) => {
        e.preventDefault();
        setEditSelectedGroups(props.groupList.groups);
        setName(props.groupList.name);
        setisSetProp(true);
        props.setEdit(true);
    }
    const onClickCancelEdit = (e) => {
        e.preventDefault();
        props.setEdit(false);
        if (props.isnew) {
            props?.oncancelnew();
        }
    }
    const onClickSave = (e) => {
        e.preventDefault();
        var item = new Object();
        if(!isSetProp && (name === undefined || name === ""))
        {
            setEditSelectedGroups(props.groupList.groups);
            setName(props.groupList.name);
            item.name = props.groupList.name;
            item.groups = props.groupList.groups;
            item.groupListId = props.groupList.groupListId;
        }
        else
        {
        item.name = name;
        item.groups = editSelectedGroups;
        item.groupListId = props.groupList.groupListId;
        }
        if (item.name !== undefined && item.name !== null && item.groups !== undefined && item.groups.length > 0) {
            setisSetProp(true);
            props?.onsave(item);
            props.setEdit(false);
        }
    }
    const onSetSelectedGroups = (groups) => {
        setEditSelectedGroups(groups);
    }
    const onRemoveGroup = (gid) => {
        var  groups = [];
        if(props.edit )
         {
            groups = editSelectedGroups.filter(g => g.groupId !== gid);
            setEditSelectedGroups(groups)  
           
         }
         else
         {
            groups =  props.groupList.groups.filter(g => g.groupId !== gid);
         }
        
        if (props.groupList.groupListId !== null) {
            var item = new Object();
            item.name = props.groupList.name;
            item.groups = groups;
            item.groupListId = props.groupList.groupListId;
            props?.onsave(item);
        }
    }
    const onClickDeleteGroupList = (e) => {
        e.preventDefault();
        var line1 =  `Are you sure you want to remove the list "###"?`.replace("###",props.groupList.name);
        var line2 =  ` This will permanently delete the list from the group.`;
        PromptActions.displayPromptWithActions(Localisation.localisedStringFor("PostGroupListDelete"), (<div><p>{line1}</p><p>{line2}</p></div>) , [{
            label: Localisation.localisedStringFor('Cancel'),
            className: 'btn btn-default'
        }, {
            label: Localisation.localisedStringFor('Confirm'),
            handler: () => props?.ondelete(props.groupList.groupListId),
            className: 'btn btn-primary'
        }]);
    }
    const blur = () => {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }
    const listvalid = () => {
       
        if (name !== undefined && name !== null && editSelectedGroups !== undefined && editSelectedGroups.length > 0) {
            return true;
        }
        return false;
    }
    return (
        <div className="distribution-list">
            { props.edit && 
            <ul className="group-lists edit">
                <li className="selected-list no-border">
                    {!props.isnew && < div > <input type="text" className="form-control inline" value={name} onKeyPress={(e) => { if (e.charCode === 13) { e.preventDefault() }} } onChange={(e) => { setName(e.target.value) }} /></div>}
                    {props.isnew && < div > <input type="text" className="form-control inline" onKeyPress={(e) => { if (e.charCode === 13) { e.preventDefault() } }} onChange={(e) => { setName(e.target.value) }} /></div>}
                    <div><a href="#"  className="group-lists-btn btn btn-default" onClick={onClickCancelEdit}>Cancel</a></div>
                    {listvalid() && <div><a href="#" className="group-lists-btn btn btn-primary" onClick={onClickSave}>Save</a></div>}
                    {!listvalid() && <div><a  className="group-lists-btn btn " >Save</a></div>}
                </li>
            </ul>
            }
            { !props.edit &&
            <ul className="group-lists">
                <li className="no-border">
                    <div><h3>{ props.groupList.name }</h3></div>
                    <a href="#" className="edit" onClick={onClickEdit}><SvgIcon icon="icon-edit" width={20} height={20} /></a>
                    <a href="#" className="btn-delete" onClick={onClickDeleteGroupList}><SvgIcon icon="icon-cross" width={20} height={20} /></a>
                </li>
            </ul>
            }
            <div className="groups">
                { props.edit && 
                <div className="add-group panel-message">
                    <div>
                        <p><strong>Add more groups</strong></p>
                        <p>Search for a group and then add them to your distribution list. 
                            Note: You can only search for and add official and/or professional 
                            groups (not social groups).</p>
                    </div>                  
                    {!props.isnew && <GroupSelector selectedGroups={props.groupList.groups} onEmit={onSetSelectedGroups} types={props.types} />}
                    {props.isnew && <GroupSelector selectedGroups={props.groupList.groups} onEmit={onSetSelectedGroups} types={props.types} />}
                </div>
                }
                { !props.edit &&
                <>
                    <h4>Current Groups ({ props.groupList.groups.length } groups)</h4>
                    <ul className="group-lists">
                    { props.groupList.groups.map(group => (
                        <GroupSummary group={group} key={group.groupId} id={group.groupId} removegroup={onRemoveGroup} />
                    )) }
                    </ul>  
                </>  
                }            
            </div>
            { props.edit &&
            <ul className="group-lists edit">
            <li className="no-border">
                    <p></p>
                    <div><a href="#" className="group-lists-btn btn btn-default" onClick={onClickCancelEdit}>Cancel</a></div>
                    {listvalid() && <div><a href="#" className="group-lists-btn btn btn-primary" onClick={onClickSave}>Save</a></div>}
                    {!listvalid() && <div><a className="group-lists-btn btn " >Save</a></div>}
                </li>
            </ul>
            }
        </div>
    )
}
import React, {
    FC,
    MouseEventHandler,
    useState,
    useRef,
    useEffect
} from "react";
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { association, forumDiscussionResult,
       } from "../../../../types/graphqlTypes";
import { AssociationType } from "../model/Enums";
import AssociationUtils from "./AssociationUtils";
import DiscussionCard from "./DiscussionCard";
import ViewMediaModal from '../../../core/components/viewmedia/ViewMediaModal';
import { ForumAssociationInput } from "../../../../types/graphqlTypes";

interface IDisplayFileListProps {
    associations: association[] | null,
    discussionResult: forumDiscussionResult,
    onRemoveLink?: (link: any, e: any) => MouseEventHandler<HTMLAnchorElement>,
}

export const DisplayFileList: FC<IDisplayFileListProps> = (props: IDisplayFileListProps) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [isModalActive, setIsModalActive] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const isPhoto = (association: ForumAssociationInput) => association.associationType == AssociationType.AttachedPhoto;
 
	const coverThumbnailUrl = (image) => {
        let href: string = "/Services/GetImage.ashx?id=" + image.associatedItemId;
		return href
	} 
    const _openImage = (i, e) => {
        setCurrentIndex(i);
        setIsModalActive(true);
	}

    const _handleModalCloseRequest = (e) => {
        setCurrentIndex(0);
        setIsModalActive(false);
	}

    return (
        <div>
            { _.filter(props?.associations, (image) => { return image.associationType == AssociationType.AttachedPhoto; }).length > 0 ? <div className="image-collection">
                <div className="image-collection-cover">                
                    {_.map(_.filter(props?.associations, (image) => { return image.associationType == AssociationType.AttachedPhoto; }), (image, i: number) => {     
                        const url = coverThumbnailUrl(image);
                        return (
                            <div key={i} className="image-collection-thumb-container thumbs-8 eight">
                                <a href="#" role="button" className="image-collection-thumb file-item" onClick={_openImage.bind(this, i)} style={{backgroundImage : 'none'}}>
                                    <span className="sr-only">Image gallery</span>
            
                                    {image.fileType == 'VIDEO' && 
                                        <span className="icon-play-icon"></span>
                                    } 
            
                                    {(url != null) ?
                                        <img src={url} />
                                    : null}
            
                                    { image.fileType == 'AUDIO' &&
                                        <div className="icon-container audio-icon-container">
                                            <span className="file-icon icon-file-icon-audio" aria-hidden="true" />
                                        </div>
                                    }   
                                </a>
                            </div>
                        );                           
                    })} 
                    {isModalActive &&
                                <ViewMediaModal
                                headerCard={<DiscussionCard discussionResult={props.discussionResult} />}
                                onRequestsClose={_handleModalCloseRequest.bind(this)}
                                items={_.filter(props?.associations, isPhoto).map(association => AssociationUtils.buildFile(association))}
                                index={currentIndex}
                                containerId={1}
                            />
                    }            
                </div>
            </div>
            : null }
            <div>
                <ul className="related-files">                
                    {_.map(_.filter(props?.associations, (image) => { return image.associationType != AssociationType.AttachedPhoto; }), (association, i: number) => {     
                        let iconType: string;
                        let href: string = "/services/DownloadFile.ashx?FileId=" + association.associatedItemId;

                        if (association.associationType == AssociationType.GroupMedia
                            || association.associationType == AssociationType.AttachedPhoto) {
                            iconType = "image";
                        } else if (association.associationType == AssociationType.GroupDocument
                            || association.associationType == AssociationType.AttachedFile) {
                            iconType = "document";
                        } else if (association.associationType == AssociationType.EmbedlyResult) {
                            iconType = "document";
                        } else {
                            iconType = "folder";
                            href = "/community/viewgroup?Id=" + searchParams.get('Id') + "&View=Files&FolderId=" + association.associatedItemId + "&Category=Document";
                        }

                        return <li key={association.associatedItemId}>
                            <a href={href} target="_blank">
                                <span className={`fn-icon fn-icon-${iconType}`}></span>
                                <span className="title"> {association.displayName} </span>
                                <span className="description">{association.description}</span>
                            </a>
                        </li>;
                    })}                      
                </ul>
            </div>              
        </div>   
    );
}

export default DisplayFileList;
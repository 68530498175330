import * as React from "react";

function SvgIconBin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8 20v40c0 2.2 1.8 4 4 4h36c2.2 0 4-1.8 4-4V20H8zm12 36h-4V28h4v28zm8 0h-4V28h4v28zm8 0h-4V28h4v28zm8 0h-4V28h4v28zM53 8H40V3c0-1.65-1.35-3-3-3H23c-1.65 0-3 1.35-3 3v5H7c-1.65 0-3 1.35-3 3v5h52v-5c0-1.65-1.35-3-3-3zM36 8H24V4.05h12V8z"
      />
    </svg>
  );
}

export default SvgIconBin;

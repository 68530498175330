import React, {
    FC,
    useState,
    useEffect,
    useRef,
}                           from "react";
import _	                from 'lodash';
import moment               from 'moment';

import { 
    useLazyQuery, 
    useMutation 
}                           from "@apollo/client";

import Pluralize            from "../../../vendor/typescript-pluralize/index";

import {
    getMyActions_myActions,
    PersonalActionType
}                           from "../../../../types/graphqlTypes";

import SvgIcon              from '../../../icons';
import PromptActions        from "../../prompt/actions/PromptActions";

const getMyActionsGql = require("../../../../gql/getMyActions.gql");

const DateColumn = "DATE";
const TypeColumn = "TYPE";
const ExpiryDateColumn = "EXPIRY_DATE";

import DateTimeUtils from "../../../core/utils/DateTimeUtils";
const renderDate = DateTimeUtils.renderDate;

const makePlural = (word: string, count: number) => count != 1 ? Pluralize.plural(word) : word;

interface IPersonalActionProps {
    action: getMyActions_myActions,
    refresh: any;
    sortColumn: string;
}

const PersonalAction : FC<IPersonalActionProps> = (props: IPersonalActionProps) => {
    const action = props.action;
    const dt = props?.action.date ? moment(props?.action.date) : "";
    const expiryDt = props?.action?.expiryDate && moment(props?.action.expiryDate);

    const isInvitedAsGroup = () => {
        if (action.event && action.notification) {
            const re = new RegExp("<b.*?>(?<p>.*?)</b>", "gm");
            const matches = [];
            let result;

            while ((result = re.exec(action.notification.text))) {
                matches.push(result[1]);
            }

            if (matches.length == 3 && matches[0] == matches[2]) {
                return true;
            }
        }

        return false;
    }

    var EOT = moment("9999/01/01", "YYYY/MM/DD");
    const hasEndDate= (theDate) => {
        return theDate.diff(EOT) < 0;
    }
    
    const hasUser = action.user && !isInvitedAsGroup();

    return (
        <tr>
            <td className={props.sortColumn == TypeColumn ? "sort-active" : ""} data-th="Type">{_.capitalize((action.type.split("_").join(" ")).toLowerCase())}</td>
            <td className={props.sortColumn == DateColumn ? "sort-active" : ""} data-th="Date">{renderDate(dt)}</td>
            <td className={props.sortColumn == ExpiryDateColumn ? "sort-active" : ""} data-th="Expiry date">{renderDate(expiryDt)}</td>
            <td>
                <div>
                    { hasUser && <div className="fn-action-user fn-action-avatar">
                        <img src={`/api/v1/image?id=${action.user.profileImageId}&amp;width=30&amp;height=30`} />
                    </div> }
                    { !hasUser && action.group && <div className="fn-action-avatar">
                        <img src={`/api/v1/image?id=${action.group.photoId}&amp;width=30&amp;height=30`} />
                    </div> }
                    <div>
                        { hasUser && <a href={`/viewprofile?Id=${action.user.id}`} target="_blank" rel="noreferrer">{action.user.fullName}</a> }
                        { !hasUser && action.group && <a href={`/community/viewgroup?Id=${action.group.groupId}`} target="_blank" rel="noreferrer">{action.group.name}</a>}
                        <span> {action.title} </span>
                        { action.event && <a href={`/community/viewevent?Id=${action.event.eventId}`} target="_blank" rel="noreferrer">{action.event.name}</a>}
                        { action.event && action.event.groupId && <span> in <a href={`/community/viewgroup?Id=${action.group.groupId}`} target="_blank" rel="noreferrer">{action.group.name}</a></span>}
                        { action.event && !action.event.groupId && action.user && <span> by <a href={`/viewprofile?Id=${action.user.id}`} target="_blank" rel="noreferrer">{action.user.fullName}</a></span>}
                        { !action.event && action.group && <a href={`/community/viewgroup?Id=${action.group.groupId}`} target="_blank" rel="noreferrer">{action.group.name}</a>}
                    </div>
                </div>
            </td>
            <td className="fn-action-links">
                { action.type == PersonalActionType.GROUP_INVITATION && <a href={"/community/groups?View=Invitations"}>View</a> }
                { action.type == PersonalActionType.EVENT_INVITATION && <a href={"/community/events?View=Invitations"}>View</a> }
                { action.type == PersonalActionType.PATTERN_OF_SERVICE_UPDATE_REMINDER && <a href={"/viewprofile/edit-patterns-of-service"}>View</a> }
                { action.type == PersonalActionType.PROFILE_UPDATE_REMINDER && <a href={"/viewprofile/editprofile"}>View</a> }
                { [PersonalActionType.FAMILY_SPONSORSHIP_RENEWAL, PersonalActionType.FAMILY_SPONSORSHIP_REQUEST].includes(action.type) && <a href={`/familiesconnection?Id=${action.familyConnection.id}`}>View</a> }
            </td>
        </tr>
    )
}

interface IPersonalActionsProps {
    setPersonalCount: any;
}

export const PersonalActions : FC<IPersonalActionsProps> = (props: IPersonalActionsProps) => {
    const [getActions, { loading, data, error, networkStatus, refetch }] = useLazyQuery(getMyActionsGql);
    const actions: Array<getMyActions_myActions> = data?.myActions;
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const SortAscending = "sort-asc";
    const SortDescending = "sort-desc";
    const [showAllRows, setShowAllRows] = useState(false);
    const [showSortByMenu, setShowSortByMenu] = useState(false);
    const [showActions, setShowActions] = useState(true);

    useEffect(() => {
        const savedSort = sessionStorage.getItem("personal-actions-sort");

        if (savedSort) {
            const typeAndOrder = savedSort.split(":");

            if (typeAndOrder.length == 2) {
                setSortColumn(typeAndOrder[0]);
                setSortOrder(typeAndOrder[1]);
            }
        }

        getActions();
    }, []);

    const refresh = () => {
        refetch();
    }

    const setSortOrderAndType = (val, order) => {
        let newSortOrder; 

        if (sortColumn == val) {
            if (!order) {
                if (sortOrder == SortDescending) {
                    newSortOrder = SortAscending;
                } else {
                    newSortOrder = SortDescending;
                }
            } else {
                newSortOrder = order;
            }
        } else {
            setSortColumn(val);
            newSortOrder = order ?? SortAscending;
        }

        setSortOrder(newSortOrder);

        sessionStorage.setItem("personal-actions-sort", `${val}:${newSortOrder}`);

        setShowSortByMenu(false);
    }

    const sortByType = () => {
        setSortOrderAndType(TypeColumn, null);
    }

    const sortByTypeAscending = () => {
        setSortOrderAndType(TypeColumn, SortAscending);
    }

    const sortByTypeDescending = () => {
        setSortOrderAndType(TypeColumn, SortDescending);
    }

    const sortByDate = () => {
        setSortOrderAndType(DateColumn, null);
    }

    const sortByDateAscending = () => {
        setSortOrderAndType(DateColumn, SortAscending);
    }

    const sortByDateDescending = () => {
        setSortOrderAndType(DateColumn, SortDescending);
    }

    const sortByExpiryDate = () => {
        setSortOrderAndType(ExpiryDateColumn, null);
    }

    const sortByExpiryDateAscending = () => {
        setSortOrderAndType(ExpiryDateColumn, SortAscending);
    }

    const sortByExpiryDateDescending = () => {
        setSortOrderAndType(ExpiryDateColumn, SortDescending);
    }

    const dateComparer = (left, right) => {
        if (!left || !right) {
            if (left && !right) {
                return sortOrder == SortAscending ? -1 : 1;
            }
            if (right) {
                return sortOrder == SortDescending ? -1 : 1;
            }
    
            return 0;
        }
    
        const ldate = moment(left);
        const rdate = moment(right);
        
        return (sortOrder == SortAscending ? ldate.isBefore(rdate) : ldate.isAfter(rdate)) ? -1 : 1;
    }

    if (actions) {
        props.setPersonalCount(actions.length);
    }

    const sortedActions = sortColumn && actions
        ? _.cloneDeep(actions).sort((l, r) => {
            if (sortColumn == TypeColumn) {
                return sortOrder == SortAscending
                    ? l.type < r.type ? -1 : 1
                    : r.type < l.type ? -1 : 1;
            } else if (sortColumn == DateColumn) {
                return dateComparer(l.date, r.date);
            } else if (sortColumn == ExpiryDateColumn) {
                return dateComparer(l.expiryDate, r.expiryDate);
            }
        })
        : actions;

    const toggleShowAll = () => {
        if (showAllRows) {
            window.scrollTo({top: 0});
        }

        setShowAllRows(!showAllRows)
    }

    const toggleSortByMenu = (e) => {
        e.preventDefault();
        setShowSortByMenu(!showSortByMenu);
    }

    const toggleShowActions = (e) => {
        e.preventDefault();
        setShowActions(!showActions);
    }

    const maxRows = 2;
    const maxRowsExceeded = sortedActions?.length > maxRows;
    const tbodyClass = maxRowsExceeded && !showAllRows ? "mobile-max" : "";

    let sortText;

    switch (sortColumn) {
        case TypeColumn:
            sortText = `: Type (${sortOrder !== SortDescending ? "A-Z" : "Z-A"})`;
            break;
        case DateColumn:
            sortText = `: Date (${sortOrder !== SortDescending ? "most recent first" : "least recent first"})`;
            break;
        case ExpiryDateColumn:
            sortText = `: Expiry date (${sortOrder !== SortDescending ? "most recent first" : "least recent first"})`;
            break;
    }

    return (
        <div>
            <div className="heading" onClick={toggleShowActions}>
                <h3>Personal</h3>
                { loading ? <span>Loading…</span> : null}
                { !loading && sortedActions ? <span>{sortedActions.length} {makePlural("action", sortedActions.length)}</span> : null}
                <SvgIcon icon={`${showActions ? "icon-arrow-up" : "icon-arrow-down"}`}/>
            </div>
            { !loading && showActions && sortedActions?.length > 0 && <div className="no-desktop fn-actions-menu">
                <a onClick={toggleSortByMenu}>Sort by{sortText}<SvgIcon icon={`${showSortByMenu ? "icon-arrow-up" : "icon-arrow-down"}`}/></a>
            </div>
            }
            { !loading && showActions && sortedActions?.length > 0 && <div className={`no-desktop fn-actions-menu-links${showSortByMenu ? " open" : ""}`}>
                <div className={sortColumn == TypeColumn && sortOrder == SortAscending ? "sort-active" : ""}><a className={`sort ${sortColumn == TypeColumn ? sortOrder : ""}`} onClick={sortByTypeAscending}>Type A-Z</a></div>
                <div className={sortColumn == TypeColumn && sortOrder == SortDescending ? "sort-active" : ""}><a className={`sort ${sortColumn == TypeColumn ? sortOrder : ""}`} onClick={sortByTypeDescending}>Type Z-A</a></div>
                <div className={sortColumn == DateColumn && sortOrder == SortAscending ? "sort-active" : ""}><a className={`sort ${sortColumn == DateColumn ? sortOrder : ""}`} onClick={sortByDateAscending}>Date most recent first</a></div>
                <div className={sortColumn == DateColumn && sortOrder == SortDescending ? "sort-active" : ""}><a className={`sort ${sortColumn == DateColumn ? sortOrder : ""}`} onClick={sortByDateDescending}>Date least recent first</a></div>
                <div className={sortColumn == ExpiryDateColumn && sortOrder == SortAscending ? "sort-active" : ""}><a className={`sort ${sortColumn == ExpiryDateColumn ? sortOrder : ""}`} onClick={sortByExpiryDateAscending}>Expiry date most recent first</a></div>
                <div className={sortColumn == ExpiryDateColumn && sortOrder == SortDescending ? "sort-active" : ""}><a className={`sort ${sortColumn == ExpiryDateColumn ? sortOrder : ""}`} onClick={sortByExpiryDateDescending}>Expiry date least recent first</a></div>
            </div>

            }

            { !loading && showActions && sortedActions?.length > 0 && <table className="table table-data sort u-align-center">
                <thead>
                    <tr>
                        <th className={`type ${sortColumn == TypeColumn ? "sort-active" : ""}`}><a className={`sort ${sortColumn == TypeColumn ? sortOrder : ""}`} onClick={sortByType}>Type</a></th>
                        <th className={`date ${sortColumn == DateColumn ? "sort-active" : ""}`}><a className={`sort ${sortColumn == DateColumn ? sortOrder : ""}`} onClick={sortByDate}>Date</a></th>
                        <th className={`date ${sortColumn == ExpiryDateColumn ? "sort-active" : ""}`}><a className={`sort ${sortColumn == ExpiryDateColumn ? sortOrder : ""}`} onClick={sortByExpiryDate}>Expiry date</a></th>
                        <th colSpan={2}>Action</th>
                    </tr>
                </thead>
                <tbody className={tbodyClass}>
                    { sortedActions?.map((action, index) => {
                        return <PersonalAction action={action} key={index} sortColumn={sortColumn} refresh={refresh}/>
                    })}
                </tbody>
            </table>
            }
            { showActions && maxRowsExceeded && <div className="no-desktop fn-actions-show-all">
                <a role="button" className="btn btn-secondary" onClick={toggleShowAll}>See {!showAllRows ? "more" : "less"} personal actions {!showAllRows ? "v" : "^"}</a>
            </div> }
        </div>
    );
}

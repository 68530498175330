import React 				from 'react';
import PropTypes from 'prop-types'

class LinkShare extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
		};
	}

	componentDidMount() {
		var self = this;

		window.setTimeout(() => {
			self.refs.url.focus();
		}, 300);
	}

	_handleCopy(event) {
		event.preventDefault();

		this.refs.url.select();
		this.refs.url.setSelectionRange(0, 99999);

		document.execCommand("copy");
	}

	render() {
		return (
		<span>
			<div className="form-horizontal">
				<div className="form-group">
					<div className="small-16 large-12 columns">
						<input ref="url" type="text" readOnly defaultValue={this.props.link} className="form-control" spellCheck="true" ></input>
					</div>
					<div className="small-4 large-4 columns">
						<button role="button" className="btn btn-primary" onClick={this._handleCopy.bind(this)}>COPY</button>
					</div>
				</div>
			</div>
		</span>
		);
	}
}

LinkShare.defaultProps = {
}

LinkShare.propTypes = {
	link:		PropTypes.string
};

export default LinkShare;

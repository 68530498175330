import React 				from 'react';
import PropTypes            from 'prop-types';
import _	                from 'lodash';

import { AdministrativeActions }    from "./components/AdministrativeActions";
import { PersonalActions }          from "./components/PersonalActions";

import "./ActionsRequiredModule.css"

class ActionsRequiredModule extends React.Component {
    personalCount = null;
    administrativeCount = null;
   
	constructor(props) {
        super(props);
    }
 

    componentDidMount() {
    }

    setPersonalCount = (count: number): void => {
        this.personalCount = count;
        this.refreshCount();
    }

    setAdministrativeCount = (count: number) => {
        this.administrativeCount = count;
        this.refreshCount();
    }
   


    refreshCount() {
        if (this.administrativeCount !== null && this.personalCount !== null) {
            const el = document.getElementById("actionCount")
            
            if (el) {
                el.innerText = this.personalCount + this.administrativeCount;
                el.style.display = this.personalCount + this.administrativeCount != 0 ? "block" : "none";
            }
        }
    }

    render(): JSX.Element {
        return <div id="primary" className="fn-actions">
            <div className="panel">
                <div className="panel-heading">
                    <h1 className="panel-title">Actions</h1>
                </div>
                <div className="panel-body">
                    <AdministrativeActions setAdministrativeCount={this.setAdministrativeCount} />
                    <PersonalActions setPersonalCount={this.setPersonalCount} />
                </div>
            </div>
        </div>
    }
}

ActionsRequiredModule.defaultProps = {
	className: '',
    items: [],
    validate: false,
    lastModified: null
};

ActionsRequiredModule.propTypes = {
	className:					PropTypes.string,
	options:					PropTypes.array,
    validate:					PropTypes.bool,
    lastModified:               PropTypes.string
};

export default ActionsRequiredModule;
import Promise					from 'bluebird';

import AppDelegate				from '../core/AppDelegate';
import HttpAuthenticatedSvc		from './HttpAuthenticatedSvc';


class NotificationSvc {

	deleteAll() {
		var req,
			endpoint = `${AppDelegate.appSettings.apiUrl}/Notifications.asmx/IgnoreNotification`,
			payload = {
				notificationId: 'all',
			},

			promise = new Promise((resolve, reject) => {
				req = HttpAuthenticatedSvc.post(endpoint, payload, resolve, reject);
			});

		return {
			promise: promise,
			abort: () => { req.abort(); }
		};
    }
}

export default new NotificationSvc();
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types'

import Select from 'react-select'


export default class RemovableDropDown extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        if (this.props.value) {
            console.log(`Default selection:`, this.props.value);
        }
    }

    onChange = (item) => {
        this.props.onChangeItem({
            index: this.props.index,
            item: item,
        });
    }

    onRemove = (e) => {
        this.props.onRemoveItem({
            index: this.props.index
        });
    }

    render() {
        return (
            <div className="row mt-1">
                <div className="columns small-14 large-12">
                    <Select id={this.props.id} name={this.props.name} value={this.props.value} onChange={this.onChange} options={this.props.options} />
                </div>
                <div className="small-2 large-4 columns end">
                    <a className="btn btn-basic mt-1" href="#" onClick={this.onRemove}>
                        <span className="fn-icon fn-icon-delete-sign-lg fn-icon-hover-primary"><span className="sr-only">Remove</span></span>
                    </a>
                </div>
            </div>
        );
    }

}
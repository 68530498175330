import React 				        from 'react';

export default class CalendarNavigator extends React.Component{
    constructor(props){
        super(props);
    }

    componentDidUpdate(){
        document.getElementById("sr-status-alert").setAttribute("role", "alert");
    }

    render(){
        return (
            <div className="calendar-Nav">
                <button role="button" className="btn btn-default btn-group-left" 
                        onClick={this.props.navigatePreMonthHandler}><span className="fn-icon icon-arrow-left-profile-dark fn-icon-white" aria-hidden="true"></span> <span aria-hidden="true">Prev</span><span className="sr-only">Previous month</span></button>
                <button role="button" className="btn btn-default btn-group-mid" 
                        onClick={this.props.navigatethisMonthHandler}><span aria-hidden="true">Today</span><span className="sr-only">Current month</span></button>
                <button role="button" className="btn btn-default btn-group-right" 
                        onClick={this.props.navigateNextMonthHandler}>Next <span className="sr-only"> month</span> <span className="fn-icon icon-arrow-right-profile-dark fn-icon-white" aria-hidden="true"></span></button>
            </div>
        );
    }
}
import React from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash';

import FileCard from './FileCard';
import ViewMediaModal from '../../../core/components/viewmedia/ViewMediaModal';

class ImageView extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			modalActive: false,
		};
	}

	show(e) {
		if (e && e.preventDefault) {
			e.preventDefault();
		}
		
		this.setState({
			modalActive: true,
		});
	}

	_renderCover() {
		let className = 'image-collection-cover'
		if (this.props.className)
		{
			className += ` ${this.props.className}`
		}

		return (
			<a href="#" role="button" onClick={this.show.bind(this)}>
				<div className={className}>
					{this.props.children}
				</div>
			</a>
		)
	}

	_renderGallery() {
		if (!this.state.modalActive) return null

		return (
			<ViewMediaModal
				headerCard={<FileCard user={this.props.user} dateUploaded={this.props.dateUploaded} title={this.props.title} />}
				onRequestsClose={this._handleModalCloseRequest.bind(this)}
				items={[this.props.file]}
				containerId={this.props.folderId}
			/>
		)
	}

	_handleModalCloseRequest() {
		this.setState({
			modalActive: false,
		});
	}

	render() {
		return (
			<div className="image-collection">
				<div>
					{this._renderCover()}
				</div>
				{this._renderGallery()}
			</div>
		);

	}
}

ImageView.defaultProps = {
};

ImageView.propTypes = {
	file: PropTypes.object,
	folderId: PropTypes.string,
	title: PropTypes.string,
	user: PropTypes.object,
	dateUploaded: PropTypes.object,
	className: PropTypes.string
};

export default ImageView;

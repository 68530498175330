import Modal 			from '../core/components/modal/Modal';
import BtnClose			from '../core/components/BtnClose';


export default class ModalConfirmTrigger
{
    constructor(ele, props)
    {
        this.node = ele
        this.props = props;
        
        this.wrapper = document.createElement('span');
        this.node.parentNode.insertBefore(this.wrapper, this.node);
        this.node.parentNode.removeChild(this.node);
        this.wrapper.appendChild(this.node);

        this.wrapper.addEventListener('click', this._click.bind(this), true);
    }

    _click(e)
    {
        e.preventDefault();

        if (!this.dialog) {
            this.dialogNode = document.createElement('div');
            this.dialogNode.setAttribute('style', 'display: none');
            document.body.appendChild(this.dialogNode);
            this.dialog = ReactDOM.render(this._renderDialog(), this.dialogNode)
        }
        this.dialog.unSuspend();

        return false;
    }

    _showModal()
    {
		this.dialog.unSuspend();
	}

    _closeModal()
    {
		this.dialog.suspend();
    }

    _confirmClick(e)
    {
        this.node.click();
        this._closeModal();
    }
    
    _renderDialog()
    {
        return (
            <Modal active={true} onRequestsClose={this._closeModal.bind(this)} hostInAspNetForm={true}>
                <div className='modal-header'>
                    <BtnClose onClick={this._closeModal.bind(this)} />
                    <h2 className='modal-title'>{this.props.confirmHeader}</h2>
                </div>
				<div className='modal-content' dangerouslySetInnerHTML={{ __html: this.props.confirmMessage }} />
                <div className='modal-actions'>
                    <button role="button" className="btn btn-secondary confirm" onClick={this._confirmClick.bind(this)}>OK</button>
                    <button role="button" className="btn btn-tertiary cancel" onClick={this._closeModal.bind(this)}>Cancel</button>
                </div>
            </Modal>
        )
    }
}
import * as React           from 'react';

export { default as IconArrowDown } from "./IconArrowDown";
export { default as IconArrowUp } from "./IconArrowUp";
export { default as IconArrowLeft } from "./IconArrowLeft";
export { default as IconArrowRight } from "./IconArrowRight";
export { default as IconBin } from "./IconBin";
export { default as IconBookmarkO } from "./IconBookmarkO";
export { default as IconBookmark } from "./IconBookmark";
export { default as IconBubble } from "./IconBubble";
export { default as IconChevronCircleRight } from "./IconChevronCircleRight";
export { default as IconCircleRight } from "./IconCircleRight";
export { default as IconCopy } from "./IconCopy";
export { default as IconCross } from "./IconCross";
export { default as IconDelete } from "./IconDelete";
export { default as IconEdit } from "./IconEdit";
export { default as IconFolder } from "./IconFolder";
export { default as IconForward } from "./IconForward";
export { default as IconRefresh } from "./IconRefresh";
export { default as IconReplyAll } from "./IconReplyAll";
export { default as IconReply } from "./IconReply";
export { default as IconReport } from "./IconReport";
export { default as IconTick } from "./IconTick";

import IconArrowDown from "./IconArrowDown";
import IconArrowUp from "./IconArrowUp";
import IconArrowLeft from "./IconArrowLeft";
import IconArrowRight from "./IconArrowRight";
import IconBin from "./IconBin";
import IconBookmarkO from "./IconBookmarkO";
import IconBookmark from "./IconBookmark";
import IconBubble from "./IconBubble";
import IconChevronCircleRight from "./IconChevronCircleRight";
import IconCircleRight from "./IconCircleRight";
import IconCopy from "./IconCopy";
import IconCross from "./IconCross";
import IconDelete from "./IconDelete";
import IconEdit from "./IconEdit";
import IconFolder from "./IconFolder";
import IconForward from "./IconForward";
import IconRefresh from "./IconRefresh";
import IconReplyAll from "./IconReplyAll";
import IconReply from "./IconReply";
import IconReport from "./IconReport"
import IconTick from "./IconTick";
    
const components = {
    "icon-arrow-down": IconArrowDown,
    "icon-arrow-up": IconArrowUp,
    "icon-arrow-left": IconArrowLeft,
    "icon-arrow-right": IconArrowRight,
    "icon-bin": IconBin,
    "icon-bookm-ark-o": IconBookmarkO,
    "icon-bookm-ark": IconBookmark,
    "icon-bubble": IconBubble,
    "icon-chevron-circle-right": IconChevronCircleRight,
    "icon-circle-right": IconCircleRight,
    "icon-cross": IconCross,
    "icon-copy": IconCopy,
    "icon-delete": IconDelete,
    "icon-edit": IconEdit,
    "icon-folder": IconFolder,
    "icon-forw-ard": IconForward,
    "icon-refresh": IconRefresh,
    "icon-reply-all": IconReplyAll,
    "icon-report": IconReport,
    "icon-reply": IconReply,
    "icon-tick": IconTick
};

const SvgIcon = props => {
    const TagName = components[props.icon];
    let cls = "fn-icon";

    if (props.className) {
        cls += " " + props.className;
    }

    if (props.disabled) {
        cls += " disabled";
    }

    return (<div className={cls}>
        <TagName {...props} />
    </div>)
}

export default SvgIcon

import React from "react";
import PropTypes from 'prop-types'

import FilterDateViewer from "./components/FilterDateViewer";
import CalendarNavigator from "./components/CalendarNavigator";
import Calendar from "./components/Calendar";
import moment from "moment";

import './Calendar.css';

export default class CalendarEventModule extends React.Component {

  constructor(props) {
    super(props);

    this.state = { currentMonth: moment().month(), currentYear: moment().year(),
                   minYearFilter: moment().year() - 3, maxYearFilter: moment().year() + 2};

    this.filterMonthHandler = this.filterMonthHandler.bind(this);
    this.filterYearHandler = this.filterYearHandler.bind(this);
    this.navigatePreMonthHandler = this.navigatePreMonthHandler.bind(this);
    this.navigatethisMonthHandler = this.navigatethisMonthHandler.bind(this);
    this.navigateNextMonthHandler = this.navigateNextMonthHandler.bind(this);
  }

  filterMonthHandler(e){
      this.setState({currentMonth : parseInt(e.target.value)});
  }

  filterYearHandler(e){
      this.setState({currentYear : parseInt(e.target.value)});
  }

  navigatePreMonthHandler(e){
    e.preventDefault();
    const previousMonthYear = moment().year(this.state.currentYear).month(this.state.currentMonth).add(-1,"month");
    if (previousMonthYear.year() >= this.state.minYearFilter){
      this.setState({currentMonth: previousMonthYear.month(), currentYear: previousMonthYear.year()});
    }
  }

  navigatethisMonthHandler(e){
    e.preventDefault();
    this.setState({currentMonth: moment().month(), currentYear: moment().year()});
  }

  navigateNextMonthHandler(e){
    e.preventDefault();
    const nextMonthYear = moment().year(this.state.currentYear).month(this.state.currentMonth).add(1,"month");
    if (nextMonthYear.year() <= this.state.maxYearFilter){
      this.setState({currentMonth: nextMonthYear.month(), currentYear: nextMonthYear.year()});
    }
  }

  render() {
    return (
      <div>
        <div className="panel">
          <div className="calendar-filter-wrapper">
            <FilterDateViewer filterMonthHandler={this.filterMonthHandler} 
                              filterYearHandler={this.filterYearHandler}
                              selectedMonth={this.state.currentMonth}
                              selectedYear={this.state.currentYear} 
                              minYearFilter={this.state.minYearFilter}
                              maxYearFilter={this.state.maxYearFilter} />
            <CalendarNavigator navigatePreMonthHandler={this.navigatePreMonthHandler}
                               navigatethisMonthHandler={this.navigatethisMonthHandler}
                               navigateNextMonthHandler={this.navigateNextMonthHandler} />
          </div>
          <div className="panel-body">
            <Calendar
              CurrentMonth={this.state.currentMonth}
              CurrentYear={this.state.currentYear}
              australianPublicHolidays = {this.props.australianPublicHolidays}
            />
          </div>
        </div>
      </div>
    );
  }
  }

CalendarEventModule.propTypes = {
	australianPublicHolidays:	PropTypes.string.isRequired
};

import React, {
    FC,
}                           from "react";

interface IActionsFilterProps {
    className: string;
    togglegroupapproval: React.ChangeEvent<HTMLInputElement>;
    groupapproval: boolean;
    togglejoin: React.ChangeEvent<HTMLInputElement>;
    join: boolean;
    togglejobapproval: React.ChangeEvent<HTMLInputElement>;
    jobapproval: boolean;
    togglejobexpiry: React.ChangeEvent<HTMLInputElement>;
    jobexpiry: boolean;
    togglecomplaints: React.ChangeEvent<HTMLInputElement>;
    complaints: boolean;
}

const ActionsFilter : FC<IActionsFilterProps> = (props: IActionsFilterProps) => {
   

    return (
        <ul className={`clean filter filter-horizontal ${props.className}`}>
            <li>
                <input id="groupapproval" type="checkbox" checked={props.groupapproval} onChange={props.togglegroupapproval } name="groupapproval"></input>
                <label htmlFor="groupapproval">Group approval</label>
            </li>
            <li>
                <input id="join" type="checkbox" checked={props.join} onChange={props.togglejoin}  name="join"></input>
                <label htmlFor="join">Join</label>
            </li>
            <li>
                <input id="jobapproval" type="checkbox" checked={props.jobapproval} onChange={props.togglejobapproval}  name="jobapproval"></input>
                <label htmlFor="jobapproval">Job approval</label>
            </li>
            <li>
                <input id="jobexpiry" type="checkbox" checked={props.jobexpiry} onChange={props.togglejobexpiry}  name="jobexpiry"></input>
                <label htmlFor="jobexpiry">Job expiry</label>
            </li>
            <li>
                <input id="complaints" type="checkbox" checked={props.complaints} onChange={props.togglecomplaints}  name="complaints"></input>
                <label htmlFor="complaints">Moderate</label>
            </li>
        </ul>
    );
}

export default ActionsFilter;
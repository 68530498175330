
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { AuditLog } from "../../../../core/models/AuditLog";
import AuditSvc from "../../../../services/AuditSvc";

export default class AuditLogViewerExport extends React.Component {

  searchQuery: Object;

  constructor(props) {
    super(props);

    this.state = {
      searchQuery: props.searchQuery
    };
  }

  //Update state if changed
  componentWillReceiveProps(nextProps) {
    if (this.state.searchQuery != nextProps.searchQuery) {
      this.setState({
        searchQuery: nextProps.searchQuery
      });
    }
  }

  _handleExport(e) {
    AuditSvc.ExportAudit(this.state.searchQuery);

    e.preventDefault();
  }

  render() {
    //let content = null;
    return <a href="#" className="btn btn-primary pull-right" onClick={this._handleExport.bind(this)}>
        Export to XLSX
      </a>;
  }
}
import Promise from 'bluebird';
import _		from 'lodash';

import FolderSvc from '../../../services/FolderSvc';


class ViewMediaActions {

	static videoStreamUrl(ids) {

        return new Promise((resolve, reject) => {
            FolderSvc.videoStreamUrl(ids)
                .promise
                .then((response) => {
                    resolve(response);
                })
        })
	}
}

export default ViewMediaActions;

import React from "react"
import RichTextEditor from "../../../core/components/richtext/RichTextEditor"


interface ReplyState {
    canSend: boolean,
    error: boolean,
    replyText: string,
}

interface ReplyProps {
    conversationId: string,
    sendReply: (conversationId:string, replyText: string, isReplyOriginalSender: boolean) => void,
    show: boolean,
    hideReply: () => void,
    replySingle: boolean,
}

class Reply extends React.Component<ReplyProps,ReplyState> {

    constructor(props) {
        super(props)
        this.state = {
            replyText: "",
            error: false,
            canSend: false,
        }
    }

    componentDidMount(){
       // this.message.current?.focus();
    }

    _onEditorChange = (value: string, empty: boolean) => {
        this.setState({
            replyText: value, 
            canSend: !empty,
        });
    }

    _submit = (e) => {
        e.preventDefault()

        if (!this.state.replyText) {
            this.setState({ error: true })
            return
        }

        this.props.sendReply(
            this.props.conversationId,
            this.state.replyText,
            this.props.replySingle
        )
        this.props.hideReply()
        var message = this.refs.message;
        if (message) {
            message.value = "";
        }
        this.setState({
            replyText: "",
            canSend: false
        })
    }

    render() {
        return (
            <div
                id="phbody_0_phcontent_0_ReplyToMessageContainer"
                className="panel-body form"
                style={{ display: this.props.show ? "block" : "none" }}>
                <div className="form-horizontal">
                    {this.state.error ? (
                        <div
                            className="alert alert-danger errorMessage js-hidden"
                            style={{ display: 'block'}}>
                            Please enter a message.
                        </div>
                    ) : null}

                    <div className="inline-edit">
                        <div className="inline-edit__inputs">
                            <label className="hidden control-label" htmlFor="input_message">
                                Message
                            </label>
                            <RichTextEditor
                                    maxLength={2000}
                                    value={this.state.replyText}
                                    onValueChange={this._onEditorChange}
                                    spellCheck="true"
                                    userPreference={this.props.userPreference}
                                    mode="Message"
                                    />
                        </div>
                        <div className="inline-edit__actions">
                            <input
                                type="submit"
                                name=""
                                value="Send message"
                                id=""
                                disabled={!this.state.canSend}
                                onClick={this._submit}
                                className="btn btn-secondary post"
                                spellCheck="true"
                            />
                            <a
                                href="#"
                                className="btn btn-default btn-cancel"
                                onClick={this.props.hideReply}>
                                Cancel
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Reply

import React, { Component } from "react";
import PropTypes from 'prop-types'

let ooyalaScriptsRequested = false; // eslint-disable-line immutable/no-let
let ooyalaStylesRequested = false;
const ooyalaLoader = [];

/**
 * Adds a player to the script load waiting queue.
 */
const queuePlayer = player => ooyalaLoader.push(player);

/**
 * Initializes all of the queued players.
 */
const loadPlayers = player => {
  ooyalaLoader.splice(0).forEach(player => player._createPlayer());
};

/**
 * Loads a list of scripts and fires a callback when done.
 */
const loadScripts = (list, callback) => {
  const waiting = list.slice();
  const loaded = [];

  const load = src => {
    let script = null;
    if (!src.toLowerCase().endsWith("css")) {
      script = document.createElement("script");
      script.src = src;
    } else {
      script = document.createElement("link");
      script.href = src;
      script.rel = "stylesheet"
    }

    script.onload = () => {
      done(src);

      if (waiting.length) {
        load(waiting.shift());
      }
    };
    const firstScript = script.src 
                        ? document.getElementsByTagName("script")[0] 
                        : document.getElementsByTagName("link")[0];
    firstScript.parentNode.insertBefore(script, firstScript);
  };

  const done = src => {
    loaded.push(src);
    if (loaded.length === list.length) {
      callback();
    }
  };

  load(waiting.shift());
};

/*
 * @class Displays an Ooyala video
 * @type: Presentational Component
 * @returns Ooyala
 */
class OoyalaPlayer extends Component {
  constructor(props) {
    super();

    const { ooyalaBranding, ooyalaPcode, isAutoPlay, version } = props;
    this._playerParam = {
      pcode: ooyalaPcode,
      playerBrandingId: ooyalaBranding,
      autoplay: isAutoPlay,
      loop: false,
      skin: {
        config: `//player.ooyala.com/static/v4/stable/${version}/skin-plugin/skin.json`,
        inline: {
          general: {
            watermark: {
              imageResource: {
                url: "",
                androidResource: "logo",
                iosResource: "logo"
              },
              position: "bottomRight",
              clickUrl: "",
              target: "_blank",
              transparency: 1,
              scalingOption: "default",
              scalingPercentage: 20
            },
            "loadingImage": {"imageResource": {"url": `//player.ooyala.com/static/v4/stable/${version}/skin-plugin/assets/images/loader_svg.svg` } },
            accentColor: "#cf4520"
          },
          startScreen: {
            promoImageSize: "default",
            showPlayButton: true,
            playButtonPosition: "center",
            playIconStyle: {
              color: "white",
              opacity: 1
            },
            showTitle: true,
            showDescription: true,
            titleFont: {
              color: "#cf4520"
            }
          },
          controlBar: {
            logo: {
              imageResource: {
                url: "",
                androidResource: "logo",
                iosResource: "logo"
              },
              clickUrl: "http://video.defence.gov.au",
              target: "_blank",
              width: 96,
              height: 24
            },
            scrubberBar: {
              backgroundColor: "rgba(175,175,175,0.5)",
              bufferedColor: "rgba(175,175,175,0.7)",
              playedColor: "",
              scrubberHandleColor: "#cf4520",
              scrubberHandleBorderColor: "#cf4520",
              thumbnailPreview: true
            }
          },
          shareScreen: {
            shareContent: ["social"],
            embed: {
              source: `<iframe width='640' height='480' frameborder='0' allowfullscreen src='//player.ooyala.com/static/v4/stable/${version}/skin-plugin/iframe.html?ec=<ASSET_ID>&pbid=<PLAYER_ID>&pcode=<PUBLISHER_ID>'></iframe>`
            }
          },
          closedCaptionOptions: {
            description: "Settings regarding closed captioning.",
            type: "object",
            properties: {
              defaultEnabled: {
                description: "Disable closed captions by default",
                type: "boolean",
                default: false
              },
              defaultLanguage: {
                description: "Set the default closed-caption language",
                type: "string",
                default: "en"
              }
            }
          }
        }
      }
    };

    this._initOoyalaPlayer.bind(this);
    this._createPlayer.bind(this);
  }

  componentDidMount() {
    const { ooyalaId, version } = this.props;

    if (!ooyalaScriptsRequested) {
      loadScripts(
        [
          `//player.ooyala.com/static/v4/stable/${version}/core.min.js`,
          `//player.ooyala.com/static/v4/stable/${version}/video-plugin/main_html5.min.js`,
          `//player.ooyala.com/static/v4/stable/${version}/skin-plugin/html5-skin.min.js`,
          `//player.ooyala.com/static/v4/stable/${version}/video-plugin/bit_wrapper.min.js`,
          `//player.ooyala.com/static/v4/stable/${version}/skin-plugin/html5-skin.min.css`
        ],
        () => {
          global.OO.ready(loadPlayers);
        }
      );

      ooyalaScriptsRequested = true;
    }

    this._initOoyalaPlayer();
  }

  componentWillUnmount() {
     if (this._player) {
       this._player.destroy();
     }
  }

  _initOoyalaPlayer() {
    const { ooyalaId } = this.props;

    if (!global.OO || !global.OO.Player) {
      queuePlayer(this);
    } else {
      this._createPlayer();
    }
  }

  _createPlayer() {
    if (this._player) return;

    const { ooyalaId } = this.props;

    this._player = global.OO.Player.create(
      `ooyala_player_${ooyalaId}_${this.props.postId}`,
      ooyalaId,
      this._playerParam
    );
  }

  render() {
    const { ooyalaId } = this.props;

    return <div id={`ooyala_player_${ooyalaId}_${this.props.postId}`} />;
  }
}

OoyalaPlayer.defaultProps = {
  ooyalaBranding: "f4b32ab0d0464b628e6dac4da6abf0c3",
  ooyalaPcode: "F4ZzIyOi-LDj1f7SH2kwHBmsHxoF",
  isAutoPlay: true,
  version: "4.23.6",
  postId: 0
};

/**
 * propTypes
 * @property {String} ooyalaId - the ID of the video
 * @property {String} ooyalaBranding - Ooyala branding config
 * @property {Strind} ooyalaPcode - Ooyala player code config
 */
OoyalaPlayer.propTypes = {
  ooyalaId: PropTypes.string.isRequired,
  ooyalaBranding: PropTypes.string,
  ooyalaPcode: PropTypes.string,
  isAutoPlay: PropTypes.bool,
  version: PropTypes.string,
  postId: PropTypes.number
};

export default OoyalaPlayer;

import React from 'react';
import PropTypes from 'prop-types'
import ToolTip			from '../../../core/components/ToolTip';
import DateTimeUtils, { DATE_TIME_STANDARD_RENDER_FORMAT } from '../../../core/utils/DateTimeUtils';
import FileUtils from '../../../core/utils/FileUtils';
import FileExplorerStore from '../stores/FileExplorerStore';
import ImageView from './ImageView';
import MediaAnalytics from './MediaAnalytics';
import { FileCategory }		from '../actions/FileExplorerActions';
import PostActions			from '../../../modules/activityfeed/actions/PostActions';
import Localisation			from '../../../core/resources/Localisation';
import ReactTooltip from 'react-tooltip';

import AppDelegate				from '../../../core/AppDelegate';

class FileItem extends React.Component {

	constructor(props) {
		super(props);

		this.state = { showAll: false };
	}

	_renderIcon() {

		var fileIcon = null;
		if (!this.props.file) {
			return null;
		}

		switch (this.props.file.fileType.toLowerCase()) {
			case 'word':
				fileIcon = 'icon-file-icon-doc';
				break;
			case 'excel':
				fileIcon = 'icon-file-icon-excel';
				break;
			case 'pdf':
				fileIcon = 'icon-file-icon-pdf';
				break;
			case 'powerpoint':
				fileIcon = 'icon-file-icon-powerpoint';
				break;
			case 'image':
				fileIcon = 'icon-file-icon-image';
				break;
			case 'csv':
				fileIcon = 'icon-file-icon-excel';
				break;
			case 'video':
				fileIcon = 'icon-file-icon-video';
				break;
			case 'audio':
				fileIcon = 'icon-file-icon-audio';
				break;				
		}


		return (<span className={`file-icon ${fileIcon}`} aria-hidden="true"></span>);

	}

	_renderThumbnail() {
		var isVideo = this.props.file.fileType === "Video" && this.props.file.hasVideoStream

		if (this.props.file.image) {
			let className = isVideo ? 'video-thumbnail' : null
			return (
				<ImageView ref="viewer" file={this.props.file} user={this.props.file.uploader}
					dateUploaded={this.props.file.dateUploaded} title={this.props.file.title} className={className} 
					folderId={this.props.folderId}>
					{isVideo && <span className='icon-play-icon'></span>}
					<img alt={this.props.file.title} src={this.props.file.image.urlWithMaxWidth(200)} />
				</ImageView>
			);
		}

		if ('audio' == this.props.file.fileType.toLowerCase()) {
			return (
				<ImageView ref="viewer" file={this.props.file} user={this.props.file.uploader} 
					dateUploaded={this.props.file.dateUploaded} title={this.props.file.title}
					folderId={this.props.folderId}>
					<div className='icon-container'>
						{ this._renderIcon() }
					</div>
				</ImageView>
			)
		}

		return (
			<div className='icon-container'>
				{!this.props.file.virusName
					? <a href={!this.props.file.url ? this.props.downloadEndpoint + this.props.file.id : this.props.file.url } target={this.props.file.url ? "_blank" : null} className="file-name" onClick={this._downloadClick.bind(this)}>{this._renderIcon()}</a>
					: null
				}
			</div>
		);
	}

	_unlinkFile(e) {

		if (e && e.preventDefault) {
			e.preventDefault();
		}

		if (this.props.onDeleteClick) {
			this.props.onDeleteClick(this.props.file);
		}
	}

	_editFile(e) {

		if (e && e.preventDefault) {
			e.preventDefault();
		}

		if (this.props.onEditClick) {
			this.props.onEditClick(this.props.file);
		}
	}

	_renderTime() {
		var fileDateUploaded = this.props.file.dateUploaded.tz(AppDelegate.config.serverTimeZone);

		return (DateTimeUtils.getAbsoluteDateTimeFormatted(fileDateUploaded));

	}

	_renderDescription() {
		if (this.props.file.description == "") {
			return null;
		}

		let small = (this.props.file.description.length <= (!this.props.thumbnail ? 200 : 100));
		let showAll = small || this.props.showAll || this.state.showAll;
		let description = this.props.file.description;
		if (!showAll) {
			description = description.substr(0, !this.props.thumbnail ? 200 : 100) + '\u2026';
		}

		return (
			<div className="file-desc">
				<div>
					{description}
				</div>
				{showAll ? null : <a href="javascript:void(0)" onClick={this._showAll.bind(this)} className="toggleSingleDesc">See more</a>}
			</div>
		);
	}

	_showAll(e) {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		this.setState({ showAll: true })
	}

	_deleteOption() {
		if (!this.props.file.canDelete) {
			return null;
		}

		return (
			<a href="javascript:void(0)"
				className="btn-delete"
				onClick={this._unlinkFile.bind(this)}>
				<span className='fn-icon fn-icon-delete-sign-lg fn-icon-light' aria-hidden="true"></span>
			</a>
		);
	}

	_editOption() {
		if (!this.props.file.canEdit) {
			return null;
		}

		let elements = [];

		if (this.props.file.isAccessRestricted) {
			elements.push(
				<span className="fn-icon fn-icon-locked fn-icon-light" style={{marginRight: '1em'}}></span>
			)
		}

		if (this.props.file.canEdit) {
			elements.push(
				<a href="javascript:void(0)"
					key="edit"
					className="edit"
					onClick={this._editFile.bind(this)}>
					<span className='fn-icon fn-icon-edit fn-icon-light' aria-hidden="true"></span>
				</a>
			);
		}
		
		return elements;
	}

	_downloadClick(e) {
		e.preventDefault ? e.preventDefault() : (e.returnValue = false);

		if (!this.props.thumbnail || !this.props.file.image)
		{
			if (this.props.onDownloadClick) {
				this.props.onDownloadClick(this.props.file);
			}
		}
		else
		{
			if (this.refs.viewer)
			{
				this.refs.viewer.show();
			}
		}

		return false;
	}

	_renderEditDeleteOption() {
		return (
			<div className="file-options">
				{ this.props.file.isAccessRestricted ? 
					<span className="fn-icon fn-icon-locked fn-icon-light" style={{marginRight: '1em'}}></span>
					:
					null
				}
				{this.props.file.canEdit ?
					<a href="javascript:void(0)" className="mod fileButton" onClick={this._editFile.bind(this)}><span className="fn-icon fn-icon-edit"></span></a>
					:
					<a href="javascript:void(0)" className="mod"></a>
				}
                {this.props.file.canEdit && this.props.file.canDelete &&
                    <span> </span>
                }
				{this.props.file.canDelete ?
					<a href="javascript:void(0)" className="close fileButton" onClick={this._unlinkFile.bind(this)}><span className="fn-icon fn-icon-delete"></span></a>
					:
					<a href="javascript:void(0)" className="close"></a>
				}
			</div>
		);
	}

	render() {
		const fileType = this.props.file.fileType
		const isMediaFile = ['AUDIO', 'IMAGE', 'URL', 'VIDEO'].includes(fileType.toUpperCase())

		return (
			<li className={(this.props.file.virusName ? " disabled" : null)}>
				<div className="file-container clearfix">
					<div className="file file-item">
						{!this.props.viewUnarchivable && this.props.thumbnail ? this._renderThumbnail() : this._renderIcon()}
						<span>
							{!this.props.file.virusName
								? !this.props.viewUnarchivable
									? <a href={!this.props.file.url ? this.props.downloadEndpoint + this.props.file.id : this.props.file.url } target={this.props.file.url ? "_blank" : null} className="file-name" onClick={this._downloadClick.bind(this)}>{this.props.file.title}</a>
									: this.props.file.title
								: <span className="virus-file-name">{this.props.file.title}</span>
							}
						</span>
						{!this.props.viewUnarchivable && !this.props.thumbnail
							? <span className="file-size">{FileUtils.formatBytesToString(this.props.file.contentLength)}</span>
							: null }
						<div className="file-details">
							<span>{this.props.file.uploader.Name}</span>
							<span className="file-details-container"> 
								<span className="file-details-date">{this._renderTime()}</span>
								{!this.props.viewUnarchivable && this.props.thumbnail ?
									this._renderEditDeleteOption()
									: null
								}
							</span>
							{ !this.props.viewUnarchivable && isMediaFile && this.props.category == "Media" ? 
									<span>
										<MediaAnalytics 
											file={this.props.file} 
											category={this.props.category}
											folderId={this.props.folderId}
											relatedItemId={this.props.type == "Group" && this.props.id || this.props.type == "Event" && this.props.id || null}
											relatedItemType={this.props.type}
										/>
									</span> : null
							}
						</div>
						{/*this._renderDescription()*/}
					</div>
					{this.props.viewUnarchivable || !this.props.thumbnail ?
						<div className="file-delete">
							{!this.props.file.virusName ? (this.props.file.scanning ? <span className="virus-detail">Virus scanning...</span> : this._editOption()) : <span className="virus-detail">Virus detected</span>}
							{this._deleteOption()}
						</div>
						: null
					}
				</div>
			</li>
		);
	}
}

FileItem.propTypes = {
	type: 				PropTypes.oneOf(['Event', 'Group']).isRequired,
	category:			PropTypes.oneOf(['Document', 'Media']).isRequired,
	id:					PropTypes.string.isRequired,
	downloadEndpoint: 	PropTypes.string.isRequired,
	showAll: 			PropTypes.bool.isRequired,
	thumbnail: 			PropTypes.bool.isRequired,
	viewUnarchivable:	PropTypes.bool,

	//onDeleteClick: PropTypes.function.isRequired,
	//onEditClick: PropTypes.function.isRequired,
};

export default FileItem;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: acceptJoinGroupRequests
// ====================================================

export interface acceptJoinGroupRequests_acceptJoinGroupRequests_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface acceptJoinGroupRequests_acceptJoinGroupRequests_GroupList {
  __typename: "GroupList";
  /**
   * The group list ID.
   */
  groupListId: string;
  /**
   * The name of the group list.
   */
  name: string;
}

export interface acceptJoinGroupRequests_acceptJoinGroupRequests_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface acceptJoinGroupRequests_acceptJoinGroupRequests_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (acceptJoinGroupRequests_acceptJoinGroupRequests_Errors_errors | null)[] | null;
}

export type acceptJoinGroupRequests_acceptJoinGroupRequests = acceptJoinGroupRequests_acceptJoinGroupRequests_Guid | acceptJoinGroupRequests_acceptJoinGroupRequests_GroupList | acceptJoinGroupRequests_acceptJoinGroupRequests_Errors;

export interface acceptJoinGroupRequests {
  acceptJoinGroupRequests: acceptJoinGroupRequests_acceptJoinGroupRequests | null;
}

export interface acceptJoinGroupRequestsVariables {
  id: string;
  userIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: assignTask
// ====================================================

export interface assignTask_assignTask_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface assignTask_assignTask_GroupList {
  __typename: "GroupList";
  /**
   * The group list ID.
   */
  groupListId: string;
  /**
   * The name of the group list.
   */
  name: string;
}

export interface assignTask_assignTask_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface assignTask_assignTask_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (assignTask_assignTask_Errors_errors | null)[] | null;
}

export type assignTask_assignTask = assignTask_assignTask_Guid | assignTask_assignTask_GroupList | assignTask_assignTask_Errors;

export interface assignTask {
  assignTask: assignTask_assignTask | null;
}

export interface assignTaskVariables {
  task: TaskDetailsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: assignTasks
// ====================================================

export interface assignTasks_assignTasks_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface assignTasks_assignTasks_GroupList {
  __typename: "GroupList";
  /**
   * The group list ID.
   */
  groupListId: string;
  /**
   * The name of the group list.
   */
  name: string;
}

export interface assignTasks_assignTasks_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface assignTasks_assignTasks_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (assignTasks_assignTasks_Errors_errors | null)[] | null;
}

export type assignTasks_assignTasks = assignTasks_assignTasks_Guid | assignTasks_assignTasks_GroupList | assignTasks_assignTasks_Errors;

export interface assignTasks {
  assignTasks: assignTasks_assignTasks | null;
}

export interface assignTasksVariables {
  tasks?: TaskDetailsInput[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createBookmark
// ====================================================

export interface createBookmark_createBookmark_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface createBookmark_createBookmark_GroupList {
  __typename: "GroupList";
  /**
   * The group list ID.
   */
  groupListId: string;
  /**
   * The name of the group list.
   */
  name: string;
}

export interface createBookmark_createBookmark_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface createBookmark_createBookmark_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (createBookmark_createBookmark_Errors_errors | null)[] | null;
}

export type createBookmark_createBookmark = createBookmark_createBookmark_Guid | createBookmark_createBookmark_GroupList | createBookmark_createBookmark_Errors;

export interface createBookmark {
  createBookmark: createBookmark_createBookmark | null;
}

export interface createBookmarkVariables {
  name?: string | null;
  url?: string | null;
  activityId?: string | null;
  groupId?: string | null;
  eventId?: string | null;
  jobId?: string | null;
  newsArticleId?: string | null;
  discussionId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: declineJoinGroupRequests
// ====================================================

export interface declineJoinGroupRequests_declineJoinGroupRequests_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface declineJoinGroupRequests_declineJoinGroupRequests_GroupList {
  __typename: "GroupList";
  /**
   * The group list ID.
   */
  groupListId: string;
  /**
   * The name of the group list.
   */
  name: string;
}

export interface declineJoinGroupRequests_declineJoinGroupRequests_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface declineJoinGroupRequests_declineJoinGroupRequests_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (declineJoinGroupRequests_declineJoinGroupRequests_Errors_errors | null)[] | null;
}

export type declineJoinGroupRequests_declineJoinGroupRequests = declineJoinGroupRequests_declineJoinGroupRequests_Guid | declineJoinGroupRequests_declineJoinGroupRequests_GroupList | declineJoinGroupRequests_declineJoinGroupRequests_Errors;

export interface declineJoinGroupRequests {
  declineJoinGroupRequests: declineJoinGroupRequests_declineJoinGroupRequests | null;
}

export interface declineJoinGroupRequestsVariables {
  id: string;
  userIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteGroupList
// ====================================================

export interface deleteGroupList_deleteGroupList_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface deleteGroupList_deleteGroupList_GroupList {
  __typename: "GroupList";
  /**
   * The group list ID.
   */
  groupListId: string;
  /**
   * The name of the group list.
   */
  name: string;
}

export interface deleteGroupList_deleteGroupList_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface deleteGroupList_deleteGroupList_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (deleteGroupList_deleteGroupList_Errors_errors | null)[] | null;
}

export type deleteGroupList_deleteGroupList = deleteGroupList_deleteGroupList_Guid | deleteGroupList_deleteGroupList_GroupList | deleteGroupList_deleteGroupList_Errors;

export interface deleteGroupList {
  deleteGroupList: deleteGroupList_deleteGroupList | null;
}

export interface deleteGroupListVariables {
  groupListId: string;
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FORUM_GET_FORUM_QUERY
// ====================================================

export interface FORUM_GET_FORUM_QUERY_getForum_forum {
  __typename: "Forum";
  /**
   * The unique identifier of this forum
   */
  forumId: string;
  /**
   * The Id of the associated group
   */
  groupId: string;
}

export interface FORUM_GET_FORUM_QUERY_getForum_topics_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_FORUM_QUERY_getForum_topics {
  __typename: "ForumTopicResult";
  /**
   * A forum topic item
   */
  topic: FORUM_GET_FORUM_QUERY_getForum_topics_topic | null;
  /**
   * Each topic's moreDiscussionsCount will be different dependendent on how many discussions were returned for that topic since that number is subtracted from the total to give how many more remain
   */
  moreDiscussionsCount: number;
  /**
   * True = accordion open, False = accordion closed
   */
  accordionState: boolean;
  /**
   * The total number of discussions in this topic
   */
  totalDiscussionCount: number;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: FORUM_GET_FORUM_QUERY_getForum_discussions_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: FORUM_GET_FORUM_QUERY_getForum_discussions_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: FORUM_GET_FORUM_QUERY_getForum_discussions_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (FORUM_GET_FORUM_QUERY_getForum_discussions_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface FORUM_GET_FORUM_QUERY_getForum_discussions {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: FORUM_GET_FORUM_QUERY_getForum_discussions_discussion;
  /**
   * The topic
   */
  topic: FORUM_GET_FORUM_QUERY_getForum_discussions_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: FORUM_GET_FORUM_QUERY_getForum_discussions_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: FORUM_GET_FORUM_QUERY_getForum_discussions_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: FORUM_GET_FORUM_QUERY_getForum_discussions_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (FORUM_GET_FORUM_QUERY_getForum_discussions_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (FORUM_GET_FORUM_QUERY_getForum_discussions_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: FORUM_GET_FORUM_QUERY_getForum_discussions_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

export interface FORUM_GET_FORUM_QUERY_getForum_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface FORUM_GET_FORUM_QUERY_getForum {
  __typename: "ForumResult";
  /**
   * A forum item
   */
  forum: FORUM_GET_FORUM_QUERY_getForum_forum | null;
  /**
   * The list of topics
   */
  topics: (FORUM_GET_FORUM_QUERY_getForum_topics | null)[] | null;
  /**
   * The list of discussions
   */
  discussions: (FORUM_GET_FORUM_QUERY_getForum_discussions | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: FORUM_GET_FORUM_QUERY_getForum_pageInfo | null;
}

export interface FORUM_GET_FORUM_QUERY {
  getForum: FORUM_GET_FORUM_QUERY_getForum;
}

export interface FORUM_GET_FORUM_QUERYVariables {
  forumId: string;
  topDiscussionsMax?: number | null;
  page?: PageInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FORUM_GET_FORUM_FOR_GROUP_QUERY
// ====================================================

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_forum {
  __typename: "Forum";
  /**
   * The unique identifier of this forum
   */
  forumId: string;
  /**
   * The Id of the associated group
   */
  groupId: string;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_topics_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_topics {
  __typename: "ForumTopicResult";
  /**
   * A forum topic item
   */
  topic: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_topics_topic | null;
  /**
   * Each topic's moreDiscussionsCount will be different dependendent on how many discussions were returned for that topic since that number is subtracted from the total to give how many more remain
   */
  moreDiscussionsCount: number;
  /**
   * True = accordion open, False = accordion closed
   */
  accordionState: boolean;
  /**
   * The total number of discussions in this topic
   */
  totalDiscussionCount: number;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_discussion;
  /**
   * The topic
   */
  topic: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup {
  __typename: "ForumResult";
  /**
   * A forum item
   */
  forum: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_forum | null;
  /**
   * The list of topics
   */
  topics: (FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_topics | null)[] | null;
  /**
   * The list of discussions
   */
  discussions: (FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_discussions | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup_pageInfo | null;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERY {
  getForumForGroup: FORUM_GET_FORUM_FOR_GROUP_QUERY_getForumForGroup;
}

export interface FORUM_GET_FORUM_FOR_GROUP_QUERYVariables {
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FORUM_GET_DISCUSSIONS_QUERY
// ====================================================

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_discussion;
  /**
   * The topic
   */
  topic: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions {
  __typename: "ForumDiscussionsResult";
  /**
   * The topic
   */
  topic: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_topic | null;
  /**
   * The list of discussions
   */
  discussions: (FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_discussions | null)[] | null;
  /**
   * Paging information for discussions
   */
  pageInfo: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions_pageInfo | null;
}

export interface FORUM_GET_DISCUSSIONS_QUERY {
  getForumDiscussions: FORUM_GET_DISCUSSIONS_QUERY_getForumDiscussions;
}

export interface FORUM_GET_DISCUSSIONS_QUERYVariables {
  forumId: string;
  topicId?: string | null;
  page?: PageInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FORUM_GET_DISCUSSION_DETAIL_QUERY
// ====================================================

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_discussion;
  /**
   * The topic
   */
  topic: FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERY {
  getForumDiscussionDetail: FORUM_GET_DISCUSSION_DETAIL_QUERY_getForumDiscussionDetail;
}

export interface FORUM_GET_DISCUSSION_DETAIL_QUERYVariables {
  discussionId: string;
  isReviewing?: boolean | null;
  replyId?: string | null;
  page?: PageInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_MOVE_ACTIVITY_MUTATION
// ====================================================

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_Guid {
  __typename: "Guid" | "Bool" | "Text" | "Errors" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_discussion;
  /**
   * The topic
   */
  topic: FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

export type FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost = FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_Guid | FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost_ForumDiscussionResult;

export interface FORUM_MOVE_ACTIVITY_MUTATION {
  moveActivityPost: FORUM_MOVE_ACTIVITY_MUTATION_moveActivityPost | null;
}

export interface FORUM_MOVE_ACTIVITY_MUTATIONVariables {
  postId: number;
  forumId: string;
  topicId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_SAVE_DISCUSSION_MUTATION
// ====================================================

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_discussion;
  /**
   * The topic
   */
  topic: FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_Errors_errors | null)[] | null;
}

export type FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion = FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_Guid | FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_ForumDiscussionResult | FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion_Errors;

export interface FORUM_SAVE_DISCUSSION_MUTATION {
  saveDiscussion: FORUM_SAVE_DISCUSSION_MUTATION_saveDiscussion | null;
}

export interface FORUM_SAVE_DISCUSSION_MUTATIONVariables {
  discussionId?: string | null;
  forumId: string;
  topicId?: string | null;
  subject: string;
  content: string;
  associations?: (ForumAssociationInput | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION
// ====================================================

export interface FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION_saveDiscussionActivity_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION_saveDiscussionActivity_ForumDiscussionActivity {
  __typename: "ForumDiscussionActivity";
  /**
   * The id of the discussion where the activity occurred
   */
  discussionId: string;
  /**
   * The id of the user who performed this activity
   */
  userId: string;
  /**
   * The timestamp when this activity occurred
   */
  lastActivityDate: any;
}

export interface FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION_saveDiscussionActivity_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION_saveDiscussionActivity_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION_saveDiscussionActivity_Errors_errors | null)[] | null;
}

export type FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION_saveDiscussionActivity = FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION_saveDiscussionActivity_Guid | FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION_saveDiscussionActivity_ForumDiscussionActivity | FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION_saveDiscussionActivity_Errors;

export interface FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION {
  saveDiscussionActivity: FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATION_saveDiscussionActivity | null;
}

export interface FORUM_SAVE_DISCUSSION_ACTIVITY_MUTATIONVariables {
  discussionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_DISCUSSION_ACK_MUTATION
// ====================================================

export interface FORUM_DISCUSSION_ACK_MUTATION_discussionAck_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface FORUM_DISCUSSION_ACK_MUTATION_discussionAck_ForumAck {
  __typename: "ForumAck";
  /**
   * The id of the discussion which was ack'd
   */
  discussionId: string;
  /**
   * The id of the reply which was ack'd, or a zero guid if the ack was on the head post of the discussion
   */
  replyId: string;
  /**
   * The id of the user who performed this activity
   */
  userId: string;
  /**
   * The timestamp when this ack occurred
   */
  createdDate: any;
}

export interface FORUM_DISCUSSION_ACK_MUTATION_discussionAck_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_DISCUSSION_ACK_MUTATION_discussionAck_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_DISCUSSION_ACK_MUTATION_discussionAck_Errors_errors | null)[] | null;
}

export type FORUM_DISCUSSION_ACK_MUTATION_discussionAck = FORUM_DISCUSSION_ACK_MUTATION_discussionAck_Guid | FORUM_DISCUSSION_ACK_MUTATION_discussionAck_ForumAck | FORUM_DISCUSSION_ACK_MUTATION_discussionAck_Errors;

export interface FORUM_DISCUSSION_ACK_MUTATION {
  discussionAck: FORUM_DISCUSSION_ACK_MUTATION_discussionAck | null;
}

export interface FORUM_DISCUSSION_ACK_MUTATIONVariables {
  discussionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_REPLY_ACK_MUTATION
// ====================================================

export interface FORUM_REPLY_ACK_MUTATION_replyAck_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface FORUM_REPLY_ACK_MUTATION_replyAck_ForumAck {
  __typename: "ForumAck";
  /**
   * The id of the discussion which was ack'd
   */
  discussionId: string;
  /**
   * The id of the reply which was ack'd, or a zero guid if the ack was on the head post of the discussion
   */
  replyId: string;
  /**
   * The id of the user who performed this activity
   */
  userId: string;
  /**
   * The timestamp when this ack occurred
   */
  createdDate: any;
}

export interface FORUM_REPLY_ACK_MUTATION_replyAck_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_REPLY_ACK_MUTATION_replyAck_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_REPLY_ACK_MUTATION_replyAck_Errors_errors | null)[] | null;
}

export type FORUM_REPLY_ACK_MUTATION_replyAck = FORUM_REPLY_ACK_MUTATION_replyAck_Guid | FORUM_REPLY_ACK_MUTATION_replyAck_ForumAck | FORUM_REPLY_ACK_MUTATION_replyAck_Errors;

export interface FORUM_REPLY_ACK_MUTATION {
  replyAck: FORUM_REPLY_ACK_MUTATION_replyAck | null;
}

export interface FORUM_REPLY_ACK_MUTATIONVariables {
  discussionId: string;
  replyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_DISCUSSION_UNACK_MUTATION
// ====================================================

export interface FORUM_DISCUSSION_UNACK_MUTATION_discussionUnAck_Guid {
  __typename: "Guid" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface FORUM_DISCUSSION_UNACK_MUTATION_discussionUnAck_Bool {
  __typename: "Bool";
  /**
   * The Boolean object type represents true or false.
   */
  value: boolean | null;
}

export interface FORUM_DISCUSSION_UNACK_MUTATION_discussionUnAck_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_DISCUSSION_UNACK_MUTATION_discussionUnAck_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_DISCUSSION_UNACK_MUTATION_discussionUnAck_Errors_errors | null)[] | null;
}

export type FORUM_DISCUSSION_UNACK_MUTATION_discussionUnAck = FORUM_DISCUSSION_UNACK_MUTATION_discussionUnAck_Guid | FORUM_DISCUSSION_UNACK_MUTATION_discussionUnAck_Bool | FORUM_DISCUSSION_UNACK_MUTATION_discussionUnAck_Errors;

export interface FORUM_DISCUSSION_UNACK_MUTATION {
  discussionUnAck: FORUM_DISCUSSION_UNACK_MUTATION_discussionUnAck | null;
}

export interface FORUM_DISCUSSION_UNACK_MUTATIONVariables {
  discussionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_REPLY_UNACK_MUTATION
// ====================================================

export interface FORUM_REPLY_UNACK_MUTATION_replyUnAck_Guid {
  __typename: "Guid" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface FORUM_REPLY_UNACK_MUTATION_replyUnAck_Bool {
  __typename: "Bool";
  /**
   * The Boolean object type represents true or false.
   */
  value: boolean | null;
}

export interface FORUM_REPLY_UNACK_MUTATION_replyUnAck_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_REPLY_UNACK_MUTATION_replyUnAck_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_REPLY_UNACK_MUTATION_replyUnAck_Errors_errors | null)[] | null;
}

export type FORUM_REPLY_UNACK_MUTATION_replyUnAck = FORUM_REPLY_UNACK_MUTATION_replyUnAck_Guid | FORUM_REPLY_UNACK_MUTATION_replyUnAck_Bool | FORUM_REPLY_UNACK_MUTATION_replyUnAck_Errors;

export interface FORUM_REPLY_UNACK_MUTATION {
  replyUnAck: FORUM_REPLY_UNACK_MUTATION_replyUnAck | null;
}

export interface FORUM_REPLY_UNACK_MUTATIONVariables {
  discussionId: string;
  replyId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_UPDATE_TOPIC_STATE
// ====================================================

export interface FORUM_UPDATE_TOPIC_STATE_updateForumTopicState_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface FORUM_UPDATE_TOPIC_STATE_updateForumTopicState_ForumTopic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_UPDATE_TOPIC_STATE_updateForumTopicState_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_UPDATE_TOPIC_STATE_updateForumTopicState_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_UPDATE_TOPIC_STATE_updateForumTopicState_Errors_errors | null)[] | null;
}

export type FORUM_UPDATE_TOPIC_STATE_updateForumTopicState = FORUM_UPDATE_TOPIC_STATE_updateForumTopicState_Guid | FORUM_UPDATE_TOPIC_STATE_updateForumTopicState_ForumTopic | FORUM_UPDATE_TOPIC_STATE_updateForumTopicState_Errors;

export interface FORUM_UPDATE_TOPIC_STATE {
  updateForumTopicState: FORUM_UPDATE_TOPIC_STATE_updateForumTopicState | null;
}

export interface FORUM_UPDATE_TOPIC_STATEVariables {
  topicId?: string | null;
  forumId: string;
  isEnabled?: boolean | null;
  title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_UPDATE_DISCUSSION_STATE
// ====================================================

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_discussion;
  /**
   * The topic
   */
  topic: FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_Errors_errors | null)[] | null;
}

export type FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState = FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_Guid | FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_ForumDiscussionResult | FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState_Errors;

export interface FORUM_UPDATE_DISCUSSION_STATE {
  updateForumDiscussionState: FORUM_UPDATE_DISCUSSION_STATE_updateForumDiscussionState | null;
}

export interface FORUM_UPDATE_DISCUSSION_STATEVariables {
  discussionId: string;
  isEnabled?: boolean | null;
  isOpen?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_SAVE_REPLY_MUTATION
// ====================================================

export interface FORUM_SAVE_REPLY_MUTATION_saveForumReply_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumTopicActivity";
}

export interface FORUM_SAVE_REPLY_MUTATION_saveForumReply_ForumReplyResult_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_SAVE_REPLY_MUTATION_saveForumReply_ForumReplyResult_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_SAVE_REPLY_MUTATION_saveForumReply_ForumReplyResult_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_SAVE_REPLY_MUTATION_saveForumReply_ForumReplyResult_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_SAVE_REPLY_MUTATION_saveForumReply_ForumReplyResult {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: FORUM_SAVE_REPLY_MUTATION_saveForumReply_ForumReplyResult_reply;
  /**
   * The person who created this reply
   */
  createdBy: FORUM_SAVE_REPLY_MUTATION_saveForumReply_ForumReplyResult_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: FORUM_SAVE_REPLY_MUTATION_saveForumReply_ForumReplyResult_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (FORUM_SAVE_REPLY_MUTATION_saveForumReply_ForumReplyResult_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface FORUM_SAVE_REPLY_MUTATION_saveForumReply_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_SAVE_REPLY_MUTATION_saveForumReply_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_SAVE_REPLY_MUTATION_saveForumReply_Errors_errors | null)[] | null;
}

export type FORUM_SAVE_REPLY_MUTATION_saveForumReply = FORUM_SAVE_REPLY_MUTATION_saveForumReply_Guid | FORUM_SAVE_REPLY_MUTATION_saveForumReply_ForumReplyResult | FORUM_SAVE_REPLY_MUTATION_saveForumReply_Errors;

export interface FORUM_SAVE_REPLY_MUTATION {
  saveForumReply: FORUM_SAVE_REPLY_MUTATION_saveForumReply | null;
}

export interface FORUM_SAVE_REPLY_MUTATIONVariables {
  discussionId: string;
  replyId?: string | null;
  discussionPostVersion: number;
  content: string;
  parentId?: string | null;
  parentVersion?: number | null;
  associations?: (ForumAssociationInput | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_UPDATE_REPLY_STATE
// ====================================================

export interface FORUM_UPDATE_REPLY_STATE_updateForumReplyState_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumTopicActivity";
}

export interface FORUM_UPDATE_REPLY_STATE_updateForumReplyState_ForumReplyResult_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface FORUM_UPDATE_REPLY_STATE_updateForumReplyState_ForumReplyResult_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_UPDATE_REPLY_STATE_updateForumReplyState_ForumReplyResult_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface FORUM_UPDATE_REPLY_STATE_updateForumReplyState_ForumReplyResult_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface FORUM_UPDATE_REPLY_STATE_updateForumReplyState_ForumReplyResult {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: FORUM_UPDATE_REPLY_STATE_updateForumReplyState_ForumReplyResult_reply;
  /**
   * The person who created this reply
   */
  createdBy: FORUM_UPDATE_REPLY_STATE_updateForumReplyState_ForumReplyResult_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: FORUM_UPDATE_REPLY_STATE_updateForumReplyState_ForumReplyResult_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (FORUM_UPDATE_REPLY_STATE_updateForumReplyState_ForumReplyResult_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface FORUM_UPDATE_REPLY_STATE_updateForumReplyState_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_UPDATE_REPLY_STATE_updateForumReplyState_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_UPDATE_REPLY_STATE_updateForumReplyState_Errors_errors | null)[] | null;
}

export type FORUM_UPDATE_REPLY_STATE_updateForumReplyState = FORUM_UPDATE_REPLY_STATE_updateForumReplyState_Guid | FORUM_UPDATE_REPLY_STATE_updateForumReplyState_ForumReplyResult | FORUM_UPDATE_REPLY_STATE_updateForumReplyState_Errors;

export interface FORUM_UPDATE_REPLY_STATE {
  updateForumReplyState: FORUM_UPDATE_REPLY_STATE_updateForumReplyState | null;
}

export interface FORUM_UPDATE_REPLY_STATEVariables {
  discussionId: string;
  replyId: string;
  isEnabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FORUM_SAVE_TOPIC_ACTIVITY_MUTATION
// ====================================================

export interface FORUM_SAVE_TOPIC_ACTIVITY_MUTATION_saveTopicActivity_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult";
}

export interface FORUM_SAVE_TOPIC_ACTIVITY_MUTATION_saveTopicActivity_ForumTopicActivity {
  __typename: "ForumTopicActivity";
  /**
   * The id of the topic where the user activity occurred
   */
  topicId: string;
  /**
   * True = accordion open, False = accordion closed
   */
  accordionState: boolean;
}

export interface FORUM_SAVE_TOPIC_ACTIVITY_MUTATION_saveTopicActivity_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface FORUM_SAVE_TOPIC_ACTIVITY_MUTATION_saveTopicActivity_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (FORUM_SAVE_TOPIC_ACTIVITY_MUTATION_saveTopicActivity_Errors_errors | null)[] | null;
}

export type FORUM_SAVE_TOPIC_ACTIVITY_MUTATION_saveTopicActivity = FORUM_SAVE_TOPIC_ACTIVITY_MUTATION_saveTopicActivity_Guid | FORUM_SAVE_TOPIC_ACTIVITY_MUTATION_saveTopicActivity_ForumTopicActivity | FORUM_SAVE_TOPIC_ACTIVITY_MUTATION_saveTopicActivity_Errors;

export interface FORUM_SAVE_TOPIC_ACTIVITY_MUTATION {
  saveTopicActivity: FORUM_SAVE_TOPIC_ACTIVITY_MUTATION_saveTopicActivity | null;
}

export interface FORUM_SAVE_TOPIC_ACTIVITY_MUTATIONVariables {
  topicId: string;
  accordionState: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllMyActions
// ====================================================

export interface getAllMyActions_myAdministrativeActions_group {
  __typename: "Group";
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The name of the group.
   */
  name: string | null;
}

export interface getAllMyActions_myAdministrativeActions_event {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
  /**
   * The group ID of the event.
   */
  groupId: string | null;
  /**
   * The photo id of the event.
   */
  photoId: string | null;
  /**
   * The start of the event recorded in DateTime with the event timezone.
   */
  startDateTime: any | null;
  /**
   * The location of the event.
   */
  locationText: string | null;
}

export interface getAllMyActions_myAdministrativeActions_user {
  __typename: "User";
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
}

export interface getAllMyActions_myAdministrativeActions_job {
  __typename: "Job";
  /**
   * The id of the job.
   */
  id: string | null;
  /**
   * The title of the job.
   */
  title: string;
  /**
   * The media item id of the job's thumbnail image.
   */
  thumbnailImageId: string | null;
}

export interface getAllMyActions_myAdministrativeActions {
  __typename: "UserAdministrativeAction";
  /**
   * The action type.
   */
  type: AdministrativeActionType;
  /**
   * The date.
   */
  date: any;
  /**
   * The expiry date when applicable.
   */
  expiryDate: any | null;
  /**
   * The title.
   */
  title: string;
  /**
   * The related group.
   */
  group: getAllMyActions_myAdministrativeActions_group | null;
  /**
   * The related event.
   */
  event: getAllMyActions_myAdministrativeActions_event | null;
  /**
   * The related user.
   */
  user: getAllMyActions_myAdministrativeActions_user | null;
  /**
   * The related job.
   */
  job: getAllMyActions_myAdministrativeActions_job | null;
}

export interface getAllMyActions_myActions_group {
  __typename: "Group";
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The name of the group.
   */
  name: string | null;
}

export interface getAllMyActions_myActions_event {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
  /**
   * The group ID of the event.
   */
  groupId: string | null;
  /**
   * The photo id of the event.
   */
  photoId: string | null;
  /**
   * The start of the event recorded in DateTime with the event timezone.
   */
  startDateTime: any | null;
  /**
   * The location of the event.
   */
  locationText: string | null;
}

export interface getAllMyActions_myActions_user {
  __typename: "User";
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
}

export interface getAllMyActions_myActions_familyConnection {
  __typename: "FamilyConnection";
  /**
   * The ID of the family connection.
   */
  id: string;
  /**
   * The ID of the family user.
   */
  familyUserId: string | null;
  /**
   * The full name of the family connection.
   */
  familyUserFullName: string;
  /**
   * The avatar file ID of the family connection.
   */
  familyUserAvatarFileId: string | null;
}

export interface getAllMyActions_myActions_notification_relatedIDs {
  __typename: "NameValuePairString";
  /**
   * The name of pair.
   */
  name: string;
  /**
   * The value of pair.
   */
  value: string | null;
}

export interface getAllMyActions_myActions_notification {
  __typename: "Notification";
  /**
   * The ID of the notification.
   */
  id: string | null;
  /**
   * Whether the notification can be ignored (true) or not (false).
   */
  canIgnore: boolean;
  /**
   * Whether the notification has been seen (true) or not (false).
   */
  isSeen: boolean;
  /**
   * Display text of the notification.
   */
  text: string;
  /**
   * The timestamp of when the notification was posted.
   */
  timestamp: any | null;
  /**
   * URL of the item associated with the notification.
   */
  url: string | null;
  /**
   * Id of thumbnail of the user associated with the notification.
   */
  userThumbnailId: string | null;
  /**
   * The icon to be displayed for this notification.
   */
  icon: NotificationIcon | null;
  /**
   * The type of this notification.
   */
  notificationType: SiteNotificationType | null;
  /**
   * The list of related IDs associated with. (ACTIVITY/POSTCOMMENT/EVENT/FILE/MEDIA/GROUP/JOB/CONVERSATION)
   */
  relatedIDs: (getAllMyActions_myActions_notification_relatedIDs | null)[] | null;
}

export interface getAllMyActions_myActions {
  __typename: "UserPersonalAction";
  /**
   * The action type.
   */
  type: PersonalActionType;
  /**
   * The date.
   */
  date: any | null;
  /**
   * The title.
   */
  title: string;
  /**
   * The related group.
   */
  group: getAllMyActions_myActions_group | null;
  /**
   * The related event.
   */
  event: getAllMyActions_myActions_event | null;
  /**
   * The related user.
   */
  user: getAllMyActions_myActions_user | null;
  /**
   * The related family connection.
   */
  familyConnection: getAllMyActions_myActions_familyConnection | null;
  /**
   * The related notification.
   */
  notification: getAllMyActions_myActions_notification | null;
}

export interface getAllMyActions {
  myAdministrativeActions: (getAllMyActions_myAdministrativeActions | null)[] | null;
  myActions: (getAllMyActions_myActions | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFileAccess
// ====================================================

export interface getFileAccess_fileAccess_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface getFileAccess_fileAccess {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The service of the user.
   */
  service: string | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
  /**
   * The user's rank.
   */
  rank: getFileAccess_fileAccess_rank | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The account status of user.
   */
  accountStatus: AccountStatus | null;
  /**
   * The defence position of the user.
   */
  defencePosition: string | null;
}

export interface getFileAccess {
  fileAccess: (getFileAccess_fileAccess | null)[] | null;
}

export interface getFileAccessVariables {
  fileId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getFolderAccess
// ====================================================

export interface getFolderAccess_folderAccess_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface getFolderAccess_folderAccess {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The service of the user.
   */
  service: string | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
  /**
   * The user's rank.
   */
  rank: getFolderAccess_folderAccess_rank | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The account status of user.
   */
  accountStatus: AccountStatus | null;
  /**
   * The defence position of the user.
   */
  defencePosition: string | null;
}

export interface getFolderAccess {
  folderAccess: (getFolderAccess_folderAccess | null)[] | null;
}

export interface getFolderAccessVariables {
  folderId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGroupJoinRequests
// ====================================================

export interface getGroupJoinRequests_groupJoinRequests_group {
  __typename: "Group";
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The name of the group.
   */
  name: string | null;
}

export interface getGroupJoinRequests_groupJoinRequests_event {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
}

export interface getGroupJoinRequests_groupJoinRequests_user {
  __typename: "User";
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
}

export interface getGroupJoinRequests_groupJoinRequests_job {
  __typename: "Job";
  /**
   * The id of the job.
   */
  id: string | null;
  /**
   * The title of the job.
   */
  title: string;
  /**
   * The media item id of the job's thumbnail image.
   */
  thumbnailImageId: string | null;
}

export interface getGroupJoinRequests_groupJoinRequests_taskAssignedByUser {
  __typename: "User";
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The user's full name.
   */
  fullName: string;
}

export interface getGroupJoinRequests_groupJoinRequests_taskAssignedToUser {
  __typename: "User";
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The user's full name.
   */
  fullName: string;
}

export interface getGroupJoinRequests_groupJoinRequests {
  __typename: "UserAdministrativeAction";
  /**
   * The action type.
   */
  type: AdministrativeActionType;
  /**
   * The action type.
   */
  description: string;
  /**
   * The date.
   */
  date: any;
  /**
   * The expiry date when applicable.
   */
  expiryDate: any | null;
  /**
   * The title.
   */
  title: string;
  /**
   * The related group.
   */
  group: getGroupJoinRequests_groupJoinRequests_group | null;
  /**
   * The related event.
   */
  event: getGroupJoinRequests_groupJoinRequests_event | null;
  /**
   * The related user.
   */
  user: getGroupJoinRequests_groupJoinRequests_user | null;
  /**
   * The related job.
   */
  job: getGroupJoinRequests_groupJoinRequests_job | null;
  /**
   * The date and time the task was assigned.
   */
  taskAssignedDate: any | null;
  /**
   * The user who assigned to task.
   */
  taskAssignedByUser: getGroupJoinRequests_groupJoinRequests_taskAssignedByUser | null;
  /**
   * The user the task is assigned to.
   */
  taskAssignedToUser: getGroupJoinRequests_groupJoinRequests_taskAssignedToUser | null;
}

export interface getGroupJoinRequests {
  groupJoinRequests: (getGroupJoinRequests_groupJoinRequests | null)[] | null;
  actionsRequiredCount: number;
}

export interface getGroupJoinRequestsVariables {
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGroupLists
// ====================================================

export interface getGroupLists_groupLists_groups_primaryAdmin_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface getGroupLists_groupLists_groups_primaryAdmin {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's rank.
   */
  rank: getGroupLists_groupLists_groups_primaryAdmin_rank | null;
}

export interface getGroupLists_groupLists_groups {
  __typename: "Group";
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The description of the group, as raw editable text.
   */
  rawDescription: string | null;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The primary administrator.
   */
  primaryAdmin: getGroupLists_groupLists_groups_primaryAdmin | null;
}

export interface getGroupLists_groupLists {
  __typename: "GroupList";
  /**
   * The group list ID.
   */
  groupListId: string;
  /**
   * The name of the group list.
   */
  name: string;
  /**
   * The list of groups.
   */
  groups: getGroupLists_groupLists_groups[] | null;
}

export interface getGroupLists {
  groupLists: (getGroupLists_groupLists | null)[] | null;
}

export interface getGroupListsVariables {
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getGroupMemberships
// ====================================================

export interface getGroupMemberships_groupMemberships_groupMembers_user {
  __typename: "User";
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The user's full name.
   */
  fullName: string;
}

export interface getGroupMemberships_groupMemberships_groupMembers {
  __typename: "GroupMembership";
  /**
   * The basic user of the group.
   */
  user: getGroupMemberships_groupMemberships_groupMembers_user | null;
}

export interface getGroupMemberships_groupMemberships {
  __typename: "GroupMembershipResult";
  /**
   * The list of group members.
   */
  groupMembers: (getGroupMemberships_groupMemberships_groupMembers | null)[] | null;
}

export interface getGroupMemberships {
  groupMemberships: getGroupMemberships_groupMemberships | null;
}

export interface getGroupMembershipsVariables {
  id: string;
  page?: PageInput | null;
  queryParameters?: MembershipQueryParamsType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyActions
// ====================================================

export interface getMyActions_myActions_group {
  __typename: "Group";
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The name of the group.
   */
  name: string | null;
}

export interface getMyActions_myActions_event {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
  /**
   * The group ID of the event.
   */
  groupId: string | null;
  /**
   * The photo id of the event.
   */
  photoId: string | null;
  /**
   * The start of the event recorded in DateTime with the event timezone.
   */
  startDateTime: any | null;
  /**
   * The location of the event.
   */
  locationText: string | null;
}

export interface getMyActions_myActions_user {
  __typename: "User";
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
}

export interface getMyActions_myActions_familyConnection {
  __typename: "FamilyConnection";
  /**
   * The ID of the family connection.
   */
  id: string;
  /**
   * The ID of the family user.
   */
  familyUserId: string | null;
  /**
   * The full name of the family connection.
   */
  familyUserFullName: string;
  /**
   * The avatar file ID of the family connection.
   */
  familyUserAvatarFileId: string | null;
}

export interface getMyActions_myActions_notification_relatedIDs {
  __typename: "NameValuePairString";
  /**
   * The name of pair.
   */
  name: string;
  /**
   * The value of pair.
   */
  value: string | null;
}

export interface getMyActions_myActions_notification {
  __typename: "Notification";
  /**
   * The ID of the notification.
   */
  id: string | null;
  /**
   * Whether the notification can be ignored (true) or not (false).
   */
  canIgnore: boolean;
  /**
   * Whether the notification has been seen (true) or not (false).
   */
  isSeen: boolean;
  /**
   * Display text of the notification.
   */
  text: string;
  /**
   * The timestamp of when the notification was posted.
   */
  timestamp: any | null;
  /**
   * URL of the item associated with the notification.
   */
  url: string | null;
  /**
   * Id of thumbnail of the user associated with the notification.
   */
  userThumbnailId: string | null;
  /**
   * The icon to be displayed for this notification.
   */
  icon: NotificationIcon | null;
  /**
   * The type of this notification.
   */
  notificationType: SiteNotificationType | null;
  /**
   * The list of related IDs associated with. (ACTIVITY/POSTCOMMENT/EVENT/FILE/MEDIA/GROUP/JOB/CONVERSATION)
   */
  relatedIDs: (getMyActions_myActions_notification_relatedIDs | null)[] | null;
}

export interface getMyActions_myActions {
  __typename: "UserPersonalAction";
  /**
   * The action type.
   */
  type: PersonalActionType;
  /**
   * The date.
   */
  date: any | null;
  /**
   * The expiry date when applicable.
   */
  expiryDate: any | null;
  /**
   * The title.
   */
  title: string;
  /**
   * The related group.
   */
  group: getMyActions_myActions_group | null;
  /**
   * The related event.
   */
  event: getMyActions_myActions_event | null;
  /**
   * The related user.
   */
  user: getMyActions_myActions_user | null;
  /**
   * The related family connection.
   */
  familyConnection: getMyActions_myActions_familyConnection | null;
  /**
   * The related notification.
   */
  notification: getMyActions_myActions_notification | null;
}

export interface getMyActions {
  myActions: (getMyActions_myActions | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyAdministrativeActions
// ====================================================

export interface getMyAdministrativeActions_myAdministrativeActions_group {
  __typename: "Group";
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The name of the group.
   */
  name: string | null;
}

export interface getMyAdministrativeActions_myAdministrativeActions_event {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
}

export interface getMyAdministrativeActions_myAdministrativeActions_user {
  __typename: "User";
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
}

export interface getMyAdministrativeActions_myAdministrativeActions_job {
  __typename: "Job";
  /**
   * The id of the job.
   */
  id: string | null;
  /**
   * The title of the job.
   */
  title: string;
  /**
   * The media item id of the job's thumbnail image.
   */
  thumbnailImageId: string | null;
}

export interface getMyAdministrativeActions_myAdministrativeActions_taskAssignedByUser {
  __typename: "User";
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The user's full name.
   */
  fullName: string;
}

export interface getMyAdministrativeActions_myAdministrativeActions_taskAssignedToUser {
  __typename: "User";
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The user's full name.
   */
  fullName: string;
}

export interface getMyAdministrativeActions_myAdministrativeActions {
  __typename: "UserAdministrativeAction";
  /**
   * The action type.
   */
  type: AdministrativeActionType;
  /**
   * The action type.
   */
  description: string;
  /**
   * The date.
   */
  date: any;
  /**
   * The expiry date when applicable.
   */
  expiryDate: any | null;
  /**
   * The title.
   */
  title: string;
  /**
   * The related group.
   */
  group: getMyAdministrativeActions_myAdministrativeActions_group | null;
  /**
   * The related event.
   */
  event: getMyAdministrativeActions_myAdministrativeActions_event | null;
  /**
   * The related user.
   */
  user: getMyAdministrativeActions_myAdministrativeActions_user | null;
  /**
   * The related job.
   */
  job: getMyAdministrativeActions_myAdministrativeActions_job | null;
  /**
   * The date and time the task was assigned.
   */
  taskAssignedDate: any | null;
  /**
   * The user who assigned to task.
   */
  taskAssignedByUser: getMyAdministrativeActions_myAdministrativeActions_taskAssignedByUser | null;
  /**
   * The user the task is assigned to.
   */
  taskAssignedToUser: getMyAdministrativeActions_myAdministrativeActions_taskAssignedToUser | null;
}

export interface getMyAdministrativeActions {
  myAdministrativeActions: (getMyAdministrativeActions_myAdministrativeActions | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyBookmarks
// ====================================================

export interface getMyBookmarks_myBookmarks_entries_post_author {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface getMyBookmarks_myBookmarks_entries_post_target {
  __typename: "ActivityFeedTarget";
  /**
   * The ID of the target activity feed object.
   */
  id: string | null;
  /**
   * The name of the target object.
   */
  name: string | null;
  /**
   * The description of the target object.
   */
  description: string | null;
  /**
   * The URL to link to the target object.
   */
  url: string | null;
}

export interface getMyBookmarks_myBookmarks_entries_post {
  __typename: "ActivityFeedObj";
  /**
   * The ID of the activity feed object.
   */
  id: string | null;
  /**
   * The subject of the activity feed object.
   */
  subject: string | null;
  /**
   * The raw message of the activity feed object.
   */
  rawMessage: string | null;
  /**
   * The author of the activity feed object.
   */
  author: getMyBookmarks_myBookmarks_entries_post_author | null;
  /**
   * The target party of the activity feed object.
   */
  target: getMyBookmarks_myBookmarks_entries_post_target | null;
}

export interface getMyBookmarks_myBookmarks_entries_event {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
  /**
   * The photo id of the event.
   */
  photoId: string | null;
  /**
   * The ID of the host for the event.
   */
  hostedById: string | null;
  /**
   * The type of host for the event.
   */
  hostedByType: string | null;
  /**
   * The hostname for the event.
   */
  hostName: string | null;
  /**
   * The start of the event recorded in DateTime with the event timezone.
   */
  startDateTime: any | null;
  /**
   * The end of the event recorded in DateTime with the event timezone.
   */
  endDateTime: any | null;
  /**
   * The location of the event.
   */
  locationText: string | null;
  /**
   * The time zone of the event.
   */
  timeZone: string | null;
}

export interface getMyBookmarks_myBookmarks_entries_job {
  __typename: "Job";
  /**
   * The id of the job.
   */
  id: string | null;
  /**
   * The title of the job.
   */
  title: string;
  /**
   * The unit of the job.
   */
  unit: string;
  /**
   * The states of the job.
   */
  states: (string | null)[] | null;
  /**
   * The service name of the job.
   */
  serviceTitle: string;
  /**
   * The positions of the job.
   */
  positions: number;
}

export interface getMyBookmarks_myBookmarks_entries_newsItem {
  __typename: "News";
  /**
   * The title of the news.
   */
  title: string;
  /**
   * The URL to the news article
   */
  url: string | null;
  /**
   * The date of the news.
   */
  date: any | null;
}

export interface getMyBookmarks_myBookmarks_entries_discussion_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
}

export interface getMyBookmarks_myBookmarks_entries_discussion_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The subject for this head post
   */
  subject: string;
}

export interface getMyBookmarks_myBookmarks_entries_discussion_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface getMyBookmarks_myBookmarks_entries_discussion {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: getMyBookmarks_myBookmarks_entries_discussion_discussion;
  /**
   * The head post for this discussion
   */
  headPost: getMyBookmarks_myBookmarks_entries_discussion_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: getMyBookmarks_myBookmarks_entries_discussion_createdBy | null;
}

export interface getMyBookmarks_myBookmarks_entries {
  __typename: "UserBookmarkDetail";
  /**
   * The date.
   */
  date: any | null;
  /**
   * The name.
   */
  name: string | null;
  /**
   * The URL.
   */
  url: string | null;
  /**
   * The ID of the bookmark.
   */
  userBookmarkId: string;
  /**
   * The activity.
   */
  post: getMyBookmarks_myBookmarks_entries_post | null;
  /**
   * The event.
   */
  event: getMyBookmarks_myBookmarks_entries_event | null;
  /**
   * The job.
   */
  job: getMyBookmarks_myBookmarks_entries_job | null;
  /**
   * The news item.
   */
  newsItem: getMyBookmarks_myBookmarks_entries_newsItem | null;
  /**
   * The forum discussion.
   */
  discussion: getMyBookmarks_myBookmarks_entries_discussion | null;
}

export interface getMyBookmarks_myBookmarks {
  __typename: "PagedResultsUserBookmarkDetail";
  /**
   * The list of entries
   */
  entries: (getMyBookmarks_myBookmarks_entries | null)[] | null;
}

export interface getMyBookmarks {
  myBookmarks: getMyBookmarks_myBookmarks | null;
}

export interface getMyBookmarksVariables {
  page?: PageInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMyLatestBookmarks
// ====================================================

export interface getMyLatestBookmarks_myLatestBookmarks_post_author {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface getMyLatestBookmarks_myLatestBookmarks_post_target {
  __typename: "ActivityFeedTarget";
  /**
   * The ID of the target activity feed object.
   */
  id: string | null;
  /**
   * The name of the target object.
   */
  name: string | null;
  /**
   * The description of the target object.
   */
  description: string | null;
  /**
   * The URL to link to the target object.
   */
  url: string | null;
}

export interface getMyLatestBookmarks_myLatestBookmarks_post {
  __typename: "ActivityFeedObj";
  /**
   * The ID of the activity feed object.
   */
  id: string | null;
  /**
   * The subject of the activity feed object.
   */
  subject: string | null;
  /**
   * The raw message of the activity feed object.
   */
  rawMessage: string | null;
  /**
   * The author of the activity feed object.
   */
  author: getMyLatestBookmarks_myLatestBookmarks_post_author | null;
  /**
   * The target party of the activity feed object.
   */
  target: getMyLatestBookmarks_myLatestBookmarks_post_target | null;
}

export interface getMyLatestBookmarks_myLatestBookmarks_event {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
  /**
   * The photo id of the event.
   */
  photoId: string | null;
  /**
   * The ID of the host for the event.
   */
  hostedById: string | null;
  /**
   * The type of host for the event.
   */
  hostedByType: string | null;
  /**
   * The hostname for the event.
   */
  hostName: string | null;
  /**
   * The start of the event recorded in DateTime with the event timezone.
   */
  startDateTime: any | null;
  /**
   * The end of the event recorded in DateTime with the event timezone.
   */
  endDateTime: any | null;
  /**
   * The location of the event.
   */
  locationText: string | null;
  /**
   * The time zone of the event.
   */
  timeZone: string | null;
}

export interface getMyLatestBookmarks_myLatestBookmarks_job {
  __typename: "Job";
  /**
   * The id of the job.
   */
  id: string | null;
  /**
   * The title of the job.
   */
  title: string;
  /**
   * The unit of the job.
   */
  unit: string;
  /**
   * The states of the job.
   */
  states: (string | null)[] | null;
  /**
   * The service name of the job.
   */
  serviceTitle: string;
  /**
   * The positions of the job.
   */
  positions: number;
}

export interface getMyLatestBookmarks_myLatestBookmarks_newsItem {
  __typename: "News";
  /**
   * The title of the news.
   */
  title: string;
  /**
   * The URL to the news article
   */
  url: string | null;
  /**
   * The date of the news.
   */
  date: any | null;
}

export interface getMyLatestBookmarks_myLatestBookmarks_discussion_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
}

export interface getMyLatestBookmarks_myLatestBookmarks_discussion_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The subject for this head post
   */
  subject: string;
}

export interface getMyLatestBookmarks_myLatestBookmarks_discussion_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface getMyLatestBookmarks_myLatestBookmarks_discussion {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: getMyLatestBookmarks_myLatestBookmarks_discussion_discussion;
  /**
   * The head post for this discussion
   */
  headPost: getMyLatestBookmarks_myLatestBookmarks_discussion_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: getMyLatestBookmarks_myLatestBookmarks_discussion_createdBy | null;
}

export interface getMyLatestBookmarks_myLatestBookmarks {
  __typename: "UserBookmarkDetail";
  /**
   * The date.
   */
  date: any | null;
  /**
   * The name.
   */
  name: string | null;
  /**
   * The URL.
   */
  url: string | null;
  /**
   * The ID of the bookmark.
   */
  userBookmarkId: string;
  /**
   * The activity.
   */
  post: getMyLatestBookmarks_myLatestBookmarks_post | null;
  /**
   * The event.
   */
  event: getMyLatestBookmarks_myLatestBookmarks_event | null;
  /**
   * The job.
   */
  job: getMyLatestBookmarks_myLatestBookmarks_job | null;
  /**
   * The news item.
   */
  newsItem: getMyLatestBookmarks_myLatestBookmarks_newsItem | null;
  /**
   * The forum discussion.
   */
  discussion: getMyLatestBookmarks_myLatestBookmarks_discussion | null;
}

export interface getMyLatestBookmarks {
  myLatestBookmarks: (getMyLatestBookmarks_myLatestBookmarks | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getUser
// ====================================================

export interface getUser_user_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
  /**
   * The user's specialisation.
   */
  specialisation: string | null;
  /**
   * The user's rank detail.
   */
  detail: string;
}

export interface getUser_user_officalGroups_detail {
  __typename: "GroupDetail";
  /**
   * The visibility of the group.
   */
  groupVisibility: GroupVisibility | null;
  /**
   * Whether the specified user can create an post within this group (true) or not (false).
   */
  canCreatePost: boolean;
  /**
   * Whether a user is able to edit this group (true) or not (false).
   */
  canEditGroupDetails: boolean;
  /**
   * Whether a user can "join" a group (true) or not (false).
   */
  canJoinGroup: boolean;
  /**
   * Whether the user should see the Members tab (true) or not (false).
   */
  canViewMembers: boolean;
  /**
   * Whether the user can request to join a group (true) or not (false).
   */
  canRequestToJoinGroup: boolean;
  /**
   * Whether the user can request to join a group (true) or not (false).
   */
  canCancelRequestToJoinGroup: boolean;
  /**
   * Whether the user is allowed to leave this group (true) or not (false).
   */
  canLeaveGroup: boolean | null;
  /**
   * Whether a user can upload a file to this group (true) or not (false).
   */
  canUploadFile: boolean;
  /**
   * IsFamily flag
   */
  isFamily: boolean;
}

export interface getUser_user_officalGroups {
  __typename: "Group";
  /**
   * My current status for the group.
   */
  myGroupStatus: GroupMembershipStatus | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The time that the group was created.
   */
  createdTime: any | null;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The detail of the group.
   */
  detail: getUser_user_officalGroups_detail | null;
}

export interface getUser_user_professionalGroups_detail {
  __typename: "GroupDetail";
  /**
   * The visibility of the group.
   */
  groupVisibility: GroupVisibility | null;
  /**
   * Whether the specified user can create an post within this group (true) or not (false).
   */
  canCreatePost: boolean;
  /**
   * Whether a user is able to edit this group (true) or not (false).
   */
  canEditGroupDetails: boolean;
  /**
   * Whether a user can "join" a group (true) or not (false).
   */
  canJoinGroup: boolean;
  /**
   * Whether the user should see the Members tab (true) or not (false).
   */
  canViewMembers: boolean;
  /**
   * Whether the user can request to join a group (true) or not (false).
   */
  canRequestToJoinGroup: boolean;
  /**
   * Whether the user can request to join a group (true) or not (false).
   */
  canCancelRequestToJoinGroup: boolean;
  /**
   * Whether the user is allowed to leave this group (true) or not (false).
   */
  canLeaveGroup: boolean | null;
  /**
   * Whether a user can upload a file to this group (true) or not (false).
   */
  canUploadFile: boolean;
  /**
   * IsFamily flag
   */
  isFamily: boolean;
}

export interface getUser_user_professionalGroups {
  __typename: "Group";
  /**
   * My current status for the group.
   */
  myGroupStatus: GroupMembershipStatus | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The time that the group was created.
   */
  createdTime: any | null;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The detail of the group.
   */
  detail: getUser_user_professionalGroups_detail | null;
}

export interface getUser_user_socialGroups_detail {
  __typename: "GroupDetail";
  /**
   * The visibility of the group.
   */
  groupVisibility: GroupVisibility | null;
  /**
   * Whether the specified user can create an post within this group (true) or not (false).
   */
  canCreatePost: boolean;
  /**
   * Whether a user is able to edit this group (true) or not (false).
   */
  canEditGroupDetails: boolean;
  /**
   * Whether a user can "join" a group (true) or not (false).
   */
  canJoinGroup: boolean;
  /**
   * Whether the user should see the Members tab (true) or not (false).
   */
  canViewMembers: boolean;
  /**
   * Whether the user can request to join a group (true) or not (false).
   */
  canRequestToJoinGroup: boolean;
  /**
   * Whether the user can request to join a group (true) or not (false).
   */
  canCancelRequestToJoinGroup: boolean;
  /**
   * Whether the user is allowed to leave this group (true) or not (false).
   */
  canLeaveGroup: boolean | null;
  /**
   * Whether a user can upload a file to this group (true) or not (false).
   */
  canUploadFile: boolean;
  /**
   * IsFamily flag
   */
  isFamily: boolean;
}

export interface getUser_user_socialGroups {
  __typename: "Group";
  /**
   * My current status for the group.
   */
  myGroupStatus: GroupMembershipStatus | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The time that the group was created.
   */
  createdTime: any | null;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The detail of the group.
   */
  detail: getUser_user_socialGroups_detail | null;
}

export interface getUser_user_familyGroups_detail {
  __typename: "GroupDetail";
  /**
   * The visibility of the group.
   */
  groupVisibility: GroupVisibility | null;
  /**
   * Whether the specified user can create an post within this group (true) or not (false).
   */
  canCreatePost: boolean;
  /**
   * Whether a user is able to edit this group (true) or not (false).
   */
  canEditGroupDetails: boolean;
  /**
   * Whether a user can "join" a group (true) or not (false).
   */
  canJoinGroup: boolean;
  /**
   * Whether the user should see the Members tab (true) or not (false).
   */
  canViewMembers: boolean;
  /**
   * Whether the user can request to join a group (true) or not (false).
   */
  canRequestToJoinGroup: boolean;
  /**
   * Whether the user can request to join a group (true) or not (false).
   */
  canCancelRequestToJoinGroup: boolean;
  /**
   * Whether the user is allowed to leave this group (true) or not (false).
   */
  canLeaveGroup: boolean | null;
  /**
   * Whether a user can upload a file to this group (true) or not (false).
   */
  canUploadFile: boolean;
  /**
   * IsFamily flag
   */
  isFamily: boolean;
}

export interface getUser_user_familyGroups {
  __typename: "Group";
  /**
   * My current status for the group.
   */
  myGroupStatus: GroupMembershipStatus | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The time that the group was created.
   */
  createdTime: any | null;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The detail of the group.
   */
  detail: getUser_user_familyGroups_detail | null;
}

export interface getUser_user {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * Whether the current profile is the users (true) or not (false).
   */
  isMyProfile: boolean;
  /**
   * Whether the user is a 'family user' (true) or not (false).
   */
  isFamilyUser: boolean;
  /**
   * Whether the user is career manager.
   */
  isCareerManager: boolean;
  /**
   * The defence position of the user.
   */
  defencePosition: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's profile status.
   */
  profileStatus: string | null;
  /**
   * The residential location of the user.
   */
  primaryLocation: string | null;
  /**
   * The defence work base of the user.
   */
  defenceWorkBase: string | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
  /**
   * The email of the user.
   */
  email: string | null;
  /**
   * The user's phone number.
   */
  phoneNumber: string | null;
  /**
   * The current email of the user.
   */
  currentEmail: string | null;
  /**
   * The user's current phone number.
   */
  currentPhoneNumber: string | null;
  /**
   * The user's rank.
   */
  rank: getUser_user_rank | null;
  /**
   * The offical groups of the user.
   */
  officalGroups: (getUser_user_officalGroups | null)[] | null;
  /**
   * The professional groups of the user.
   */
  professionalGroups: (getUser_user_professionalGroups | null)[] | null;
  /**
   * The social groups of the user.
   */
  socialGroups: (getUser_user_socialGroups | null)[] | null;
  /**
   * The family groups of the user.
   */
  familyGroups: (getUser_user_familyGroups | null)[] | null;
}

export interface getUser {
  user: getUser_user | null;
}

export interface getUserVariables {
  userid?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: performSearch
// ====================================================

export interface performSearch_search_ConversationPage {
  __typename: "ConversationPage";
}

export interface performSearch_search_EventsResult_events {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
  /**
   * The group ID of the event.
   */
  groupId: string | null;
  /**
   * The ID of the host for the event.
   */
  hostedById: string | null;
  /**
   * The type of host for the event.
   */
  hostedByType: string | null;
  /**
   * The hostname for the event.
   */
  hostName: string | null;
  /**
   * The start of the event recorded in DateTime with the event timezone.
   */
  startDateTime: any | null;
  /**
   * The location of the event.
   */
  locationText: string | null;
}

export interface performSearch_search_EventsResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_EventsResult {
  __typename: "EventsResult";
  /**
   * The list of events.
   */
  events: (performSearch_search_EventsResult_events | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_EventsResult_pageInfo | null;
}

export interface performSearch_search_SearchAllResultType_allEvents_events {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
  /**
   * The group ID of the event.
   */
  groupId: string | null;
  /**
   * The ID of the host for the event.
   */
  hostedById: string | null;
  /**
   * The type of host for the event.
   */
  hostedByType: string | null;
  /**
   * The hostname for the event.
   */
  hostName: string | null;
  /**
   * The start of the event recorded in DateTime with the event timezone.
   */
  startDateTime: any | null;
  /**
   * The location of the event.
   */
  locationText: string | null;
}

export interface performSearch_search_SearchAllResultType_allEvents_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_SearchAllResultType_allEvents {
  __typename: "EventsResult";
  /**
   * The list of events.
   */
  events: (performSearch_search_SearchAllResultType_allEvents_events | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_SearchAllResultType_allEvents_pageInfo | null;
}

export interface performSearch_search_SearchAllResultType_allGroups_groups_detail {
  __typename: "GroupDetail";
  /**
   * Whether the specified user can create an post within this group (true) or not (false).
   */
  canCreatePost: boolean;
  /**
   * Whether a user can upload a file to this group (true) or not (false).
   */
  canUploadFile: boolean;
}

export interface performSearch_search_SearchAllResultType_allGroups_groups_primaryAdmin_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface performSearch_search_SearchAllResultType_allGroups_groups_primaryAdmin {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's rank.
   */
  rank: performSearch_search_SearchAllResultType_allGroups_groups_primaryAdmin_rank | null;
}

export interface performSearch_search_SearchAllResultType_allGroups_groups {
  __typename: "Group";
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The description of the group, as raw editable text.
   */
  rawDescription: string | null;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The detail of the group.
   */
  detail: performSearch_search_SearchAllResultType_allGroups_groups_detail | null;
  /**
   * The primary administrator.
   */
  primaryAdmin: performSearch_search_SearchAllResultType_allGroups_groups_primaryAdmin | null;
}

export interface performSearch_search_SearchAllResultType_allGroups_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_SearchAllResultType_allGroups {
  __typename: "GroupsResult";
  /**
   * The list of groups.
   */
  groups: (performSearch_search_SearchAllResultType_allGroups_groups | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_SearchAllResultType_allGroups_pageInfo | null;
}

export interface performSearch_search_SearchAllResultType_allJobs_jobs {
  __typename: "Job";
  /**
   * The id of the job.
   */
  id: string | null;
  /**
   * The 'from' rank of the job.
   */
  fromRank: string | null;
  /**
   * The 'to' rank of the job.
   */
  toRank: string | null;
  /**
   * The unit of the job.
   */
  unit: string;
  /**
   * The types of the job.
   */
  types: (string | null)[] | null;
  /**
   * The states of the job.
   */
  states: (string | null)[] | null;
  /**
   * The title of the job.
   */
  title: string;
  /**
   * The skills area of the job.
   */
  skillsArea: string | null;
  /**
   * The service name of the job.
   */
  serviceTitle: string;
  /**
   * The description of the job.
   */
  description: string;
  /**
   * The location of the job.
   */
  location: string | null;
  /**
   * The positions of the job.
   */
  positions: number;
  /**
   * The media item id of the job's image.
   */
  imageId: string | null;
  /**
   * The width of the job's image.
   */
  imageWidth: number | null;
  /**
   * The height of the job's image.
   */
  imageHeight: number | null;
  /**
   * The end date of the job.
   */
  endDate: any | null;
  /**
   * The start date of the job.
   */
  startDate: any | null;
  /**
   * The closing date of the job.
   */
  closingDate: any | null;
  /**
   * The last modified date of the job.
   */
  lastModified: any | null;
}

export interface performSearch_search_SearchAllResultType_allJobs_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_SearchAllResultType_allJobs {
  __typename: "JobsResult";
  /**
   * The list of jobs.
   */
  jobs: (performSearch_search_SearchAllResultType_allJobs_jobs | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_SearchAllResultType_allJobs_pageInfo | null;
}

export interface performSearch_search_SearchAllResultType_allPeople_users_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface performSearch_search_SearchAllResultType_allPeople_users {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The service of the user.
   */
  service: string | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
  /**
   * The user's rank.
   */
  rank: performSearch_search_SearchAllResultType_allPeople_users_rank | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The account status of user.
   */
  accountStatus: AccountStatus | null;
  /**
   * The defence position of the user.
   */
  defencePosition: string | null;
}

export interface performSearch_search_SearchAllResultType_allPeople_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_SearchAllResultType_allPeople {
  __typename: "UsersResult";
  /**
   * The list of users.
   */
  users: (performSearch_search_SearchAllResultType_allPeople_users | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_SearchAllResultType_allPeople_pageInfo | null;
}

export interface performSearch_search_SearchAllResultType_allPosts_posts_author {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface performSearch_search_SearchAllResultType_allPosts_posts_target {
  __typename: "ActivityFeedTarget";
  /**
   * The ID of the target activity feed object.
   */
  id: string | null;
  /**
   * The name of the target object.
   */
  name: string | null;
  /**
   * The description of the target object.
   */
  description: string | null;
}

export interface performSearch_search_SearchAllResultType_allPosts_posts {
  __typename: "ActivityFeedObj";
  /**
   * The ID of the activity feed object.
   */
  id: string | null;
  /**
   * The subject of the activity feed object.
   */
  subject: string | null;
  /**
   * The subtitle of the activity feed object.
   */
  subTitle: string | null;
  /**
   * The rendered message of the activity feed object.
   */
  postMessage: string | null;
  /**
   * The type of activity feed object.
   */
  postType: FrontEndActivityType | null;
  /**
   * The creation date of the activity feed object.
   */
  dateCreated: any | null;
  /**
   * The author of the activity feed object.
   */
  author: performSearch_search_SearchAllResultType_allPosts_posts_author | null;
  /**
   * The target party of the activity feed object.
   */
  target: performSearch_search_SearchAllResultType_allPosts_posts_target | null;
}

export interface performSearch_search_SearchAllResultType_allPosts_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_SearchAllResultType_allPosts {
  __typename: "ActivityFeed";
  /**
   * The list of posts.
   */
  posts: (performSearch_search_SearchAllResultType_allPosts_posts | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_SearchAllResultType_allPosts_pageInfo | null;
}

export interface performSearch_search_SearchAllResultType {
  __typename: "SearchAllResultType";
  /**
   * List of event of search result.
   */
  allEvents: performSearch_search_SearchAllResultType_allEvents | null;
  /**
   * List of group of search result.
   */
  allGroups: performSearch_search_SearchAllResultType_allGroups | null;
  /**
   * List of job of search result.
   */
  allJobs: performSearch_search_SearchAllResultType_allJobs | null;
  /**
   * List of user of search result.
   */
  allPeople: performSearch_search_SearchAllResultType_allPeople | null;
  /**
   * List of posts of search result.
   */
  allPosts: performSearch_search_SearchAllResultType_allPosts | null;
}

export interface performSearch_search_GroupsResult_groups_detail {
  __typename: "GroupDetail";
  /**
   * Whether the specified user can create an post within this group (true) or not (false).
   */
  canCreatePost: boolean;
  /**
   * Whether a user can upload a file to this group (true) or not (false).
   */
  canUploadFile: boolean;
}

export interface performSearch_search_GroupsResult_groups_primaryAdmin_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface performSearch_search_GroupsResult_groups_primaryAdmin {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's rank.
   */
  rank: performSearch_search_GroupsResult_groups_primaryAdmin_rank | null;
}

export interface performSearch_search_GroupsResult_groups {
  __typename: "Group";
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The description of the group, as raw editable text.
   */
  rawDescription: string | null;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The detail of the group.
   */
  detail: performSearch_search_GroupsResult_groups_detail | null;
  /**
   * The primary administrator.
   */
  primaryAdmin: performSearch_search_GroupsResult_groups_primaryAdmin | null;
}

export interface performSearch_search_GroupsResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_GroupsResult {
  __typename: "GroupsResult";
  /**
   * The list of groups.
   */
  groups: (performSearch_search_GroupsResult_groups | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_GroupsResult_pageInfo | null;
}

export interface performSearch_search_JobsResult_jobs {
  __typename: "Job";
  /**
   * The id of the job.
   */
  id: string | null;
  /**
   * The 'from' rank of the job.
   */
  fromRank: string | null;
  /**
   * The 'to' rank of the job.
   */
  toRank: string | null;
  /**
   * The unit of the job.
   */
  unit: string;
  /**
   * The types of the job.
   */
  types: (string | null)[] | null;
  /**
   * The states of the job.
   */
  states: (string | null)[] | null;
  /**
   * The title of the job.
   */
  title: string;
  /**
   * The skills area of the job.
   */
  skillsArea: string | null;
  /**
   * The service name of the job.
   */
  serviceTitle: string;
  /**
   * The description of the job.
   */
  description: string;
  /**
   * The location of the job.
   */
  location: string | null;
  /**
   * The positions of the job.
   */
  positions: number;
  /**
   * The media item id of the job's image.
   */
  imageId: string | null;
  /**
   * The width of the job's image.
   */
  imageWidth: number | null;
  /**
   * The height of the job's image.
   */
  imageHeight: number | null;
  /**
   * The end date of the job.
   */
  endDate: any | null;
  /**
   * The start date of the job.
   */
  startDate: any | null;
  /**
   * The closing date of the job.
   */
  closingDate: any | null;
  /**
   * The last modified date of the job.
   */
  lastModified: any | null;
}

export interface performSearch_search_JobsResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_JobsResult {
  __typename: "JobsResult";
  /**
   * The list of jobs.
   */
  jobs: (performSearch_search_JobsResult_jobs | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_JobsResult_pageInfo | null;
}

export interface performSearch_search_UsersResult_users_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface performSearch_search_UsersResult_users {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The service of the user.
   */
  service: string | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
  /**
   * The user's rank.
   */
  rank: performSearch_search_UsersResult_users_rank | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The account status of user.
   */
  accountStatus: AccountStatus | null;
  /**
   * The defence position of the user.
   */
  defencePosition: string | null;
}

export interface performSearch_search_UsersResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_UsersResult {
  __typename: "UsersResult";
  /**
   * The list of users.
   */
  users: (performSearch_search_UsersResult_users | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_UsersResult_pageInfo | null;
}

export interface performSearch_search_ConversationPageResult_conversations_otherParticipants {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface performSearch_search_ConversationPageResult_conversations_hiddenParticipants {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface performSearch_search_ConversationPageResult_conversations {
  __typename: "MessagingConversation";
  /**
   * The subject of the conversation.
   */
  subject: string | null;
  /**
   * The ID of the conversation.
   */
  id: string | null;
  /**
   * Whether the conversation is important (true) or not (false).
   */
  isImportant: boolean | null;
  /**
   * ID of the image
   */
  imageId: string | null;
  /**
   * Whether the conversation is archived (true) or not (false).
   */
  isArchived: boolean | null;
  /**
   * List of other participants.
   */
  otherParticipants: (performSearch_search_ConversationPageResult_conversations_otherParticipants | null)[] | null;
  /**
   * List of hidden participants.
   */
  hiddenParticipants: (performSearch_search_ConversationPageResult_conversations_hiddenParticipants | null)[] | null;
}

export interface performSearch_search_ConversationPageResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_ConversationPageResult {
  __typename: "ConversationPageResult";
  /**
   * The list of conversations.
   */
  conversations: (performSearch_search_ConversationPageResult_conversations | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_ConversationPageResult_pageInfo | null;
}

export interface performSearch_search_ActivityFeed_posts_author {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface performSearch_search_ActivityFeed_posts_target {
  __typename: "ActivityFeedTarget";
  /**
   * The ID of the target activity feed object.
   */
  id: string | null;
  /**
   * The name of the target object.
   */
  name: string | null;
  /**
   * The description of the target object.
   */
  description: string | null;
}

export interface performSearch_search_ActivityFeed_posts {
  __typename: "ActivityFeedObj";
  /**
   * The ID of the activity feed object.
   */
  id: string | null;
  /**
   * The subject of the activity feed object.
   */
  subject: string | null;
  /**
   * The subtitle of the activity feed object.
   */
  subTitle: string | null;
  /**
   * The rendered message of the activity feed object.
   */
  postMessage: string | null;
  /**
   * The type of activity feed object.
   */
  postType: FrontEndActivityType | null;
  /**
   * The creation date of the activity feed object.
   */
  dateCreated: any | null;
  /**
   * The author of the activity feed object.
   */
  author: performSearch_search_ActivityFeed_posts_author | null;
  /**
   * The target party of the activity feed object.
   */
  target: performSearch_search_ActivityFeed_posts_target | null;
}

export interface performSearch_search_ActivityFeed_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface performSearch_search_ActivityFeed {
  __typename: "ActivityFeed";
  /**
   * The list of posts.
   */
  posts: (performSearch_search_ActivityFeed_posts | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: performSearch_search_ActivityFeed_pageInfo | null;
}

export type performSearch_search = performSearch_search_ConversationPage | performSearch_search_EventsResult | performSearch_search_SearchAllResultType | performSearch_search_GroupsResult | performSearch_search_JobsResult | performSearch_search_UsersResult | performSearch_search_ConversationPageResult | performSearch_search_ActivityFeed;

export interface performSearch {
  search: performSearch_search | null;
}

export interface performSearchVariables {
  query: SearchQueryInputType;
  page?: PageInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeBookmark
// ====================================================

export interface removeBookmark_removeBookmark_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface removeBookmark_removeBookmark_GroupList {
  __typename: "GroupList";
  /**
   * The group list ID.
   */
  groupListId: string;
  /**
   * The name of the group list.
   */
  name: string;
}

export interface removeBookmark_removeBookmark_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface removeBookmark_removeBookmark_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (removeBookmark_removeBookmark_Errors_errors | null)[] | null;
}

export type removeBookmark_removeBookmark = removeBookmark_removeBookmark_Guid | removeBookmark_removeBookmark_GroupList | removeBookmark_removeBookmark_Errors;

export interface removeBookmark {
  removeBookmark: removeBookmark_removeBookmark | null;
}

export interface removeBookmarkVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: saveGroupList
// ====================================================

export interface saveGroupList_saveGroupList_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface saveGroupList_saveGroupList_GroupList {
  __typename: "GroupList";
  /**
   * The group list ID.
   */
  groupListId: string;
  /**
   * The name of the group list.
   */
  name: string;
}

export interface saveGroupList_saveGroupList_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface saveGroupList_saveGroupList_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (saveGroupList_saveGroupList_Errors_errors | null)[] | null;
}

export type saveGroupList_saveGroupList = saveGroupList_saveGroupList_Guid | saveGroupList_saveGroupList_GroupList | saveGroupList_saveGroupList_Errors;

export interface saveGroupList {
  saveGroupList: saveGroupList_saveGroupList | null;
}

export interface saveGroupListVariables {
  groupListId?: string | null;
  groupId: string;
  name: string;
  groupItemIdList?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: sitecoreItemQuery
// ====================================================

export interface sitecoreItemQuery_sitecoreItem_fields_values_Guid {
  __typename: "Guid";
}

export interface sitecoreItemQuery_sitecoreItem_fields_values_Text {
  __typename: "Text";
  /**
   * The string object type represents text as a sequence of UTF-16 code units.
   */
  TextValue: string | null;
}

export interface sitecoreItemQuery_sitecoreItem_fields_values_Bool {
  __typename: "Bool";
  /**
   * The Boolean object type represents true or false.
   */
  BoolValue: boolean | null;
}

export interface sitecoreItemQuery_sitecoreItem_fields_values_DateTimeType {
  __typename: "DateTimeType";
  /**
   * The datetime object value.
   */
  DateTimeValue: any | null;
}

export interface sitecoreItemQuery_sitecoreItem_fields_values_NameValueString {
  __typename: "NameValueString";
  /**
   * The name.
   */
  name: string | null;
  /**
   * The value.
   */
  NameValue: string | null;
}

export type sitecoreItemQuery_sitecoreItem_fields_values = sitecoreItemQuery_sitecoreItem_fields_values_Guid | sitecoreItemQuery_sitecoreItem_fields_values_Text | sitecoreItemQuery_sitecoreItem_fields_values_Bool | sitecoreItemQuery_sitecoreItem_fields_values_DateTimeType | sitecoreItemQuery_sitecoreItem_fields_values_NameValueString;

export interface sitecoreItemQuery_sitecoreItem_fields {
  __typename: "SitecoreItemFieldValue";
  /**
   * Name of the field.
   */
  name: string | null;
  /**
   * List of field values.
   */
  values: (sitecoreItemQuery_sitecoreItem_fields_values | null)[] | null;
}

export interface sitecoreItemQuery_sitecoreItem {
  __typename: "SitecoreItemFields";
  /**
   * List of fields
   */
  fields: (sitecoreItemQuery_sitecoreItem_fields | null)[] | null;
}

export interface sitecoreItemQuery {
  sitecoreItem: sitecoreItemQuery_sitecoreItem | null;
}

export interface sitecoreItemQueryVariables {
  id: string;
  fields?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: result
// ====================================================

export interface result_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface result_GroupList {
  __typename: "GroupList";
  /**
   * The group list ID.
   */
  groupListId: string;
  /**
   * The name of the group list.
   */
  name: string;
}

export interface result_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface result_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (result_Errors_errors | null)[] | null;
}

export type result = result_Guid | result_GroupList | result_Errors;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: saveTopicActivityResult
// ====================================================

export interface saveTopicActivityResult_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult";
}

export interface saveTopicActivityResult_ForumTopicActivity {
  __typename: "ForumTopicActivity";
  /**
   * The id of the topic where the user activity occurred
   */
  topicId: string;
  /**
   * True = accordion open, False = accordion closed
   */
  accordionState: boolean;
}

export interface saveTopicActivityResult_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface saveTopicActivityResult_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (saveTopicActivityResult_Errors_errors | null)[] | null;
}

export type saveTopicActivityResult = saveTopicActivityResult_Guid | saveTopicActivityResult_ForumTopicActivity | saveTopicActivityResult_Errors;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: saveReplyResult
// ====================================================

export interface saveReplyResult_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumTopicActivity";
}

export interface saveReplyResult_ForumReplyResult_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface saveReplyResult_ForumReplyResult_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface saveReplyResult_ForumReplyResult_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface saveReplyResult_ForumReplyResult_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface saveReplyResult_ForumReplyResult {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: saveReplyResult_ForumReplyResult_reply;
  /**
   * The person who created this reply
   */
  createdBy: saveReplyResult_ForumReplyResult_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: saveReplyResult_ForumReplyResult_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (saveReplyResult_ForumReplyResult_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface saveReplyResult_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface saveReplyResult_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (saveReplyResult_Errors_errors | null)[] | null;
}

export type saveReplyResult = saveReplyResult_Guid | saveReplyResult_ForumReplyResult | saveReplyResult_Errors;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: topicStateResult
// ====================================================

export interface topicStateResult_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface topicStateResult_ForumTopic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface topicStateResult_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface topicStateResult_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (topicStateResult_Errors_errors | null)[] | null;
}

export type topicStateResult = topicStateResult_Guid | topicStateResult_ForumTopic | topicStateResult_Errors;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: saveAckResult
// ====================================================

export interface saveAckResult_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface saveAckResult_ForumAck {
  __typename: "ForumAck";
  /**
   * The id of the discussion which was ack'd
   */
  discussionId: string;
  /**
   * The id of the reply which was ack'd, or a zero guid if the ack was on the head post of the discussion
   */
  replyId: string;
  /**
   * The id of the user who performed this activity
   */
  userId: string;
  /**
   * The timestamp when this ack occurred
   */
  createdDate: any;
}

export interface saveAckResult_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface saveAckResult_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (saveAckResult_Errors_errors | null)[] | null;
}

export type saveAckResult = saveAckResult_Guid | saveAckResult_ForumAck | saveAckResult_Errors;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: saveDiscussionActivityResult
// ====================================================

export interface saveDiscussionActivityResult_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface saveDiscussionActivityResult_ForumDiscussionActivity {
  __typename: "ForumDiscussionActivity";
  /**
   * The id of the discussion where the activity occurred
   */
  discussionId: string;
  /**
   * The id of the user who performed this activity
   */
  userId: string;
  /**
   * The timestamp when this activity occurred
   */
  lastActivityDate: any;
}

export interface saveDiscussionActivityResult_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface saveDiscussionActivityResult_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (saveDiscussionActivityResult_Errors_errors | null)[] | null;
}

export type saveDiscussionActivityResult = saveDiscussionActivityResult_Guid | saveDiscussionActivityResult_ForumDiscussionActivity | saveDiscussionActivityResult_Errors;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: saveDiscussionResult
// ====================================================

export interface saveDiscussionResult_Guid {
  __typename: "Guid" | "Bool" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface saveDiscussionResult_ForumDiscussionResult_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface saveDiscussionResult_ForumDiscussionResult_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface saveDiscussionResult_ForumDiscussionResult_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface saveDiscussionResult_ForumDiscussionResult_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface saveDiscussionResult_ForumDiscussionResult_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface saveDiscussionResult_ForumDiscussionResult_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface saveDiscussionResult_ForumDiscussionResult_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface saveDiscussionResult_ForumDiscussionResult_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface saveDiscussionResult_ForumDiscussionResult_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface saveDiscussionResult_ForumDiscussionResult_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface saveDiscussionResult_ForumDiscussionResult_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: saveDiscussionResult_ForumDiscussionResult_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: saveDiscussionResult_ForumDiscussionResult_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: saveDiscussionResult_ForumDiscussionResult_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (saveDiscussionResult_ForumDiscussionResult_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface saveDiscussionResult_ForumDiscussionResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface saveDiscussionResult_ForumDiscussionResult {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: saveDiscussionResult_ForumDiscussionResult_discussion;
  /**
   * The topic
   */
  topic: saveDiscussionResult_ForumDiscussionResult_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: saveDiscussionResult_ForumDiscussionResult_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: saveDiscussionResult_ForumDiscussionResult_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: saveDiscussionResult_ForumDiscussionResult_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (saveDiscussionResult_ForumDiscussionResult_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (saveDiscussionResult_ForumDiscussionResult_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: saveDiscussionResult_ForumDiscussionResult_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

export interface saveDiscussionResult_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface saveDiscussionResult_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (saveDiscussionResult_Errors_errors | null)[] | null;
}

export type saveDiscussionResult = saveDiscussionResult_Guid | saveDiscussionResult_ForumDiscussionResult | saveDiscussionResult_Errors;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: boolResult
// ====================================================

export interface boolResult_Guid {
  __typename: "Guid" | "Text" | "SimpleCommentObj" | "ActivityFeedObj" | "ActivityFeed" | "AckResult" | "ActivityFeedRedirectPost" | "ParticipantAcksList" | "AckSummary" | "File" | "FileIdWithFlag" | "EventAttendance" | "Group" | "Conversation" | "Message" | "MessagingConversation" | "LoginResult" | "GroupList" | "ForumDiscussionResult" | "ForumDiscussionActivity" | "ForumAck" | "ForumTopic" | "ForumReplyResult" | "ForumTopicActivity";
}

export interface boolResult_Bool {
  __typename: "Bool";
  /**
   * The Boolean object type represents true or false.
   */
  value: boolean | null;
}

export interface boolResult_Errors_errors {
  __typename: "ErrorResponse";
  /**
   * The error code of the response.
   */
  code: string | null;
  /**
   * The error message of the response.
   */
  message: string | null;
}

export interface boolResult_Errors {
  __typename: "Errors";
  /**
   * The errors of the response.
   */
  errors: (boolResult_Errors_errors | null)[] | null;
}

export type boolResult = boolResult_Guid | boolResult_Bool | boolResult_Errors;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: forumDiscussionsResult
// ====================================================

export interface forumDiscussionsResult_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface forumDiscussionsResult_discussions_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface forumDiscussionsResult_discussions_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface forumDiscussionsResult_discussions_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface forumDiscussionsResult_discussions_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumDiscussionsResult_discussions_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumDiscussionsResult_discussions_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface forumDiscussionsResult_discussions_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface forumDiscussionsResult_discussions_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumDiscussionsResult_discussions_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumDiscussionsResult_discussions_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface forumDiscussionsResult_discussions_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: forumDiscussionsResult_discussions_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: forumDiscussionsResult_discussions_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: forumDiscussionsResult_discussions_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (forumDiscussionsResult_discussions_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface forumDiscussionsResult_discussions_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface forumDiscussionsResult_discussions {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: forumDiscussionsResult_discussions_discussion;
  /**
   * The topic
   */
  topic: forumDiscussionsResult_discussions_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: forumDiscussionsResult_discussions_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: forumDiscussionsResult_discussions_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: forumDiscussionsResult_discussions_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (forumDiscussionsResult_discussions_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (forumDiscussionsResult_discussions_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: forumDiscussionsResult_discussions_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

export interface forumDiscussionsResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface forumDiscussionsResult {
  __typename: "ForumDiscussionsResult";
  /**
   * The topic
   */
  topic: forumDiscussionsResult_topic | null;
  /**
   * The list of discussions
   */
  discussions: (forumDiscussionsResult_discussions | null)[] | null;
  /**
   * Paging information for discussions
   */
  pageInfo: forumDiscussionsResult_pageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: forumDiscussionResult
// ====================================================

export interface forumDiscussionResult_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface forumDiscussionResult_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface forumDiscussionResult_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface forumDiscussionResult_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumDiscussionResult_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumDiscussionResult_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface forumDiscussionResult_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface forumDiscussionResult_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumDiscussionResult_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumDiscussionResult_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface forumDiscussionResult_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: forumDiscussionResult_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: forumDiscussionResult_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: forumDiscussionResult_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (forumDiscussionResult_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface forumDiscussionResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface forumDiscussionResult {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: forumDiscussionResult_discussion;
  /**
   * The topic
   */
  topic: forumDiscussionResult_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: forumDiscussionResult_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: forumDiscussionResult_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: forumDiscussionResult_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (forumDiscussionResult_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (forumDiscussionResult_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: forumDiscussionResult_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: forumAck
// ====================================================

export interface forumAck {
  __typename: "ForumAck";
  /**
   * The id of the discussion which was ack'd
   */
  discussionId: string;
  /**
   * The id of the reply which was ack'd, or a zero guid if the ack was on the head post of the discussion
   */
  replyId: string;
  /**
   * The id of the user who performed this activity
   */
  userId: string;
  /**
   * The timestamp when this ack occurred
   */
  createdDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: forumDiscussionActivity
// ====================================================

export interface forumDiscussionActivity {
  __typename: "ForumDiscussionActivity";
  /**
   * The id of the discussion where the activity occurred
   */
  discussionId: string;
  /**
   * The id of the user who performed this activity
   */
  userId: string;
  /**
   * The timestamp when this activity occurred
   */
  lastActivityDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: forumTopicActivity
// ====================================================

export interface forumTopicActivity {
  __typename: "ForumTopicActivity";
  /**
   * The id of the topic where the user activity occurred
   */
  topicId: string;
  /**
   * True = accordion open, False = accordion closed
   */
  accordionState: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: participant
// ====================================================

export interface participant {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: forumReplyResult
// ====================================================

export interface forumReplyResult_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface forumReplyResult_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumReplyResult_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumReplyResult_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface forumReplyResult {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: forumReplyResult_reply;
  /**
   * The person who created this reply
   */
  createdBy: forumReplyResult_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: forumReplyResult_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (forumReplyResult_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: forumResult
// ====================================================

export interface forumResult_forum {
  __typename: "Forum";
  /**
   * The unique identifier of this forum
   */
  forumId: string;
  /**
   * The Id of the associated group
   */
  groupId: string;
}

export interface forumResult_topics_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface forumResult_topics {
  __typename: "ForumTopicResult";
  /**
   * A forum topic item
   */
  topic: forumResult_topics_topic | null;
  /**
   * Each topic's moreDiscussionsCount will be different dependendent on how many discussions were returned for that topic since that number is subtracted from the total to give how many more remain
   */
  moreDiscussionsCount: number;
  /**
   * True = accordion open, False = accordion closed
   */
  accordionState: boolean;
  /**
   * The total number of discussions in this topic
   */
  totalDiscussionCount: number;
}

export interface forumResult_discussions_discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

export interface forumResult_discussions_topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface forumResult_discussions_headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

export interface forumResult_discussions_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumResult_discussions_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumResult_discussions_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface forumResult_discussions_replies_reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

export interface forumResult_discussions_replies_createdBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumResult_discussions_replies_lastModifiedBy {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
}

export interface forumResult_discussions_replies_associations {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

export interface forumResult_discussions_replies {
  __typename: "ForumReplyResult";
  /**
   * The discussion
   */
  reply: forumResult_discussions_replies_reply;
  /**
   * The person who created this reply
   */
  createdBy: forumResult_discussions_replies_createdBy | null;
  /**
   * The person who last modified this reply
   */
  lastModifiedBy: forumResult_discussions_replies_lastModifiedBy | null;
  /**
   * True if the reply has been edited
   */
  hasBeenEdited: boolean;
  /**
   * The total number of acks for this reply
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on this reply
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for this reply
   */
  associations: (forumResult_discussions_replies_associations | null)[] | null;
  /**
   * Enum of rights that the current user has on this reply
   */
  userRights: number;
  /**
   * True if the reply is under review
   */
  isReported: boolean;
  /**
   * True if the reply can be reported
   */
  canReport: boolean;
}

export interface forumResult_discussions_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface forumResult_discussions {
  __typename: "ForumDiscussionResult";
  /**
   * The discussion
   */
  discussion: forumResult_discussions_discussion;
  /**
   * The topic
   */
  topic: forumResult_discussions_topic | null;
  /**
   * The head post for this discussion
   */
  headPost: forumResult_discussions_headPost;
  /**
   * The person who created this discussion
   */
  createdBy: forumResult_discussions_createdBy | null;
  /**
   * The person who last modified this discussion
   */
  lastModifiedBy: forumResult_discussions_lastModifiedBy | null;
  /**
   * The datetime of the most recent reply or edit to this discussion or the head post
   */
  lastActivityDate: any;
  /**
   * Should the user see the 'new' marker for this discussion
   */
  hasNewMarker: boolean;
  /**
   * The total number of replies in this discussion
   */
  totalRepliesCount: number;
  /**
   * The number of unread replies in this discussion for the current user
   */
  unreadRepliesCount: number;
  /**
   * The total number of acks for the head post of this discussion
   */
  totalAcks: number;
  /**
   * True if the user has made an ack on the discussion head post
   */
  hasUserAck: boolean | null;
  /**
   * The list of associated items for the discussion head post
   */
  associations: (forumResult_discussions_associations | null)[] | null;
  /**
   * The list of associated items for the discussion head post
   */
  replies: (forumResult_discussions_replies | null)[] | null;
  /**
   * Paging information for replies
   */
  pageInfo: forumResult_discussions_pageInfo | null;
  /**
   * Enum of rights that the current user has on this discussion
   */
  userRights: number;
  /**
   * True if the user can report the post
   */
  canReport: boolean | null;
  /**
   * True if the discussion has been reported
   */
  isReported: boolean | null;
}

export interface forumResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface forumResult {
  __typename: "ForumResult";
  /**
   * A forum item
   */
  forum: forumResult_forum | null;
  /**
   * The list of topics
   */
  topics: (forumResult_topics | null)[] | null;
  /**
   * The list of discussions
   */
  discussions: (forumResult_discussions | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: forumResult_pageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: forum
// ====================================================

export interface forum {
  __typename: "Forum";
  /**
   * The unique identifier of this forum
   */
  forumId: string;
  /**
   * The Id of the associated group
   */
  groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: topic
// ====================================================

export interface topic {
  __typename: "ForumTopic";
  /**
   * The unique identifier of this topic
   */
  topicId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The title of this topic
   */
  title: string;
  /**
   * Is this topic active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: discussion
// ====================================================

export interface discussion {
  __typename: "ForumDiscussion";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The Id of the associated forum
   */
  forumId: string;
  /**
   * The user who created this discussion
   */
  createdBy: string;
  /**
   * The Id of the associated topic(if topics are enabled) or zero guid
   */
  topicId: string | null;
  /**
   * The timestamp when this discussion was created
   */
  createdDate: any;
  /**
   * Is this discussion active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
  /**
   * Is this discussion open (true) or closed (false)  Note that this controls only whether new replies are allowed. A closed discussion can still be seen.
   */
  isOpen: boolean;
  /**
   * The datetime of either the last post within this discusssion or the last change to the discussion state (other than ReadCount updates)
   */
  lastModifiedDate: any;
  /**
   * The number of times this discussion has been viewed/read
   */
  readCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: reply
// ====================================================

export interface reply {
  __typename: "ForumReply";
  /**
   * The unique identifier of this discussion
   */
  replyId: string;
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
  /**
   * Is this reply active (enabled) or hidden (disabled)
   */
  isEnabled: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: association
// ====================================================

export interface association {
  __typename: "ForumAssociation";
  /**
   * The Id of the associated discussion
   */
  discussionId: string;
  /**
   * The Id of the reply or zero guid if this association is on the head post
   */
  replyId: string;
  /**
   * The id of the associated item
   */
  associatedItemId: string;
  /**
   * Enum of types of things that can be associated with a post
   */
  associationType: number;
  /**
   * The order that associated items were added (or the order they should be displayed)
   */
  priority: number;
  /**
   * The display name of the associated item
   */
  displayName: string | null;
  /**
   * The description of the associated item
   */
  description: string | null;
  /**
   * The type of the associated item.  Its meaning is specific to the particular association type. 
   * 
   * See enum definitions at .\src\ForceNetFrontEnd\js\modules\fileexplorer\actions\FileExplorerActions.js
   * 
   * Associated file & photo uploads and group files have the following ItemType values
   * 	UNKNOWN: 0,
   * 	WORD: 1,
   * 	EXCEL: 2,
   * 	PDF: 3,
   * 	POWER_POINT: 4,
   * 	IMAGE: 5,
   * 	CSV: 6,
   * 	VIDEO: 7,
   * 	URL: 8,
   * 	AUDIO: 9
   * 
   * Associated group folders have the following ItemType values
   *     Document
   *     Media
   */
  itemType: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: headPost
// ====================================================

export interface headPost {
  __typename: "ForumHead";
  /**
   * The unique identifier of this discussion
   */
  discussionId: string;
  /**
   * The version number of the draft that was published
   */
  draftVersion: number;
  /**
   * The user who created this head post
   */
  createdBy: string;
  /**
   * The timestamp when this head post was created
   */
  createdDate: any;
  /**
   * The user who last modified this head post
   */
  lastModifiedBy: string;
  /**
   * The timestamp when this head post was last modified
   */
  lastModifiedDate: any;
  /**
   * The subject for this head post
   */
  subject: string;
  /**
   * The content of the head post
   */
  content: string;
  /**
   * True if the content contains markup
   */
  isRichText: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: pageInfo
// ====================================================

export interface pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: groupFields
// ====================================================

export interface groupFields_detail {
  __typename: "GroupDetail";
  /**
   * The visibility of the group.
   */
  groupVisibility: GroupVisibility | null;
  /**
   * Whether the specified user can create an post within this group (true) or not (false).
   */
  canCreatePost: boolean;
  /**
   * Whether a user is able to edit this group (true) or not (false).
   */
  canEditGroupDetails: boolean;
  /**
   * Whether a user can "join" a group (true) or not (false).
   */
  canJoinGroup: boolean;
  /**
   * Whether the user should see the Members tab (true) or not (false).
   */
  canViewMembers: boolean;
  /**
   * Whether the user can request to join a group (true) or not (false).
   */
  canRequestToJoinGroup: boolean;
  /**
   * Whether the user can request to join a group (true) or not (false).
   */
  canCancelRequestToJoinGroup: boolean;
  /**
   * Whether the user is allowed to leave this group (true) or not (false).
   */
  canLeaveGroup: boolean | null;
  /**
   * Whether a user can upload a file to this group (true) or not (false).
   */
  canUploadFile: boolean;
  /**
   * IsFamily flag
   */
  isFamily: boolean;
}

export interface groupFields {
  __typename: "Group";
  /**
   * My current status for the group.
   */
  myGroupStatus: GroupMembershipStatus | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The time that the group was created.
   */
  createdTime: any | null;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The detail of the group.
   */
  detail: groupFields_detail | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: allResult
// ====================================================

export interface allResult_allEvents_events {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
  /**
   * The group ID of the event.
   */
  groupId: string | null;
  /**
   * The ID of the host for the event.
   */
  hostedById: string | null;
  /**
   * The type of host for the event.
   */
  hostedByType: string | null;
  /**
   * The hostname for the event.
   */
  hostName: string | null;
  /**
   * The start of the event recorded in DateTime with the event timezone.
   */
  startDateTime: any | null;
  /**
   * The location of the event.
   */
  locationText: string | null;
}

export interface allResult_allEvents_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface allResult_allEvents {
  __typename: "EventsResult";
  /**
   * The list of events.
   */
  events: (allResult_allEvents_events | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: allResult_allEvents_pageInfo | null;
}

export interface allResult_allGroups_groups_detail {
  __typename: "GroupDetail";
  /**
   * Whether the specified user can create an post within this group (true) or not (false).
   */
  canCreatePost: boolean;
  /**
   * Whether a user can upload a file to this group (true) or not (false).
   */
  canUploadFile: boolean;
}

export interface allResult_allGroups_groups_primaryAdmin_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface allResult_allGroups_groups_primaryAdmin {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's rank.
   */
  rank: allResult_allGroups_groups_primaryAdmin_rank | null;
}

export interface allResult_allGroups_groups {
  __typename: "Group";
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The description of the group, as raw editable text.
   */
  rawDescription: string | null;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The detail of the group.
   */
  detail: allResult_allGroups_groups_detail | null;
  /**
   * The primary administrator.
   */
  primaryAdmin: allResult_allGroups_groups_primaryAdmin | null;
}

export interface allResult_allGroups_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface allResult_allGroups {
  __typename: "GroupsResult";
  /**
   * The list of groups.
   */
  groups: (allResult_allGroups_groups | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: allResult_allGroups_pageInfo | null;
}

export interface allResult_allJobs_jobs {
  __typename: "Job";
  /**
   * The id of the job.
   */
  id: string | null;
  /**
   * The 'from' rank of the job.
   */
  fromRank: string | null;
  /**
   * The 'to' rank of the job.
   */
  toRank: string | null;
  /**
   * The unit of the job.
   */
  unit: string;
  /**
   * The types of the job.
   */
  types: (string | null)[] | null;
  /**
   * The states of the job.
   */
  states: (string | null)[] | null;
  /**
   * The title of the job.
   */
  title: string;
  /**
   * The skills area of the job.
   */
  skillsArea: string | null;
  /**
   * The service name of the job.
   */
  serviceTitle: string;
  /**
   * The description of the job.
   */
  description: string;
  /**
   * The location of the job.
   */
  location: string | null;
  /**
   * The positions of the job.
   */
  positions: number;
  /**
   * The media item id of the job's image.
   */
  imageId: string | null;
  /**
   * The width of the job's image.
   */
  imageWidth: number | null;
  /**
   * The height of the job's image.
   */
  imageHeight: number | null;
  /**
   * The end date of the job.
   */
  endDate: any | null;
  /**
   * The start date of the job.
   */
  startDate: any | null;
  /**
   * The closing date of the job.
   */
  closingDate: any | null;
  /**
   * The last modified date of the job.
   */
  lastModified: any | null;
}

export interface allResult_allJobs_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface allResult_allJobs {
  __typename: "JobsResult";
  /**
   * The list of jobs.
   */
  jobs: (allResult_allJobs_jobs | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: allResult_allJobs_pageInfo | null;
}

export interface allResult_allPeople_users_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface allResult_allPeople_users {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The service of the user.
   */
  service: string | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
  /**
   * The user's rank.
   */
  rank: allResult_allPeople_users_rank | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The account status of user.
   */
  accountStatus: AccountStatus | null;
  /**
   * The defence position of the user.
   */
  defencePosition: string | null;
}

export interface allResult_allPeople_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface allResult_allPeople {
  __typename: "UsersResult";
  /**
   * The list of users.
   */
  users: (allResult_allPeople_users | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: allResult_allPeople_pageInfo | null;
}

export interface allResult_allPosts_posts_author {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface allResult_allPosts_posts_target {
  __typename: "ActivityFeedTarget";
  /**
   * The ID of the target activity feed object.
   */
  id: string | null;
  /**
   * The name of the target object.
   */
  name: string | null;
  /**
   * The description of the target object.
   */
  description: string | null;
}

export interface allResult_allPosts_posts {
  __typename: "ActivityFeedObj";
  /**
   * The ID of the activity feed object.
   */
  id: string | null;
  /**
   * The subject of the activity feed object.
   */
  subject: string | null;
  /**
   * The subtitle of the activity feed object.
   */
  subTitle: string | null;
  /**
   * The rendered message of the activity feed object.
   */
  postMessage: string | null;
  /**
   * The type of activity feed object.
   */
  postType: FrontEndActivityType | null;
  /**
   * The creation date of the activity feed object.
   */
  dateCreated: any | null;
  /**
   * The author of the activity feed object.
   */
  author: allResult_allPosts_posts_author | null;
  /**
   * The target party of the activity feed object.
   */
  target: allResult_allPosts_posts_target | null;
}

export interface allResult_allPosts_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface allResult_allPosts {
  __typename: "ActivityFeed";
  /**
   * The list of posts.
   */
  posts: (allResult_allPosts_posts | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: allResult_allPosts_pageInfo | null;
}

export interface allResult {
  __typename: "SearchAllResultType";
  /**
   * List of event of search result.
   */
  allEvents: allResult_allEvents | null;
  /**
   * List of group of search result.
   */
  allGroups: allResult_allGroups | null;
  /**
   * List of job of search result.
   */
  allJobs: allResult_allJobs | null;
  /**
   * List of user of search result.
   */
  allPeople: allResult_allPeople | null;
  /**
   * List of posts of search result.
   */
  allPosts: allResult_allPosts | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: eventResult
// ====================================================

export interface eventResult_events {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
  /**
   * The group ID of the event.
   */
  groupId: string | null;
  /**
   * The ID of the host for the event.
   */
  hostedById: string | null;
  /**
   * The type of host for the event.
   */
  hostedByType: string | null;
  /**
   * The hostname for the event.
   */
  hostName: string | null;
  /**
   * The start of the event recorded in DateTime with the event timezone.
   */
  startDateTime: any | null;
  /**
   * The location of the event.
   */
  locationText: string | null;
}

export interface eventResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface eventResult {
  __typename: "EventsResult";
  /**
   * The list of events.
   */
  events: (eventResult_events | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: eventResult_pageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: groupResult
// ====================================================

export interface groupResult_groups_detail {
  __typename: "GroupDetail";
  /**
   * Whether the specified user can create an post within this group (true) or not (false).
   */
  canCreatePost: boolean;
  /**
   * Whether a user can upload a file to this group (true) or not (false).
   */
  canUploadFile: boolean;
}

export interface groupResult_groups_primaryAdmin_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface groupResult_groups_primaryAdmin {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's rank.
   */
  rank: groupResult_groups_primaryAdmin_rank | null;
}

export interface groupResult_groups {
  __typename: "Group";
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The description of the group, as raw editable text.
   */
  rawDescription: string | null;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The detail of the group.
   */
  detail: groupResult_groups_detail | null;
  /**
   * The primary administrator.
   */
  primaryAdmin: groupResult_groups_primaryAdmin | null;
}

export interface groupResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface groupResult {
  __typename: "GroupsResult";
  /**
   * The list of groups.
   */
  groups: (groupResult_groups | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: groupResult_pageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: messageResult
// ====================================================

export interface messageResult_conversations_otherParticipants {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface messageResult_conversations_hiddenParticipants {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface messageResult_conversations {
  __typename: "MessagingConversation";
  /**
   * The subject of the conversation.
   */
  subject: string | null;
  /**
   * The ID of the conversation.
   */
  id: string | null;
  /**
   * Whether the conversation is important (true) or not (false).
   */
  isImportant: boolean | null;
  /**
   * ID of the image
   */
  imageId: string | null;
  /**
   * Whether the conversation is archived (true) or not (false).
   */
  isArchived: boolean | null;
  /**
   * List of other participants.
   */
  otherParticipants: (messageResult_conversations_otherParticipants | null)[] | null;
  /**
   * List of hidden participants.
   */
  hiddenParticipants: (messageResult_conversations_hiddenParticipants | null)[] | null;
}

export interface messageResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface messageResult {
  __typename: "ConversationPageResult";
  /**
   * The list of conversations.
   */
  conversations: (messageResult_conversations | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: messageResult_pageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: jobResult
// ====================================================

export interface jobResult_jobs {
  __typename: "Job";
  /**
   * The id of the job.
   */
  id: string | null;
  /**
   * The 'from' rank of the job.
   */
  fromRank: string | null;
  /**
   * The 'to' rank of the job.
   */
  toRank: string | null;
  /**
   * The unit of the job.
   */
  unit: string;
  /**
   * The types of the job.
   */
  types: (string | null)[] | null;
  /**
   * The states of the job.
   */
  states: (string | null)[] | null;
  /**
   * The title of the job.
   */
  title: string;
  /**
   * The skills area of the job.
   */
  skillsArea: string | null;
  /**
   * The service name of the job.
   */
  serviceTitle: string;
  /**
   * The description of the job.
   */
  description: string;
  /**
   * The location of the job.
   */
  location: string | null;
  /**
   * The positions of the job.
   */
  positions: number;
  /**
   * The media item id of the job's image.
   */
  imageId: string | null;
  /**
   * The width of the job's image.
   */
  imageWidth: number | null;
  /**
   * The height of the job's image.
   */
  imageHeight: number | null;
  /**
   * The end date of the job.
   */
  endDate: any | null;
  /**
   * The start date of the job.
   */
  startDate: any | null;
  /**
   * The closing date of the job.
   */
  closingDate: any | null;
  /**
   * The last modified date of the job.
   */
  lastModified: any | null;
}

export interface jobResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface jobResult {
  __typename: "JobsResult";
  /**
   * The list of jobs.
   */
  jobs: (jobResult_jobs | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: jobResult_pageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: postResult
// ====================================================

export interface postResult_posts_author {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface postResult_posts_target {
  __typename: "ActivityFeedTarget";
  /**
   * The ID of the target activity feed object.
   */
  id: string | null;
  /**
   * The name of the target object.
   */
  name: string | null;
  /**
   * The description of the target object.
   */
  description: string | null;
}

export interface postResult_posts {
  __typename: "ActivityFeedObj";
  /**
   * The ID of the activity feed object.
   */
  id: string | null;
  /**
   * The subject of the activity feed object.
   */
  subject: string | null;
  /**
   * The subtitle of the activity feed object.
   */
  subTitle: string | null;
  /**
   * The rendered message of the activity feed object.
   */
  postMessage: string | null;
  /**
   * The type of activity feed object.
   */
  postType: FrontEndActivityType | null;
  /**
   * The creation date of the activity feed object.
   */
  dateCreated: any | null;
  /**
   * The author of the activity feed object.
   */
  author: postResult_posts_author | null;
  /**
   * The target party of the activity feed object.
   */
  target: postResult_posts_target | null;
}

export interface postResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface postResult {
  __typename: "ActivityFeed";
  /**
   * The list of posts.
   */
  posts: (postResult_posts | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: postResult_pageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: userResult
// ====================================================

export interface userResult_users_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface userResult_users {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The service of the user.
   */
  service: string | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
  /**
   * The user's rank.
   */
  rank: userResult_users_rank | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The account status of user.
   */
  accountStatus: AccountStatus | null;
  /**
   * The defence position of the user.
   */
  defencePosition: string | null;
}

export interface userResult_pageInfo {
  __typename: "PageInfo";
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
  totalCount: number;
}

export interface userResult {
  __typename: "UsersResult";
  /**
   * The list of users.
   */
  users: (userResult_users | null)[] | null;
  /**
   * Paging information
   */
  pageInfo: userResult_pageInfo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: event
// ====================================================

export interface event {
  __typename: "Event";
  /**
   * The name of the event.
   */
  name: string | null;
  /**
   * The ID of the event.
   */
  eventId: string | null;
  /**
   * The group ID of the event.
   */
  groupId: string | null;
  /**
   * The ID of the host for the event.
   */
  hostedById: string | null;
  /**
   * The type of host for the event.
   */
  hostedByType: string | null;
  /**
   * The hostname for the event.
   */
  hostName: string | null;
  /**
   * The start of the event recorded in DateTime with the event timezone.
   */
  startDateTime: any | null;
  /**
   * The location of the event.
   */
  locationText: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: group
// ====================================================

export interface group_detail {
  __typename: "GroupDetail";
  /**
   * Whether the specified user can create an post within this group (true) or not (false).
   */
  canCreatePost: boolean;
  /**
   * Whether a user can upload a file to this group (true) or not (false).
   */
  canUploadFile: boolean;
}

export interface group_primaryAdmin_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface group_primaryAdmin {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's rank.
   */
  rank: group_primaryAdmin_rank | null;
}

export interface group {
  __typename: "Group";
  /**
   * The name of the group.
   */
  name: string | null;
  /**
   * The groupType of the group.
   */
  groupType: string | null;
  /**
   * The groupID of the group.
   */
  groupId: string | null;
  /**
   * The service of the group.
   */
  service: string | null;
  /**
   * The summary of the group.
   */
  summary: string;
  /**
   * The number of members of the group.
   */
  numberOfMembers: number;
  /**
   * The description of the group.
   */
  description: string | null;
  /**
   * The description of the group, as raw editable text.
   */
  rawDescription: string | null;
  /**
   * The photo id of the group.
   */
  photoId: string | null;
  /**
   * The detail of the group.
   */
  detail: group_detail | null;
  /**
   * The primary administrator.
   */
  primaryAdmin: group_primaryAdmin | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: job
// ====================================================

export interface job {
  __typename: "Job";
  /**
   * The id of the job.
   */
  id: string | null;
  /**
   * The 'from' rank of the job.
   */
  fromRank: string | null;
  /**
   * The 'to' rank of the job.
   */
  toRank: string | null;
  /**
   * The unit of the job.
   */
  unit: string;
  /**
   * The types of the job.
   */
  types: (string | null)[] | null;
  /**
   * The states of the job.
   */
  states: (string | null)[] | null;
  /**
   * The title of the job.
   */
  title: string;
  /**
   * The skills area of the job.
   */
  skillsArea: string | null;
  /**
   * The service name of the job.
   */
  serviceTitle: string;
  /**
   * The description of the job.
   */
  description: string;
  /**
   * The location of the job.
   */
  location: string | null;
  /**
   * The positions of the job.
   */
  positions: number;
  /**
   * The media item id of the job's image.
   */
  imageId: string | null;
  /**
   * The width of the job's image.
   */
  imageWidth: number | null;
  /**
   * The height of the job's image.
   */
  imageHeight: number | null;
  /**
   * The end date of the job.
   */
  endDate: any | null;
  /**
   * The start date of the job.
   */
  startDate: any | null;
  /**
   * The closing date of the job.
   */
  closingDate: any | null;
  /**
   * The last modified date of the job.
   */
  lastModified: any | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: user
// ====================================================

export interface user_rank {
  __typename: "Rank";
  /**
   * The user's rank title.
   */
  title: string;
}

export interface user {
  __typename: "User";
  /**
   * The user's full name.
   */
  fullName: string;
  /**
   * The user's ID.
   */
  id: string | null;
  /**
   * The service of the user.
   */
  service: string | null;
  /**
   * The profile image ID of the user.
   */
  profileImageId: string | null;
  /**
   * The user's rank.
   */
  rank: user_rank | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The account status of user.
   */
  accountStatus: AccountStatus | null;
  /**
   * The defence position of the user.
   */
  defencePosition: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: message
// ====================================================

export interface message_otherParticipants {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface message_hiddenParticipants {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface message {
  __typename: "MessagingConversation";
  /**
   * The subject of the conversation.
   */
  subject: string | null;
  /**
   * The ID of the conversation.
   */
  id: string | null;
  /**
   * Whether the conversation is important (true) or not (false).
   */
  isImportant: boolean | null;
  /**
   * ID of the image
   */
  imageId: string | null;
  /**
   * Whether the conversation is archived (true) or not (false).
   */
  isArchived: boolean | null;
  /**
   * List of other participants.
   */
  otherParticipants: (message_otherParticipants | null)[] | null;
  /**
   * List of hidden participants.
   */
  hiddenParticipants: (message_hiddenParticipants | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: post
// ====================================================

export interface post_author {
  __typename: "Participant";
  /**
   * The ID of this user.
   */
  id: string | null;
  /**
   * The full name of this user.
   */
  name: string | null;
  /**
   * The avatar image ID of this user.
   */
  avatarId: string | null;
  /**
   * The type of permanent user.
   */
  permanentUserType: PermanentUserType | null;
  /**
   * The user's AccountStatus as an integer.
   */
  status: number;
}

export interface post_target {
  __typename: "ActivityFeedTarget";
  /**
   * The ID of the target activity feed object.
   */
  id: string | null;
  /**
   * The name of the target object.
   */
  name: string | null;
  /**
   * The description of the target object.
   */
  description: string | null;
}

export interface post {
  __typename: "ActivityFeedObj";
  /**
   * The ID of the activity feed object.
   */
  id: string | null;
  /**
   * The subject of the activity feed object.
   */
  subject: string | null;
  /**
   * The subtitle of the activity feed object.
   */
  subTitle: string | null;
  /**
   * The rendered message of the activity feed object.
   */
  postMessage: string | null;
  /**
   * The type of activity feed object.
   */
  postType: FrontEndActivityType | null;
  /**
   * The creation date of the activity feed object.
   */
  dateCreated: any | null;
  /**
   * The author of the activity feed object.
   */
  author: post_author | null;
  /**
   * The target party of the activity feed object.
   */
  target: post_target | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
  LOCKED = "LOCKED",
}

export enum AdministrativeActionType {
  ABUSE_REPORTS = "ABUSE_REPORTS",
  GROUP_APPROVAL = "GROUP_APPROVAL",
  GROUP_JOIN_REQUEST = "GROUP_JOIN_REQUEST",
  JOB_APPROVAL = "JOB_APPROVAL",
  JOB_EXPIRY = "JOB_EXPIRY",
}

export enum FrontEndActivityType {
  DISCUSSION = "DISCUSSION",
  EVENT_CANCELLED = "EVENT_CANCELLED",
  EVENT_CREATED = "EVENT_CREATED",
  EVENT_MESSAGE = "EVENT_MESSAGE",
  EVENT_UPDATE = "EVENT_UPDATE",
  GROUP_MESSAGE = "GROUP_MESSAGE",
  JOB = "JOB",
  NEWS = "NEWS",
  UNKNOWN = "UNKNOWN",
}

export enum GroupMembershipStatus {
  GROUP_ADMINISTRATOR = "GROUP_ADMINISTRATOR",
  GROUP_COMMANDER = "GROUP_COMMANDER",
  GROUP_PRIMARY_ADMINISTRATOR = "GROUP_PRIMARY_ADMINISTRATOR",
  INVITED = "INVITED",
  MEMBER = "MEMBER",
  NO_RELATION = "NO_RELATION",
  REQUEST_PENDING_ACCEPTANCE = "REQUEST_PENDING_ACCEPTANCE",
}

export enum GroupVisibility {
  MEMBERS_ONLY = "MEMBERS_ONLY",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum NotificationIcon {
  ACK = "ACK",
  EVENT = "EVENT",
  FILE_ADD = "FILE_ADD",
  FLAG = "FLAG",
  GROUP = "GROUP",
  JOB = "JOB",
  MEMBER_ADD = "MEMBER_ADD",
  MEMBER_DELETE = "MEMBER_DELETE",
  MESSAGE = "MESSAGE",
  PHOTO = "PHOTO",
  POST = "POST",
  USER_PROFILE = "USER_PROFILE",
}

export enum PermanentUserType {
  CONTRACTOR = "CONTRACTOR",
  FORCE_NET_SUPPORT = "FORCE_NET_SUPPORT",
  OTHER_GOV = "OTHER_GOV",
}

export enum PersonalActionType {
  CONTRACT_EXPIRY_REMINDER = "CONTRACT_EXPIRY_REMINDER",
  EVENT_INVITATION = "EVENT_INVITATION",
  FAMILY_SPONSORSHIP_RENEWAL = "FAMILY_SPONSORSHIP_RENEWAL",
  FAMILY_SPONSORSHIP_REQUEST = "FAMILY_SPONSORSHIP_REQUEST",
  GROUP_INVITATION = "GROUP_INVITATION",
  PATTERN_OF_SERVICE_UPDATE_REMINDER = "PATTERN_OF_SERVICE_UPDATE_REMINDER",
  PROFILE_UPDATE_REMINDER = "PROFILE_UPDATE_REMINDER",
}

export enum SearchType {
  ALL = "ALL",
  EVENT = "EVENT",
  GROUP = "GROUP",
  JOB = "JOB",
  MESSAGE = "MESSAGE",
  PEOPLE = "PEOPLE",
  POSTS = "POSTS",
  RECIPIENT = "RECIPIENT",
}

export enum SiteNotificationType {
  ACK_COMMENT = "ACK_COMMENT",
  ACK_POST = "ACK_POST",
  ADDED_TO_GROUP = "ADDED_TO_GROUP",
  COMMENT_ON_POST = "COMMENT_ON_POST",
  COMMENT_ON_POST_FOR_AUTHOR = "COMMENT_ON_POST_FOR_AUTHOR",
  COMPLAINT_REPORT = "COMPLAINT_REPORT",
  DELETE_GROUP_CONTENT_COMPLETED = "DELETE_GROUP_CONTENT_COMPLETED",
  EVENT_IN_GROUP_CANCELLED = "EVENT_IN_GROUP_CANCELLED",
  EVENT_IN_GROUP_REMINDER = "EVENT_IN_GROUP_REMINDER",
  EVENT_IN_GROUP_UPDATED = "EVENT_IN_GROUP_UPDATED",
  FAMILY_CONNECTION = "FAMILY_CONNECTION",
  FILE_UPLOAD = "FILE_UPLOAD",
  FILE_VIRUS_DETECTED_IN_GROUP = "FILE_VIRUS_DETECTED_IN_GROUP",
  FILE_VIRUS_DETECTED_OTHER = "FILE_VIRUS_DETECTED_OTHER",
  FORCE_NET_INVITATION = "FORCE_NET_INVITATION",
  GROUP_ACCESS_REQUESTED = "GROUP_ACCESS_REQUESTED",
  GROUP_ACCESS_REQUEST_ASSIGNED = "GROUP_ACCESS_REQUEST_ASSIGNED",
  GROUP_ACCESS_REQUEST_DENIED = "GROUP_ACCESS_REQUEST_DENIED",
  GROUP_CREATION_REQUESTED = "GROUP_CREATION_REQUESTED",
  IMPORTANT_MESSAGE_FOR_PARTICIPANT = "IMPORTANT_MESSAGE_FOR_PARTICIPANT",
  IMPORTANT_POST = "IMPORTANT_POST",
  INVITED_TO_EVENT_IN_GROUP = "INVITED_TO_EVENT_IN_GROUP",
  INVITED_TO_GROUP = "INVITED_TO_GROUP",
  INVITED_TO_STANDALONE_EVENT = "INVITED_TO_STANDALONE_EVENT",
  JOB_POST_MATCHES_MEMBER = "JOB_POST_MATCHES_MEMBER",
  JOB_SEEKER_REVIEW_STATUS = "JOB_SEEKER_REVIEW_STATUS",
  LEFT_THE_GROUP = "LEFT_THE_GROUP",
  MESSAGE_FOR_ORIGINAL_SENDER = "MESSAGE_FOR_ORIGINAL_SENDER",
  MESSAGE_FOR_PARTICIPANT = "MESSAGE_FOR_PARTICIPANT",
  PHOTO_POST = "PHOTO_POST",
  POST = "POST",
  REMOVED_FROM_GROUP = "REMOVED_FROM_GROUP",
  RESPONDED_TO_GROUP_EVENT = "RESPONDED_TO_GROUP_EVENT",
  RESPONDED_TO_STANDALONE_EVENT = "RESPONDED_TO_STANDALONE_EVENT",
  SITEWIDE_NOTICE = "SITEWIDE_NOTICE",
  STANDALONE_EVENT_CANCELLED = "STANDALONE_EVENT_CANCELLED",
  STANDALONE_EVENT_REMINDER = "STANDALONE_EVENT_REMINDER",
  STANDALONE_EVENT_UPDATED = "STANDALONE_EVENT_UPDATED",
}

export interface ConversationFilterInputType {
  folderId?: string | null;
  focusConversation?: number | null;
}

export interface EventQueryInputType {
  includeHiddenEvents?: boolean | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface ForumAssociationInput {
  discussionId?: string | null;
  replyId?: string | null;
  associatedItemId: string;
  associationType: number;
  priority: number;
  displayName?: string | null;
  description?: string | null;
  itemType?: string | null;
}

export interface MembershipQueryParamsType {
  membershipStatuses?: GroupMembershipStatus[] | null;
  searchTerm?: string | null;
  includeDeactivatedUsers?: boolean | null;
}

export interface PageInput {
  pageAfter?: string | null;
  pageBefore?: string | null;
  pageFirst?: number | null;
  pageLast?: number | null;
}

export interface PostFilterInputType {
  startDate?: any | null;
  endDate?: any | null;
}

export interface SearchQueryInputType {
  queryType?: SearchType | null;
  types?: (string | null)[] | null;
  services?: (string | null)[] | null;
  locations?: (string | null)[] | null;
  skillAreas?: (string | null)[] | null;
  ranks?: (string | null)[] | null;
  defenceCategories?: (string | null)[] | null;
  keywords?: string | null;
  userQuery?: UserQueryInputType | null;
  conversationQuery?: ConversationFilterInputType | null;
  eventQuery?: EventQueryInputType | null;
  postFilter?: PostFilterInputType | null;
  excludeIds?: (string | null)[] | null;
}

export interface TaskDetailsInput {
  groupJoinRequestGroupId?: string | null;
  groupJoinRequestUserId?: string | null;
  assignToUserId?: string | null;
}

export interface UserQueryInputType {
  firstOrLastName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  username?: string | null;
  categories?: (string | null)[] | null;
  maxResults?: number | null;
  excludeUserIds?: (string | null)[] | null;
  skill?: string | null;
  searchRankOrService?: boolean | null;
  group?: string | null;
  event?: number | null;
  notAccountStatus?: AccountStatus | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

import BaseStore 				from '../../../../core/stores/BaseStore';
import AppDispatcher 			from '../../../../core/dispatcher/AppDispatcher';

import FamiliesTrialConstants  		from '../constants/FamiliesTrialConstants';
import FamiliesTrialImporterStatuses  	from '../models/FamiliesTrialImporterStatuses';

let EMPTY_TASK = {
	type: 'Default',
	task: {
		step:-1,
		progress:-1,
		status:''
	}
};

class FamiliesTrialImporterStore extends BaseStore {

	constructor() {

		super();

		/**
		 * The current status of the import wizard
		 * @type {number}
		 */
		this.status = FamiliesTrialImporterStatuses.Ready;

		/**
		 * The current task being performed.
		 * @type {Object}
		 */
		this.currentTask = EMPTY_TASK;

		/**
		 * The lastest server state object
		 * @type {string}
		 */
		this.currentState = null;


		/**
		 * The current import summary
		 * @type {null}
		 */
		this.currentSummary = null;

	}


	reset() {
		this.status = FamiliesTrialImporterStatuses.Ready;
		this.currentTask = EMPTY_TASK;
		this.currentState = null;
		this.currentSummary = null;
	}


}

/**
 * Our Store singleton
 */
let _store = new FamiliesTrialImporterStore();



/**
 *  Watch for store specific actions
 */
AppDispatcher.register(function(action) {

	switch(action.actionType) {

		case FamiliesTrialConstants.FAMILIES_TRIAL_IMPORT_FINISHED:

			_store.status = FamiliesTrialImporterStatuses.Completed;
			_store.currentSummary = action.chunk.Summary;
			_store.currentState = action.chunk.State;
			_store.emitChange();
			break;

		case FamiliesTrialConstants.FAMILIES_TRIAL_IMPORT_STOPPED:
			_store.reset();
			_store.emitChange();
			break;

		default:
			// No op
			break;
	}

});

export default _store;

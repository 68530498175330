import React 				from 'react';
import PropTypes from 'prop-types'
import FileUtils 			from '../../../core/utils/FileUtils';
import FileExplorerStore 	from '../stores/FileExplorerStore';
import Modal        		from '../../../core/components/modal/Modal';
import BtnClose     		from '../../../core/components/BtnClose';


class ModalFileDelete extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
            canUnarchive: !props.isArchive
        };
	}

    _handleModalCloseRequest() {
        this.props.onCloseClick()
    }

    _handleUnlinkClick(e) {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

        this.props.onUnlinkRequest(this.props.file, this.state.canUnarchive)
    }

    onChangeCanUnarchive = (e) => {
        this.setState({
            canUnarchive: e.target.value && parseInt(e.target.value) == 1 ? true : false
        });
    }

	render() {
        let title = "this file";
        if (this.props.file != null) {
            title = `"${this.props.file.title}"`;
        }

		return (
            <Modal onRequestsClose={this._handleModalCloseRequest.bind(this)} active={this.props.active} size='default'>
                <div className='modal-header'>
                    <BtnClose onClick={this._handleModalCloseRequest.bind(this)} />
                    <h2 className='modal-title'>Confirm deletion</h2>
                </div>
                <div className='modal-content'>
                    { !this.props.isArchive && <div>
                        <div className="fn-flex" style={{ padding: "5px" }}>
                            <div style={{ padding: "0px 10px 0px 0px" }}>
                                <input type="radio" id="canUnarchive" name="canUnarchive" value="1" checked={this.state.canUnarchive} onChange={this.onChangeCanUnarchive} />
                            </div>
                            <div>
                                <div>
                                    <label htmlFor="canUnarchive">Move {title} to "recently deleted".</label>
                                </div>
                                <div>
                                    You have 30 days to restore the file before it's deleted permanently.
                                </div>
                            </div>
                        </div>
                        <div className="fn-flex" style={{ padding: "5px" }}>
                            <div style={{ padding: "0px 10px 0px 0px" }}>
                                <input type="radio" id="cantUnarchive" name="canUnarchive" value="0" checked={!this.state.canUnarchive} onChange={this.onChangeCanUnarchive} />
                            </div>
                            <div>
                                <div>
                                    <label htmlFor="cantUnarchive">Delete {title} permanently.</label>
                                </div>
                                <div>
                                    It will be removed and you cannot restore it.
                                </div>
                            </div>
                        </div>
                    </div> }
                    { this.props.isArchive && <div>Delete {title} permanently.</div> }
                </div>
                <div className="modal-actions">
                    <button role="button" className="btn btn-primary" onClick={this._handleUnlinkClick.bind(this)}>OK</button>
                    &nbsp;
                    <button role="button" className="btn btn-default" onClick={this._handleModalCloseRequest.bind(this)}>Cancel</button>
                </div>
            </Modal>
		);
	}
}

ModalFileDelete.propTypes = {
	file: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    isArchive: PropTypes.bool.isRequired,
	//onCloseClick: PropTypes.function.isRequired,
    //onUnlinkRequest: PropTypes.function.isRequired,
};

export default ModalFileDelete;

import { AssociationType, FileCategory, FileType } from "./Enums";

// See  ForceNet.Business.Model.ActivityFeed.ActivityLink
export default class ActivityLink {

    Category: FileCategory;
    Description: string | null;
    EventId: string | null;
    FileId: string | null;
    FolderId: string | null;
    FileType: FileType;
    GroupId: string | null;
    Title: string | null;

    constructor(id: string, title: string, associationType: AssociationType, fileType: FileType) {
        this.FileId = associationType != AssociationType.GroupDocumentFolder && associationType != AssociationType.GroupMediaFolder ? id : null;
        this.FolderId = this.FileId ? null : id;
        this.Title = title;
        this.FileType = fileType;
    }
}
import React 											from 'react';
import PropTypes from 'prop-types'

import AppDispatcher									from '../../core/dispatcher/AppDispatcher';
import AppDelegate										from '../../core/AppDelegate';
import Localisation										from '../../core/resources/Localisation';
import Pagination										from '../../core/components/Pagination';
import BtnClose											from '../../core/components/BtnClose';
import BtnUpload										from '../../core/components/BtnUpload';
import Modal											from '../../core/components/modal/Modal';
import FolderSvc 										from '../../services/FolderSvc';
import FolderItem										from './components/FolderItem';
import FileItem											from './components/FileItem';
import ModalFileDelete									from './components/ModalFileDelete';
import ModalFileEdit									from './components/ModalFileEdit';
import ModalFolderCreate								from './components/ModalFolderCreate';
import ModalFolderDelete								from './components/ModalFolderDelete';
import ModalFolderEdit									from './components/ModalFolderEdit';
import BtnFolderSelect									from './components/BtnFolderSelect';
import FileExplorerActions, {FileExplorerSort, FileCategory}	from './actions/FileExplorerActions';
import FileExplorerStore								from './stores/FileExplorerStore';
import PromptActions									from '../../modules/prompt/actions/PromptActions';
import ActivityIndicator								from '../../core/components/ActivityIndicator';
import PostActions										from '../../modules/activityfeed/actions/PostActions';
import ReactTooltip 									from 'react-tooltip';
import _				from 'lodash';

import "./FileExplorerModule.css";

/**
 * The file explorer module
 */
 const FILE_TXT_SEARCH = 'txtSearch';
 const SORT_SELECTOR = 'sortSelect';

class FileExplorerModule extends React.Component {

	constructor(props) {

		super(props);

		// Get the current state from the store
		this.state = FileExplorerStore.getState();
		this.state.isWorking = false;
		this.state.expandAll = false;
		this.state.modal = { action: null, file: null, folder: null };
		this.state.category = this.props.category;
		this.state.isVideoUrl = false;
		this._init();
		
		var p = parseInt(this.props.page)
		if (_.isNumber(p)) {
			this.state[this.state.category].currentPage = p;
		}

		if (_.isString(this.props.folder)) {
			this.state[this.state.category].folderId = this.props.folder;
		}

		// The store change binding
		this._storeChangeBinding = this._onStoreChange.bind(this);

	}

	/**
	 * The component did mount event
	 */
	componentDidMount () {

		FileExplorerStore.addChangeListener(this._storeChangeBinding);

		if(this.props.searchQuery && this.props.searchQueryShown) {
			this.refs[FILE_TXT_SEARCH].value = this.props.searchQuery;
		}

		this._applyFiltersForPage(this.state[this.state.category].currentPage);
	}

	/**
	 * The component will unmount event
	 */
	componentWillUnmount () {

		// Clean up store change binding
		FileExplorerStore.removeChangeListener(this._storeChangeBinding);

	}


	/**
	 * Updates the file collection set for the current filter values
	 * @private
	 */
	_applyFiltersForPage(page) {
		let txtSearch = this.refs[FILE_TXT_SEARCH],
			sortOrder = parseInt(this.refs[SORT_SELECTOR].value),
			query = txtSearch.value;

		// Clear IE8 Placeholder text. Once we no longer support ie8 we can remove this line
		if(txtSearch.attributes && txtSearch.attributes.placeholder && query === txtSearch.attributes.placeholder.value){
			query = '';
		}

		this.setState({
			isWorking: true
		});

		FileExplorerActions
				.loadFolder(this.props.id, this.props.type, this.state.category, this.state[this.state.category].folderId, !!this.state[this.state.category].viewUnarchivable, query, page, sortOrder)
				.then(() => {
					this.setState({isWorking: false, expandAll: false});
				})
				.catch(err => {
					this.setState({isWorking: false, expandAll: false});
					PromptActions.displayFriendlyErrorPrompt(err);
				});
	}

	/**
	 * The store change event handler
	 * @private
	 */
	_onStoreChange() {
		// Update our state
		this.setState({isWorking: false, expandAll: false});

		let state = FileExplorerStore.getState();

		this.setState({ state, [this.state.category]: {
			currentPage: state.folders[this.state.category].currentPage,
			folderId: state.folders[this.state.category].folderId,
			viewUnarchivable: this.state[this.state.category] && this.state[this.state.category].viewUnarchivable
		}});
	}

	_onChangePage(page) {
		this.setState({[this.state.category]: { currentPage: page, folderId: this.state[this.state.category].folderId, viewUnarchivable: this.state[this.state.category].viewUnarchivable }});

		this._applyFiltersForPage(page);
	}

	_handleInputKeyDown (e)
	{
		// Supress enter key
		if(e && e.keyCode === 13){
			e.preventDefault();
		}
	}

	_handleInputChange () {
		this._applyFiltersForPage(1);
	}

	_handleSortChange() {
		this._applyFiltersForPage(1);
	}

	_handleUploadFileClose() {
		this.setState({ forceFileId: null, modal: { action: null, file: null, folder: null }});
	}

	_handleUploadFileComplete (file) {

		// Update the UI
		this._applyFiltersForPage(this.state.folders[this.state.category].currentPage);
	}

	_uploadType() {
		switch (this.props.type) {
			case 'Event':
				return 'EventFile';
			case 'Group':
				return 'GroupFile';
			default:
				return 'Unknown';
		}
	}

	_init() {
		var self = this;
		
		_.forEach(Object.keys(FileCategory), (c) => {
			var category = FileCategory[c];
			self.state[category] = {};
			self.state[category].currentPage = 1;
			self.state[category].folderId = "00000000-0000-0000-0000-000000000000";
		});
	}

	hasResults () {
		return this.state.searchResults !== null;
	}

	hasFiles() {
		return this.state.folders[this.state.category] !== null && this.state.folders[this.state.category].hasFiles()
	}

	hasFolders() {
		return this.state.folders[this.state.category] !== null && this.state.folders[this.state.category].hasFolders()
	}

	getSelectedFolder(folders, folderId) {
		for(let f in folders) {
			const subFolder = folders[f]
			if (subFolder.id == folderId) {
				return subFolder
			} else if (subFolder.folders) {
				let result = this.getSelectedFolder(subFolder.folders, folderId)
				if (result) return result
			}
		}
		return null
	}

	getSelectedFolderName() {
		if (!this.state.category) return ''
		if (!this.state.folders) return ''
		if (!this.state.folders[this.state.category]) return ''
		let folder = this.getSelectedFolder(this.state.folders[this.state.category].folders, this.state[this.state.category].folderId)

		return folder?.name ?? ''
	}

	getUploadDialogTitle() {
		return `${Localisation.localisedStringFor('UploadTo')} "${this.getSelectedFolderName()}"`;
	}

	_fileSetSummary()
	{
		if (!this.hasFiles()) return null;

		let n1 = (this.state.folders[this.state.category].filesPerPage * (this.state.folders[this.state.category].currentPage - 1)) + 1;
		let n2 = Math.min(n1 + this.state.folders[this.state.category].filesPerPage - 1, this.state.folders[this.state.category].totalFiles);

		return `${n1}-${n2} of ${this.state.folders[this.state.category].totalFiles}`;
	}

	_onExpandAllClick()
	{
		this.setState({ expandAll: true })
	}

	_onFileUnlinkClick(file) {
		if (!file.canDelete) return;

		this.setState({ modal: { action: 'FileDelete', file: file, folder: null }});
	}

	_onFileDownloadClick(file) {
		file.scanning = true;
		this.forceUpdate();

		FileExplorerActions.verifyFileVirusFree(file).then(action => {
			AppDispatcher.dispatch(action);
		}).catch((e) => {
			file.scanning = false;
			this.forceUpdate();
			PromptActions.displayFriendlyErrorPrompt(e);
		});
	}

	_unlinkFile(file, canUnarchive)	{
		if (!file.canDelete) {
			this._closeModal();
			return;
		}

		FileExplorerActions.unlinkFile(file, this.props.id, this.props.type, this.state.category, canUnarchive, !!this.state[this.state.category].viewUnarchivable).then(action => {
            this._closeModal();
            
			AppDispatcher.dispatch(action);

			this.setState({
				modalState: null
			});

			if (this.state.folders[this.state.category].currentPage != this.state.folders[this.state.category].totalPages)
			{
				this._applyFiltersForPage(this.state.folders[this.state.category].currentPage);
			}
			else if (this.state.folders[this.state.category].currentPage != 1 && this.state.folders[this.state.category].files.length == 0)
			{
				--(this.state[this.state.category].currentPage);
				this._applyFiltersForPage(this.state.folders[this.state.category].currentPage);
			}

			return null;
		}).catch(PromptActions.displayFriendlyErrorPrompt);

	}

	_onFileEditClick(file) {
		if (!file.canEdit) return;

		this.setState({ modal: { action: 'FileEdit', file: file, folder: null }});
	}

	_updateFile(file, title, description, folderId, parentFolderId, folderTitle, replaceFromFileId, isAccessRestricted, accessUsers)
	{
		if (!file.canEdit) {
			this._closeModal();
			return;
		}
        
		FileExplorerActions.updateFile(this.state[this.state.category].folderId, file, this.props.id, this.props.type, this.state.category, title, description, folderId, parentFolderId, folderTitle, replaceFromFileId, !!this.state[this.state.category].viewUnarchivable, isAccessRestricted, accessUsers).then(action => {

            this._closeModal();
            
			AppDispatcher.dispatch(action);

			this.setState({
				modalState: null
			});

        }).catch((error) => {
            PromptActions.displayFriendlyErrorPrompt(error)
        });
	}

	_handleUploadFileUrlClose() {
		this.setState({ modal: { action: null, file: null, folder: null }});
	}

	_uploadFileUrl(url, title, description, thumbnailUrl, notify)
	{
		FileExplorerActions.uploadFileUrl(url, title, this.state[this.state.category].folderId, this.props.id, this._uploadType(), this.state.category, description, thumbnailUrl, notify).then(action => {
			this._closeModal();
			this._applyFiltersForPage(this.state[this.state.category].currentPage);
			return null;
        }).catch((error) => {
            PromptActions.displayFriendlyErrorPrompt(error)
        });
	}

	_onChangeCategory(category, event)
	{
		event?.preventDefault();
		this.setState({category: category}, () => this.state.folders[this.state.category] == null ? this._applyFiltersForPage(this.state[this.state.category].currentPage) : null);
	}

	_onDeleteAll = (e) => {
		FileExplorerActions.clearAllUnarchivable(this.props.type, this.props.id, this.state.category).then(action => {
			this._closeModal();

			this.setState({ [this.state.category]: {folderId: "00000000-0000-0000-0000-000000000000", viewUnarchivable: false }}, () => this._applyFiltersForPage(1));

			return null;
		}).catch(PromptActions.displayFriendlyErrorPrompt);
	}

	_onDeleteAllModal = (e) => {
		this.setState({
			modal: {
				action: 'DeleteAll',
				file: null,
				folder: null
			}
		});
	}

	_renderUnarchivableHeader() {
		return <div className="row">
			<div className="small-16 large-12 columns">
				<h3>Your recently deleted items</h3>
				<p>You can permanently delete your files from here or click the edit button to move your files back into your saved files space.</p>
				<p><strong className="text-primary">Important!</strong> Files will be permanently deleted from this space 30 days after they appear here. You cannot restore them once this happens.</p>
			</div>
			<div className="small-16 large-4 columns">
				<a href="#" className="btn btn-primary right" role="button" onClick={this._onDeleteAllModal}>
					<span>
						Delete all files
					</span>
				</a>
			</div>
			<Modal active={this.state.modal.action == 'DeleteAll'} onRequestsClose={this._closeModal.bind(this)}>
					<div className='modal-header'>
						<BtnClose onClick={this._closeModal.bind(this)} />
						<h2 className='modal-title'>Empty recently deleted items permanently</h2>
					</div>
					<div className='modal-content'>
						Delete all your recently deleted items permanently.
					</div>
					<div className="modal-actions">
                    <button role="button" className="btn btn-primary"  onClick={this._onDeleteAll}>Delete</button>
                    &nbsp;
                    <button role="button" className="btn btn-default" onClick={this._closeModal.bind(this)}>Cancel</button>
                </div>
			</Modal>			
		</div>
	}

	_renderCategories() {
		var self = this;

		const items = _.map(Object.keys(FileCategory), (c) => {
			let category = FileCategory[c];
			let label;
			let className = this.state.category == category ? "active" : "inactive";

			switch (category)
			{
				case FileCategory.DOCUMENT:
					label = 'Documents';
					break;

				case FileCategory.MEDIA:
					label = 'Media';
					break;
			}

			return (<li key={c}><a href={label} id={label} className={ className } onClick={this._onChangeCategory.bind(this, category)}>{label}</a></li>);
		});
		
		return (
			<div className="tool-stack-group file-categories">
				<div className="form-group">
					<ul>
						{items}
					</ul>
				</div>
			</div>
		);
	}

	_renderFileList()
	{
		let listClassName = 'file-list';
		if (this.state.category !== FileCategory.DOCUMENT && !this.state[this.state.category].viewUnarchivable) {
			listClassName += ' thumbnails';
		}

		if (this.state.folders[this.state.category] == null) return null; 

		let renderExpand = this.hasFiles() && !this.state.expandAll;
		if (renderExpand) {
			let first = _.find(this.state.folders[this.state.category].files, (f) => {
				return f.description.length > 200;
			})
			renderExpand = first != null;
		}

		return (
			<div>
				{ renderExpand ?
					<a href="javascript:void(0)" id="toggleDesc" onClick={this._onExpandAllClick.bind(this)} className="plus lnk">Expand all descriptions</a>
				: null }
				{ this.hasFiles() ?
					<ul className={listClassName}>
						{
							_.map(this.state.folders[this.state.category].files, (file) => {
								return (
									<FileItem
										category={this.state.category}
										id={this.props.id}
										type={this.props.type}
										folderId={this.state[this.state.category].folderId}
										key={file.id}
										file={file}
										showAll={this.state.expandAll}
										onEditClick={this._onFileEditClick.bind(this)}
										onDeleteClick={this._onFileUnlinkClick.bind(this)}
										onDownloadClick={this._onFileDownloadClick.bind(this)}
										downloadEndpoint={this.props.downloadEndpoint}
										thumbnail={this.state.category !== FileCategory.DOCUMENT }
										viewUnarchivable={this.state[this.state.category].viewUnarchivable} />
								);
							})
						}
					</ul>
					:
					<div className="fileExplorer-message">
						{this.state.folders[this.state.category].message ? this.state.folders[this.state.category].message.replace(/\{0}/, this.props.type || 'Location') : ''}
					</div>
				}
			</div>
		);
	}

	_onFolderSelectClick(folder) {
		if (folder.id == this.state[this.state.category].folderId && !this.state[this.state.category].viewUnarchivable) return;
		this.setState({ [this.state.category]: {folderId: folder.id, viewUnarchivable: false }}, () => this._applyFiltersForPage(this.state[this.state.category].currentPage));
	}

	_onFolderDeleteClick(folder)
	{
		if (!folder.canDelete) return;

		this.setState({ modal: { action: 'FolderDelete', file: null, folder: folder }});
	}

	_onFolderEditClick(folder)
	{
		if (!folder.canDelete) return;

		this.setState({ modal: { action: 'FolderEdit', file: null, folder: folder }});
	}

	_onViewUnarchivable = (e) => {
		this.setState({
			[this.state.category]: {currentPage: 1, viewUnarchivable: true },
		}, () => this._applyFiltersForPage(this.state[this.state.category].currentPage));
	}

	_unlinkFolder(folder, canUnarchive)	{
		if (!folder.canDelete) {
			this._closeModal();
			return;
		}

		FileExplorerActions.unlinkFolder(this.state[this.state.category].folderId, this.props.id, this.props.type, this.state.category, folder.id, canUnarchive).then((folderId) => {
            if (this.state[this.state.category].folderId != folderId) {
                this.setState({ [this.state.category]: {folderId: folder.id }});
            }
			this._closeModal();

		}).catch((error) => PromptActions.displayFriendlyErrorPrompt(error));
	}

	_updateFolder(folder, parentFolderId, title, isAccessRestricted, accessUsers)
	{
		if (!folder.canDelete) {
			this._closeModal();
			return;
		}

		FileExplorerActions.updateFolder(this.state[this.state.category].folderId, this.props.id, this.props.type, this.state.category, folder.id, parentFolderId, title, isAccessRestricted, accessUsers).then(action => {

            this._closeModal();
            
			AppDispatcher.dispatch(action);

			this.setState({
				modalState: null
			});

        }).catch((error) => {
            PromptActions.displayFriendlyErrorPrompt(error)
        });
	}

	_onFolderCreateClick(folder)
	{
		if (!this.props.canUpload) return;

		this.setState({ modal: { action: 'FolderCreate', file: null, folder: folder }});
	}

	_createFolder(parentFolderId, name, onCreated)
	{
		if (!this.props.canUpload) {
			this._closeModal();
			return;
		}

		FileExplorerActions.createFolder(this.state[this.state.category].folderId, this.props.id, this.props.type, this.state.category, parentFolderId, name).then(() => {

			this._closeModal();

			if (onCreated)
			{
				onCreated();
			}

        }).catch((error) => {
            PromptActions.displayFriendlyErrorPrompt(error)
        });
	}

	_onFolderShowMobileMenuClick(folder)
	{
		this.setState({ [this.state.category]: {folderId: null }});
	}

	_onFolderSelected(folder, title, parentFolderId) {
		if (folder)
		{
			this.setState({ [this.state.category]: {folderId: folder, viewUnarchivable: false }}, () => this._applyFiltersForPage(this.state[this.state.category].currentPage));
			this.setState({ modal: { action: 'FileUpload', file: null, folder: null }});
		}
		else if (title)
		{
			this._createFolder(parentFolderId, title, this.setState.bind(this, { modal: { action: 'FileUpload', file: null, folder: null }}));
		}
	}

	_onConfirmFileUpload(notify, fileIds) {
		if (notify) {
			FileExplorerActions.sendFileUploadNotification(fileIds, this.props.id, this.props.type).then(action => {
			}).catch(PromptActions.displayFriendlyErrorPrompt);
		}
	}

	_getFolderLabel()
	{
		return this.state.category === FileCategory.DOCUMENT ? Localisation.localisedStringFor('Folder') : Localisation.localisedStringFor('Album');
	}

	_getSearchFilesLabel()
	{
		let label = Localisation.localisedStringFor('SearchFiles');
		switch (this.state.category)
		{
			case FileCategory.MEDIA:
				label = Localisation.localisedStringFor('SearchMedia');
				break;
		}
		
		return label;
	}

	_renderFolderList()
	{
		/** To toggle folder menu in mobile, add/remove class "show" to the UL.folderList **/
		let listClassName = 'folderList';
		if (this.state[this.state.category].folderId == null) {
			listClassName += ' show';
		}

		return (
			<div>
				{ this.hasFolders() ?
					<ul className={listClassName}>
						{
							_.map(this.state.folders[this.state.category].folders, f => {
								return (
									<FolderItem category={this.state.category} key={f.id} folder={f} selected={!this.state[this.state.category].viewUnarchivable && f.id == this.state[this.state.category].folderId} selectedId={!this.state[this.state.category].viewUnarchivable ? this.state[this.state.category].folderId : null} onSelectClick={this._onFolderSelectClick.bind(this)} onDeleteClick={this._onFolderDeleteClick.bind(this)} onEditClick={this._onFolderEditClick.bind(this)}/>
								);
							})
						}
					</ul>
					: null
				}
			</div>
		);
	}

	_closeModal() {
		this.setState({ modal: { action: null, file: null, folder: null }});
	}

    addFolder(folders, folder, indent, maxDepth) {
        if (indent < maxDepth) {
            let f = _.cloneDeep(folder);

            if (folder.id === "00000000-0000-0000-0000-000000000000") {
                f.name = f.label = "None";
            } else {
                f.label = folder.name;
            }

            f.value = folder.id;
            f.indent = indent;

            folders.push(f);

            if (folder.folders) {
                folder.folders.forEach(child => {
                    this.addFolder(folders, child, indent + 1, maxDepth);
                })
            }
        }
    }

    findParentFolder(folders, id, hierarchy) {
        let parentFolderId;

        folders.find(f => {
            if (f.id === id) {
                parentFolderId = f.id;
                hierarchy.push(f.id);
                return true;
            }

            if (f.folders) {
                if (this.findParentFolder(f.folders, id, hierarchy)) {
                    parentFolderId = f.id;
                    hierarchy.push(f.id);
                    return true;
                }
            }

            return false;
        });
        
        return parentFolderId;
    }

	/**
	 * Returns the components current view
	 * @returns {*}
	 */
	render() {
        let creationFolders = [];
        let selectableFolders = [];
		let folders = this.hasFolders() ? this.state.folders[this.state.category].folders : [];
		const notFiledFolderId = "00000000-0000-0000-0000-000000000000";

		if (AppDelegate.config.maxFolderDepth > 1) {
			folders.forEach(folder => {
				this.addFolder(creationFolders, folder, 0, AppDelegate.config.maxFolderDepth - 1);
			});
		}

        folders.forEach(folder => {
            this.addFolder(selectableFolders, folder, 0, AppDelegate.config.maxFolderDepth);
        });

		let hierarchy = [];
		let parentFolderId;
		let parentFolderCreationId;

        this.findParentFolder(folders, this.state[this.state.category].folderId, hierarchy);

        if (hierarchy && hierarchy.length > 0 && AppDelegate.config.maxFolderDepth > 1) {
            parentFolderCreationId = hierarchy.length >= AppDelegate.config.maxFolderDepth && AppDelegate.config.maxFolderDepth > 0 ? hierarchy[1] : hierarchy[0];
        } else {
            parentFolderCreationId = "00000000-0000-0000-0000-000000000000";
		}
		
		if (this.state.modal.folder && this.state.modal.folder.id && this.state.modal.folder.id !== this.state[this.state.category].folderId) {
			hierarchy = [];
			this.findParentFolder(folders, this.state.modal.folder.id, hierarchy);
		}

        if (hierarchy && hierarchy.length > 1) {
            parentFolderId = hierarchy[1];
        } else {
            parentFolderId = "00000000-0000-0000-0000-000000000000";
        }

		return (
			<div className="panel fileExplorer">
				<div className="panel-heading">
					<div className="row">
		                <div className="small-16 large-10 columns">
		                    <h2 className="panel-title">{this.props.title}</h2>
		                </div>
						<div className="small-16 large-6 columns large-align-r tool-stack">
							{ this._renderCategories() }
						</div>
		            </div>
				</div>
				<div className="panel-message">
					<div className="row">
		                <div className="small-16 large-4 columns">
		                    <h2 className="panel-title">Security Reminder</h2>
		                </div>
						<div className="small-16 large-12 columns">
							<p>Remember, you are responsible for the security of official information and how it's used.</p>
							<p>Information classified PROTECTED or higher is not to be transmitted, stored or accessed via ForceNet.</p>
						</div>
		            </div>
				</div>
				<div className="panel-body fileExplorer-body">
					<div className="row">
						<div className="large-3 small-16 columns">
							<div>
								{ this.props.canUpload ?
									<a href="javascript:void(0)" id="createFolder" onClick={this._onFolderCreateClick.bind(this)}>Create new {this._getFolderLabel()}</a>
									: null
								}
								<a href="javascript:void(0)" id="chooseFolder" onClick={this._onFolderShowMobileMenuClick.bind(this)}>Choose another {this._getFolderLabel()}</a>
							</div>
						</div>
						<div className="large-3 small-16 columns">
							{this.state.folders[this.state.category] !== null && this.state.folders[this.state.category].unarchivableCount > 0 && <div className=""><a href="#" onClick={this._onViewUnarchivable}>Recently deleted ({this.state.folders[this.state.category].unarchivableCount} item{this.state.folders[this.state.category].unarchivableCount != 1 ? 's' : ''})</a></div>}
						</div>
						<div className="large-10 small-16 columns">
							<div className="row">
								<div className="small-16 large-16 large-align-r tool-stack">
									{this.props.canUpload ?
										<div className="tool-stack-group tool-stack-group-small">
											{this.state[this.state.category].folderId == '00000000-0000-0000-0000-000000000000' && this.state.modal.action != 'FileUpload' ?
												<BtnFolderSelect
													folders={selectableFolders}
													creationFolders={creationFolders} 
													folder={this.state[this.state.category].folderId}
													onFolderSelected={this._onFolderSelected.bind(this)}
													folderLabel={this._getFolderLabel()}
													toggleUploadTypeText={Localisation.localisedStringFor('FolderSelectVideoUrlText')}
												/>
											:
													<BtnUpload
														url={AppDelegate.appSettings.fileUploadUrl}
														onFileUploadClose={this._handleUploadFileClose.bind(this)}
														onFileUploadComplete={this._handleUploadFileComplete.bind(this)}
														onFileUploadFailed={PromptActions.displayFriendlyErrorPrompt}
														onConfirm={this._onConfirmFileUpload.bind(this)}
														uploadFormData={[{ name: 'Type', value: this._uploadType()}, { name: 'Id', value: this.props.id}, { name: 'FolderId', value: this.state[this.state.category].folderId }, { name: 'Category', value: this.state.category}, { name: 'NoNotification', value: true }, { name: 'ForceFileId', value: this.state.forceFileId }  ]}
														allowMultiple={this.state.forceFileId == null}
														active={this.state.modal.action == 'FileUpload'}
														title={this.getUploadDialogTitle()}
														isUploadTypeVisible={this.state.category != FileCategory.DOCUMENT}
														toggleUploadTypeText={Localisation.localisedStringFor('UploadVideoUrlText')}
													/>
										}
										</div>
									: null}

									<div className="tool-stack-group">
										<div className="form-group has-feedback sort">
											<label htmlFor="filter" className="control-label">Sort by:</label>
											<div className="end">
												<select className="form-control"
														id="filter"
														defaultValue={FileExplorerSort.MOST_RECENT}
														ref={SORT_SELECTOR}
														onChange={this._handleSortChange.bind(this)}>
													<option value={FileExplorerSort.FILENAME_AZ}>Alphabetical (A-Z)</option>
													<option value={FileExplorerSort.FILENAME_ZA}>Alphabetical (Z-A)</option>
													<option value={FileExplorerSort.MOST_RECENT}>Most recent first</option>
													<option value={FileExplorerSort.OLDEST_FIRST}>Oldest first</option>
												</select>
											</div>
										</div>
									</div>
									<div className="tool-stack-group">
										<div className="form-group has-feedback search">
											<input type="text" ref={FILE_TXT_SEARCH}
												className="form-control"
												onChange={_.debounce(this._handleInputChange.bind(this), 550)}
												onKeyDown={this._handleInputKeyDown.bind(this)}
												placeholder={this._getSearchFilesLabel()}
												autoComplete="off" 
                                                spellCheck="true" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row file-panel">
						<div className="large-5 small-16 columns">
							{ this._renderFolderList() }
						</div>
						<div className="large-11 small-16 columns">
							{ !!this.state[this.state.category].viewUnarchivable && this._renderUnarchivableHeader() }
							{ this._renderFileList() }
						</div>
					</div>
					{this.state.isWorking ? <div className="fileExplorer-busy"><ActivityIndicator size="Large"/></div> : null}
				</div>

				{this.state.folders[this.state.category] !== null && this.state.folders[this.state.category].totalPages > 0 ?
					<div className="panel-footer">
						<div className="row">
							<div className="columns small-16 large-3 text-muted">{this._fileSetSummary()}</div>
							<nav className="columns small-16 large-13 large-align-r">
								<Pagination
									numPages={this.state.folders[this.state.category].totalPages}
									currentPage={this.state.folders[this.state.category].currentPage}
									onChange={this._onChangePage.bind(this)} />
							</nav>
						</div>
					</div>
				: null}

				{ this.state.modal.file && <ModalFileDelete isArchive={!!this.state[this.state.category].viewUnarchivable} active={this.state.modal.action == 'FileDelete'} file={this.state.modal.file} onCloseClick={this._closeModal.bind(this)} onUnlinkRequest={this._unlinkFile.bind(this)} />}
				{ this.state.modal.file && <ModalFileEdit
												url={AppDelegate.appSettings.fileUploadUrl}
												active={this.state.modal.action == 'FileEdit'}
												file={this.state.modal.file} folderid={!this.state[this.state.category].viewUnarchivable ? this.state[this.state.category].folderId : notFiledFolderId}
												creationFolders={creationFolders} folders={selectableFolders}
												onCloseClick={this._closeModal.bind(this)}
												onDeleteClick={this._onFileUnlinkClick.bind(this)}
												onUpdateRequest={this._updateFile.bind(this)}
												folderLabel={this._getFolderLabel()}
												canManageAccessRestriction={this.props.canManageAccess}
												groupId={this.props.type == 'Group' ? this.props.id : null}
												eventId={this.props.type == 'Event' ? parseInt(this.props.id) : null}
												uploadFormData={[{ name: 'Type', value: this._uploadType()}, { name: 'Id', value: this.props.id}, { name: 'FolderId', value: this.state[this.state.category].folderId }, { name: 'Category', value: this.state.category}, { name: 'NoNotification', value: true }, { name: 'ForceFileId', value: this.state.forceFileId }, { name: 'IsArchived', value: true }  ]}
											/>}
				{ this.state.modal.folder && <ModalFolderCreate active={this.state.modal.action == 'FolderCreate'} onCloseClick={this._closeModal.bind(this)} onCreateRequest={this._createFolder.bind(this)} folderLabel={this._getFolderLabel()} creationFolders={creationFolders} folders={this.hasFolders() ? this.state.folders[this.state.category].folders : []} parentFolderId={parentFolderCreationId}/>}
				{ this.state.modal.folder && <ModalFolderDelete active={this.state.modal.action == 'FolderDelete'} folder={this.state.modal.folder} onCloseClick={this._closeModal.bind(this)} onUnlinkRequest={this._unlinkFolder.bind(this)} folderLabel={this._getFolderLabel()} />}
				{ this.state.modal.folder && <ModalFolderEdit active={this.state.modal.action == 'FolderEdit'}
					folders={creationFolders} folder={this.state.modal.folder} onCloseClick={this._closeModal.bind(this)} onUpdateRequest={this._updateFolder.bind(this)} folderLabel={this._getFolderLabel()} parentFolderId={parentFolderId}
					canManageAccessRestriction={this.props.canManageAccess}
					groupId={this.props.type == 'Group' ? this.props.id : null}
					eventId={this.props.type == 'Event' ? parseInt(this.props.id) : null}
				/>}

			</div>
		);
	}
}


FileExplorerModule.defaultProps = {
	title: 'Files',
	query: null,
	searchQueryShown: true,
	canPost: false,
	canUpload: false,
	canManageAccess: false,
	category: 'Document'
};

FileExplorerModule.propTypes = {
	type: 				PropTypes.oneOf(['Event', 'Group']).isRequired,
	category:			PropTypes.oneOf(['Document', 'Media']).isRequired,
	id:					PropTypes.string.isRequired,
	searchQuery:		PropTypes.string,
	searchQueryShown:	PropTypes.bool,
	canPost:			PropTypes.bool,
	canUpload:			PropTypes.bool,
	canManageAccess:	PropTypes.bool,
	downloadEndpoint:	PropTypes.string.isRequired
};

export default FileExplorerModule;

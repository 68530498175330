
import React from "react";
import C3Chart from "../../../C3Chart";

class PieChart extends React.Component {

  render() {
    return <C3Chart data={this.props.data} pie={config.pie} size={config.size} tooltip={config.tooltip} legend={config.legend} />;

  }
}

const config = {
  pie: {
    label: {
      format: function (value, ratio, id) {
        return d3.format('%')(ratio);
      }
    }
  },
  size: {
    //width: 120,
    height: 210
  },
  tooltip: {
    format: {
      title: function (d) {d;},
      value: function (value, ratio, id) {
        return d3.format('%')(ratio);
      }
    }
  },
  legend: {
    show: false
  }
};

export default PieChart;``;
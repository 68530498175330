
import React from "react";
import _ from "lodash";


class GroupReportChartCompare extends React.Component {

  componentWillMount() {
    this.setState({
      left: 'Posts',
      right: 'None'
    });
  }

  componentDidMount() {
    this.props.onChange(this.state);
  }

  showButtonClick(ev) {
    ev.preventDefault();
    this.props.onChange(this.state);
  }

  onLeftChange(ev) {
    let state = this.state;
    state.left = ev.target.value;
    this.setState(state);
    this.props.onChange(state);
  }

  onRightChange(ev) {
    let state = this.state;
    state.right = ev.target.value;
    this.setState(state);
    this.props.onChange(state);
  }

  alloptions() {
    return ['None', 'Posts', 'Comments', 'Acks', 'Events', 'Page views', 'File downloads', 'File uploads', 'Members', 'Join requests'];
  }

  leftOptions() {
    let options = _.filter(this.alloptions(), a => a != 'None' && a != this.state.right);

    return _.map(options, a => <option>{a}</option>);
  }

  rightOptions() {
    let options = _.filter(this.alloptions(), a => a != this.state.left);

    return _.map(options, a => <option>{a}</option>);
  }

  render() {
    return <div>
                <select className="form-control short" value={this.state.left} defaultValue={'Posts'} onChange={a => this.onLeftChange(a)}>
                    {this.leftOptions()}
                </select>
                <span> WITH </span>
                <select className="form-control short" value={this.state.right} defaultValue={'None'} onChange={a => this.onRightChange(a)}>
                    {this.rightOptions()}
                </select>
                <button className="btn btn-primary" onClick={e => this.showButtonClick(e)}>Show</button>
            </div>;
  }
}

export default GroupReportChartCompare;
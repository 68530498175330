import React, {
  FC,
  MouseEventHandler,
} from "react";
import _ from 'lodash';

import FilesLinksUtils from "../utils/FilesLinksUtils";

interface IAttachedPhotosFiles {
  files?: any[],
  onClick: (e) => MouseEventHandler<HTMLAnchorElement>,
}

export const AttachedPhotosFiles: FC<IAttachedPhotosFiles> = (props: IAttachedPhotosFiles) => {

  return (
    <div className="create-post__photos">
      {_.map(props.files, (p, i: number) => {

        if (i < 5) {

          return <a href="#" role="button" className="create-post__photos-item" onClick={() => props.onClick(this)}>
            {(p.fileType == "Audio") &&
              <div className="icon-container">
                <span className="file-icon icon-file-icon-audio" aria-hidden="true" />
                {(i == 4 && props.files.length > 5) ? <div className="excess-count"> +{props.files.length - 5} </div> : null}
              </div>
            }
            {p && p.image && p.image.url &&
              <div key={p.id} className="create-post__photos-item" style={{ backgroundImage: 'url(' + p.image.url + ')' }}>
                {(i == 4 && props.files.length > 5) ? <div className="excess-count"> +{props.files.length - 5} </div> : null}
              </div>
            }
            {p && !p.image &&
              <div key={p.id} className="icon-container">
                <span className={`file-icon ${FilesLinksUtils.renderFileClass(p.fileType)}`} aria-hidden="true" />
                {(i == 4 && props.files.length > 5) ? <div className="excess-count"> +{props.files.length - 5} </div> : null}
              </div>
            }
          </a>;
        }

      })}

    </div>
  );

}

export default AttachedPhotosFiles;
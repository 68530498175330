
import ImgUtils from "../utils/ImgUtils";

/**
 * An image object that Forcenet can alter on the fly.
 */
export class DynamicImage {

  url: string;

  constructor(url: string) {
    /**
     * The url of the image
     */
    this.url = url;
  }

  /**
   * The url of the image with size parameters
   * This is used on Forcenet to resize images on the fly
   * @param {int} 	width	- The desired width of the image
   * @param {int}		height	- The desired height of the image
   * @returns {string}
   */
  urlWithSize(width: number = 30, height: number = 30): string {
    return ImgUtils.srcWithSizeParams(width, height, this.url);
  }

  /**
   * The url of the image with a max width size parameter
   * @param {int}			width		- The desired max width of the image
   * @returns {string}
   */
  urlWithMaxWidth(width: number): string {
    return ImgUtils.srcWithSizeParams(width, null, this.url);
  }


  /**
   * Handle JSON serialisation
   * @returns {*}
   */
  toJSON(): string {
    return this.url;
  }
}

/**
 * The DynamicImage Factory
 */
export class DynamicImageFactory {

  /**
   * Create a new DynamicImage from a url string.
   * @param {string}	url	- The url string of the image
   * @returns {DynamicImage|null}
   */
  static dynamicImageFromUrl(url: string | null | undefined): DynamicImage | null | undefined {
    if (url == null) {return null;}

    if (typeof url !== 'string') {
      throw new Error('Unexpected type. Image url must be a string.');
    }

    return new DynamicImage(url);
  }
}

import React from "react";
import _ from "lodash";
import GroupReportActions from "../actions/GroupReportActions";
import GroupReportStore from "../stores/GroupReportStore";
import { GroupReportState } from "../stores/GroupReportStore";
//import PromptActions		from '../../prompt/actions/PromptActions';

import { GroupReport } from "../models/GroupReport";


class GroupReportSummary extends React.Component {


  render() {
    if (null == this.props.reportData) return null;

    var new_members = 0;
    var members = this.props.reportData.Members;
    if (members.length > 0) {
      new_members = Math.max(0, _.last(members, 0) - _.first(members, 0));
    }

    return <div className="panel group-summary">
                <div className="item summary-members"><p>{new_members} new members</p></div>
                <div className="item summary-posts"><p>{_.sum(this.props.reportData.Posts)} posts</p></div>
                <div className="item summary-events"><p>{_.sum(this.props.reportData.Events)} events</p></div>
                <div className="item summary-files"><p>{_.sum(this.props.reportData.FileUploads)} new files</p></div>
			</div>;
  }
}

export default GroupReportSummary;
import React, {
    FC,
} from "react";
import _ from 'lodash';
import { ReplySortOrder } from "../model/ReplySortOrder";

interface SortRepliesProps {
    sortList: any,
    sortOrder: typeof ReplySortOrder,
    onChangeSortOrder: (e) => void,
}

export const SortReplies: FC<SortRepliesProps> = (props: SortRepliesProps) => {
    return (
        <div className="sort-replies">
            <div> </div>
            <div>
                <label>Order by: </label>
                <ul className="horizontal-list">
                    {Object.keys(props.sortList).map((key, index) =>
                        <li key={index} className={props.sortList[key] == props.sortOrder ? "selected" : ""}>
                            <a rel={props.sortList[key]} onClick={props.onChangeSortOrder}> {props.sortList[key]}</a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default SortReplies;

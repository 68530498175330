import React, {
    FC,
    useEffect,
    useState,
} from "react";
import SvgIcon              from '../../../icons';
import PromptActions from "../../prompt/actions/PromptActions";
import Localisation from "../../../core/resources/Localisation";
type CallbackFunctionUpdate = (item: any) => void
type CallbackFunctionDelete = (id: string) => void
type CallbackFunctionCopy = (item: any) => void
interface IGroupListSummaryProps {
    groupList: object,
    selectedGroupList: object,
    updateSelectedGroupList: CallbackFunctionUpdate,
    ondelete: CallbackFunctionDelete,
    oncopygrouplist: CallbackFunctionCopy
}

export const GroupListSummary : FC<IGroupListSummaryProps> = (props: IGroupListSummaryProps) => {
    const onUpdateSelectedGroupList = (e) => {
        e.preventDefault();
        props.updateSelectedGroupList(props.groupList);
    }
    const blur = () => {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }
    const onClickDeleteGroupList = (e) => {
        e.preventDefault();
       var line1 =  `Are you sure you want to remove the list "###"?`.replace("###",props.groupList.name);
       var line2 =  ` This will permanently delete the list from the group.`;
        PromptActions.displayPromptWithActions(Localisation.localisedStringFor("PostGroupListDelete"),(<div><p>{line1}</p><p>{line2}</p></div>) , [{
            label: Localisation.localisedStringFor('Cancel'),
            className: 'btn btn-default'
        }, {
            label: Localisation.localisedStringFor('PostGroupListDelete'),
            handler: () => props?.ondelete(props.groupList.groupListId),
            className: 'btn btn-primary'
            }]);
        blur();
    }
    const onClickCopyGroupList = (e) => {
        e.preventDefault();
        props?.oncopygrouplist(props.groupList),
        blur();
        
    }
    return (
    <div>
        { props.groupList &&
        <li key={ props.groupList.groupListId } 
            className={ (props.selectedGroupList && props.selectedGroupList.groupListId == props.groupList.groupListId) ? "selected" : "not-selected" }>
                <a href="#" className="name" onClick={onUpdateSelectedGroupList}>{props.groupList.name} ({props.groupList.groups && <span>{ props.groupList.groups.length}</span> })</a>
            <a href="#" className="mod"  onClick={onClickCopyGroupList}   ><SvgIcon icon="icon-copy" width={20} height={20} /></a>
                <a href="#" className="close" onClick={onClickDeleteGroupList} ><SvgIcon icon="icon-cross" width={20} height={20} /></a>
        </li >
        }
            {!props.groupList && <div></div>}
            </div>
    )
}
import * as React from "react";

function SvgIconArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 63 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M30.233 31.999L0 1.766 1.732.034 30.3 28.602 61.284.001l1.66 1.799z"
        stroke="currentColor"
        strokeWidth={4}
      />
    </svg>
  );
}

export default SvgIconArrowDown;

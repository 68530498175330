import React, {
	useEffect,
	useRef,
} from 'react';
import PropTypes from 'prop-types'


function ItemTools(props) {

	const node = useRef()

	const close = () => {
		props.onRequestsClose()
	}

	const captureKeyPressBinding = (e) => {
		if (e && e.keyCode === 27){
			close();
		}
	}
	useEffect(() => {
		document.addEventListener('keyup', captureKeyPressBinding)

		return () => {
			document.removeEventListener('keyup', captureKeyPressBinding)
		}
	}, [])

	const captureDocumentClickEvent = (e) => {
		if (!node.current.contains(e.target)) {
			close()
		}
	}
	useEffect(() => {
		document.addEventListener('mousedown', captureDocumentClickEvent)

		return () => {
			document.removeEventListener('mousedown', captureDocumentClickEvent)
		}
	}, [])

	return (
		<div ref={node} className="activity-post-tools">
			{props.children}
		</div>
	);
}


ItemTools.propTypes = {
	onRequestsClose:		PropTypes.func.isRequired
};
export default ItemTools;

import AppDelegate				from '../core/AppDelegate';
import HttpAuthenticatedSvc		from './HttpAuthenticatedSvc';
import {AusGovHolidayFactory}   from '../modules/event/calendar/models/AusGovHoliday';
import {CalendarEventFactory}   from '../modules/event/calendar/models/CalendarEvents';
import HttpSvc 		    		from '../services/HttpSvc';

class EventSvc {

    /**
     * Get australia public holidays from gov site
     */
	getHolidays(){
		var req,
			endpoint = `${AppDelegate.appSettings.apiUrl}/EventsService.asmx/GetHolidays`,
			payload = {};
			let promise = new Promise((resolve, reject) => {

				req = HttpAuthenticatedSvc.post(endpoint, payload, function (response) {
					resolve(AusGovHolidayFactory.AusGovHolidayFromObject(response));
				}, function (err) {
					reject({reason: err.message});
				});
			});

		return {
			promise: promise,
			abort: () => {
				req.abort();
			}
		};
    }
    
	/**
	 * Get Australian public holidays from JSON
	 * @param {} 
	 */	
	getPublicHolidays() {
		var req,
			endpoint = `${AppDelegate.appSettings.apiUrl}/EventsService.asmx/GetHolidays`,
			payload = {},
			promise = new Promise((resolve, reject) => {
				req = HttpAuthenticatedSvc.post(endpoint, payload, function (response) {
					var data = JSON.parse(response);
					resolve(CalendarEventFactory.EventsFromHolidayObject(data.result.records));
				}, function (err) {
					reject({ reason: err.message });
				});

			});


		return {
			promise: promise,
			abort: () => { req.abort(); }
		};
	}


	/**
     * Get calendar events
     */
    getCalendarEvents(filterDate){
        var req,
			endpoint = `${AppDelegate.appSettings.apiUrl}/EventsService.asmx/GetCalendarEvents`,
			payload = {filterDate: filterDate };
			let promise = new Promise((resolve, reject) => {

				req = HttpAuthenticatedSvc.post(endpoint, payload, function (response) {
					resolve(CalendarEventFactory.EventsFromObject(response));
				}, function (err) {
					reject({reason: err.message});
				});
			});

		return {
			promise: promise,
			abort: () => {
				req.abort();
			}
		};
    }
}

export default new EventSvc();
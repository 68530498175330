import * as React from "react";

function SvgIconEdit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M29.31 2.671C26.384-.26 24.188.18 24.188.18L2.224 22.17.176 31.846l9.662-2.052L31.8 7.805c0-.002.44-2.203-2.49-5.134zM9.262 28.629l-3.293.71a7.521 7.521 0 00-1.401-1.892 7.619 7.619 0 00-1.89-1.405l.71-3.297.951-.953s1.793.037 3.815 2.062c2.024 2.026 2.061 3.822 2.061 3.822l-.953.951z"
      />
    </svg>
  );
}

export default SvgIconEdit;

import * as React from "react";

function SvgIconFolder(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 384"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        d="M464 64H272L208 0H48A48 48 0 000 48v288a48 48 0 0048 48h416a48 48 0 0048-48V112a48 48 0 00-48-48z"
      />
    </svg>
  );
}

export default SvgIconFolder;


import React from "react";
import AuditLogViewerSearch from "./components/AuditLogViewerSearch";
import AuditLogViewerResult from "./components/AuditLogViewerResult";
import PromptActions from "../../prompt/actions/PromptActions";
import DateTimeUtils from "../../../core/utils/DateTimeUtils";
import "./auditlog.css";

class AuditLogViewerModule extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      auditSearchResults: [],
      auditSearchStatus: "",
      auditSearchQuery: {
        userName: "",
        pMKey: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        intents: "",
        keywordSearch: ""
      }
    };
  }

  callbackAuditSearch = (searchResults, searchStatus, searchQuery) => {
    this.setState({
      auditSearchResults: searchResults,
      auditSearchStatus: searchStatus,
      auditSearchQuery: searchQuery
    });
  };

  render() {
    return <div className="auditlog panel">
        <div className="panel-body">
          <AuditLogViewerSearch callbackAuditSearch={this.callbackAuditSearch} />
        </div>
        <div className="panel-body">
          <AuditLogViewerResult resultList={this.state.auditSearchResults} searchStatus={this.state.auditSearchStatus} searchQuery={this.state.auditSearchQuery} />
        </div>
      </div>;
  }
}

export default AuditLogViewerModule;
import * as React from "react";

function SvgIconRefresh(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 28"
      {...props}
    >
      <path
        fill="currentColor"
        d="M23.609 16.5c0 .031 0 .078-.016.109C22.265 22.14 17.702 26 11.937 26c-3.047 0-6-1.203-8.219-3.313l-2.016 2.016A.996.996 0 01.999 25c-.547 0-1-.453-1-1v-7c0-.547.453-1 1-1h7c.547 0 1 .453 1 1a.99.99 0 01-.297.703l-2.141 2.141A7.985 7.985 0 0011.998 22a7.976 7.976 0 006.813-3.813c.375-.609.562-1.203.828-1.828.078-.219.234-.359.469-.359h3c.281 0 .5.234.5.5zM24 4v7c0 .547-.453 1-1 1h-7c-.547 0-1-.453-1-1a.99.99 0 01.297-.703l2.156-2.156A8.036 8.036 0 0012 6a7.976 7.976 0 00-6.813 3.813c-.375.609-.562 1.203-.828 1.828-.078.219-.234.359-.469.359H.781a.503.503 0 01-.5-.5v-.109C1.625 5.844 6.234 2 12 2c3.063 0 6.047 1.219 8.266 3.313l2.031-2.016A.996.996 0 0123 3c.547 0 1 .453 1 1z"
      />
    </svg>
  );
}

export default SvgIconRefresh;

import React, {
    FC,
    useState,
}                           from "react";
import _	                from 'lodash';

import { 
    useMutation,
}                           from "@apollo/client";

const forumsGql = require('../../../../gql/forums.gql');

import {
    forumResult_topics,
    forumResult_discussions,
    forumDiscussionResult,
    FORUM_SAVE_TOPIC_ACTIVITY_MUTATIONVariables,
}                           from "../../../../types/graphqlTypes";

import { Topic } from "./Topic";
import PromptActions from "../../prompt/actions/PromptActions";

interface ITopicsProps {
    forumId?: string,
    topicResults: forumResult_topics[],
    discussionResults: forumDiscussionResult[],    
    onSelectTopic: (topicResult: forumResult_topics) => void,
    onSelectDiscussion: (discussionResult: forumResult_discussions, topicResult: forumResult_topics) => void,
    onCancel: () => void
}

export const Topics : FC<ITopicsProps> = (props: ITopicsProps) => {
    const [expandAllTopics, setExpandAllTopics] = useState(false);
    const [collapseAllTopics, setCollapseAllTopics] = useState(false);
    const [saveTopicActivity] = useMutation(forumsGql.FORUM_SAVE_TOPIC_ACTIVITY_MUTATION);

    const saveTopicActivityState = (topicId, accordionState) => {
        saveTopicActivity({
            variables: {
                topicId: topicId,
                accordionState: accordionState,
            } as FORUM_SAVE_TOPIC_ACTIVITY_MUTATIONVariables
        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);
        }).finally();
    }

    const onClickCollapseTopics = (e) => {
        e.preventDefault();
        setCollapseAllTopics(true);
        setExpandAllTopics(false);
        props.topicResults.map((topicResult) => {
            saveTopicActivityState(topicResult.topic?.topicId, false);
        });
    }
    const onClickExpandTopics = (e) => {
        e.preventDefault();
        setCollapseAllTopics(false);
        setExpandAllTopics(true);
        props.topicResults.map((topicResult) => {
            saveTopicActivityState(topicResult.topic?.topicId, true);
        });
    }

    const updateTopicAccordionState = (topicId, accordionState) => {
        setCollapseAllTopics(false);
        setExpandAllTopics(false);
        saveTopicActivityState(topicId, accordionState);
    }

    return (
        <div className="topics">
            <div className="actions topic-toggle">
                <span><a onClick={onClickCollapseTopics}>collapse all</a>
                |
                <a onClick={onClickExpandTopics}>expand all</a></span>
            </div>
            { props.topicResults && props.topicResults.map((topicResult) => <Topic 
            key={topicResult.topic.topicId} topicResult={topicResult} forumId={props.forumId} 
            expandAllTopics={expandAllTopics} collapseAllTopics={collapseAllTopics}
            discussionResults={props.discussionResults.filter((discussionResult) => discussionResult.topic.topicId == topicResult.topic.topicId)} 
            onCancel={props.onCancel} onSelectDiscussion={props.onSelectDiscussion} 
            onSelectTopic={props.onSelectTopic} updateTopicAccordionState={updateTopicAccordionState} />) }
        </div>
    )
    
}

export default Topics;
import React from "react"
import BtnClose from "../../../core/components/BtnClose"
import Modal from "../../../core/components/modal/Modal"

let max = 5

class Conversation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            confirming: false,
        }
    }

    _renderList() {
        return this.props.people.map((item, i, arr) => {
            return (
                <a key={i} href={"javascript:void(0)"} className={`listName ${i >= max ? "js-hidden" : ""} ${item.Status !== 0 ? "user-deactivated" : ""}`} title={item.Status !== 0 ? "This user is no longer active on ForceNet" : null}>
                    {arr.length - 1 === i ? `${item.Name} ` : `${item.Name}, `}
                </a>
            )
        })
    }

    _renderMore() {
        if (this.props.people.length <= max) return null
        return (
            <a href="#" className="more-participants">
                {`(${this.props.people.length - max} more)`}
            </a>
        )
    }

    _handleModalCloseRequest() {
        this.setState({ confirming: false })
    }

    _handleSubmit() {
        this.props._archive(this.props.id, this.props.archived)
        this._handleModalCloseRequest()
    }

    _handleArchive() {
        this.setState({ confirming: true })
    }

    render() {
        return (
            <li itemID={this.props.id} className={`item friend ${this.props.type}`} onClick={e => this.props._open(e, this.props.item)}>
                <Modal onRequestsClose={this._handleModalCloseRequest.bind(this)} active={this.state.confirming} size="default">
                    <div className="modal-header">
                        <BtnClose onClick={this._handleModalCloseRequest.bind(this)} />
                        <h2 className="modal-title">{this.props.archived ? "Un-Archive" : "Archive" } Message?</h2>
                    </div>
                    <div className="modal-content">{this.props.archived ? "If your settings permit, notifications for this message will be enabled." : "You will no longer receive notifications about this message" }</div>
                    <div className="modal-actions">
                        <button role="button" className="btn btn-primary" onClick={this._handleSubmit.bind(this)}>
                            {this.props.archived ? "Un-Archive" : "Archive" }
                        </button>
                        <button role="button" className="btn btn-default" onClick={this._handleModalCloseRequest.bind(this)}>
                            Cancel
                        </button>
                    </div>
                </Modal>
                <div className="item-header">
                    <div className="profilepic">
                        <a href="javascript:void(0)">
                            <img src={this.props.image} />
                            {this.props.unread > 0 ? (
                                <span className="thread-unread-count" data-unread-count={this.props.unread}>
                                    {this.props.unread}
                                </span>
                            ) : null}
                        </a>
                        {this.props.important ? <div className="flag isImportantMessage">Important message</div> : null}
                    </div>
                    <div className="item-details">
                        <div className="title">
                            {this._renderList()}
                            {this._renderMore()}
                        </div>
                        <div className="commentbar">{this.props.subject}</div>
                    </div>
                    <a
                        href="javascript:void(0)"
                        onClick={e => {
                            e.preventDefault()
                            e.stopPropagation();
                            this._handleArchive()
                        }}
                        role="button"
                        className="item-archive"
                        title={this.props.archived ? "This message has been archived. Click to Un-archive message" : "Archive Message"}>
                        <i className={`fn-icon ${this.props.archived ? "fn-icon-move-out" : "fn-icon-delete" } fn-icon-hover-primary fn-default`} aria-hidden="true" />
                        <i className={`fn-icon ${this.props.archived ? "fn-icon-move-out" : "fn-icon-delete" } fn-icon-white fn-selected`} aria-hidden="true" />
                    </a>
                </div>
            </li>
        )
    }
}

export default Conversation

import * as React from "react";

function SvgIconCross(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16 0C7.164 0 0 7.164 0 16s7.164 16 16 16 16-7.164 16-16S24.836 0 16 0zm4.402 17.031l4.151 4.151c.583.583.583 1.54 0 2.123l-1.184 1.18a1.507 1.507 0 01-2.123 0l-4.149-4.151a1.505 1.505 0 00-2.121 0l-4.151 4.151a1.505 1.505 0 01-2.121 0l-1.184-1.18a1.503 1.503 0 010-2.123l4.148-4.151a1.507 1.507 0 000-2.123l-4.153-4.155a1.501 1.501 0 010-2.121L8.699 7.45a1.501 1.501 0 012.121 0l4.151 4.153c.585.583 1.54.583 2.123 0l4.149-4.153a1.503 1.503 0 012.123 0l1.184 1.182a1.505 1.505 0 010 2.121l-4.151 4.153a1.508 1.508 0 00.004 2.124z"
      />
    </svg>
  );
}

export default SvgIconCross;

import React 				from 'react';
import PropTypes            from 'prop-types';
import _	                from 'lodash';

import { GroupJoinRequests }    from "./components/GroupJoinRequests";
// import { PersonalActions }          from "./components/PersonalActions";

import "./GroupJoinRequestsModule.css"

class GroupJoinRequestsModule extends React.Component {
	constructor(props) {
        super(props);
    }


    componentDidMount() {
    }


    refreshCount() {
    }

    render(): JSX.Element {
        return <GroupJoinRequests groupId={this.props.groupId} />
    }
}

GroupJoinRequestsModule.defaultProps = {
	className: '',
    groupId: null,
};

GroupJoinRequestsModule.propTypes = {
	className:					PropTypes.string,
    groupId:                    PropTypes.string
};

export default GroupJoinRequestsModule;
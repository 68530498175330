
import _ from "lodash";

export class CalendarEvents {

  Events: Array<Event>;

  constructor() {
    this.Events = [];
  }
}

export class Event {

  EventId: Number;
  Name: String;
  Summary: String;
  Description: String;
  TimeZone: String;
  StartDateTime: Date;
  EventURL: String;
  Data: String;
  AttendanceStatus: String;
  ZoneOffset: String;

  constructor() {
    this.EventId = 0;
    this.Name = this.StartDateTime = this.Summary = this.Description = this.TimeZone = this.EventURL = null;
  }

  DisplayTime() {
    return `${this.StartDateTime.format("HH:mm")} ${this.ZoneOffset}`.trim();
  }

  getDisplayName() {
    return `${this.Name} (${this.Data.split("|").join(", ")})`;
  }
}

export class CalendarEventFactory {

  static EventsFromObject(o) {
    let calendarEvents = new CalendarEvents();
    calendarEvents.Events = _.map(o, e => {
      let event = new Event();
      event.EventId = e.EventId;
      event.Name = e.Name;
      event.Summary = e.Summary;
      event.Description = e.Description;
      event.TimeZone = e.TimeZone;
      event.StartDateTime = moment(e.StartDateTime);
      event.EventURL = e.EventURL;
      event.AttendanceStatus = e.AttendanceStatus;
      event.ZoneOffset = e.ZoneOffset;
      return event;
    });

    return calendarEvents;
  }

  static EventsFromHolidayObject(o) {
    let calendarEvents = new CalendarEvents();
    calendarEvents.Events = _.map(o, e => {
      let event = new Event();
      event.Name = e["Holiday Name"];
      event.Summary = e.Information;
      event.EventURL = e["More Information"];
      event.StartDateTime = moment(e.Date);
      event.Data = e["Applicable To"] == "ACT|NSW|NT|QLD|SA|TAS|VIC|WA" ? "Australia" : e["Applicable To"];
      return event;
    });

    return calendarEvents;
  }
}
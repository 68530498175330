import React 				from 'react';
import PropTypes from 'prop-types'
import FileUtils 			from '../../../core/utils/FileUtils';
import FileExplorerStore 	from '../stores/FileExplorerStore';
import Modal        		from '../../../core/components/modal/Modal';
import BtnClose     		from '../../../core/components/BtnClose';
import DropDown				from '../../../core/components/DropDown';

class ModalFolderCreate extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
            name: '',
            valid: false,
            folders:  props.creationFolders || props.parentFolderId,
            parentFolderId: props.parentFolderId
        };

    }

	_handleWindowKeyUp(e) {
        // return/enter key pressed while typing folder title
		if (e.keyCode === 13 && this.state.valid) {
             this.props.onCreateRequest(this.state.parentFolderId, this.state.name);
		}
	}
    
    _handleModalCloseRequest() {
        this.props.onCloseClick()
    }

    _handleCreateRequest() {
        if (this.state.valid) {
            this.props.onCreateRequest(this.state.parentFolderId, this.state.name);
        }
    }

    componentWillReceiveProps(props) {
		this.setState({
            name: "",
            valid: false
        });

        let folders = props.creationFolders;
        let parentFolderId = props.parentFolderId

      
        this.setState({
            folders,
            parentFolderId
        });

    }
    
    _handleFolderChange(option) {
        this.setState({
        	parentFolderId: option.value
        }, () => this.checkValid());
    }

    checkValid() {
        let valid = 0 < this.state.name.trim().length && this.state.parentFolderId;

        this.setState({
            valid
        });
    }
    
    _handleEditChange(event)
	{
		const target = event.target;
		const name = target.name;
   		let value = target.type === 'checkbox' ? target.checked : target.value;

        if (value.length > 50) {
            value = value.substring(0, 50);
        }
		
		this.setState({ name: value }, () => this.checkValid());
    }
    
	render() {
        let saveButtonClassName = this.state.valid ? '' : ' disabled';
        
		return (
            <Modal onRequestsClose={this._handleModalCloseRequest.bind(this)} active={this.props.active} size='default'>
                <div className='modal-header'>
                    <BtnClose onClick={this._handleModalCloseRequest.bind(this)} />
                    <h2 className='modal-title'>Create new {this.props.folderLabel}</h2>
                </div>
                <div className='modal-content file-modal'>
                    { this.state.folders.length > 0 && [
                    <div key="parent">
                        <label htmlFor="folder-parent">Parent {this.props.folderLabel.toLowerCase()}</label>
                    </div>,
                    <div key="parentname">
                        <DropDown folderDropdown={true} options={this.state.folders} ref="folderId" defaultValue={this.state.parentFolderId} disabled={false} onChange={this._handleFolderChange.bind(this)}></DropDown>
                    </div>]}
                    <input type="text" id="folder-title" className="form-control" placeholder={"Enter the name of your " + this.props.folderLabel.toLowerCase()} value={this.state.name} onChange={this._handleEditChange.bind(this)} onKeyUp={this._handleWindowKeyUp.bind(this)} spellCheck="true" />
                </div>
                <div className="modal-actions">
                    <button role="button" className={"btn btn-primary"+saveButtonClassName} onClick={this._handleCreateRequest.bind(this)}>Create</button>
                    &nbsp;
                    <button role="button" className="btn btn-default" onClick={this._handleModalCloseRequest.bind(this)}>Cancel</button>
                </div>
            </Modal>
		);
	}
}

ModalFolderCreate.defaultProps = {
    folderLabel: 'Folder'
}

ModalFolderCreate.propTypes = {
	//folder: PropTypes.object.isRequired,
    active: PropTypes.bool.isRequired,
    folderLabel: PropTypes.string,
    folders: PropTypes.array.isRequired,
    creationFolders: PropTypes.array.isRequired
	//onCloseClick: PropTypes.function.isRequired,
    //onCreateRequest: PropTypes.function.isRequired,
};

export default ModalFolderCreate;

import React from "react"

class NoMessage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div id="postMessage">
                <div className="body" style={{ top: "89px" }}>
                    <div id="noSelectionMessage" style={{ display: "block" }}>
                        No messages selected
                    </div>
                </div>
            </div>
        )
    }
}

export default NoMessage

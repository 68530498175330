import React, {
    FC,
    useEffect,
    useState,
    useRef,
} from "react";
import _ from 'lodash';
import { useLazyQuery, useMutation } from "@apollo/client";
import Pluralize from "../../vendor/typescript-pluralize/index";
import {
    getGroupLists_groupLists,
    saveGroupListVariables,
    deleteGroupListVariables,
} from "../../../types/graphqlTypes";
const getGroupListsGql = require("../../../gql/getGroupLists.gql");
const saveGroupListGql = require("../../../gql/saveGroupList.gql");
const deleteGroupListGql = require("../../../gql/deleteGroupList.gql");
import PromptActions from "../prompt/actions/PromptActions";
import Localisation from "../../core/resources/Localisation";
import './GroupManageListsModule.css'
import { GroupList } from './components/GroupList';
import { GroupListSummary } from './components/GroupListSummary';
interface IGroupManageListsProps {
    groupId: string;
    groupType: string;
}
export const GroupManageListsModule: FC<IGroupManageListsProps> = (props: IGroupManageListsProps) => {
    const [getGroupLists, { loading, data, error, networkStatus, refetch }] = useLazyQuery(getGroupListsGql);
    const [saveGroupList] = useMutation(saveGroupListGql);
    const [deleteGroupList] = useMutation(deleteGroupListGql);
    const groupLists: Array<getGroupLists_groupLists> = data?.groupLists;
    const types = [...props.groupType == "Service" ? ["Service"] : [], ...["Official", "Professional"]];
    const [newList, setnewList] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [saving, setSaving] = useState(false);
    const [currentGroupList, setCurrentGroupList] = useState(null);

    useEffect(() => {
        if (props.groupId) {
            getGroupLists({
                variables: {
                    groupId: props.groupId
                }
            });
        }
    }, [props.groupId]);

    const blur = () => {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }
    const onUpdateSelectedGroupList = (newSelectedGroupList) => {
        setCurrentGroupList(newSelectedGroupList);
        setnewList(false);
        setEditMode(false);
    }
    const onCopyFrom = (groupList) => {
        var item = new Object();
        item.name = groupList.name + " copy";
        item.groupListId = null;
        item.groups =  groupList.groups;      
        setCurrentGroupList(item);
        setnewList(true);
    }

    const onCancelNew = () =>
    {
        setCurrentGroupList(undefined);
    }
    const refreshPage = (e) => {
        e.preventDefault();
        window.location.reload();
    }
    const onNewSelectedGroupList = (e) => {
        e.preventDefault();

        var item = new Object();
        item.name = "";
        item.groupListId = null;
        item.groups = [];      
        setCurrentGroupList(item);
        setnewList(true);
    }
    const onsave = (item) => {
        if (!saving) {
            setnewList(false);
            setSaving(true);
            saveGroupList({
                variables: {
                    groupListId: item.groupListId,
                    groupId: props.groupId,
                    name: item.name,
                    groupItemIdList: item.groups.map(i => i.groupId)
                } as saveGroupListVariables
            }).then((savedList) => {
                const errors = savedList?.data?.saveGroupList?.errors;
                if (errors && errors.length > 0) {
                    throw errors[0];
                }
                refetch().then((fetchedData) => {
                    const refetchedGroupList: Array<getGroupLists_groupLists> = fetchedData.data.groupLists;
                    var glA = refetchedGroupList.filter(i => i.groupListId == savedList.data.saveGroupList.groupListId);
                    if (glA.length > 0) {
                        onUpdateSelectedGroupList(glA[0]);
                    }               
                });
            }).catch(err => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally(() => setSaving(false));
        }
        blur();
    }

    const deleteList = (id) => {
        if (!saving) {        
            setSaving(true);
            deleteGroupList({
                variables: {
                    groupListId: id,
                    groupId: props.groupId,
                } as deleteGroupListVariables
            }).then((data) => {
                const errors = data?.data?.deleteGroupList?.errors;
                if (errors && errors.length > 0) {
                    throw errors[0];
                }
                refetch().then((fetchedData) => {
                    const refetchedGroupList: Array<getGroupLists_groupLists> = fetchedData.data.groupLists;
                    setCurrentGroupList(undefined);                
                });
            }).catch(err => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally(() => setSaving(false));
        }
    }

    return (
        <div id="primary" className="fn-group-manage-lists">          
            <div className="panel">
                <div className="panel-heading">
                    <h1 className="panel-title">Manage Lists of Groups</h1>
                    <p>You can use lists you create in this space to share your post to not only your group,
                        but to multiple groups contained within a specific list. You can create a new list,
                        manage a current list (add and remove groups or delete a list) and copy a current
                        list to change it.</p>
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="large-6 small-16 columns">
                            <div>
                                <p><a href="#" onClick={refreshPage}>&lt; Back to group</a></p>
                                <p><a href="#" onClick={onNewSelectedGroupList}>+ Create a new list</a></p>
                            </div>
                            <ul className="group-lists">
                                {groupLists && groupLists.length > 0 && groupLists.map(groupLista => (
                                    <GroupListSummary groupList={groupLista} key={groupLista.groupListId}
                                        selectedGroupList={currentGroupList}
                                        updateSelectedGroupList={onUpdateSelectedGroupList} ondelete={deleteList}  oncopygrouplist={onCopyFrom}/>
                                ))}
                            </ul>
                        </div>
                        <div className="large-10 small-16 columns">
                            {false &&
                                <p className="alert-info">You have copied "Name of distribution list". You can edit this list, including the name and the groups included in the list.</p>
                            }
                            {!currentGroupList &&
                                <p><i>Choose a list to see the groups added to the list</i></p>
                            }
                            {currentGroupList &&
                                <GroupList groupList={currentGroupList} onsave={onsave} ondelete={deleteList} oncancelnew= { onCancelNew } types={types}
                                edit={editMode || newList} isnew={newList} setEdit={setEditMode} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GroupManageListsModule;
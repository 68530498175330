
import _ from "lodash";
import BaseStore from "../../../core/stores/BaseStore";
import AppDispatcher from "../../../core/dispatcher/AppDispatcher";
import { GroupReport, GroupReportFactory } from "../models/GroupReport";
import GroupReportConstants from "../constants/GroupReportConstants";

export interface GroupReportState {
  reportData: GroupReport | null | undefined;
}

class GroupReportStore extends BaseStore implements GroupReportState {

  reportData: GroupReport | null | undefined;

  constructor() {
    super();

    this.reportData = null;
  }

  getState(): GroupReportState {
    return {
      reportData: this.reportData
    };
  }

  _didLoadGroupReport(data: any): void {
    this.reportData = GroupReportFactory.groupReportFromObject(data);
    this.emitChange();
  }
}

// The store instance
let _store = new GroupReportStore();

AppDispatcher.register(action => {

  switch (action.actionType) {

    case GroupReportConstants.GROUP_REPORT_LOADED:
      _store._didLoadGroupReport(action.data);

      break;

  }
});

export default _store;
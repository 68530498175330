import { FileType } from "./Enums";

// See    ForceNet.Business.Model.Files.FileHeader
export default class FileHeader {

    id: string;
    name: string;
    fileType: FileType;
    image: object;
    objectUrl: string;
    index: number;

    constructor(id: string, name: string, type: FileType, image: object, objectUrl: string, index: number) {
        this.id = id;
        this.name = name;
        this.fileType = type;
        this.image = image;
        this.objectUrl = objectUrl;
        this.index = index;
    }

}


import React 				from 'react';
import PropTypes from 'prop-types'
import FileUtils 			from '../../../core/utils/FileUtils';
import FileExplorerStore 	from '../stores/FileExplorerStore';
import FileCategory	        from '../actions/FileExplorerActions';
import UrlUtils             from '../../../core/utils/UrlUtils';
import SvgIcon              from '../../../icons';


class FolderItem extends React.Component {

	constructor(props) {
		super(props);
	}

	blur() {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
	}

    _selectClick(e) {        
		if (e && e.preventDefault) {
			e.preventDefault();
        }

        this.blur();

        this.props.onSelectClick(this.props.folder)
    }

    _deleteClick() {
        this.props.onDeleteClick(this.props.folder)
    }

    _editClick(e) {
        this.props.onEditClick(this.props.folder)
    }
	_renderFolderList()
	{
		return (
            <div className="subFolder">
                { _.map(this.props.folder.folders, f => {
                    return (
                        <FolderItem category={this.props.category} key={f.id} folder={f} selected={f.id == this.props.selectedId} selectedId={this.props.selectedId} onSelectClick={this.props.onSelectClick} onDeleteClick={this.props.onDeleteClick} onEditClick={this.props.onEditClick}/>
                    );
                })
            }
            </div>
		);
	}

    render() {
        var folderClassName = "fn-icon fn-icon-folder";
        var folderIcon = this.props.folder.id != "00000000-0000-0000-0000-000000000000" ? null : null ;
        var url = UrlUtils.setParams(window.location, [["View","Files"],["FolderId",this.props.folder.id],["Category",this.props.category.toString()]]);

		return (<div>
            <li className={ this.props.selected ? "selected" : "not-selected" }>
                <a href={url} onClick={this._selectClick.bind(this)}><SvgIcon icon="icon-folder" width={20} height={20} /> {this.props.folder.name}</a>
                { this.props.folder.isAccessRestricted ? 
                    <span className="fn-icon fn-icon-locked"></span>
                    :
                    null
                }
                { this.props.folder.canDelete ? 
                    <a href="javascript:void(0)" className="mod" onClick={this._editClick.bind(this)}><span className="fn-icon fn-icon-edit"></span></a>
                    :
                    <a href="javascript:void(0)" className="mod"></a>
                }
                { this.props.folder.canDelete ? 
                    <a href="javascript:void(0)" className="close" onClick={this._deleteClick.bind(this)}><span className="fn-icon fn-icon-delete-sign-lg"></span></a>
                    :
                    <a href="javascript:void(0)" className="close"></a>
                }
            </li>
            { this.props.folder.folders && this.props.folder.folders.length > 0 && this._renderFolderList(this.props.folder.folders) }
        </div>);
	}
}

FolderItem.propTypes = {
	category: PropTypes.oneOf(['Document', 'Media']).isRequired,
	folder: PropTypes.object.isRequired,
	selected: PropTypes.bool.isRequired,
	selectedId: PropTypes.string.isRequired,
	//onDeleteClick: PropTypes.function.isRequired,
    //onSelectClick: PropTypes.function.isRequired,
};

export default FolderItem;

import React, {
    FC,
    useState,
    useEffect,
    useCallback,
}                           from "react";
import _	                from 'lodash';
import UserDisplayPreferenceActions    from '../../core/actions/UserDisplayPreferenceActions';

export interface IRecentActivityListProps {
    isOpen: boolean;
    recentActivityList: Array<any>;
}

export const RecentActivityList : FC<IRecentActivityListProps> = (props: IRecentActivityListProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
    const savedIsOpen = props.isOpen;

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        const savePostState = (e) => {
            let savedDisplayPreferences = { 
                ShowPosts: isOpen
            };
            if (savedIsOpen != isOpen){
                UserDisplayPreferenceActions.saveDisplayPreferences(savedDisplayPreferences); 
            }
        }

        window.addEventListener("beforeunload", savePostState);
        return () => {
            window.removeEventListener("beforeunload", savePostState);
        };
    }, [isOpen]);

    const buttonClassName = `fn-icon-button fn-float-right${(!isOpen ? " fn-icon-rotate-180" : "")}`;

    const items = <div className="fn-section-body">
        { props?.recentActivityList?.recentActivities.map((activity, i) =>
            <div className="latest-post activity-post">
                <div className="author-card clearfix">
                    <div className="author-card-avatar">
                        <a href={activity.groupUrl}>
                            <img src={activity.groupImageUrl}></img>    
                        </a>  
                    </div>
                    <div className="author-card-details">
                        <div>
                            <a href={activity.groupUrl}>{activity.groupName}</a>
                        </div>
                        <div className="timestamp">{activity.datePosted}</div>
                    </div>
                </div>          
                { activity.postMessage ? <div className="activity-post-body">
                    <div className="activity-post-content" dir="auto">
                        <div className="content" dangerouslySetInnerHTML={{__html: activity.postMessage}}></div>
                        { activity.hasMore ? <a href={activity.linkToPost}>Read more</a> : null}
                    </div>
                </div> : null }
                <div className="activity-post-body">
                    <a href={activity.groupUrl}>
                        See more in this group
                    </a>
                </div>                   
            </div>  
        )}                       
    </div>;

    return (
        <div>
            <div className="fn-header">
                <span>
                    Latest Activities
                </span>
                <span className={buttonClassName} onClick={toggle}> 
                    <span className="fn-icon fn-icon-arrow-down fn-icon-white">
                    </span>
                </span>                
            </div>
            <div className="fn-section-right">
                { isOpen && (props.recentActivityList?.recentActivities?.length > 0) && items}
            </div>
        </div>
    );
}

export default RecentActivityList;
import React, {
    FC, useState, useEffect,
}                           from "react";
import { createSearchParams, useSearchParams, useNavigate } from 'react-router-dom'
import _	                from 'lodash';
import Modal 		from '../../../core/components/modal/Modal';
import BtnClose		from '../../../core/components/BtnClose';

const forumsGql = require('../../../../gql/forums.gql');

import { 
    useMutation,
    useLazyQuery,
}                           from "@apollo/client";

import {    
    forumResult,
    FORUM_GET_FORUM_QUERYVariables,
    FORUM_MOVE_ACTIVITY_MUTATIONVariables,
    forumDiscussionResult,
    forumResult_topics,
}                           from "../../../../types/graphqlTypes";
import PromptActions from "../../prompt/actions/PromptActions";
import GroupTabsConstants from "../../../core/constants/GroupTabsConstants";

interface DiscussionForumActivityModalProps {
    groupId: string,    
    itemId: number,    
    onClose: () => void,
}

export const DiscussionForumActivityModal : FC<DiscussionForumActivityModalProps> = (props: DiscussionForumActivityModalProps) => {

    const [forumTopics, setForumTopics] = useState(null);
    const [topic, setTopic] = useState(null);
    const [forumId, setForumId] = useState(null);

    useEffect(() => {
        getForumForGroup({
            variables: {
                groupId: props.groupId,
            }}
        );
    }, []);

    const [getForumForGroup, { floading, fdata, ferror, networkStatus, refetch }] = useLazyQuery(forumsGql.FORUM_GET_FORUM_FOR_GROUP_QUERY, {
        fetchPolicy: "network-only",
        onCompleted: fdata => {
            setForumTopics(fdata.getForumForGroup.topics.filter(topicResult => topicResult.topic?.isEnabled));
            setTopic(fdata.getForumForGroup.topics[0])
            setForumId(fdata.getForumForGroup.forum.forumId)
        }
    });

    const [itemId, setItemId] = useState(props.itemId);

    const [moveActivityPost, { data, loading, error }] = useMutation(forumsGql.FORUM_MOVE_ACTIVITY_MUTATION);
    
    const onChangeTopic = (e) => {
        let selectedTopic = forumTopics?.find(t => t.topic.topicId == e.target.value);
        if(selectedTopic) setTopic(selectedTopic);
    }
    
    const variables: FORUM_MOVE_ACTIVITY_MUTATIONVariables = {
        postId: props.itemId,
        forumId: forumId,
        topicId: topic?.topic?.topicId,
    }

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const redirectToViewDiscussion = () => {

        const newParams: {[key: string]: string} = {Id: props.groupId};
        searchParams.forEach((value: string, key: string) => {
            newParams[key] = value;
        });
        setSearchParams(newParams);
        
        navigate({
            pathname: '/community/viewgroup',
            search: createSearchParams(newParams).toString(),
        },{replace: true});
        navigate(0);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        moveActivityPost({
            variables
        }).then((savedDiscussion) => {
            const errors = savedDiscussion?.data?.saveDiscussion?.errors;

            if (errors && errors.length > 0) {
                throw errors[0];
            }

//            props.onClose();
            
            let  discussionResult: forumDiscussionResult = savedDiscussion.data.moveActivityPost;

            sessionStorage.setItem(GroupTabsConstants.DISCUSSION_RESULT_STORAGE, JSON.stringify(discussionResult));

            searchParams.set(GroupTabsConstants.VIEW_PARAM, GroupTabsConstants.DISCUSSIONS_TAB);
            searchParams.append(GroupTabsConstants.DISCUSSION_ID_PARAM, discussionResult.discussion.discussionId);

            redirectToViewDiscussion();
    
        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);
        }).finally();
    }

    return (
        <div>
            <Modal active={true} onRequestsClose={props.onClose}>
                <div className="modal-header">
                    <BtnClose onClick={props.onClose} />
                    <h2 className="modal-title">Move to Discussions</h2>
                </div>
                <div className="modal-content">
                { forumTopics &&
                    <div className="form-field">
                        <label
                            htmlFor="topic">
                                Discussion topic:
                            <abbr className="req" title="required">
                                    *
                            </abbr>
                        </label>
    
                        <select name="topic" onChange={onChangeTopic} className="form-control">
                            <option value="">Choose a topic for your discussion</option>
                            { forumTopics.map((item, index) => <option key={index} value={item.topic.topicId}>{item.topic.title}</option>) }
                        </select>
                    </div>
                }
                </div>
                <div className="modal-actions">
                    <button role="button" className="btn btn-default" onClick={props.onClose}>Cancel</button>
                    &nbsp;&nbsp;
                    <button role="button" className="btn btn-primary" onClick={onSubmit}>Confirm</button>
                </div>
            </Modal>
        </div>
    )
}

export default DiscussionForumActivityModal;
import React from 'react';
import PropTypes from 'prop-types'
import Modal from '../../../core/components/modal/Modal';
import BtnClose from '../../../core/components/BtnClose';
import LinkShare from './LinkShare';


class ModalLinkShare extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
		};

	}

	_handleModalCloseRequest(event) {
		event.preventDefault();

		this.props.onCloseClick();
	}

	render() {
		return (
			<Modal onRequestsClose={this._handleModalCloseRequest.bind(this)} active={this.props.active} size='default'>
				<form onSubmit={this._handleModalCloseRequest.bind(this)}>
					<div className='modal-header'>
						<BtnClose onClick={this._handleModalCloseRequest.bind(this)} />
						<h2 className='modal-title'>Copy link</h2>
					</div>

					<div className='modal-content'>
						<LinkShare ref="linkSelect" link={this.props.link} />
					</div>
				</form>
			</Modal>
		);
	}
}

ModalLinkShare.defaultProps = {
	link: '',
	onCloseClick: () => {}
}

ModalLinkShare.propTypes = {
	active: PropTypes.bool.isRequired,
	link: PropTypes.string,
	onCloseClick: PropTypes.func
};

export default ModalLinkShare;

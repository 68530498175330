import React, {
    FC,
    useState,
    useRef,
} from "react";
import _ from 'lodash';

import {
    forumResult_topics,
    topic,
} from "../../../../types/graphqlTypes";

import SvgIcon from '../../../icons';

interface ITopicControlsProps {
    topicResult: forumResult_topics,
    updateTopic: (topic, title, isEnabled) => void,
    allowDelete: boolean,
}

export const TopicControls: FC<ITopicControlsProps> = (props: ITopicControlsProps) => {
    const [showEdit, setShowEdit] = useState(false);
    const editRef = useRef(null);
    const [newTitle, setNewTitle] = useState(props.topicResult.topic ? props.topicResult.topic.title : "");

    const onEditTopic = () => setShowEdit(!showEdit);

    const onChangeTitle = (e) => {
        setNewTitle(e.target.value);
    }

    const onDisableTopic = (e) => {
        props.updateTopic(props.topicResult, props.topicResult.topic?.title, false);
    }

    const onSaveTopic = (e) => {
        e.preventDefault();
        props.updateTopic(props.topicResult, newTitle, true);
        setShowEdit(false);
    }

    return (props.topicResult.topic &&
        <div>
            <span className={`menu-toggle ${showEdit ? 'inactive' : 'active'}`}>{props.topicResult.topic.title} ({props.topicResult.totalDiscussionCount})</span>
            <form ref={editRef} method="post" name="editTopic" onSubmit={onSaveTopic}
                className={`menu-toggle ${showEdit ? 'active' : 'inactive'}`}>
                <input
                    name="title"
                    type="text"
                    maxLength={100}
                    className="form-control"
                    onChange={onChangeTitle}
                    spellCheck={true}
                    value={newTitle}
                /><button className="btn btn-primary" type="submit">Save</button>
                <button className="close" type="reset" onClick={onEditTopic}><SvgIcon icon="icon-delete" width="8" height="8" /></button>
            </form>
            <span className={`icons menu-toggle ${showEdit ? 'inactive' : 'active'}`}>
                {props.allowDelete &&
                    <a title="Delete" onClick={onDisableTopic}><SvgIcon icon="icon-delete" width="8" height="8" /></a>
                }
                <a title="Edit" onClick={onEditTopic}><SvgIcon icon="icon-edit" /></a>
            </span>
        </div>
    )
}


import React from 'react'
import ReactQuill from 'react-quill'
import RichTextEditorConfiguration from './RichTextEditorConfiguration'
import 'quill-paste-smart'

export enum RichTextEditorMode {
    ActivityPost = "ActivityPost",
    ActivityPostNoToolbar = "ActivityPostNoToolbar",
    EventDescription = "EventDescription",
    EventDescriptionNoToolbar = "EventDescriptionNoToolbar",
    GroupDescription = "GroupDescription",
    GroupDescriptionNoToolbar = "GroupDescriptionNoToolbar",
    InviteToForceNet = "InviteToForceNet",
    InviteToForceNetNoToolbar = "InviteToForceNetNoToolbar",
    Message = "Message",
    MessageNoToolbar = "MessageNoToolbar",
}

interface RichTextEditorProps {
    mode: RichTextEditorMode,
    spellcheck: boolean | undefined,
    onValueChange: ((value: string, empty: boolean) => void) | undefined,
    value: string,
    readOnly: boolean,
    userPreference: object
}

interface RichTextEditorState {
    spellcheck: boolean,
    value: string,
    position: number,
}

class RichTextEditor extends React.Component<RichTextEditorProps, RichTextEditorState> {

    constructor (props) {
        super(props)
        this.state = {
            spellcheck: props.spellcheck ?? true,
            value: props.value,
            position: window.scrollY,
            windowWidth: window.outerWidth
        }
    }

    me     =  React.createRef<HTMLDivElement>()
    editor =  React.createRef<ReactQuill>()

    componentDidMount() {
        // after mount change mouse hover titles of my toolbar icons
        RichTextEditorConfiguration.setQuillToolbarTitles(this.me.current)

        document.addEventListener('scroll', this.onScroll);
        window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('resize', this.onResize);
    }

    showToolbar(){
        // Shortcut - no need to consult the window width if the values are the same.
        if (this.props.userPreference != undefined){
            if (this.props.userPreference.ShowRteToolbarNarrow === this.props.userPreference.ShowRteToolbarWide){
                // console.log(`shortcut: ${this.props.userPreference.ShowRteToolbarWide}`)
                return this.props.userPreference.ShowRteToolbarWide
            }
        }
        try{
            const mobileBreakpoint = window.matchMedia("(max-width: 47.9375em)");
            if (this.props.userPreference == undefined){
                console.log("####################  RichTextEditor.tsx")
                console.log("WARNING: this.props.userPreference is undefined")
                console.log(this.props)
                return  ! mobileBreakpoint.matches
            } else if (mobileBreakpoint.matches) {
                return this.props.userPreference.ShowRteToolbarNarrow;
            } else {
                return this.props.userPreference.ShowRteToolbarWide;
            }
        } catch (err){
            console.log("An Error occured geting the Toolbar preference")
            console.log(err)
            // We have to return something, and this is most likely a mobile phone, so return the Narrow setting.
            return this.props.userPreference.ShowRteToolbarNarrow != undefined ? this.props.userPreference.ShowRteToolbarNarrow : false;
        }
    }

    isEmptyContent() {
        let quill = this.editor.current.getEditor();
        return quill.getText().trim() == '' || quill.getText().trim() == '<p><br></p>'
    }

    insertEmbed(embedType: string, embedValue: any) {
        let quill = this.editor.current.getEditor();
        let range = quill.getSelection(true);
        quill.insertEmbed(range.index, embedType, embedValue, 'user');
        quill.setSelection(range.index + 1, 0, 'silent');
    }
    
    _config() {
        const showToolbar = this.showToolbar();
        const ToolBarMode = showToolbar ? "": "NoToolbar";
        const TheMode = this.props.mode + ToolBarMode

        var config = null
        switch (TheMode) {
        case RichTextEditorMode.ActivityPost:
            config = RichTextEditorConfiguration.ActivityPost
            break
        case RichTextEditorMode.ActivityPostNoToolbar:
            config = RichTextEditorConfiguration.ActivityPostNoToolbar
            break
        case RichTextEditorMode.EventDescription:
            config = RichTextEditorConfiguration.EventDescription
            break
        case RichTextEditorMode.EventDescriptionNoToolbar:
            config = RichTextEditorConfiguration.EventDescriptionNoToolbar
            break
        case RichTextEditorMode.GroupDescription:
            config = RichTextEditorConfiguration.GroupDescription
            break
        case RichTextEditorMode.GroupDescriptionNoToolbar:
            config = RichTextEditorConfiguration.GroupDescriptionNoToolbar
            break
        case RichTextEditorMode.InviteToForceNet:
            config = RichTextEditorConfiguration.InviteToForceNet
            break
        case RichTextEditorMode.InviteToForceNetNoToolbar:
            config = RichTextEditorConfiguration.InviteToForceNetNoToolbar
            break
        case RichTextEditorMode.Message:
            config = RichTextEditorConfiguration.Message
            break
        case RichTextEditorMode.MessageNoToolbar:
            config = RichTextEditorConfiguration.MessageNoToolbar
            break
        }
        if(config.modules.toolbar){
            config.modules.toolbar.handlers['undo'] = this._undo
            config.modules.toolbar.handlers['redo'] = this._redo
        }
        return config
    }

    _undo = () => {
        this.editor.current.getEditor().history.undo()
    }

    _redo = () => {
        this.editor.current.getEditor().history.redo()
    }

    _onEditorChange = (value, delta, source, editor) => {
        const empty = editor.getText().trim() == ''
        this.setState({
            value
        });
        this.props.onValueChange?.(value, empty);
    }

    _onFocus = (range, source, editor) => {
        this.setState({
            position: window.scrollY
        });

        this.editor.current.getEditor().root.addEventListener('paste', this.onPaste);
    }

    _onBlur = (range, source, editor) => {
        this.editor.current.getEditor().root.removeEventListener('paste', this.onPaste);
    }

    onPaste = () => {
        window.scroll(0, this.state.position);
    }

    onResize = () => {
        let width = window.outerWidth;
        this.setState({
            windowWidth: width
        });
    }

    onScroll = () => {
        let scroll = window.scrollY;

        this.setState({
            position: scroll
        });
    }

    clear() {
        this.editor.current?.getEditor().setText('','silent');
    }

    setHtmlContent(markup: string) {
        this.editor.current?.getEditor().clipboard.dangerouslyPasteHTML(0, markup);
    }

    clearHistory() {
        this.editor.current.getEditor().history.clear();
    }

    getContents() {
        return this.state.value;
    }

    render() {
        const config = this._config()
        return (
            <div ref={this.me}>
                <ReactQuill {...this.props}
                    theme={config.theme}
                    readOnly={this.props.readOnly}
                    value={this.state.value}
                    onChange={this._onEditorChange}
                    modules={config.modules}
                    ref={this.editor}
                    onFocus={this._onFocus}
                    onBlur={this._onBlur}
                />
            </div>
        )
    }
}

export default RichTextEditor

/*
 react quill does not support max length or spell check
 these have to be implemented in RichTextEditor class
 maxLength={this.props.maxLength} 
 spellCheck={this.state.spellcheck}
 formats={config.formats}
*/

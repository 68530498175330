import React from "react"
import RichTextEditor from "../../../core/components/richtext/RichTextEditor"

const properEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface ForwardState {
    email: string,
    body: string,
    isBodyEmpty: boolean,
    errorEmail: boolean,
    showErrorEmail: boolean,
    errorBody: boolean,
    canSend: boolean
}

interface ForwardProps {
    conversationId: string,
    hideForward: () => void,
    sendForward: (conversationId: string, email:string, body: string) => void
}

class Forward extends React.Component<ForwardProps, ForwardState> {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            body: "",
            isBodyEmpty: true,
            errorEmail: false,
            showErrorEmail: false,
            errorBody:false,
            canSend: false
        }
    }

    _onEditorChange = (value: string, empty: boolean) => {
        this.setState({
            body: value, 
            isBodyEmpty: empty
        }); 
        this._validate();
    }

    _onChange = (e) => {
        this.setState({ email: e.target.value })
        this._validate();
    }

    _onEditorFocus = (value: string, empty: boolean) => {
        this.setState({
            body: value, 
            isBodyEmpty: empty
        }); 
        this._validate();
    }

    _onEmailBlur = (e) => {
        this._validate();
        this.setState({ showErrorEmail: true, email: e.target.value })
    }

    _validate() : boolean {
        const errorEmail = !this.state.email || !properEmailRegEx.test(this.state.email)
        const canSend = !errorEmail
        this.setState({ errorEmail, canSend });
        return canSend;
    }
    
    _forward = (e) => {
        e.preventDefault();
        this.props.sendForward(
            this.props.conversationId,
            this.state.email,
            this.state.body
        );
        this.props.hideForward();
        this.setState({ email: "", body: ""});
    }

    render() {
        return (
            <div
                className="panel-body form"
                style={{ display: this.props.show ? "block" : "none" }}>
                <div className="form-horizontal">
                    { this.state.errorEmail && this.state.showErrorEmail? 
                        <div className="alert alert-danger errorMessage ">
                            <span>Please enter a valid email address.</span>
                        </div>
                    : null }
                    { this.state.errorBody ? 
                        <div className="alert alert-danger errorMessage ">
                            <span>Please enter a message.</span>
                        </div>
                    : null }
                    <div className="inline-edit">
                        <div className="inline-edit__inputs">
                            <input
                                ref="email"
                                type="email"
                                className="form-control"
                                placeholder="Enter Email"
                                title="Enter Email"
                                onChange={this._onChange}
                                onBlur={this._onEmailBlur}
                                id="forward_email"
                                spellCheck="true"
                            />
                        </div>
                        <div className="inline-edit__inputs">
                            <RichTextEditor
                                maxLength={2000}
                                value={this.state.body}
                                onValueChange={this._onEditorChange}
                                onFocus={this._onEditorFocus}
                                spellCheck="true"
                                userPreference={this.props.userPreference}
                                mode="Message"
                                />
                        </div>
                        <div className="inline-edit__actions">
                            <input
                                type="submit"
                                value="Forward message"
                                disabled={!this.state.canSend}
                                className="btn btn-secondary post"
                                onClick={this._forward}
                                spellCheck="true"
                            />
                            <a
                                href="#"
                                className="btn btn-default btn-cancel"
                                onClick={this.props.hideForward}>
                                Cancel
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Forward

import {Quill} from 'react-quill';
import {ShiftEnterBlot} from "./ShiftEnterBlot";

// This file implements a default set of quill editor behaviour and configurations.
interface QuillConfiguration {
    modules: any,
    theme: string,
    //formats: any,     <- this is not required 
    //toolbar: () => JSX.Element,
}

// Quill uses <strong> by default, change that to <B>
var Bold = Quill.import('formats/bold');
Bold.tagName = 'B';
Bold.blotName = 'bold';
Quill.register(Bold, true);

// Quill uses <em> by default, change that to <I>
var Italic = Quill.import('formats/italic');
Italic.tagName = 'I';   
Italic.blotName = 'italic';
Quill.register(Italic, true);

// Shift-enter newline
var Delta = Quill.import('delta');
Quill.register(ShiftEnterBlot);


var icons = Quill.import("ui/icons");
icons['undo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
icons['redo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

const setQuillToolbarTitles = (el: HTMLElement) => {

    function setTitle(selector: string, title: string) {
        return el.querySelector(selector)?.setAttribute('title', title)
    }

    setTitle('.ql-bold', 'bold');
    setTitle('.ql-italic', 'italic');
    setTitle('.ql-underline', 'underline');
    setTitle('.ql-blockquote', 'block quote');
    setTitle('.ql-list[value=ordered]', 'numbered list');
    setTitle('.ql-list[value=bullet]', 'bullet point list');
    setTitle('.ql-clean', 'Clear Formatting');
    setTitle('.ql-undo', 'Undo');
    setTitle('.ql-redo', 'Redo');
}

const BaseConfig: QuillConfiguration = {
    modules: {
        clipboard: {
            matchVisual: false,  // TODO SA check up on this for deprecation
        },
        toolbar: {
            container: [
                [{ header: [2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'blockquote', {'list': 'ordered'}, {'list': 'bullet'}],
                ['undo', 'redo'],
                [{'direction': 'rtl'}]
            ],
            handlers: {
            },
        },
        history: {
            delay: 1000,
        },
        keyboard: {
            bindings: {
                linebreak: {
                    key: 13,
                    shiftKey: true,
                    handler: function(range) {
                                this.quill.updateContents(new Delta()
                                .retain(range.index)
                                .delete(range.length)
                                .insert({ "ShiftEnter": true }),
                                'user');
            
                                if (!this.quill.getLeaf(range.index + 1)[0].next) {
                                    this.quill.updateContents(new Delta()
                                                .retain(range.index + 1)
                                                .delete(0)
                                                .insert({ "ShiftEnter": true }),
                                                'user');
                                }
                            
                                this.quill.setSelection(range.index + 1, 'silent');
                                return false; // Don't call other candidate handlers
                    },
                },
            },
        },
    },
    theme: 'snow', // 'snow', bubble', false
}
const BaseConfigNoToolbar: QuillConfiguration = {
    ...BaseConfig,
    modules: {
    ...BaseConfig.modules,
        toolbar: false,
    },
}

const Message = { ...BaseConfig }
const MessageNoToolbar = { ...BaseConfigNoToolbar }

const ActivityPost = { ...BaseConfig }
const ActivityPostNoToolbar = { ...BaseConfigNoToolbar }

const EventDescription = { ...BaseConfig }
const EventDescriptionNoToolbar = { ...BaseConfigNoToolbar }

const GroupDescription = { ...BaseConfig }
const GroupDescriptionNoToolbar = { ...BaseConfigNoToolbar }

const InviteToForceNet = { ...BaseConfig }
const InviteToForceNetNoToolbar = { ...BaseConfigNoToolbar }


// if any specifig editor needs customisations do that here
// eg: Message.modules.tooolbar = [ buttons ...]

export default {
    ActivityPost,
    ActivityPostNoToolbar,
    EventDescription,
    EventDescriptionNoToolbar,
    GroupDescription,
    GroupDescriptionNoToolbar,
    InviteToForceNet,
    InviteToForceNetNoToolbar,
    Message,
    MessageNoToolbar,
    setQuillToolbarTitles,
}

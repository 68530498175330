import AppDelegate from '../core/AppDelegate';
import HttpAuthenticatedSvc from './HttpAuthenticatedSvc';
import UserCollection from '../core/models/UserCollection';

class UserTempStoreSvc {
	async getLastSave(type) {
		if (!type) {
			throw new Error('Invalid type object');
		}
		var headerItems = new Headers();
		headerItems.append('Content-Type', 'application/json');
		headerItems.append('type', type);
		const response = await fetch(`${AppDelegate.appSettings.apiUrl}/UserTempStore.asmx/CheckGetLastSave`, {
			method: 'POST',
			headers: headerItems,
			body: null
		})
			.then(response => response.json())
			.then(result => {
				return result;
			})
			.catch((error) => {
				var result = new Object();
				result.valid = false;
				result.error = error;
				return result;
			});
		return response;
	}
	async removeLastSave(type) {
		if (!type) {
			throw new Error('Invalid type object');
		}
		var headerItems = new Headers();
		headerItems.append('Content-Type', 'application/json');
		headerItems.append('type', type);
		const response = await fetch(`${AppDelegate.appSettings.apiUrl}/UserTempStore.asmx/RemoveLastSave`, {
			method: 'POST',
			headers: headerItems,
			body: null
		})
			.then(response => response.json())
			.then(result => {
				return result;
			})
			.catch((error) => {
				var result = new Object();
				result.valid = false;
				result.error = error;
				return result;
			});
		return response;
	}
	async saveToTempStore(type, data) {
		if (!type) {
			throw new Error('Invalid type object');
		}
		var headerItems = new Headers();
		headerItems.append('Content-Type', 'application/json');
		headerItems.append('type', type);

		const response = await fetch(`${AppDelegate.appSettings.apiUrl}/UserTempStore.asmx/SaveValue`, {
			method: 'POST',
			headers: headerItems,
			body: JSON.stringify(data)
		})
			.then(response => response.json())
			.then(result => {			
				return result;
			})
			.catch((error) => {
				var result = new Object();
				result.valid = false;			
				result.error = error;
				return result;
			});
		return response;
	}

}

export default new UserTempStoreSvc();
import AppDelegate				from '../core/AppDelegate';
import HttpAuthenticatedSvc		from './HttpAuthenticatedSvc';


class MessageSvc
{
    mailbox(searchTerm, conversationId, folderId)
    {
        let filter = null
        if (searchTerm || conversationId || folderId)
        {
            filter = {
                FocusConversation: conversationId || 0,
                SearchTerm: searchTerm || null,
                FolderId: folderId || null
            }
        }
        let payload = {
            filter: filter
        };
        return this._request('GetMailbox', payload);
    }

    listConversations(pageNo, searchTerm, conversationId, folderId)
    {
        let filter = null
        if (searchTerm || conversationId || folderId)
        {
            filter = {
                FocusConversation: conversationId || null,
                SearchTerm: searchTerm || null,
                FolderId: folderId || null
            }
        }
        let payload = {
            state: pageNo || null,
            filter: filter
        };
        return this._request('GetConversationsPage', payload);
    }

    listMessages(conversationId, pageNo)
    {
        let payload = {
            conversationId,
            pageNo: pageNo || 1
        };
        return this._request('GetConversationMessages', payload);
    }

    create(body, subject, recipientList, bccRecipientList, isImportant, isReplyable, sendSms, isRichText, isDraft, draftConversationId)
    {
        let payload = {
            body: body,
            createMessageSubject: subject,
            recipientList: recipientList,
            bccRecipientList,
            isImportantAndReadReceipts: isImportant,
            replyable: isReplyable,
            sendSms: sendSms,
            isRichText: isRichText,
            isDraft: isDraft,
            draftConversationId: draftConversationId
        }
        return this._request('CreateConversation', payload);
    }

    reply(conversationId, body, isRichText, isReplyOriginalSender)
    {
        let payload = {
            conversationId,
            body,
            isRichText: isRichText,
            isReplyOriginalSender,
        };
        return this._request('ReplyToConversation', payload);
    }

    archive(conversationId, archive)
    {
        let payload = {
            conversationId,
            archive
        };
        return this._request('SetConversationArchived', payload);
    }

    email(conversationId, email, body)
    {
        let payload = {
            conversationId,
            email,
            body
        };
        return this._request('EmailConversation', payload);
    }

    deleteDraftInboxMessage(draftConversationId){
        let payload = {
            draftConversationId
        };     
        return this._request('DeleteDraftInboxMessage', payload);   
    }

    _request(method, payload)
    {
        let req;
        let endpoint = `${AppDelegate.appSettings.apiUrl}/Messaging.asmx/${method}`;

        let promise = new Promise((resolve, reject) => {
            req = HttpAuthenticatedSvc.post(endpoint, payload, function (response) {
                resolve(response);
            }, function (err) {
                reject(err);
            });
        });

        return {
            promise: promise,
            abort: () => {
                req.abort();
            }
        };
    }
}

export default MessageSvc;

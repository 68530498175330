import * as React from "react";

function SvgIconDelete(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16 20.867L4.867 32 0 27.133 11.133 16 0 4.867 4.867 0 16 11.133 27.133 0 32 4.867 20.864 16 32 27.133 27.133 32 16 20.867z"
      />
    </svg>
  );
}

export default SvgIconDelete;

import AppDelegate from '../core/AppDelegate';
import MetaData, {
    MetaDataFactory
} from '../modules/crawler/models/MetaData';
import HttpAuthenticatedSvc from './HttpAuthenticatedSvc';
import HttpSvc from '../services/HttpSvc';

class CrawlerSvc {
   /**
     * Get defence video meta data
     */
    crawlDefenceVideo(id) {
        var req,
            endpoint = `${AppDelegate.appSettings.apiUrl}/CrawlerService.asmx/CrawlDefenceVideo`,
            payload = {
                id: id
            };
        let promise = new Promise((resolve, reject) => {
            req = HttpAuthenticatedSvc.post(endpoint, payload, function (response) {
                resolve(MetaDataFactory.MetaDataFromObject(response));
            }, function (err) {
                reject({
                    reason: err.message
                });
            });
        });

        return {
            promise: promise,
            abort: () => {
                req.abort();
            }
        };
    }
}

export default new CrawlerSvc();
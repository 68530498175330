import React 				from 'react';
import PropTypes from 'prop-types'
import FileUtils 			from '../../../core/utils/FileUtils';
import FileExplorerStore 	from '../stores/FileExplorerStore';
import Modal        		from '../../../core/components/modal/Modal';
import BtnClose     		from '../../../core/components/BtnClose';
import FolderSelect			from './FolderSelect';
import InlineFileUpload		from './../../../core/components/InlineFileUpload';
import { UserSelector } 	from "./UserSelector";
import AppDelegate			from '../../../core/AppDelegate';

import {
	getFileAccess,
	getFileAccess_fileAccess
} from "../../../../types/graphqlTypes";


const fileAccessGql = require('../../../../gql/getFileAccess.gql')
class ModalFileEdit extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			edit: { valid: true, title: props.file?.title ?? '', description: props.file?.description ?? '', folderValid: true},
			newFileId: null,
			newFileTitle: null,
			error: false,
			errorMessage: null,
			saveButtonClassName: "btn btn-secondary",
			showAccessRestriction: false,
			accessRestrictionsApply: props.file?.isAccessRestricted,
			selectedUsers: [],
		};
        this.defaultFolderId = props.folderid;
        this._setFolderOptions(props.folders);
	}

	async componentDidMount() {
		if (this.props.canManageAccessRestriction) {
			const selectedUsers = await AppDelegate.client.query({
				query: fileAccessGql,
				variables: {
					fileId: this.props.file.id
				},
				fetchPolicy: "no-cache"
			}).then(({data}) => data?.fileAccess) ?? [];

			this.setState({
				selectedUsers: selectedUsers.sort((l, r) => l.fullName < r.fullName ? -1 : 0)
			})
		}
	}

	componentWillReceiveProps(props)
    {
        let title = '';
        let description = '';
        if (props.file != null && props.file.title != null) {
            title = props.file.title;
        }
        if (props.file != null && props.file.description != null) {
            description = props.file.description;
        }

        this._setFolderOptions(props.folders);
        let valid = 0 != title.trim().length;
        this.defaultFolderId = props.folderid;

		this.setState({
			edit: { valid: valid, title: title, description: description, folderValid: true },
		});
    }

    _setFolderOptions(folders) {
        this.folderOptions = folders;
    }

    _handleModalCloseRequest() {
        this.props.onCloseClick()
    }

	_handleEditChange(event)
	{

		const target = event.target;
		const name = target.name;
   		let value = target.type === 'checkbox' ? target.checked : target.value;
		let valid = true;
		if (name == 'title') {
			if (value.length > 50) {
				value = value.substring(0, 50);
			}
			if (value.trim().length <= 0) {
				valid = false;
			}
		}
		if (name == 'description') {
			if (value.length > 500) {
				value = value.substring(0, 500);
			}
		}

		let edit = this.state.edit;
		edit[name] = value;
		edit.valid = valid;

		this.setState({
			edit: edit,
			saveButtonClassName: edit.valid ? "btn btn-secondary" : "btn btn-secondary disabled"
		});
	}

	_handleFolderChange() {
		let edit = this.state.edit;
		edit.folderValid = edit.valid = this.refs.folderSelect.isValid();

		this.setState({
			edit: edit
		});
	}

	_clearEditDescription(e)
	{
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		let edit = this.state.edit;
		edit.description = '';

		this.setState({
			edit: edit
		});
	}


    _handleUpdateClick(e)
    {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		if (!this.state.edit.valid) {
			return;
		}

        this.props.onUpdateRequest(this.props.file, this.state.edit.title, this.state.edit.description, !this.refs.folderSelect.isCreateFolder() ? this.refs.folderSelect.getFolderId() : null, this.refs.folderSelect.isCreateFolder() ? this.refs.folderSelect.getParentFolderId() : null, this.refs.folderSelect.isCreateFolder() ? this.refs.folderSelect.getFolderTitle() : null, this.state.newFileId ? this.state.newFileId.toString() : null,
			this.props.canManageAccessRestriction ? this.state.accessRestrictionsApply : null,
			this.props.canManageAccessRestriction ? this.state.selectedUsers.map(i => i.id) : null);
	}

	_onUploadComplete(file) {
		this.setState({
			newFileTitle: file.name,
			newFileId: file.id,
			error: file.error ? true : false,
			errorMessage: file.error
		})
	}

	blur() {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
	}

	_setShowAccessRestriction = (e) => {
		e?.preventDefault();
		this.blur();
		this.setState({
			showAccessRestriction: true
		});
	}
	
	_setHideAccessRestriction = (e) => {
		e?.preventDefault();
		this.blur();
		this.setState({
			showAccessRestriction: false
		});
	}

	_onSetSelectedUsers = (users) => {
		this.setState({
			selectedUsers: users
		});

    }

	_toggleAccess = (e) => {
		this.setState({
			accessRestrictionsApply: !this.state.accessRestrictionsApply
		})
	}

	render() {
		const tabClassName = this.props.canManageAccessRestriction ? "tabs tabs-access" : "";
		const filesClassName = `files ${this.state.showAccessRestriction ? "hidden" : ""}`;

		return (
				<Modal onRequestsClose={this._handleModalCloseRequest.bind(this)} active={this.props.active} size='default'>
					<div className='modal-header'>
						<BtnClose onClick={this._handleModalCloseRequest.bind(this)} />
						<h2 className='modal-title'>Update {this.props.file?.title ? this.props.file?.title : "file" }</h2>
					</div>
                    <div className='modal-content file-modal' style={{ display: "flex", flexDirection: "column", paddingTop: "10px", paddingBottom: "5px", overflowY: "auto"}}>
						<div className={tabClassName}>
							{ this.props.canManageAccessRestriction && 
								[ <div key={0} className="tab-header">
									<a href="#" role="button" className={!this.state.showAccessRestriction ? 'active' : ''} onClick={this._setHideAccessRestriction}><span>File details</span></a>
									<a href="#" role="button" className={this.state.showAccessRestriction ? 'active' : ''} onClick={this._setShowAccessRestriction}><span>Access restrictions</span></a>
								</div>,
								<br key={1}/>
								]
							}

							<div className={filesClassName}>
								<label htmlFor="edit-title">Title</label>
								<input type="text" id="edit-title" value={this.state.edit.title} className="form-control" name="title" placeholder="Title" onChange={this._handleEditChange.bind(this)} spellCheck="true" />

								<FolderSelect ref="folderSelect" folderLabel={this.props.folderLabel} creationFolders={this.props.creationFolders} folderOptions={this.folderOptions} defaultFolderId={this.defaultFolderId} onChange={this._handleFolderChange.bind(this)}/>

								<label htmlFor="edit-description">Description</label><br /><span>Characters used: {this.state.edit.description.length} of 500</span>
								<a href="javascript:void(0)" className="pull-right" onClick={this._clearEditDescription.bind(this)}>Clear description</a>
								<textarea spellCheck="true" rows="2" id="edit-description" className="form-control" cols="40" value={this.state.edit.description} name="description" onChange={this._handleEditChange.bind(this)} />
								<div style={{marginTop: '5px', display: 'flex', justifyContent:'space-between'}}>
									<div style={{marginTop: '5px'}}>
										<label>Filename:</label>
										<span> {this.state.newFileTitle && !this.state.error ? this.state.newFileTitle : this.props.file?.title}</span>
										{this.state.error &&
											<span> not replaced.</span>
										}
										{!this.state.error && this.state.newFileTitle &&
											<span> uploaded.</span>
										}
									</div>
									<InlineFileUpload
										ref="fileuploader"
										uploadUrl={this.props.url}
										formData={this.props.uploadFormData}
										onUploadComplete={this._onUploadComplete.bind(this)}
										buttonText={"Replace File"}
									/>
								</div>
								{this.state.error && this.state.errorMessage &&
									<div>
										<span style={{fontSize: '12px'}}>{this.state.errorMessage}</span>
									</div>
								}
							</div>
							{ this.state.showAccessRestriction && <div className="access">
							 	<div className="checkbox-inline">
										<label><input type="checkbox" onClick={this._toggleAccess} defaultChecked={this.state.accessRestrictionsApply}/>Limit access only to administrators</label>
								</div>

								{ this.state.accessRestrictionsApply && <div>
									<div>Also, allow access to the following members</div>
									<UserSelector eventId={this.props.eventId} groupId={this.props.groupId} selectedUsers={this.state.selectedUsers} onEmit={this._onSetSelectedUsers} label="Members" placeholder="Search for a member" />
								</div>}
							</div>}
						</div>
					</div>
					<div className="modal-actions">
						<button role="button" className={this.state.saveButtonClassName} onClick={this._handleUpdateClick.bind(this)}>Save</button>
						&nbsp;
						<button role="button" className="btn btn-default" onClick={this._handleModalCloseRequest.bind(this)}>Cancel</button>
					</div>
				</Modal>
		);
	}

}

ModalFileEdit.defaultProps = {
    folderLabel: 'Folder',
	canManageAccessRestriction: true
}

ModalFileEdit.propTypes = {
	file: PropTypes.object.isRequired,
	active: PropTypes.bool.isRequired,
    folderLabel: PropTypes.string,
    creationFolders: PropTypes.array,
	canManageAccessRestriction: PropTypes.bool,
	eventId: PropTypes.number,
	groupId: PropTypes.string,
	//onCloseClick: PropTypes.function.isRequired,
    //onUpdateRequest: PropTypes.function.isRequired,
};

export default ModalFileEdit;

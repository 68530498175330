import React, {
    FC,
} from "react";
import _ from 'lodash';

import DisplayFileList from "./DisplayFileList";
import {
    forumReplyResult
} from "../../../../types/graphqlTypes";
import moment from 'moment';

import DateTimeUtils from "../../../core/utils/DateTimeUtils";
const renderDateTime = DateTimeUtils.renderDateTime;

interface IAssociatedFilesProps {
    discussionReplyResult: forumReplyResult,
}

export const AssociatedFiles: FC<IAssociatedFilesProps> = (props: IAssociatedFilesProps) => {

    return (
        <div>
            {props.discussionReplyResult.associations && props.discussionReplyResult.associations.length > 0 &&
                <div className="fn-section-right fn-service-border-colour">
                    <div className="form-horizontal fn-flex">
                        <div className="row full-width">
                            <div className="small-16 column">
                                <div className="fn-title">
                                    <span>Related files</span>
                                </div>
                                <div className="fn-section-header">
                                    <span> {props.discussionReplyResult.createdBy.name}</span>
                                    <span className="fn-float-right">{renderDateTime(moment(props.discussionReplyResult.reply.lastModifiedDate))}</span>
                                </div>
                                <div className="fn-section-body">
                                    <DisplayFileList associations={props.discussionReplyResult.associations} discussionResult={props.discussionReplyResult}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AssociatedFiles;


class PageViewDetail {

  Count: Number;
  Title: String;
}

class PostCommentActivity {

  PoistId: Number;
  Date: String;
  Message: String;
  AckCount: Number;
  CommentCount: Number;
  CommentAckCount: Number;
}

export class GroupReport {

  GroupId: String;
  Dates: [String];
  Posts: [Number];
  Comments: [Number];
  Acks: [Number];
  Events: [Number];
  FileUploads: [Number];
  FileDownloads: [Number];
  Members: [Number];
  NewMembers: [Number];
  JoinRequests: [Number];
  PageViews: [Number];
  UniquePageViews: [Number];
  TotalPageView: Number;
  TotalPageViewUnique: Number;
  UserDetail: [PageViewDetail];
  DeviceDetail: [PageViewDetail];
  HeatmapMon: [Number];
  HeatmapTue: [Number];
  HeatmapWed: [Number];
  HeatmapThu: [Number];
  HeatmapFri: [Number];
  HeatmapSat: [Number];
  HeatmapSun: [Number];
  MostJoinRequests: PageViewDetail;
  MostPopularPost: PostCommentActivity;
  MostActivePost: PostCommentActivity;
}

export class GroupReportFactory {

  static groupReportFromObject(o: any): GroupReport {
    let r: GroupReport = o;
    return r;
  }
}
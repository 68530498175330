import _ 				from 'lodash';
import ReactContainer 	from './ReactContainer';


let FIRST_CLASS_SELECTOR = 'data-fn-module';

/**
 * The Bootstrapper is responsible for wiring up components on initialisation (or app start).
 */
class Bootstrapper {

	/**
	 * The Constructor
	 */
	constructor() {

		let body = window.document.body;
		this.controller = body.getAttribute('data-controller');

		this.appStart();

		FORCENET.Bootstrapper = this;
	}

	/**
	 * Configure the default container
	 * Will wire up default containers and additionally attempt to wire up
	 * controllers defined in the 'data-controller' attribute on the <body> tag.
	 */
	appStart() {

		if(window && window.document) {
			// By default always wire up web modules
			this._configureDocumentContainer();
			this.initComponents(window.document);
		}
	}

	initComponents(el)
	{
		if (!el) el = window.document;

		_.each(el.querySelectorAll(`[${FIRST_CLASS_SELECTOR}]`), this._configureComponent);
	}

	uninitComponents(el)
	{
		if (!el) el = window.document;

		_.each(el.querySelectorAll(`[${FIRST_CLASS_SELECTOR}]`), this._unconfigureComponent);
	}

	/**
	 * Configure the required web modules
	 * @param {Array} controllers The 'controllers' to wire up
	 */
	_configureDocumentContainer()
	{
		// Wire app app root
		ReactContainer.ControllerContainer.root();

		// Wire up the main controller
		var module = ReactContainer.ControllerContainer[this.controller];
		if(!_.isUndefined(module)) {
			module.call(this);
		}
	}

	/**
	 * Configures the first class elements.
	 * These are elements that are self initiating via the `data-fn-module` attribute.
	 */
	_configureComponent(el)
	{
		var module = ReactContainer.FirstClassContainer[el.getAttribute(FIRST_CLASS_SELECTOR)];
		if (_.isUndefined(module)) {
			module = ReactContainer.DOMContainer[el.getAttribute(FIRST_CLASS_SELECTOR)];
		}
		if (!_.isUndefined(module)) {
			if (!el.dataset.reactInit) {
				module.call(this, el);
				el.dataset.reactInit = true;
			}
		}
	}

	/**
	 * Configures the first class elements.
	 * These are elements that are self initiating via the `data-fn-module` attribute.
	 */
	_unconfigureComponent(el)
	{
		var module = ReactContainer.FirstClassContainer[el.getAttribute(FIRST_CLASS_SELECTOR)];
		if (_.isUndefined(module)) {
			module = ReactContainer.DOMContainer[el.getAttribute(FIRST_CLASS_SELECTOR)];
		}
		if (!_.isUndefined(module)) {
			if (el.id)
			{
                let target = window.document.getElementById("react-" + el.id);
				if (target)
				{
					let cln = target.cloneNode(true);
					let unMounted = ReactDOM.unmountComponentAtNode(target);
					target.parentNode.insertBefore(cln, target.nextSibling);

					el.dataset.reactInit = false;
				}
			}
		}
	}
}

export default new Bootstrapper();

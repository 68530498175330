import React from 'react';
import PostAnalytics from './components/PostAnalytics';

class PostAnalyticsModule extends React.Component {

	render() {
		return (
			<PostAnalytics {...this.props} />
		);
	}
}

export default PostAnalyticsModule;

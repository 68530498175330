import React 	from 'react';
import _		from 'lodash';

import BtnClose						from '../BtnClose';
import ActivityIndicator			from '../ActivityIndicator';
import Modal	from '../modal/Modal';
import ViewMediaActions from './ViewMediaActions'
import ViewMediaItemAudio from './ViewMediaItemAudio'
import ViewMediaItemImage from './ViewMediaItemImage'
import ViewMediaItemVideo from './ViewMediaItemVideo'
import folderSvc from '../../../services/FolderSvc'

class ViewMediaModal extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
            loading: false,
            loadingVideo: false,
            index: props.index || 0,
            items: _.map(props.items, f => {
                f.fileType = f.fileType.toUpperCase()
                return f
            }),
            autoplay: true,
            videoError: null
		};

        this.viewMediaItemAudio = React.createRef();
        this.viewMediaItemVideo = React.createRef();
    }

    componentWillMount() {
		if ('function' == typeof(closeCovidChat)) closeCovidChat()
        var videos = _.filter(this.state.items, f => f.fileType == 'VIDEO' || f.fileType == 'AUDIO')
        if (videos.length == 0) {
            setImmediate(() => this.forceUpdate());
        } else {
            this.setState({
                loadingVideo: true,
            })
            ViewMediaActions.videoStreamUrl(_.map(videos, v => v.id))
            .then(files => {
                if (!files || !files.Files || files.Files.length == 0) return
                var streams = files.Files

                // adapted from https://stackoverflow.com/a/58065241/103568
                let isApple = (/MacIntel|iPad|iPhone|iPod/.test(navigator.platform) && !window.MSStream)

                var items = this.state.items
                for(var i=0; i<items.length; i++) {
                    const fileType = items[i].fileType
                    if (fileType != 'VIDEO' && fileType != 'AUDIO') continue

                    for(var v=0; v<streams.length; v++) {
                        if (items[i].id == streams[v].Id) {
                            var url = streams[v].StreamUrl
                            if (url.startsWith('/')) {
                                url = window.location.origin + url
                            }
                            if (isApple) {
                                if (url.endsWith('.mpd')) {
                                    url = url.substring(0, url.lastIndexOf('.mpd')) + '.m3u8'
                                }
                            } else {
                                if (url.endsWith('.m3u8')) {
                                    url = url.substring(0, url.lastIndexOf('.m3u8')) + '.mpd'
                                }
                            }
                
                            items[i].videoStreamUrl = url
                            break
                        }
                    }
                }
                this.setState({
                    items: items,
                    loadingVideo: false,
                }) 
            })
        }
    }

    _handleNextPrevious(next, e) {
        if(e && e.preventDefault) {
			e.preventDefault();
		}

        var autoplay = this.state.autoplay
        if (this.state.items[this.state.index].fileType == 'AUDIO') {
            autoplay = this.viewMediaItemAudio?.current?.isPlaying() ?? autoplay;
        } else if (this.state.items[this.state.index].fileType == 'VIDEO') {
            autoplay = this.viewMediaItemVideo?.current?.isPlaying() ?? autoplay;
        }

        let index;
        if (next) {
            index = this.state.index + 1
            if (index == this.props.items.length) {
                index = 0;
            }
        } else {
            index = this.state.index - 1
            if (index < 0) {
                index = this.props.items.length - 1
            }
        }

        this.setState({
            index,
            autoplay,
            videoError: null,
        })

        if (this.props.items[index].fileType == 'IMAGE' || ((this.props.items[index].fileType == 'AUDIO' || 'VIDEO') && autoplay == true)) {
            folderSvc.createVideoPlayLogEntry(this.props.items[index].id, this.props.containerId)
            .promise
            .then(_ => {})
        }
    }
    
    _renderLoading() {
        if (!this.state.loading) return null

        return (
            <ActivityIndicator active={true} inverted={true} size="Large" />
        )
    }

    _renderItem(item) {
        if (this.state.loading) return null

        switch (item.fileType) {
        case 'AUDIO':
            return (<ViewMediaItemAudio ref={this.viewMediaItemAudio} file={item} autoplay={this.state.autoplay} containerId={this.props.containerId}/>)
        case 'IMAGE':
            return (<ViewMediaItemImage file={item} containerId={this.props.containerId} />)
        case 'VIDEO':
            return (<ViewMediaItemVideo ref={this.viewMediaItemVideo} file={item} autoplay={this.state.autoplay} containerId={this.props.containerId} loadingVideo={this.state.loadingVideo}/>)
        default:
            return null
        }
    }

    render() {
        var isGallery = this.props.items.length > 1

        return (
            <Modal forceMaxHeight={true} active={true} size="large" isMaxHeight={true} onRequestsClose={this.props.onRequestsClose}>
                <div className="modal-header modal-header-dark ">
                    <BtnClose onClick={this.props.onRequestsClose} />
                    { this.props.headerCard }
                </div>
                <div className="image-collection-stage">
                    <div className="image-collection-stage-pane">
                        { isGallery ?
							<a href="#" role="button" className="image-collection-prev fn-icon icon-arrow-left" onClick={this._handleNextPrevious.bind(this, false)}><span className="sr-only">Previous</span></a>
						: null}
                        { this._renderLoading() }
                        { this._renderItem(this.state.items[this.state.index]) }
                        { isGallery ?
							<a href="#" role="button" className="image-collection-next fn-icon icon-arrow-right" onClick={this._handleNextPrevious.bind(this, true)}><span className="sr-only">Next</span></a>
						: null}
                        { isGallery ?
							<span className="image-collection-label">{(this.state.index + 1) + " of " + this.props.items.length}</span>
						: null}
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ViewMediaModal

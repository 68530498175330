import React 				from 'react';
import PropTypes from 'prop-types'
import FileUtils 			from '../../../core/utils/FileUtils';
import FileExplorerStore 	from '../stores/FileExplorerStore';
import Modal        		from '../../../core/components/modal/Modal';
import BtnClose     		from '../../../core/components/BtnClose';


class ModalFolderDelete extends React.Component {

	constructor(props) {
		super(props);

        this.state = {
            canUnarchive: true
        };
	}

    _handleModalCloseRequest() {
        this.props.onCloseClick()
    }

    _handleUnlinkRequest(e) {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

        this.props.onUnlinkRequest(this.props.folder, this.state.canUnarchive)
    }

    onChangeCanUnarchive = (e) => {
        this.setState({
            canUnarchive: e.target.value && parseInt(e.target.value) == 1 ? true : false
        });
    }

    render() {
        let name = "this folder";
        if (this.props.folder != null) {
            name = `"${this.props.folder.name}"`;
        }

        let folderLabel = this.props.folderLabel.toLowerCase();

		return (
            <Modal onRequestsClose={this._handleModalCloseRequest.bind(this)} active={this.props.active} size='default'>
                <div className='modal-header'>
                    <BtnClose onClick={this._handleModalCloseRequest.bind(this)} />
                    <h2 className='modal-title'>Confirm delete</h2>
                </div>
                <div className='modal-content fn-delete-warning'>
                    <div className="fn-flex" style={{ padding: "5px" }}>
                        <div style={{ padding: "0px 10px 0px 0px" }}>
                            <input type="radio" id="canUnarchive" name="canUnarchive" value="1" checked={this.state.canUnarchive} onChange={this.onChangeCanUnarchive} />
                        </div>
                        <div>
                            <div>
                                <label htmlFor="canUnarchive">Move all files in { name } to "recently deleted"</label>
                            </div>
                            <div>
                                <div>You have 30 days to restore the files before it's deleted permanently.</div>
                                <div><strong>Important!</strong> While your files will move, your {folderLabel} will be permanently deleted.</div>
                            </div>
                        </div>
                    </div>
                    <div className="fn-flex" style={{ padding: "5px" }}>
                        <div style={{ padding: "0px 10px 0px 0px" }}>
                            <input type="radio" id="cantUnarchive" name="canUnarchive" value="0" checked={!this.state.canUnarchive} onChange={this.onChangeCanUnarchive} />
                        </div>
                        <div>
                            <div>
                                <label htmlFor="cantUnarchive">Permanently delete the {folderLabel} {name} and all its files.</label>
                            </div>
                            <div>
                                They will be removed and you can't restore them.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-actions">
                    <button role="button" className="btn btn-primary"  onClick={this._handleUnlinkRequest.bind(this)}>Delete</button>
                    &nbsp;
                    <button role="button" className="btn btn-default" onClick={this._handleModalCloseRequest.bind(this)}>Cancel</button>
                </div>
            </Modal>
		);
	}
}

ModalFolderDelete.defaultProps = {
    folderLabel: 'Folder'
}

ModalFolderDelete.propTypes = {
	folder: PropTypes.object.isRequired,
	active: PropTypes.bool.isRequired,
    folderLabel: PropTypes.string
	//onCloseClick: PropTypes.function.isRequired,
    //onUnlinkRequest: PropTypes.function.isRequired,
};

export default ModalFolderDelete;

export const ServiceGroupIds = [
	"94f08ecf-ac98-4155-b909-f456e24546aa", // AirForce
	"09a8177c-8f45-4bca-84d1-c37e6c739430", // Navy
	"0f330963-d5ab-40fd-bace-b94d54e81ba3", // Army
	"a94fd141-b6e2-4ef5-8615-34a29b32c843", // APS
	"82640fdf-4250-4cec-b5d6-d943031b7b57", // TriService
	"740df6e5-6f8f-43eb-a815-04599b9dee41", // ExService
	"b9fbd566-6acc-4058-aff1-646b83d0d0aa", // Families - AirForce
	"38575c36-c948-4f61-943e-4425cd518978", // Families - Navy
	"2c3d1508-5f0b-40a7-937b-1797e9f96c19", // Families - Army
	"f8182540-4d0b-4b86-a618-b79bae8b5080", // Families - APS
	"5b647db9-b109-43c7-a208-ce185224bfe3", // Families - TriService
	"abc65f60-2061-4439-bda0-dce6c203adff"  // Families - ExService
];


import _ from "lodash";

/**
 * AuditLog Model
 */
export class AuditLog {

  auditLogId: Number;
  userId: String; //The user id
  userName: String; //The username
  auditContextId: String; //The audit context id
  sessionId: String; //The session Id
  pMKey: Number; //The PMKey
  logType: String; //The logging type
  dateCreated: Date; //The creation date
  intentsDescription: String; //The intent description
  data: String; // The data containing stack trace
  auditChangeColumns: Array<AuditChangeColumn | null | undefined>; //The audit change columns

  constructor(auditLogId: Number, userId: String, userName: String, auditContextId: String, sessionId: String, pMKey: Number, logType: String, dateCreated: Date, intentsDescription: String, data: String, auditChangeColumns: Array<AuditChangeColumn | null | undefined>) {
    this.auditLogId = auditLogId;
    this.userId = userId;
    this.userName = userName;
    this.auditContextId = auditContextId;
    this.sessionId = sessionId;
    this.pMKey = pMKey;
    this.logType = logType;
    this.dateCreated = dateCreated;
    this.intentsDescription = intentsDescription;
    this.data = data ? JSON.parse(data) : null;
    this.auditChangeColumns = auditChangeColumns;

    if (this.data && this.data.StackTrace) {
      this.data.StackTrace = JSON.parse(this.data.StackTrace);
    }
  }
}

export class AuditChangeColumn {

  action: String; //The action type
  tableName: String; //The reflected table name
  columnName: String; //The column name
  oldValue: String; //The old value
  newValue: String; //The new value

  constructor(action: String, tableName: String, columnName: String, oldValue: String, newValue: String) {
    this.action = action;
    this.tableName = tableName;
    this.columnName = columnName;
    this.oldValue = oldValue;
    this.newValue = newValue;
  }
}

/**
 * Creates audit log from an object
 * @param o
 * @returns {AuditLog}
 */
export class AuditLogFactory {

  static auditLogFromOject(o) {
    var auditLog = new AuditLog(o.AuditSetId, o.UserId, o.UserName, o.AuditContextId, o.SessionId, o.PMKey, o.LogType, this.parseJsonDate(o.DateCreated), o.IntentsDescription, o.Data);

    if (o.AuditChangeColumns) {
      auditLog.auditChangeColumns = _.map(o.AuditChangeColumns, u => {
        return AuditLogFactory.auditChangeColumnFromObject(u);
      });
    }

    return auditLog;
  }

  /**
   * Creates AuditChangeColumn from an object
   * @param o
   * @returns {AuditChangeColumn}
   */
  static auditChangeColumnFromObject(o) {
    return new AuditChangeColumn(o.Action, o.TableName, o.ColumnName, o.OldValue, o.NewValue);
  }

  /**
   * Parse Json date to date type
   */
  static parseJsonDate(jsonDate: String) {
    var fullDate = new Date(parseInt(jsonDate.substr(6)));
    var twoDigitMonth = fullDate.getMonth() + 1 + "";
    if (twoDigitMonth.length == 1) twoDigitMonth = "0" + twoDigitMonth;

    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.length == 1) twoDigitDate = "0" + twoDigitDate;
    var currentDate = twoDigitDate + "/" + twoDigitMonth + "/" + fullDate.getFullYear() + " " + fullDate.format("HH:MM:ss");

    return currentDate;
  }
}
import React from "react"
import moment from "moment"

import AppDelegate				from '../../../core/AppDelegate';

class Discussion extends React.Component {
    constructor(props) {
        super(props);
        var time = moment.tz(props.time, AppDelegate.config.serverTimeZone);

        if (time.isAfter(moment())) {
            time = moment();
        }

        this.state = {
            showRead: false,
            showUnread: false,
            height: 0,
            time: time,
            fromNow: time.fromNow()
        }

        this.timer = null;
        this.boundUpdateTime = this.updateTime.bind(this);
    }

	componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ height: "4000px" });
            this.updateTime();
        },10);
    }

	componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    updateTime() {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.setState({
            fromNow: this.state.time.fromNow()
        });

        let diff = moment().diff(this.state.time) / 1000;

        if (diff < 60) {
            this.timer = setTimeout(this.boundUpdateTime, 2000);
        } else if (diff < 600) {
            this.timer = setTimeout(this.boundUpdateTime, 10000);
        } else {
            this.timer = setTimeout(this.boundUpdateTime, 30000);
        }
    }

    toggleShowRead() {
        this.setState({
            showRead: !this.state.showRead
        });
    }

    toggleShowUnread() {
        this.setState({
            showUnread: !this.state.showUnread
        });
    }

    renderRead() {
        return (
            <ul className="users" style={{ display: "block" }}>
                { this.props.read.map(u => {
                        var designator = null;
                        if (u.PermanentUserType == 1 || u.PermanentUserType == 2) {
                            designator = (<span className="designator small-text">{(u.PermanentUserType == 1 ? '(CONTR)' : (u.PermanentUserType == 2 ? '(O/GOV)' : ''))}</span>);
                        }
                        
                        return <li><span><a href={u.Url}>{u.UserName}</a>{designator}</span></li>
                    })
                }
            </ul>
        )
    }

    renderUnread() {
        return (
            <ul className="users" style={{ display: "block" }}>
                { this.props.unread.map(u => {
                        var designator = null;
                        if (u.PermanentUserType == 1 || u.PermanentUserType == 2) {
                            designator = (<span className="designator small-text">{(u.PermanentUserType == 1 ? '(CONTR)' : (u.PermanentUserType == 2 ? '(O/GOV)' : ''))}</span>);
                        }
                        
                        return <li><span><a href={u.Url}>{u.UserName}</a>{designator}</span></li>
                    }
                )}
            </ul>
        )
    }

    render() {
        var designator = null;
        if (this.props.permanentUserType == 1 || this.props.permanentUserType == 2) {
            designator = (<div className="designator"><span><span className="fn-icon fn-icon-user-permanent"/>{(this.props.permanentUserType == 1 ? 'Contractor' : (this.props.permanentUserType == 2 ? 'Other Gov' : ''))}</span></div>);
        }

        return (
            <div className="reply content" style={{maxHeight: this.state.height, transition: "max-height 2s ease-in", overflow: "hidden" }}>
                <div className="profilePic">
                    <a href={this.props.url}>
                        <img src={this.props.image} alt={this.props.name} />
                    </a>
                </div>
                <div className="discussionText">
                    <a className="person" href={this.props.url}>
                        {this.props.name}
                    </a>
                    { designator }
                    <p className="info" timestamp={this.props.time}>
                        {this.state.time.tz("Australia/NSW").format("DD MMM YYYY [at] HH:mm")}
                    </p>
                    <div dir="auto" dangerouslySetInnerHTML={{ __html: this.props.content }} className="richtext" />
                    {this.props.unread && this.props.unread.length > 0
                        || this.props.read && this.props.read.length > 0
                        ? [
                            <div key="receipts-read" rel="messages-receipts" className={`messages-receipts-read${this.state.showRead ? " showAll" : ""}`}>
                                <p className="summary">
                                    <a href="#" onClick={this.toggleShowRead.bind(this)}>Message seen by {this.props.read.length}</a>
                                </p>
                                { this.state.showRead && this.renderRead() }
                            </div>,
                            <div key="receipts-unread" rel="messages-receipts" className={`messages-receipts-unread${this.state.showUnread ? " showAll" : ""}`}>
                                <p className="summary">
                                    <a href="#" onClick={this.toggleShowUnread.bind(this)}>Message not seen by {this.props.unread.length}</a>
                                </p>
                                { this.state.showUnread && this.renderUnread() }
                            </div>,
                        ]
                        : null}
                </div>
            </div>
        )
    }
}

export default Discussion

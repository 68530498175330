import React, {
    FC,
    useEffect,
    useState,
}                           from "react";
import _	                from 'lodash';

import { 
    useMutation,
}                           from "@apollo/client";

import {
    createBookmarkVariables,
    forumDiscussionResult,
    removeBookmarkVariables,
}                           from "../../../types/graphqlTypes";

import SvgIcon              from '../../icons';
import PromptActions        from "../../modules/prompt/actions/PromptActions";
import {PostType}			from "../../modules/activityfeed/models/Post";
import GroupTabsConstants from "../constants/GroupTabsConstants";

const createBookmarkGql = require("../../../gql/createBookmark.gql");
const removeBookmarkGql = require("../../../gql/removeBookmark.gql");

interface IBookmarkToggleProps {
    bookmarkId: string | null | undefined;
    post: any;
    className: string | null;
    discussionResult: forumDiscussionResult | null; // Note:  This is currently only used by the PostType.DISCUSSION bookmark
    groupId: string | null; // Note:  This is currently only used by the PostType.DISCUSSION bookmark
}

export const BookmarkToggle : FC<IBookmarkToggleProps> = (props: IBookmarkToggleProps) => {
    const [createBookmark] = useMutation(createBookmarkGql);
    const [removeBookmark] = useMutation(removeBookmarkGql);
    const [bookmarkId, setBookmarkId] = useState<string | null | undefined>(props.bookmarkId);

    useEffect(() => {
        setBookmarkId(props.bookmarkId);
    }, [props.bookmarkId]);

    const bookmarkType = props.post ? props.post.type : PostType.DISCUSSION;
  
    const toggle = (e) => {
        if (bookmarkId) {
            removeBookmark({
                variables: {
                    id: bookmarkId,
                } as removeBookmarkVariables
            }).then((savedList) => {
                const errors = savedList?.data?.createBookmark?.errors;

                if (errors && errors.length > 0) {
                    throw errors[0];
                }

                setBookmarkId(null);
                window.dispatchEvent(new Event("refreshLatestBookmarks"));
            }).catch(err => {
                PromptActions.displayFriendlyErrorPrompt(err);

            }).finally();
        } else {
            const variables = {} as createBookmarkVariables;

            switch (bookmarkType) {
                case PostType.GROUP:
                case PostType.EVENT:
                    variables.activityId = props.post.id.toString();
                    break;

                case PostType.EVENT_CREATED:
                case PostType.EVENT_UPDATED:
                case PostType.EVENT_CANCELLED:
                    variables.eventId = props.post.relatedId;
                    break;

                case PostType.JOB:
                    variables.jobId = props.post.id;
                    break;

                case PostType.NEWS:
                    variables.newsArticleId = props.post.id;
                    break;
                    
                case PostType.GROUP_CREATED:
                case PostType.GROUP_DELETED:
                    break;
                
                case PostType.DISCUSSION:
                    variables.discussionId = props.discussionResult.discussion.discussionId;
                    break;
            }

            if(bookmarkType != PostType.DISCUSSION)
            {
                variables.url = props.post?.target.url;
            } else {
                // See ForceNet.Business.Model.InternalLinkHelper.LinkToForumDiscusssion(..)
                variables.url = '/community/viewgroup?Id=' + props.groupId + '&' + GroupTabsConstants.DISCUSSION_ID_PARAM + '=' + props.discussionResult.discussion.discussionId + '&' + GroupTabsConstants.VIEW_PARAM + '=' + GroupTabsConstants.DISCUSSIONS_TAB;
            }

            createBookmark({
                variables
            }).then((savedList) => {
                const errors = savedList?.data?.createBookmark?.errors;

                if (errors && errors.length > 0) {
                    throw errors[0];
                }

                setBookmarkId(savedList?.data?.createBookmark?.value);
                window.dispatchEvent(new Event("refreshLatestBookmarks"));
            }).catch(err => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally();
        }
    }

    return (
        <a role='button' aria-label="Bookmark" onClick={toggle} className={props.className}>
            <SvgIcon icon={(bookmarkId ? "icon-bookm-ark" : "icon-bookm-ark-o")} width={props.width} height={props.height} className={props.className}/>
        </a>
    )
}

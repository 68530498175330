import React from "react"

class Button extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div
                id="phbody_0_phcontent_0_NewMessageButtonContainer"
                className={`action ${this.props.primaryClass}`}>
                <a
                    href="javascript:void(0)"
                    onClick={this.props.action}
                    className={this.props.btnClass}>
                    {this.props.btnText}
                </a>
            </div>
        )
    }
}

export default Button

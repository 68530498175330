
import { Enum } from "../utils/LanguageUtils";
import DateTimeUtils from "../utils/DateTimeUtils";
import { UserFactory, SimpleUser } from "./User";
import { DynamicImageFactory, DynamicImage } from "./DynamicImage";


/**
 * The file model
 */
export class File {

  id: string; // The file's id
  contentLength: number; // The file's size
  dateUploaded: Date; // Date the file was uploaded
  title: string;
  description: string;
  fileType: string; // The file's type
  fileName: string; // The file's original filename
  canDelete: boolean; // User's ability to delete file
  canEdit: boolean; // User's ability to edit file
  uploader: SimpleUser; // User who uploaded the file
  image: DynamicImage | null | undefined; // Optional. This field will always be null if the file this represents is not an image.
  virusName: string;
  scanning: boolean;
  url: string;
  hasVideoStream: boolean;
  staticMp4Filename:string;
  thirtyDaysViews: number;
  allViews: number;
  duration: number;
  isAccessRestricted: boolean;

  constructor(id: string, contentLength: number, dateUploaded: Date, title: string, description: string, fileType: string, canDelete: boolean, canEdit: boolean, uploader: SimpleUser, image: DynamicImage | null | undefined, virusName: string, url: string, hasVideoStream: boolean, thirtyDaysViews: number, allViews: number, staticMp4Filename: string, duration: number, fileName: string, isAccessRestricted: boolean) {
    this.id = id;
    this.contentLength = contentLength;
    this.dateUploaded = dateUploaded;
    this.title = title;
    this.description = description;
    this.fileType = fileType;
    this.canDelete = canDelete;
    this.canEdit = canEdit;
    this.uploader = uploader;
    this.image = image;
    this.virusName = virusName;
    this.scanning = false;
    this.url = url;
    this.hasVideoStream = hasVideoStream;
    this.thirtyDaysViews = thirtyDaysViews;
    this.allViews = allViews;
    this.staticMp4Filename=staticMp4Filename;
    this.duration = duration;
    this.fileName=fileName;
    this.isAccessRestricted = isAccessRestricted;
  }

  isEmpty(): boolean {
    return false;
  }
}

export class FileFactory {


  static fileFromObject(obj: any): File | null | undefined {
    if (obj == null) {return null;}

    let id: string | null | undefined = _.isString(obj.Id) ? obj.Id : null;
    let contentLength: number | null | undefined = _.isNumber(obj.ContentLength) ? obj.ContentLength : null;
    let title: string | null | undefined = _.isString(obj.Title) ? obj.Title : null;
    let description: string | null | undefined = _.isString(obj.Description) ? obj.Description : null;
    let fileType: string | null | undefined = _.isString(obj.FileType) ? obj.FileType : null;
    let fileName: string | null | undefined = _.isString(obj.FileName) ? obj.FileName : null;
    let canDelete: boolean | null | undefined = _.isBoolean(obj.CanDelete) ? obj.CanDelete : null;
    let canEdit: boolean | null | undefined = _.isBoolean(obj.CanEdit) ? obj.CanEdit : null;
    let uploader: SimpleUser | null | undefined = UserFactory.simpleUserFromObject(obj.Uploader || null);
    let image: DynamicImage | null | undefined = DynamicImageFactory.dynamicImageFromUrl(obj.ImageUrl || null);
    let dateUploaded: Date | null | undefined = null;
    let virusName: string | null | undefined = _.isString(obj.VirusName) ? obj.VirusName : "";
    let url: string | null | undefined = _.isString(obj.Url) ? obj.Url : "";
    let hasVideoStream: boolean | null | undefined = _.isBoolean(obj.HasVideoStream) ? obj.HasVideoStream : false;
    let staticMp4Filename: string | null | undefined = _.isString(obj.StaticMp4Filename) ? obj.StaticMp4Filename : "";
    let thirtyDaysViews: number | null | undefined = _.isNumber(obj.NumberOfLastThirtyDaysViews) ? obj.NumberOfLastThirtyDaysViews : 0;
    let allViews: number | null | undefined = _.isNumber(obj.NumberOfAllViews) ? obj.NumberOfAllViews : 0;
    let duration: number | null | undefined = _.isNumber(obj.Duration) ? obj.Duration : null;
    let isAccessRestricted: boolean | null | undefined = _.isBoolean(obj.IsAccessRestricted) ? obj.IsAccessRestricted : null;
    if (obj.DateUploaded) {
      dateUploaded = DateTimeUtils.dateFromISOString(obj.DateUploaded);
    }

    if (id == null) {return null;}
    if (contentLength == null) {return null;}
    if (dateUploaded == null) {return null;}
    if (title == null) {return null;}
    if (description == null) {return null;}
    if (fileType == null) {return null;}
    if (canDelete == null) {return null;}
    if (canEdit == null) {return null;}
    if (uploader == null) {return null;}
    if (virusName == null) {return null;}
    if (url == null) {return null;}
    if (hasVideoStream == null) {return null;}

    return new File(id, contentLength, dateUploaded, title, description, fileType, canDelete, canEdit, uploader, image, virusName, url, hasVideoStream, thirtyDaysViews, allViews, staticMp4Filename, duration, fileName, isAccessRestricted);
  }
}
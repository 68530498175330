
import _ from "lodash";
import moment from "moment";
import StringUtils from "../../../../core/utils/StringUtils";

export class AusGovHoliday {

  Jurisdictions: Array<Jurisdiction>;

  constructor() {
    this.Jurisdictions = [];
  }

  GetHolidaysByDate(date: Date) {
    let events = [];
    for (var i = 0; i < this.Jurisdictions.length; i++) {
      for (var j = 0; j < this.Jurisdictions[i].GovEventRoots.length; j++) {
        for (var k = 0; k < this.Jurisdictions[i].GovEventRoots[j].GovEvents.length; k++) {
          const event = this.Jurisdictions[i].GovEventRoots[j].GovEvents[k];
          if (moment(date).format("DDMMYYYY") === event.Date.format("DDMMYYYY")) {
            events.push(event);
          }
        }
      }
    }

    // Concatenate states if holiday title is same
    let results = [];
    if (events.length > 0) {
      events = _.sortBy(events, "HolidayTitle");

      let tmpResults = {};
      _.forEach(events, r => {
        if (!tmpResults[r.HolidayTitle]) {
          tmpResults[r.HolidayTitle] = [];
        }
        tmpResults[r.HolidayTitle].push(r.Jurisdiction);
      });
      _.forEach(_.keys(tmpResults), k => {
        results.push(`${k} (${tmpResults[k].join(",")})`);
      });
    }

    return results;
  }

}

export class Jurisdiction {

  JurisdictionName: String;
  DateUpdated: Date;
  GovEventRoots: Array<GovEventRoot>;

  constructor() {
    this.JurisdictionName = this.DateUpdated = null;
    this.GovEventRoots = [];
  }
}

export class GovEventRoot {

  GovEvents: Array<GovEvent>;

  constructor() {
    this.GovEvents = [];
  }
}

export class GovEvent {

  Jurisdiction: String;
  Year: Number;
  RawDate: Number;
  Date: Date;
  HolidayTitle: String;

  constructor() {
    this.Jurisdiction = this.Year = this.RawDate = this.Date = this.HolidayTitle = null;
  }

  DisplayText() {
    return `${this.HolidayTitle} (${this.Jurisdiction})`;
  }
}

export class AusGovHolidayFactory {

  static GovEventFromObject(o) {
    let govEvent = new GovEvent();
    govEvent.Jurisdiction = o.Jurisdiction.toUpperCase();
    govEvent.Year = o.Year;
    govEvent.RawDate = o.RawDate;
    govEvent.Date = moment(o.Date).isValid ? moment(o.Date, "DD/MM/YYYY") : null;
    govEvent.HolidayTitle = StringUtils.toTitleCase(o.HolidayTitle.trim());

    return govEvent;
  }

  static GovEventRootFromObject(o) {
    let govEventRoot = new GovEventRoot();
    govEventRoot.GovEvents = _.map(o.Event, e => {
      return this.GovEventFromObject(e);
    });

    return govEventRoot;
  }

  static JurisdictionFromObject(o) {
    let jurisdiction = new Jurisdiction();
    jurisdiction.JurisdictionName = o.JurisdictionName;
    jurisdiction.DateUpdated = moment(o.DateUpdated).isValid ? moment(o.DateUpdated, "DD/MM/YYYY") : null;
    jurisdiction.GovEventRoots = _.map(o.Events, e => {
      return this.GovEventRootFromObject(e);
    });

    return jurisdiction;
  }

  static AusGovHolidayFromObject(o) {
    let ausGovHoliday = new AusGovHoliday();
    ausGovHoliday.Jurisdictions = _.map(o.Jurisdictions, j => {
      return this.JurisdictionFromObject(j);
    });

    return ausGovHoliday;
  }
}
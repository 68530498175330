import React, {
    FC,
    useState,
} from "react";
import Modal from '../../../core/components/modal/Modal';
import BtnClose from '../../../core/components/BtnClose';
import _ from 'lodash';
import { UserRightsType } from "../model/UserRightsType";
import { Enum } from "../../../core/utils/LanguageUtils";

interface IConfirmationModalProps {
    displayModal: boolean,
    title: string,
    heading: string,
    reasonMessage: string,
    confirmMessage: string,
    userRights: Enum,
    onConfirm: (string) => void,
    onRequestClose: (e) => void,
}

export const ConfirmationModal: FC<IConfirmationModalProps> = (props: IConfirmationModalProps) => {
    const [reason, setReason] = useState("");
    const [confirmEnabled, setConfirmEnabled] = useState((props.userRights == UserRightsType.Author) ? true : false);

    const onReasonChange = (e) => {
        var reason = e.target.value;
        setReason(reason);
        setConfirmEnabled(reason != "");
    }
    const onConfirmDelete = (e) => {
        props.onConfirm(reason);
    }

    return (
        <div>
            <Modal active={props.displayModal} onRequestsClose={props.onRequestClose}>
                <div className="modal-header">
                    <BtnClose onClick={props.onRequestClose} />
                    <h2 className="modal-title">{props.heading}</h2>
                </div>
                <div className="modal-content">
                    <p><span className="bold">Discussion:</span> {props.title}</p>
                    {props.userRights != UserRightsType.Author &&
                        <div className="form-fields">
                            <label>{props.reasonMessage}:
                                <abbr className="req" title="required">*</abbr></label>
                            <textarea value={reason} onChange={onReasonChange}></textarea>
                        </div>
                    }
                    {props.userRights == UserRightsType.Author &&
                        <p>{props.confirmMessage}</p>
                    }
                </div>
                <div className="modal-actions">
                    <button role="button" className="btn btn-default" onClick={props.onRequestClose}>Cancel</button>
                    &nbsp;&nbsp;
                    <button role="button" className={`btn btn-primary${confirmEnabled ? '' : ' disabled'}`}
                        onClick={onConfirmDelete}>Confirm</button>
                </div>
            </Modal>
        </div>
    )
}
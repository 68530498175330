
import React from "react";
import _ from "lodash";
import GroupReportHeatmap from "./GroupReportHeatmap";
import PieChart from "./PieChart";
import "./usage.css";

class GroupReportUsage extends React.Component {


  constructor(props) {
    super(props);
    this.state = this.chartData(this.props.reportData);
  }

  componentWillReceiveProps(newProps) {
    this.setState(this.chartData(newProps.reportData));
  }

  chartData(reportData) {
    return {
      userChartData: {
        type: 'pie',
        columns: _.map(reportData.UserDetail, u => [u.Title, u.Count])
      },
      deviceChartData: {
        type: 'pie',
        columns: _.map(reportData.DeviceDetail, u => [u.Title, u.Count])
      }
    };
  }

  render() {
    const totalPageView = this.props.reportData.TotalPageView;
    const totalPageViewUnique = this.props.reportData.TotalPageViewUnique;
    const days = this.props.reportData.Dates != null ? this.props.reportData.Dates.length : 1;
    const averagePageView = Math.round(this.props.reportData.TotalPageView / days);
    let userDetailTotal = _.reduce(this.props.reportData.UserDetail, (acc, u) => acc + u.Count, 0);
    if (userDetailTotal == 0) {userDetailTotal = 1;}
    let deviceDetailTotal = _.reduce(this.props.reportData.DeviceDetail, (acc, u) => acc + u.Count, 0);
    if (deviceDetailTotal == 0) {deviceDetailTotal = 1;}

    return <div className="panel">
                <div className="panel-body report-usage">
                    <h2>Usage</h2>
                    <p>
                    Some general information about your members and how they use the group.
                    </p>
                    <div className="usage-panels row">
                        <div className="heatmap-panel">
                            <div>
                                <h3>Page views</h3>
                                <GroupReportHeatmap reportData={this.props.reportData} />
                                <table className='legend'>
                                    <caption>Data summary</caption>
                                    <tr>
                                        <td>{totalPageView}</td>
                                        <th>Page views</th>
                                    </tr>
                                    <tr>
                                        <td>{totalPageViewUnique}</td>
                                        <th>Unique page views</th>
                                    </tr>
                                    <tr>
                                        <td>{averagePageView}</td>
                                        <th>Avg. page views per day</th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="pie-panel">
                            <div>
                                <h3>Service</h3>
                                <PieChart data={this.state.userChartData} />
                                <table className='legend'>
                                    <caption>Data summary</caption>
                                    {_.map(this.props.reportData.UserDetail, u => <tr>
                                            <td>{Math.round(100 * u.Count / userDetailTotal)}%</td>
                                            <th>{u.Title}</th>
                                        </tr>)}
                                </table>
                            </div>
                        </div>
                        <div className="pie-panel">
                            <div>
                                <h3>Device</h3>
                                <PieChart data={this.state.deviceChartData} />
                                <table className='legend'>
                                    <caption>Data summary</caption>
                                    {_.map(this.props.reportData.DeviceDetail, u => <tr>
                                            <td>{Math.round(100 * u.Count / deviceDetailTotal)}%</td>
                                            <th>{u.Title}</th>
                                        </tr>)}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
			</div>;
  }
}

export default GroupReportUsage;
import React, {
    FC,
    useState,
    useEffect,
    useRef,
} from "react";
import _	             from 'lodash';

import Modal			 from "../../../core/components/modal/Modal";
import BtnClose			 from "../../../core/components/BtnClose";
import Localisation		 from "../../../core/resources/Localisation";
import { GroupSelector } from "./GroupSelector";

import { useLazyQuery, useMutation } from "@apollo/client";
import Pluralize from "../../../vendor/typescript-pluralize/index";

import {
    getGroupLists_groupLists,
    saveGroupListVariables,
    deleteGroupListVariables,
} from "../../../../types/graphqlTypes";

import "./PostList.css";

import SvgIcon from '../../../icons';
import PromptActions from "../../prompt/actions/PromptActions";

const getGroupListsGql = require("../../../../gql/getGroupLists.gql");
const saveGroupListGql = require("../../../../gql/saveGroupList.gql");
const deleteGroupListGql = require("../../../../gql/deleteGroupList.gql");

const makePlural = (word: string, count: number) => count != 1 ? Pluralize.plural(word) : word;

type CallbackFunction = () => void;
type CallbackFunctionItem = (item: any, index: number) => void;
type CallbackFunctionDelete = (item: any) => void
type CallbackFunctionArray = (array: any[]) => void;

interface IPostListProps {
    active: boolean,
    onClose: CallbackFunction,
    onSelectList: CallbackFunctionItem,
    onDeleteList: CallbackFunctionDelete,
    selectedGroupId: string,
    selectedGroupListIndex: number,
    groupType: string
}

export const PostList : FC<IPostListProps> = (props: IPostListProps) => {
    const [getGroupLists, { loading, data, error, networkStatus, refetch }] = useLazyQuery(getGroupListsGql);
    const [saveGroupList] = useMutation(saveGroupListGql);
    const [deleteGroupList] = useMutation(deleteGroupListGql);
    const groupLists: Array<getGroupLists_groupLists> = data?.groupLists;
    const bottomRef = useRef<HTMLDivElement>();
    const [selectedIndex, setSelectedIndex] = useState(props.selectedGroupListIndex);
    const [toggleIndex, setToggleIndex] = useState(-1);
    const [editIndex, setEditIndex] = useState(-1);
    const [editSelectedGroups, setEditSelectedGroups] = useState([]);
    const [name, setName] = useState("");
    const [addMode, updateAddMode] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (props.selectedGroupId) {
            getGroupLists({
                variables: {
                    groupId: props.selectedGroupId
                }
            });
        }
    }, [props.selectedGroupId]);

    useEffect(() => {
        if (addMode) {
            setTimeout(() => {
                bottomRef.current.scrollIntoView({
                    behavior: "smooth"
                });
            }, 100);

        }
    }, [addMode])

    useEffect(() => {
        setSelectedIndex(props.selectedGroupListIndex);
    }, [props.active]);

    const onRequestClose = (e) => {
        props?.onClose();

        setToggleIndex(-1);
        setEditIndex(-1);
        updateAddMode(false);
    };

    const onSelectList = (e) => {
        if (editIndex < 0 && selectedIndex >= 0) {
            props?.onSelectList(groupLists[selectedIndex], selectedIndex);
            onRequestClose(e);
        }
    }

    const blur = () => {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }

    const toggleView = (index) => {
        if (editIndex < 0 && !addMode) {
            setToggleIndex(toggleIndex !== index ? index : -1);
        }

        blur();
    }

    const edit = (index) => {
        if (!addMode) {
            if (editIndex < 0) {
                setToggleIndex(index);
                setName(groupLists[index].name);
                setEditSelectedGroups(groupLists[index].groups);
                setEditIndex(index);
                setSelectedIndex(index);
            }
        }

        blur();
    }

    const deleteList = (index) => {
        if (!saving) {
            const groupList = groupLists[index];
            const selectedGroupListId = selectedIndex >= 0 ? groupLists[selectedIndex].groupListId : null;
            const editGroupListId = editIndex >= 0 ? groupLists[editIndex].groupListId : null;
            const toggleGroupListId = toggleIndex >= 0 ? groupLists[toggleIndex].groupListId : null;

            setSaving(true);
            deleteGroupList({
                variables: {
                    groupListId: groupList.groupListId,
                    groupId: props.selectedGroupId,
                } as deleteGroupListVariables
            }).then((data) => {
                const errors = data?.data?.deleteGroupList?.errors;

                if (errors && errors.length > 0) {
                    throw errors[0];
                }

                props?.onDeleteList(groupList.groupListId);

                refetch().then((fetchedData) => {
                    const refetchedGroupList: Array<getGroupLists_groupLists> = fetchedData.data.groupLists;

                    setSelectedIndex(refetchedGroupList.findIndex(i => i.groupListId == selectedGroupListId));
                    setEditIndex(refetchedGroupList.findIndex(i => i.groupListId == editGroupListId));
                    setToggleIndex(refetchedGroupList.findIndex(i => i.groupListId == toggleGroupListId));
                });
            }).catch(err => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally(() => setSaving(false));
        }
    }

    const remove = (index) => {
        if (!addMode && editIndex < 0) {
            PromptActions.displayPromptWithActions(Localisation.localisedStringFor("PostGroupListDelete"), Localisation.localisedStringFor("ConfirmRemoveGroupList"), [{
                label: Localisation.localisedStringFor('Cancel'),
                className: 'btn btn-default'
            }, {
                label: Localisation.localisedStringFor('Confirm'),
                handler: () => deleteList(index),
                className: 'btn btn-primary'
            }]);
        }

        blur();
    }

    const okEdit = (index) => {
        const isValid = name && editSelectedGroups && editSelectedGroups.length > 0;

        if (index == editIndex && isValid && !saving) {
            setSaving(true);
            saveGroupList({
                variables: {
                    groupListId: index >= 0 ? groupLists[index].groupListId : null,
                    groupId: props.selectedGroupId,
                    name: name,
                    groupItemIdList: editSelectedGroups.map(i => i.groupId)
                } as saveGroupListVariables
            }).then((savedList) => {
                const errors = savedList?.data?.saveGroupList?.errors;

                if (errors && errors.length > 0) {
                    throw errors[0];
                }

                refetch().then((fetchedData) => {
                    const refetchedGroupList: Array<getGroupLists_groupLists> = fetchedData.data.groupLists;
                    const index = refetchedGroupList.findIndex(i => i.groupListId == savedList.data.saveGroupList.groupListId);

                    setToggleIndex(-1);
                    setSelectedIndex(index);
                });
                setEditIndex(-1);
                updateAddMode(false);
            }).catch(err => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally(() => setSaving(false));
        }

        blur();
    }
 
    const cancelEdit = (index) => {
        if (addMode) {
            updateAddMode(false);
        } else if (index == editIndex) {
            setEditIndex(-1);
        }

        blur();
    }
    
    const onSetSelectedGroups = (groups) => {
        setEditSelectedGroups(groups);
    }

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e?.target?.value);
    }

    const setAddMode = () => {
        if (editIndex < 0) {
            setName("");
            updateAddMode(true);

            setEditIndex(-1);
            setToggleIndex(-1);
        }
    }

    let selectButtonClass = "btn btn-primary";

    if (editIndex >= 0 || selectedIndex < 0 || addMode) {
        selectButtonClass += " disabled";
    }

    const renderGroupListItem = (i: getGroupLists_groupLists, index: number) => {
        const listClassName = (toggleIndex == index || editIndex == index && !addMode ? "expanded" : "")
            + (editIndex == index || index == -1 ? " edit" : "")
            + (editIndex < 0 && !addMode ? "enabled" : "")
            + (selectedIndex == index ? " selected-list" : "");
        const viewClassName = editIndex == index || index == -1 ? "fn-icon invisible" : "fn-icon";
        const removeClassName = editIndex == index || index == -1 ? "fn-icon invisible fn-margin-left-small" : "fn-icon fn-margin-left-small";
        const isValid = name && editSelectedGroups && editSelectedGroups.length > 0;
        const groupsClassName = "groups" + (index == -1 ? " add" : "");
        const editButtonClassName = !isValid ? "btn btn-primary disabled" : "btn btn-primary";
        const cancelButtonClassName = "btn btn-default";

        return <li key={i.groupListId} className={listClassName}>
            { index != -1 && <input type="radio" id={i.groupListId}
                value={i.groupListId} 
                checked={index == selectedIndex && !addMode}
                onChange={() => editIndex == -1 && !addMode && setSelectedIndex(index)}>
            </input>}
            { index != editIndex && <label htmlFor={i.groupListId}>{ i.name } ({ i.groups.length } { makePlural("group", i.groups.length) })</label> }
           

            <span className="tools">
             
                
                { index != -1 && <a href="#" onClick={() => toggleView(index)} className="fn-margin-left-small"><SvgIcon icon={ toggleIndex == index ? "icon-arrow-up" : "icon-arrow-down" } width={16} height={16}/></a> }
            </span>
            <div className={ groupsClassName }>
                {
                    toggleIndex == index && editIndex < 0 && !addMode
                        ?  i.groups.map(g => {
                            return (
                                <div key={ g.groupId } className="group">
                                    <div><h3>{ g.name }</h3></div>
                                    { g.primaryAdmin && <div><strong>Primary admin:</strong> { g.primaryAdmin.fullName } ({ g.primaryAdmin.rank?.title })</div> }
                                    <div className="description">{ g.rawDescription }</div>
                                    <div><strong>{ g.numberOfMembers } { makePlural("member", g.numberOfMembers) }</strong></div>
                                </div>
                            );
                        })
                        : null
                }
               
            </div>
        </li>;
    };

    const types = [...props.groupType == "Service" ? ["Service"] : [], ...["Official", "Professional"]];

    return (
        <Modal active={props.active} onRequestsClose={onRequestClose}>
            <div className="modal-header">
                <BtnClose onClick={onRequestClose} />
                <h2 className="modal-title">{Localisation.localisedStringFor("PostGroupList")}</h2>
            </div>
            <div className="modal-content modal-group-list">
                <h2>Current lists</h2>
                { error && <div>{ Localisation.localisedStringFor('ErrorGenericMsg') }</div>}
                <ul className="postList">
                    {
                        groupLists?.map((i, index) => {
                            return renderGroupListItem(i, index);
                        })
                    }
                    
                </ul>
                
            </div>
            <div className="modal-actions">
                <button role="button" className="btn btn-default" onClick={onRequestClose}>Cancel</button>
                &nbsp;&nbsp;
                <button role="button" className={selectButtonClass} onClick={onSelectList}>Select list</button>
            </div> 
        </Modal>
    );
}

import React                                              from "react";
import { EditorState, convertToRaw, ContentState }        from "draft-js";
import { Editor }                                         from "react-draft-wysiwyg";
import draftToHtml                                        from 'draftjs-to-html';
import { stateFromHTML }                                  from 'draft-js-import-html';
import HtmlUtils                                          from '../../core/utils/HtmlUtils';
import htmlToDraft                                        from 'html-to-draftjs';
import AppDelegate		                                    from '../../core/AppDelegate';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const toolbarParams = {
  options: ['inline', 'list', 'textAlign'],
  inline:{
    inDropdown: false,
    options: ['bold', 'italic', 'underline']
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered']
  },
  textAlign: {
    inDropdown: false,
    options: ['center']
  }
};

export default class RichTextEditor extends React.Component {

  constructor(props) {
    super(props);
    
    let editorState = EditorState.createEmpty();

    if (this.props.value && AppDelegate.appSettings.ieVersion > 9){
      const { contentBlocks, entityMap } = htmlToDraft(this.props.value);
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      editorState = EditorState.createWithContent(contentState);
    } else if(this.props.value){
      editorState = EditorState.createWithContent(stateFromHTML(this.props.value));
    }
    this.state = { editorState: editorState };
    
		this._rootDOMNode = null;
		this._onRootDOMNode = this._onRootDOMNode.bind(this);
  }

	_onRootDOMNode(node) {
    this._rootDOMNode = node;
	}

	blur() {
		if (this._rootDOMNode){
      this._rootDOMNode.editor.blur();
		}
	}

	focus() {
		if (this._rootDOMNode){
      this._rootDOMNode.editor.focus();
		}
	}

	reset(){
		this.state.editorState = EditorState.createEmpty();
  }

  _onEditorStateChange(editorState){
    if (editorState !== this.state.editorState){
      this.setState({
        editorState: editorState
      });

      //Reflect to parent node if isBindBack is true from bootstrapper
      if (this.props.isBindBack){
        let nativeElement = this.props.fnNode;
        nativeElement.value = this.getHtmlContent();
      }
    }
  }

  /**
   * Return html content
   * @returns {string}
   */
  getHtmlContent(){
    return draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
  }

  /**
   * Return inner text content
   * @returns {string}
   */
  getInnerText(){
    return HtmlUtils.htmlToText2(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
  }

  render() {
    const { editorState } = this.state;
    return (
      <div {...this.props} onKeyUp={this.props.onKeyUp}>
        <Editor ref = {this._onRootDOMNode}
          toolbar={toolbarParams}
          editorState={editorState}
          onEditorStateChange={this._onEditorStateChange.bind(this)}
          placeholder={this.props.placeholder}
          stripPastedStyles={true}
        />
      </div>
    );
  }
}

import React 				from 'react';
import moment               from "moment";

import PromptActions		from '../prompt/actions/PromptActions';
import BtnUpload			from '../../core/components/BtnUpload';
import AppDelegate			from '../../core/AppDelegate';
import Localisation			from '../../core/resources/Localisation';
import QualificationFile from './QualificationFile';

interface IQualification {
    uniqueId: string;
    typeId: string;
    type: string;
    id: string;
    description: string;
    recordedInPMKeyS: Boolean;
    issueDate: string;
    issuingInstitution: string;
    yearEarned: Number;
    firstMajor: string;
    secondMajor: string;
    firstMinor: string;
    secondMinor: string;
    employeeName: string;
    certificationNumber: string;
    rtoCode: string;
    files: any[];
    validation: any;
}

interface Props {
    className?: string;
	item: IQualification | null;
    options: any[];
    onChange: Function;
    onDelete: Function;
    validate: Boolean;
    editMode: Boolean;
}

interface State extends IQualification {
    yearEarnedString: string;
    editMode: Boolean;
}

export default class Qualification extends React.Component<Props, State> {
	constructor(props) {
        super(props);

        this.state = {
            uniqueId: props.item.uniqueId,
            typeId: props.item.typeId,
            type: props.item.type,
            id: props.item.id,
            description:  props.item.description,
            recordedInPMKeyS: props.item.recordedInPMKeyS,
            issueDate: props.item.issueDate || "",
            issuingInstitution: props.item.issuingInstitution || "",
            yearEarned: props.item.yearEarned || "",
            yearEarnedString: props.item.yearEarned && props.item.yearEarned.toString() || "",
            firstMajor: props.item.firstMajor || "",
            secondMajor:props.item.secondMajor || "",
            firstMinor: props.item.firstMinor || "",
            secondMinor:props.item.secondMinor || "",
            employeeName: props.item.employeeName || "",
            certificationNumber: props.item.certificationNumber || "",
            rtoCode: props.item.rtoCode || "",
            files: props.item.files || [],
            validation: props.item.validation,
            editMode: props.item.editMode === true
        };
    }

    get qualifications() {
        return this.state.typeId && this.props.options.find(i => i.value == this.state.typeId)?.options || [];
    }

    emit() {
        let validation = this.validate(true);

        this.setState({
            validation
        });

        if (this.props.onChange) {
            this.props.onChange({
                uniqueId: this.state.uniqueId,
                typeId: this.state.typeId,
                type: this.state.type,
                id: this.state.id,
                description:  this.state.description,
                recordedInPMKeyS: this.state.recordedInPMKeyS,
                issueDate: this.state.issueDate,
                issuingInstitution: this.state.issuingInstitution,
                yearEarned: this.state.yearEarned,
                yearEarnedString: this.state.yearEarned && this.state.yearEarned.toString() || "",
                firstMajor: this.state.firstMajor,
                secondMajor:this.state.secondMajor,
                firstMinor: this.state.firstMinor,
                secondMinor:this.state.secondMinor,
                employeeName: this.state.employeeName,
                certificationNumber: this.state.certificationNumber,
                rtoCode: this.state.rtoCode,
                files: this.state.files || [],
                validation
            });
        }
    }

    formGroupClassName(valid) {
        return "form-group row small-16 large-16 padding-1" + (!valid ? " has-error" : "");
    }

    onDeleteFile = (id) => {
        let index = this.state.files.findIndex(i => i.Id === id);

        if (index >= 0) {
			this.state.files.splice(index, 1);

            this.setState({
                files: this.state.files,
            }, this.emit);
        }
    }

    onDeleteItem = (e) => {
        if (this.props.onDelete) {
            this.props.onDelete({
                uniqueId: this.state.uniqueId
            });
        }
    }

    onEditItem = (e) => {
        this.setState({ editMode: true }, this.emit);
    }

    onChangeType = (e) => {
        this.setState({
            typeId: e.target.value,
            type: e.target.label,
            id: ""
        }, this.emit);
    }

    onChangeDescription = (e) => {
        this.setState({
            id: e.target.value,
            description: e.target.label
        }, this.emit);
    }

    onChangeIssueDate = (e) => {
        this.setState({
            issueDate: e.target.value
        }, this.emit);
    }

    onChangeIssuingInstitution = (e) => {
        this.setState({
            issuingInstitution: e.target.value
        }, this.emit);
    }

    onChangeYearEarned = (e) => {
        if (e.target.validity.valid) {
            this.setState({
                yearEarnedString: e.target.value,
                yearEarned: e.target.value && parseInt(e.target.value) || 0,
            }, this.emit);
        }
    }

    onChangeFirstMajor = (e) => {
        this.setState({
            firstMajor: e.target.value,
        }, this.emit);
    }

    onChangeSecondMajor = (e) => {
        this.setState({
            secondMajor: e.target.value,
        }, this.emit);
    }

    onChangeFirstMinor = (e) => {
        this.setState({
            firstMinor: e.target.value,
        }, this.emit);
    }

    onChangeSecondMinor = (e) => {
        this.setState({
            secondMinor: e.target.value,
        }, this.emit);
    }

    onChangeEmployeeName = (e) => {
        this.setState({
            employeeName: e.target.value,
        }, this.emit);
    }

    onChangeCertificationNumber = (e) => {
        if (e.target.validity.valid) {
            this.setState({
                certificationNumber: e.target.value,
            }, this.emit);
        }
    }

    onChangeRtoCode = (e) => {
        if (e.target.validity.valid) {
            this.setState({
                rtoCode: e.target.value,
            }, this.emit);
        }
    }

    get year() {
        return moment().year().toString();
    }

    get isVocational() {
        return this.state.typeId && this.props.options?.find(i => i.value == this.state.typeId)?.isVocational;
    }

    _showUploadModal(){
    }

    _hideUploadModal() {
    }

	_onFileUploaded = (file, files) => {
        this.setState({
            files: [...this.state.files, {
				Id: file.Id,
                Filename: file.Title,
                FileType: file.FileType,
                Size: file.ContentLength,
                Url: file.Url
			}]
        }, this.emit);
	}

	_onFileUploadFailed = (err) => {
		PromptActions.displayFriendlyErrorPrompt(err);
	}
    
    _onFileUploadClose = () => {
		this._hideUploadModal();
    }

    validate(validate: Boolean) {
        let isVocational = this.isVocational;
        
        return {
            typeInvalid: validate && typeof(this.state.typeId) != "string",
            descriptionInvalid: validate && (typeof(this.state.id) != "string" || !this.state.id),
            yearEarnedInvalid: validate && !isVocational && this.state.yearEarned && (typeof(this.state.yearEarned) != "number" || (this.state.yearEarned !== 0 && this.state.yearEarned < 1900)) || false,
            firstMajorInvalid: false,
            secondMajorInvalid: false,
            firstMinorInvalid: false,
            secondMinorInvalid: false,
            issueDateInvalid: validate && this.state.issueDate && !moment(this.state.issueDate, "YYYY-MM-DD").isValid() || false,
            issuingInstitutionInvalid: validate && !isVocational && !this.state.issuingInstitution,
            employeeNameInvalid: validate && isVocational && !this.state.employeeName,
            certificationNumberInvalid: validate && isVocational && !this.state.certificationNumber,
            rtoCodeInvalid: validate && isVocational && !this.state.rtoCode
        };
    }

	_renderIcon(file) {
		let fileIcon = null;

        if (!file) {
			return null;
		}

		switch (file.FileType.toLowerCase()) {
			case 'word':
				fileIcon = 'icon-file-icon-doc';
				break;
			case 'excel':
				fileIcon = 'icon-file-icon-excel';
				break;
			case 'pdf':
				fileIcon = 'icon-file-icon-pdf';
				break;
			case 'powerpoint':
				fileIcon = 'icon-file-icon-powerpoint';
				break;
			case 'image':
				fileIcon = 'icon-file-icon-image';
				break;
			case 'csv':
				fileIcon = 'icon-file-icon-excel';
				break;
			case 'video':
				fileIcon = 'icon-file-icon-video';
				break;
			case 'audio':
				fileIcon = 'icon-file-icon-audio';
				break;				
		}


		return (<span className={`file-icon ${fileIcon}`} aria-hidden="true"></span>);
    }

    get typeName() {
        if (this.state.type) {
            return this.state.type;
        }

        if (this.state.typeId) {
            return this.props.options.find(i => i.value === this.state.typeId)?.label;
        }

        return "N/A";
    }

    get description() {
        if (this.state.description) {
            return this.state.description;
        }

        if (this.state.id) {
            return this.qualifications?.find(i => i.value === this.state.id)?.label;
        }

        return "N/A";
    }
    
    renderDisplay() {
        let isVocational = this.isVocational;

        return [ 
        <div key="left" style={{display:"flex", justifyContent:"space-between"}}>
            <div className="row small-16 large-16 padding-4">

                <div className="row small-16 large-16 row-to-column-tablet padding-1">
                    <div className="small-8 large-4">
                        <label>Qualification type:</label>
                    </div>
                    <div className="small-8 large-4">
                        {this.typeName}
                    </div>
                </div>

                <div className="row small-16 large-16 row-to-column-tablet padding-1">
                    <div className="small-16 large-4">
                        <label>Degree title:</label>
                    </div>
                    <div className="small-16 large-4">
                        {this.description}
                    </div>
                    <div className="small-16 large-4">
                        <label>Issue date:</label>
                    </div>
                    <div className="small-16 large-4">
                        {this.state.issueDate ? this.state.issueDate && moment(this.state.issueDate, "YYYY-MM-DD").format("DD/MM/YYYY") : "N/A"}
                    </div>
                </div>

                { !isVocational && <div className="small-16 large-16">
                    <div className="row small-16 large-16 row-to-column-tablet padding-1">
                        <div className="small-16 large-8">
                            <label>Issuing institution:</label>
                        </div>
                        <div className="small-16 large-8">
                            {this.state.issuingInstitution ? this.state.issuingInstitution : "N/A"}
                        </div>
                        <div className="small-16 large-8">
                            <label>Year earned:</label>
                        </div>
                        <div className="small-16 large-8">
                            {this.state.yearEarned ? this.state.yearEarned : "N/A"}
                        </div>
                    </div>
                </div>
                }
                { !isVocational && <div className="small-16 large-16">
                    <div className="row small-16 large-16 row-to-column-tablet padding-1">
                        <div className="small-16 large-8">
                            <label>First major:</label>
                        </div>
                        <div className="small-16 large-8">
                            {this.state.firstMajor ? this.state.firstMajor : "N/A"}
                        </div>
                        <div className="small-16 large-8">
                            <label>Second major:</label>
                        </div>
                        <div className="small-16 large-8">
                            {this.state.secondMajor ? this.state.secondMajor : "N/A"}
                        </div>
                    </div>
                </div>
                }
                { !isVocational && <div className="small-16 large-16">
                    <div className="row small-16 large-16 row-to-column-tablet padding-1">
                        <div className="small-16 large-8">
                            <label>First minor:</label>
                        </div>
                        <div className="small-16 large-8">
                            {this.state.firstMinor ? this.state.firstMinor : "N/A"}
                        </div>
                        <div className="small-16 large-8">
                            <label>Second minor:</label>
                        </div>
                        <div className="small-16 large-8">
                            {this.state.secondMinor ? this.state.secondMinor : "N/A"}
                        </div>
                    </div>
                </div>
                }
                { isVocational && <div className="small-16 large-16">
                    <div className="row small-16 large-16 row-to-column-tablet padding-1">
                        <div className="small-16 large-8">
                            <label>Employee name:</label>
                        </div>
                        <div className="small-16 large-8">
                            {this.state.employeeName ? this.state.employeeName : "N/A"}
                        </div>
                        <div className="small-16 large-8">
                            <label>Certificate number:</label>
                        </div>
                        <div className="small-16 large-8">
                            {this.state.certificationNumber ? this.state.certificationNumber : "N/A"}
                        </div>
                    </div>
                </div>
                }
                { isVocational && <div className="small-16 large-16">
                    <div className="row small-16 large-16 row-to-column-tablet padding-1">
                        <div className="small-16 large-4">
                            <label>RTO code:</label>
                        </div>
                        <div className="small-16 large-4">
                            {this.state.rtoCode ? this.state.rtoCode : "N/A"}
                        </div>
                    </div>
                </div>
                }
                { this.state.files && this.state.files.length > 0 && 
                <div className="small-16 large-16">
                    <div className="row small-16 large-16 row-to-column-tablet padding-1">
                        <div className="small-16 large-16">
                            <label>Qualification:</label>
                            { this.state.files.map(file => {
                                return <div key={file.Id} className="file">
                                    { this._renderIcon(file) }
                                    <span className="file-title">
                                        <a className="file-name" href={file.Url}>{file.Filename}</a>
                                    </span>
                                </div>;
                            })}
                        </div>
                    </div>
                </div>
                }
            </div>
            <div className="large-1 padding-2">
                <div className="form-control-static button-fix">
                    <a className="btn btn-basic" role="Button" onClick={this.onEditItem}><span className="fn-icon fn-icon-edit edit-skill-btn-profile fn-icon-hover-primary"></span></a>
                    <a className="btn btn-basic small-offset-1" role="Button" onClick={this.onDeleteItem}><span className="fn-icon fn-icon-delete-sign-lg fn-icon-hover-primary"></span></a>
                </div>
            </div>
        </div>,
        <div key="right" className="clearfix"></div>
        ]
    }

    renderEdit() {
        let validation = this.validate(this.props.validate);
        let isVocational = this.isVocational;
       
        return [
        <div key="left" style={{display:"flex", justifyContent:"space-between"}}>
            <div className="row small-16 large-16 padding-4">
            <div className={this.formGroupClassName(!validation.typeInvalid)}>
                <label className="small-16 large-16" htmlFor="type">Qualification type <abbr className="req" title="required">*</abbr></label>
                <div className="small-16 large-8">
                    <select name="type" id="type" className="form-control" onChange={this.onChangeType} value={this.state.typeId}>
                        <option value="">Please select</option>
                        { this.props.options.map((i) => {
                            return <option key={i.value} value={i.value}>{i.label}</option>

                        })}
                    </select>
                </div>
            </div>
            {this.state.typeId && 
                <div>
                    <div className={this.formGroupClassName(!validation.descriptionInvalid)}>
                        <label className="small-16 large-16" htmlFor="description">{ !isVocational ? "Degree title" : "Qualification title & authority code" } <abbr className="req" title="required">*</abbr></label>
                        <div className="small-16 large-14">
                            <select name="qualification" id="qualification" className="form-control" onChange={this.onChangeDescription} value={this.state.id}>
                                <option value="">Please select</option>
                                { this.qualifications?.map((i) => {
                                    return <option key={i.value} value={i.value}>{i.label}</option>

                                })}
                            </select>
                        </div>
                    </div>

                    <div className={this.formGroupClassName(!validation.issueDateInvalid)}>
                        <label className="small-16 large-16" htmlFor={this.state.id + "-issueDate"}>Issue date</label>
                        <div className="small-16 large-4">
                            <input type="date" className="form-control datepicker-placeholder" placeholder="dd / mm / yyyy" name={this.props.item.uniqueId + "-issueDate"} min="0" value={this.state.issueDate} onChange={this.onChangeIssueDate} />
                        </div>
                    </div>
                </div>
            }

            { !isVocational && this.state.typeId &&
                <div className={this.formGroupClassName(!validation.issuingInstitutionInvalid)}>
                    <label className="small-16 large-16" htmlFor={this.state.id + "-issuingInstitution"}>Issuing institution <abbr className="req" title="required">*</abbr></label>
                    <div className="small-16 large-16">
                        <input type="text" className="form-control" name={this.props.item.uniqueId + "-issuingInstitution"} min="0" value={this.state.issuingInstitution} onChange={this.onChangeIssuingInstitution} />
                    </div>
                </div>
            }

            { !isVocational && this.state.typeId &&
                <div className={this.formGroupClassName(!validation.yearEarnedInvalid)}>
                    <label className="small-16 large-16" htmlFor={this.state.id + "-yearEarned"}>Year earned</label>
                    <div className="small-16 large-2">
                        <input type="text" pattern="[0-9]*" className="form-control webkit-fix" name={this.props.item.uniqueId + "-yearEarned"} min="0" max={this.year} value={this.state.yearEarnedString} onChange={this.onChangeYearEarned} maxLength={4} />
                    </div>
                </div>
            }

            { !isVocational && this.state.typeId &&
                <div>
                    <div className={this.formGroupClassName(!validation.firstMajorInvalid)}>
                        <label className="small-16 large-16" htmlFor={this.state.id + "-firstMajor"}>First major</label>
                        <div className="small-16 large-8">
                            <input type="text" className="form-control" name={this.props.item.uniqueId + "-firstMajor"} onChange={this.onChangeFirstMajor} maxLength={300} value={this.state.firstMajor}/>
                        </div>
                    </div>
                    <div className={this.formGroupClassName(!validation.secondMajorInvalid)}>
                        <label className="small-16 large-16" htmlFor={this.state.id + "-secondMajor"}>Second major</label>
                        <div className="small-16 large-8">
                            <input type="text" className="form-control" name={this.props.item.uniqueId + "-secondMajor"} onChange={this.onChangeSecondMajor} maxLength={300} value={this.state.secondMajor}/>
                        </div>
                    </div>
                </div>
            }

            { !isVocational && this.state.typeId &&
                <div>
                    <div className={this.formGroupClassName(!validation.firstMinorInvalid)}>
                        <label className="small-16 large-16" htmlFor={this.state.id + "-firstMinor"}>First minor</label>
                        <div className="small-16 large-8">
                            <input type="text" className="form-control" name={this.props.item.uniqueId + "-firstMinor"} onChange={this.onChangeFirstMinor} maxLength={300} value={this.state.firstMinor} />
                        </div>
                    </div>
                    <div className={this.formGroupClassName(!validation.secondMinorInvalid)}>
                        <label className="small-16 large-16" htmlFor={this.state.id + "-secondMinor"}>Second minor</label>
                        <div className="small-16 large-8">
                            <input type="text" className="form-control" name={this.props.item.uniqueId + "-secondMinor"} onChange={this.onChangeSecondMinor} maxLength={300} value={this.state.secondMinor}/>
                        </div>
                    </div>
                </div>
            }

            { isVocational && this.state.typeId &&
                <div className={this.formGroupClassName(!validation.employeeNameInvalid)}>
                    <label className="small-16 large-16" htmlFor={this.state.id + "-employeeName"}>Employee name as shown on certificate <abbr className="req" title="required">*</abbr></label>
                    <div className="small-16 large-10">
                        <input type="text" className="form-control" name={this.props.item.uniqueId + "-employeeName"} value={this.state.employeeName} onChange={this.onChangeEmployeeName} maxLength={300} />
                    </div>
                </div>
            }

            { isVocational && this.state.typeId &&
                <div className={this.formGroupClassName(!validation.certificationNumberInvalid)}>
                    <label className="small-16 large-16" htmlFor={this.state.id + "-certificationNumber"}>Certification number <abbr className="req" title="required">*</abbr></label>
                    <div className="small-16 large-4">
                        <input type="text" pattern="[0-9]*" className="form-control" name={this.props.item.uniqueId + "-certificationNumber"} value={this.state.certificationNumber} onChange={this.onChangeCertificationNumber} maxLength={100} />
                    </div>
                </div>
            }

            { isVocational && this.state.typeId &&
                <div className={this.formGroupClassName(!validation.rtoCodeInvalid)}>
                    <label className="small-16 large-16" htmlFor={this.state.id + "-rtoCode"}>Registered Training Organisation (RTO code) <abbr className="req" title="required">*</abbr></label>
                    <div className="small-16 large-8">
                        <input type="text" pattern="[0-9]*" className="form-control webkit-fix" name={this.props.item.uniqueId + "-rtoCode"} min="0" value={this.state.rtoCode} onChange={this.onChangeRtoCode} maxLength={10} />
                    </div>
                </div>
            }
            
            { this.state.typeId &&
                <div>
                    <label>Qualification</label>
                    { this.state.files.map(file => {
                        return (
                            <QualificationFile key={file.Id} file={file} id={file.Id} filename={file.Filename} contentLength={file.ContentLength} size={file.Size} fileType={file.FileType} onDelete={this.onDeleteFile} renderIcon={this._renderIcon}/>
                        )
                    })}
                </div>
            }

            { this.state.typeId &&
                <BtnUpload
                        url={AppDelegate.appSettings.fileUploadUrl}
                        onFileUploadCompleteImmediate={this._onFileUploaded}
                        onFileUploadFailed={this._onFileUploadFailed }
                        uploadFormData={[{ name: 'Type', value: 'SkillFile'}, { name: 'SkillType', value: "Education"}, { name: 'RelatedSkillId', value: this.props.item.id} ]}
                        allowMultiple={true}
                        title="Add file for education"
                        label={Localisation.localisedStringFor('SkillFileUploadText')}
                        allowRemove={false}
                        showNotify={false}
                        onFileUploadClose={this._onFileUploadClose}
                    />
            }
            </div>
            <div className="large-1 padding-2">
                <div className="form-control-static">
                    <a className="btn btn-basic" role="Button" onClick={this.onDeleteItem}><span className="fn-icon fn-icon-delete-sign-lg fn-icon-hover-primary"></span></a>
                </div>
            </div>
        </div>
        ]
    }

    render() {
        return this.state.editMode ? this.renderEdit() : this.renderDisplay();
    }
}

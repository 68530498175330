import React, {
    FC,
    useState,
    useEffect,
    useRef,
}                           from "react";
import _	                from 'lodash';

import { 
    useLazyQuery, 
    useMutation,
}                           from "@apollo/client";
import { useSearchParams } from "react-router-dom";

import {
    getGroupJoinRequests_groupJoinRequests,
    getGroupJoinRequestsVariables,
    getGroupMemberships_groupMemberships,
    GroupMembershipStatus,
    TaskDetailsInput,
    assignTasksVariables,
    acceptJoinGroupRequestsVariables,
    declineJoinGroupRequestsVariables,
}                           from "../../../../types/graphqlTypes";

import moment               from 'moment';

import DateTimeUtils from "../../../core/utils/DateTimeUtils";

import AppDelegate 				from "../../../core/AppDelegate";
import ActivityIndicator		from "../../../core/components/ActivityIndicator";
import PromptActions            from "../../prompt/actions/PromptActions";

const getGroupJoinRequestsGql = require("../../../../gql/getGroupJoinRequests.gql");
const getGroupMembershipsGql = require("../../../../gql/getGroupMemberships.gql");
const {assignTasks: assignTasksGql } = require("../../../../gql/assignTask.gql");
const acceptJoinGroupRequestsGql = require("../../../../gql/acceptJoinGroupRequests.gql");
const declineJoinGroupRequestsGql = require("../../../../gql/declineJoinGroupRequests.gql");

export enum JoinRequestFilter {
    ME = "ME",
    OTHERS = "OTHERS",
    UNASSIGNED = "UNASSIGNED",
}

enum JoinRequestAction {
    NONE = "NONE",
    ASSIGN = "ASSIGN",
    UNASSIGN = "UNASSIGN",
    ACCEPT = "ACCEPT",
    REJECT = "REJECT"
}

interface IGroupJoinRequestProps {
    action: getGroupJoinRequests_groupJoinRequests,
    selected: any,
    filter: JoinRequestFilter,
    onToggleSelection: any
}

const GroupJoinRequest : FC<IGroupJoinRequestProps> = (props: IGroupJoinRequestProps) => {
    const action = props.action;
    const formatDate = (dt) => {
        return DateTimeUtils.getAbsoluteDateTimeFormatted(moment(dt));
    }

    const meOrUser = (user) => {
        return user.id != AppDelegate.config.publicUserId
            ? <a href={`/viewprofile?Id=${user.id}`} target="_blank" rel="noreferrer">{user.fullName}</a>
            : <span>you</span>;
    }

    const toggle = (e) => {
        props?.onToggleSelection(props.action.user?.id);
    }

    return (<li className="large-5 small-16 join-request">
        <div className="fn-flex">
            <input type="checkbox" onChange={toggle} checked={props.selected.has(props.action.user?.id)}/>
            <div className="avatar">
                <a onClick={toggle}>
                    <img src={`/api/v1/image?id=${action.user?.profileImageId}&amp;width=60&amp;height=60`} alt="" className="thumb"/>
                </a>
            </div>
            <div className="detail">
                <a href={`/viewprofile?Id=${action.user?.id}`} target="_blank" rel="noreferrer">{action.user?.fullName}</a>
                <div>
                    {formatDate(action.date)}
                </div>
                { action.taskAssignedByUser && action.taskAssignedToUser && <div className="fn-action-assignment">
                    Assigned by {meOrUser(action.taskAssignedByUser)}
                    { props.filter != JoinRequestFilter.ME && <span>
                        <span> to </span>
                        {meOrUser(action.taskAssignedToUser)}
                    </span> }
                    <div className="metric">
                        {formatDate(action.taskAssignedDate)}
                    </div>
                </div>
                }
            </div>
        </div>
    </li>)
}

interface IGroupMembersSelectorProps {
    groupId: string;
    onSelectUser: any
}

const GroupMembersSelector: FC<IGroupMembersSelectorProps> = (props: IGroupMembersSelectorProps) => {
    const [getGroupMemberships, { loading, data, error, networkStatus, refetch }] = useLazyQuery(getGroupMembershipsGql, { 
        fetchPolicy: "network-only"
    });
    const groupMemberships: getGroupMemberships_groupMemberships = data?.groupMemberships;
    const groupMembers = groupMemberships?.groupMembers
        ? _.cloneDeep(groupMemberships.groupMembers).sort((l, r) => (l.user.fullName < r.user.fullName ? -1 : 0))
        : null;

    useEffect(() => {
        getGroupMemberships({
            variables: {
                id: props.groupId,
                page: {
                    "pageFirst": 1000
                },
                queryParameters: {
                    membershipStatuses: [ GroupMembershipStatus.GROUP_COMMANDER, GroupMembershipStatus.GROUP_PRIMARY_ADMINISTRATOR, GroupMembershipStatus.GROUP_ADMINISTRATOR ]
                }
            }
        });

    }, [])

    const onChange = (e) => {
        props.onSelectUser(e.target.value);
    }

    return (groupMembers && <select className="form-control" onChange={onChange}>
        <option value={""}>Select an admin</option>
        { groupMembers.map(i => {
            return <option value={i?.user?.id ?? ""} key={i?.user?.id}>{i?.user?.fullName}</option>
        })}
    </select>)
}

interface IGroupJoinRequestsProps {
    groupId: string;
}

export const GroupJoinRequests : FC<IGroupJoinRequestsProps> = (props: IGroupJoinRequestsProps) => {
    const [assignTasks] = useMutation(assignTasksGql);
    const [acceptJoinGroupRequests] = useMutation(acceptJoinGroupRequestsGql);
    const [declineJoinGroupRequests] = useMutation(declineJoinGroupRequestsGql);
    const [getGroupJoinRequests, { loading, data, error, networkStatus, refetch }] = useLazyQuery(getGroupJoinRequestsGql, {
        fetchPolicy: "network-only"
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const groupJoinRequests: Array<getGroupJoinRequests_groupJoinRequests> = data?.groupJoinRequests;
    const actionsRequiredCount: number = data?.actionsRequiredCount;
    const assignedToMe = groupJoinRequests?.filter(i => i.taskAssignedToUser?.id == AppDelegate.config.publicUserId);
    const assignedToOthers = groupJoinRequests?.filter(i => i.taskAssignedToUser && i.taskAssignedToUser.id != AppDelegate.config.publicUserId);
    const unassigned = groupJoinRequests?.filter(i => i.taskAssignedToUser == null);
    const [filter, setFilter] = useState<JoinRequestFilter>(searchParams?.get("filter") as JoinRequestFilter ?? JoinRequestFilter.ME);
    const [selected, setSelected] = useState(new Set<string>());
    const [assignTo, setAssignTo] = useState("");
    const [requestAction, setRequestAction] = useState<JoinRequestAction>(JoinRequestAction.NONE);
    const [processing, setProcessing] = useState(false);

    const toggleSelection = (id: string) => {
        if (selected.has(id)) {
            selected.delete(id)
        } else {
            selected.add(id);
        }
        setSelected(new Set<string>(selected));
    }

    const selectUser = (id) => {
        setAssignTo(id);
    }

    useEffect(() => {
        const variables: getGroupJoinRequestsVariables = {
            groupId: props.groupId
        };

        getGroupJoinRequests({
            variables
        });
    }, []);

    const refresh = () => {
        setSelected(new Set<string>());
        setRequestAction(JoinRequestAction.NONE);
        setAssignTo("");
        refetch();
    }

    const showAssignedToMe = (e) => {
        e.preventDefault();
        setFilter(JoinRequestFilter.ME);
    }

    const showAssignedToOthers = (e) => {
        e.preventDefault();
        setFilter(JoinRequestFilter.OTHERS);
    }

    const showUnassigned = (e) => {
        e.preventDefault();
        setFilter(JoinRequestFilter.UNASSIGNED);
    }

    let filteredJoinRequests;
    let requestActions = [{
        label: "Choose an action",
        value: JoinRequestAction.NONE
    }, {
        label: "Reassign to another admin",
        value: JoinRequestAction.ASSIGN
    }, {
        label: "Unassign request",
        value: JoinRequestAction.UNASSIGN
    }, {
        label: "Accept request",
        value: JoinRequestAction.ACCEPT
    }, {
        label: "Reject  request",
        value: JoinRequestAction.REJECT
    }];

    switch (filter) {
        case JoinRequestFilter.ME:
            filteredJoinRequests = assignedToMe;
            break;
        case JoinRequestFilter.OTHERS:
            filteredJoinRequests = assignedToOthers;
            break;
        case JoinRequestFilter.UNASSIGNED:
            filteredJoinRequests = unassigned;

            requestActions = requestActions.filter(i => i.value != JoinRequestAction.UNASSIGN);
            break;
    }

    let isValid = true;

    if (requestAction == JoinRequestAction.NONE || (requestAction == JoinRequestAction.ASSIGN && !assignTo)) {
        isValid = false;
    }

    const filteredSelectedUserIds = [...selected].filter(id => filteredJoinRequests?.some(i => i.user.id == id));

    if (isValid) {
        isValid = filteredSelectedUserIds.length > 0;
    }

    const onChangeRequestAction = (e) => {
        e.preventDefault();

        setRequestAction(e.target.value);
    }

    const apply = (e) => {
        e.preventDefault();

        if (isValid && !processing) {
            let promise: any = null;

            if (requestAction == JoinRequestAction.ASSIGN || requestAction == JoinRequestAction.UNASSIGN) {
                const userId = requestAction == JoinRequestAction.ASSIGN ? assignTo : null;
                const tasks = filteredSelectedUserIds.map(i => {
                    return {
                        groupJoinRequestGroupId: props.groupId,
                        groupJoinRequestUserId: i,
                        assignToUserId: userId
                    } as TaskDetailsInput;
                });

                promise = assignTasks({
                    variables: {
                        tasks
                    } as assignTasksVariables
                });
            } else if (requestAction == JoinRequestAction.ACCEPT) {
                promise = acceptJoinGroupRequests({
                    variables: {
                        id: props.groupId,
                        userIds: filteredSelectedUserIds,
                    } as acceptJoinGroupRequestsVariables
                })
            } else if (requestAction == JoinRequestAction.REJECT) {
                promise = declineJoinGroupRequests({
                    variables: {
                        id: props.groupId,
                        userIds: filteredSelectedUserIds,
                    } as declineJoinGroupRequestsVariables
                })
            }

            if (promise) {
                setProcessing(true);

                promise.then(() => {
                    refresh();
                }).catch(err => {
                    PromptActions.displayFriendlyErrorPrompt(err);
                }).finally(() => {
                    setProcessing(false);
                });
            }
        }
    }

    if (groupJoinRequests) {
        let el = document.getElementById("join-requests-count")
            
        if (el) {
            const count = groupJoinRequests.length;
            el.innerText = count.toString();
            el.style.display = count != 0 ? "inline-block" : "none";
        }

        el = document.getElementById("actionCount")
            
        if (el) {
            el.innerText = actionsRequiredCount.toString();
            el.style.display = actionsRequiredCount != 0 ? "block" : "none";
        }
    }

    if (loading) {
        return <div className="loading"><ActivityIndicator /></div>;
    }

    return ( groupJoinRequests
        ? <div className="fn-group-join-requests">
            <div className="row">
                <div className="small-16 columns">
                    <div className="content filter">
                        <a href="#" onClick={showAssignedToMe} className={filter != JoinRequestFilter.ME ? "inactive" : ""}>Assigned to me</a> ({assignedToMe.length}) | <a href="#" onClick={showAssignedToOthers} className={filter != JoinRequestFilter.OTHERS ? "inactive" : ""}>Assigned to others</a> ({assignedToOthers.length}) | <a href="#" onClick={showUnassigned} className={filter != JoinRequestFilter.UNASSIGNED ? "inactive" : ""}>Unassigned</a> ({unassigned.length})
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="small-16 columns">
                    <div className="content form-inline">
                        Action <select className="form-control" onChange={onChangeRequestAction} value={requestAction}>
                            {requestActions.map((action, index) => {
                                return <option value={action.value} key={index}>{action.label}</option>;
                            })}
                        </select>
                        { requestAction == JoinRequestAction.ASSIGN && <GroupMembersSelector groupId={props.groupId} onSelectUser={selectUser}/> }
                        <button type="button" className={`btn btn-primary${!isValid || processing ? " disabled" : ""}`} onClick={apply}>
                        Apply
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="small-16 columns">
                    <div className="content">
                        { filteredJoinRequests?.length === 0 && filter == JoinRequestFilter.ME && <span>There are no requests assigned to you.</span> }
                        { filteredJoinRequests?.length === 0 && filter == JoinRequestFilter.OTHERS && <span>There are no requests assigned to others.</span> }
                        { filteredJoinRequests?.length === 0 && filter == JoinRequestFilter.UNASSIGNED && <span>There are no unassigned requests.</span> }
                        <ul className="fn_equalHeights">
                            {filteredJoinRequests?.map((action, index) => {
                                return <GroupJoinRequest action={action} key={index} onToggleSelection={toggleSelection} selected={selected} filter={filter}/>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        : null
    );
}

import BaseStore from '../../../core/stores/BaseStore';
import AppDispatcher from '../../../core/dispatcher/AppDispatcher';

import CrawlerConstants from '../constants/CrawlerConstants';
import MetaData from '../models/MetaData';

class CrawlerStore extends BaseStore {

    constructor() {

        super();
        this.metaData = null;

    }

    setMetaData(metaData) {
        this.metaData = metaData;
    }

    reset() {
		this.metaData = null;
	}

    /**
     * Gets a state from the store data
     */
    getState() {
        return this.metaData;
    }

}

/**
 * Our Store singleton
 */
let _store = new CrawlerStore();


/**
 *  Watch for store specific actions
 */
AppDispatcher.register(function (action) {

    switch (action.actionType) {

		case CrawlerConstants.CRAWLER_CRAWLED:
            _store.setMetaData(action.data);
            _store.emitChange();
            break;

		case CrawlerConstants.CRAWLER_RESET:
            _store.reset();
            _store.emitChange();
            break;

        default:
            // No op
            break;
    }

});

export default _store;
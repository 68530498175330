import React 	from 'react';
import filesize             from "filesize"

interface Props {
    key: string;
    id: string;
    file: Object;
    filename: string;
    fileType: string;
    size: number;
    contentLength: string;
    onDelete: Function;
    renderIcon: Function;
}

export default class QualificationFile extends React.Component<Props> {

    constructor(props) {
        super(props)
    }

    onClick = (e) => {
        e?.preventDefault();
        
        if (this.props.onDelete) {
            this.props.onDelete(this.props.id);
        }
    }

    render() {
        return (
            <div className="small-16 large-16 education-filename-container">
                <div className="small-10 large-10 file">
                    <div key={this.props.id} className="small-1 file education-filename">
                        { this.props.renderIcon(this.props.file) }
                    </div>
                    <div>
                        {<span className="primary">{this.props.filename}</span> }
                        {<span> ({this.props.contentLength || filesize(this.props.size)})</span>}
                    </div>
                </div>
                <div className="small-4 large-4 columns inline-label">
                    <a href="#" onClick={this.onClick}><span className="fn-icon fn-icon-delete fn-icon-primary" /></a>
                </div>
            </div>
        )
    }
}
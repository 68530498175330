import React 				from 'react';
import PropTypes            from 'prop-types';
import _	                from 'lodash';
import PatternOfService		from './PatternOfService';

class PatternsOfServiceModule extends React.Component {
	constructor(props) {
        super(props);

        let items = [];

		if (this.props.fnNode) {
			let nativeElement = this.props.fnNode;

			if (nativeElement.value) {
                items = JSON.parse(nativeElement.value);
                
                if (items) {
                    items.forEach(item => {
                        if (item.selectedMonths) {
                            let months = item.selectedMonths;

                            item.fromMonth = months[0];
                            item.toMonth = months[months.length-1];
                        }

                        item.id = _.uniqueId();
                    });

                    if (!props.validate) {
                        let currentMonth = new Date().getMonth();

                        items = items.sort((l, r) => {
                            let left = l.fromMonth;
                            let right = r.fromMonth;

                            if (!l.selectedMonths.includes(currentMonth) && l.fromMonth < currentMonth) {
                                left += 12;
                            }

                            if (!r.selectedMonths.includes(currentMonth) && r.fromMonth < currentMonth) {
                                right += 12;
                            }

                            return left < right ? -1 : 0;
                        });
                    }
                }
            }
        }

        let allSelectedMonths = this.getAllSelectedMonths(items);

        this.state = {
            items,
            allSelectedMonths,
            validate: props.validate
        }

        let el = document.getElementById(props.submit);

        el.addEventListener("click", this.onSubmit);
    }

    onSubmit = (e) => {
        if (e && e.target && e.target.classList && e.target.classList.contains("disabled")) {
            if (e.preventDefault) {
                e.preventDefault();
            }

            this.setState({
                validate: true
            });
        }
    }

    isComplete() {
        return this.state.allSelectedMonths.size === 12;
    }

    allFromMonthsSelected() {
        return this.state.items.findIndex(i => typeof(i.fromMonth) != "number") < 0;
    }

    onAddNewItem = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        this.setState({
            items: [...this.state.items, {
                id: _.uniqueId(),
                blockOfDays: "20",
                validation: {
                    monthsInvalid: true,
                    typeInvalid: true
                }
            }]
        }, this.evaluateSubmitState);
    }

    onChangePatternOfService = (e) => {
        let index = this.state.items.findIndex(i => i.id === e.id);

        if (index >= 0) {
            this.state.items[index] = e;
        }

        this.setState({
            items: this.state.items,
            allSelectedMonths: this.getAllSelectedMonths(this.state.items)
        }, this.emit);
    }

    getAllSelectedMonths(items) {
        let allSelectedMonths = new Set();

        items.forEach(item => {
            if (item.selectedMonths) {
                for (let i of item.selectedMonths) {
                    allSelectedMonths.add(i);
                }
            }
        });

        return allSelectedMonths;
    }

    onDeletePatternOfService = (e) => {
        let index = this.state.items.findIndex(i => i.id === e.id);

        if (index >= 0) {
            this.state.items.splice(index, 1);

            this.setState({
                items: this.state.items,
                allSelectedMonths: this.getAllSelectedMonths(this.state.items)
            }, this.emit);
        }
    }

	_setValue(value) {
		if (this.props.fnNode) {
			let nativeElement = this.props.fnNode;

			nativeElement.value = value;
		}
	}

    emit() {
        this._setValue(JSON.stringify(this.state.items));
        this.evaluateSubmitState();
    }
    
    evaluateSubmitState() {
        let valid = true;

        this.state.items.forEach(i => {
            if (i.validation) {
                for (let key of Object.keys(i.validation)) {
                    if (i.validation[key] !== false) {
                        valid = false;
                        break;
                    }
                }
            }
        });

        if (valid == false && this.state.items.length == 1) {
            let item = this.state.items[0];
        }

        let el = document.getElementById(this.props.submit);

        if (el && el.classList) {
            if (!valid) {
                el.classList.add("disabled");
            } else {
                el.classList.remove("disabled");
            }
        }
    }

    componentDidMount() {
        if(!this.isComplete()) {
            this.setState({
                items: [...this.state.items, {
                    id: _.uniqueId(),
                    blockOfDays: "20",
                    validation: {
                        monthsInvalid: true,
                        typeInvalid: true
                    }
                }]
            }),
            this.evaluateSubmitState()
        }
    }

    render() {
        return <div>{
            this.state.items.map((item, index) => {
                return <div>
                        {index > 0 && <hr/>}
                        <PatternOfService key={index} item={item} allSelectedMonths={this.state.allSelectedMonths} validate={this.state.validate} onChange={this.onChangePatternOfService} onDelete={this.onDeletePatternOfService}/>
                    </div>;
            })}
            { !this.isComplete() && 
                <div className="form-group">
                    <label className="small-16 large-4 columns control-label"></label>
                    <div className="small-16 large-16 columns"><a href="#" onClick={this.onAddNewItem}>{this.state.items.length > 0 ? "+ Add another pattern of service" : "+ Add a pattern of service"}</a></div>
                </div>
            }
            { this.isComplete() && 
                <div className="form-group">
                <label className="small-16 large-4 columns control-label"></label>
                <div className="small-16 large-16 columns fn-flex">
                    <a className="btn btn-basic"><span className="fn-icon icon-success fn-icon-primary icon-large" />&nbsp;</a>
                    <span style={{marginTop: "10px"}}>You've provided information for 12 months. You don't need to add another availability period. You can change this information at any time.
                    </span>
                </div>
            </div>
        }
        </div>
    }
}

PatternsOfServiceModule.defaultProps = {
	className: '',
    items: [],
    validate: false,
    lastModified: null
};

PatternsOfServiceModule.propTypes = {
	className:					PropTypes.string,
	options:					PropTypes.array,
    validate:					PropTypes.bool,
    lastModified:               PropTypes.string
};

export default PatternsOfServiceModule;
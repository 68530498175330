import React, {
    FC,
}                           from "react";
import _	                from 'lodash';

import {LatestBookmarks }   from "./LatestBookmarks";

interface ILatestBookmarksModuleProps {
    bookmarkId: string;
    activityId: number | null,
    className: string | null;
    isOpen: boolean;
    isPostBookmarkOpen: boolean;
    isEventBookmarkOpen: boolean;
    isNewsBookmarkOpen: boolean;
    isJobBookmarkOpen: boolean;
    isDiscussionBookmarkOpen: boolean;
}

export const LatestBookmarksModule : FC<ILatestBookmarksModuleProps> = (props: ILatestBookmarksModuleProps) => {
    return (
        <LatestBookmarks isOpen={props.isOpen} 
        isPostBookmarkOpen={props.isPostBookmarkOpen} isEventBookmarkOpen={props.isEventBookmarkOpen} isNewsBookmarkOpen={props.isNewsBookmarkOpen} 
        isJobBookmarkOpen={props.isJobBookmarkOpen} isDiscussionBookmarkOpen={props.isDiscussionBookmarkOpen}/>
    )
}

import React, {
    FC,
    MouseEventHandler,
    useEffect,
    useState,
} from "react";
import _ from 'lodash';

import {
    useMutation,
} from "@apollo/client";

import RichTextEditor, { RichTextEditorMode } from "../../../core/components/richtext/RichTextEditor";
import PromptActions from "../../prompt/actions/PromptActions";
import AppDelegate from "../../../core/AppDelegate";
import { Clock } from "../action/Clock";
import { ConfirmationModal } from "./ConfirmationModal";
import UrlUtils from "../../../core/utils/UrlUtils";
import { FileType } from '../model/Enums';

const forumsGql = require('../../../../gql/forums.gql');

import {
    forumResult_topics,
    FORUM_SAVE_DISCUSSION_MUTATIONVariables,
    forumDiscussionResult,
    association,
} from "../../../../types/graphqlTypes";

import { UserRightsType } from "../model/UserRightsType";
import DisplayFileList from "./DisplayFileList";
import ForumUpload from "./ForumUpload";
import AssociationUtils from "./AssociationUtils";
import BtnUpload from "../../../core/components/BtnUpload";
import { FileFactory } from "../../../core/models/File";
import Localisation from "../../../core/resources/Localisation";
import { ForumAssociationInput } from "../../../../types/graphqlTypes";
import { AssociationType } from "../model/Enums";
import AttachedPhotosFiles from "../../../core/components/AttachedPhotosFiles";
import FileHeader from "../model/FileHeader";

interface IUpdateDiscussionProps {
    forumId: string,
    groupId: string,
    discussionResult?: forumDiscussionResult,
    topicResult: forumResult_topics,
    topicResults: forumResult_topics[],
    onSelectDiscussion: (discussionResult: forumDiscussionResult, topicResult: forumResult_topics) => void,
    onCancel: () => void,
    onClick: (e) => MouseEventHandler<HTMLAnchorElement>,
}

const isNumber = (n: string | number | undefined): boolean =>
    n !== undefined && !isNaN(parseFloat(String(n))) && isFinite(Number(n));

export const UpdateDiscussion: FC<IUpdateDiscussionProps> = (props: IUpdateDiscussionProps) => {
    const [saveDiscussion] = useMutation(forumsGql.FORUM_SAVE_DISCUSSION_MUTATION);
    const discussionId = props.discussionResult ? props.discussionResult.discussion.discussionId : null;
    const [topicId, setTopicId] = useState((props.topicResult && props.topicResult.topic) ? props.topicResult.topic.topicId : "");
    const [subject, setSubject] = useState(props.discussionResult ? props.discussionResult.headPost.subject : "");
    const [content, setContent] = useState(props.discussionResult ? props.discussionResult.headPost.content : "");
    const [associations, setAssociations] = useState<association[] | null>(props.discussionResult ? props.discussionResult.associations : null);
    const [displayEditModal, setDisplayEditModal] = useState(false);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const isFile = (association: ForumAssociationInput) => association.associationType == AssociationType.AttachedFile ||
        association.associationType == AssociationType.AttachedPhoto ||
        association.associationType == AssociationType.EmbedlyResult;
    const isLink = (association: ForumAssociationInput) => association.associationType == AssociationType.GroupDocumentFolder ||
        association.associationType == AssociationType.GroupDocument ||
        association.associationType == AssociationType.GroupMediaFolder ||
        association.associationType == AssociationType.GroupMedia;
    const [attachedPhotos, setAttachedPhotos] = useState(null);
    const [links, setLinks] = useState(null);
    const [files, setFiles] = useState([]);
    const rte = React.createRef<RichTextEditor>();

    if (topicId == "" && props.topicResults.length == 1) {
        if (props.topicResults[0].topic) {
            setTopicId(props.topicResults[0].topic.topicId);
        }
    }

    useEffect(() => {
        let files = [];
        let index = 0;
        _.forEach(props?.discussionResult?.associations, a =>{
            var itemType = isNumber(a.itemType) ? FileType[Number(a.itemType)] : a.itemType;
            if (itemType == "Image"){
                let imgUrl = '/Services/GetImage.ashx?id=' + a.associatedItemId;
                UrlUtils.toDataURL(
                    imgUrl, 
                    (dataUrl) => {
                      let base64String = dataUrl.replace(/^data:image\/(png|jpg|bmp|jpeg|gif);base64,/,"");
                      let blob = UrlUtils.b64toBlob(base64String);
                      let objectUrl = URL.createObjectURL(blob);
                      files.push(new FileHeader(a.associatedItemId, a.displayName, itemType, {url:imgUrl}, objectUrl, index++));  
                      setFiles(files);                  
                    }, '');
            }else{
                files.push(new FileHeader(a.associatedItemId, a.displayName, itemType, null, null, index++));
                setFiles(files); 
            }
        });

        setAttachedPhotos(_.filter(props?.discussionResult?.associations, isFile).map(association => AssociationUtils.buildFile(association)));
        setLinks(_.filter(props?.discussionResult?.associations, isLink).map(association => AssociationUtils.buildLink(association)));
    }, []);
       

    useEffect(() => {
        enableSave(false); 
    }, [topicId, subject, content]);

    const onEditModalConfirm = () => {
        setDisplayEditModal(false);
        saveChanges();
    }

    const onEditModalClose = (e) => {
        setDisplayEditModal(false);
        setIsSaveEnabled(false);
        props.onCancel();
    }

    const enableSave = (filesChanged) => {
        var dataIsValid = (topicId != "") && (subject != "") && (!rte.current?.isEmptyContent());

        if (props.discussionResult) {
            var changeHasBeenMade = false;

            if (topicId != props.discussionResult.topic?.topicId && topicId != "") {
                changeHasBeenMade = true;
            }
            if (!changeHasBeenMade && (subject != props.discussionResult.headPost.subject) && (subject != "")) {
                changeHasBeenMade = true;
            }
            if (!changeHasBeenMade && (content != props.discussionResult.headPost.content) && (!rte.current?.isEmptyContent())) {
                changeHasBeenMade = true;
            }
            if (!changeHasBeenMade && filesChanged) {
                changeHasBeenMade = true;
            }

            setIsSaveEnabled(changeHasBeenMade && dataIsValid);
        } else { // it's a new discussion
            setIsSaveEnabled(dataIsValid);
        }
    }

    const onFilesChanged = (files: any[], links: any[]) => {
        let validFiles = _.filter(files,(file) => file.error === undefined);
        setAssociations(AssociationUtils.getAssociationInputs(validFiles, links));
        enableSave(true);
    }

    const userPreference = {
        ShowRteToolbarWide: true,
        ShowRteToolbarNarrow: false
    }

    const onChangeTopic = (e) => {
        setTopicId(e.target.value);
    }

    const onChangeSubject = (e) => {
        setSubject(e.target.value);
    }

    const onChangeContent = (value, e) => {
        setContent(value);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        // confirmation modal
        if (props.discussionResult && props.discussionResult.userRights != UserRightsType.Author) {
            setDisplayEditModal(true);
        } else { // this is a new discussion, or the editor is the author
            saveChanges();
        }
    }

    const saveChanges = () => {
        var selectedTopic = props.topicResults.find(t => t.topic?.topicId == topicId);

        saveDiscussion({
            variables: {
                discussionId: discussionId,
                forumId: props.forumId,
                topicId: topicId,
                subject,
                content,
                associations: associations,
            } as FORUM_SAVE_DISCUSSION_MUTATIONVariables
        }).then((savedDiscussion) => {
            const errors = savedDiscussion?.data?.saveDiscussion?.errors;

            if (errors && errors.length > 0) {
                throw errors[0];
            }

            if (props.discussionResult) {
                // update state
                var discussionResultNew = {
                    ...props.discussionResult,
                    headPost: { ...savedDiscussion.data.saveDiscussion.headPost },
                    topic: { ...savedDiscussion.data.saveDiscussion.topic }
                };

                var selectedTopicResult = props.topicResults.filter(
                    topicResult => topicResult.topic.topicId == discussionResultNew.topic.topicId
                )[0];

                var topicResultNew = {
                    ...props.topicResult,
                    topic: selectedTopicResult.topic
                }

                console.log(topicResultNew);
                console.log(selectedTopic);
                props.onSelectDiscussion(discussionResultNew, topicResultNew);
            }
        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);
        }).finally();

        props.onCancel();
    }

    function _onFileUploadFailed(err) {
        PromptActions.displayFriendlyErrorPrompt(err);
    }

    function _onFileUploadClose() {
        setIsActive(false);

    }

    function _onFileUploaded(result, domFiles) {

        let file = FileFactory.fileFromObject(result);

        if (file == null) {
            PromptActions.displayPrompt(Localisation.localisedStringFor('Error'), Localisation.localisedStringFor('ErrorUnexpectedResponse'));
            return;
        }

        let photos = [...attachedPhotos, file].sort((left, right) => {
            let l = domFiles.findIndex(i => i.id == left.id);
            let r = domFiles.findIndex(i => i.id == right.id);

            return l < r ? -1 : 0;
        });

        setAttachedPhotos(photos);
    }

    const onUpdateFileList = (f) => {
        setFiles(f);
        setAttachedPhotos(_.filter(attachedPhotos, photo => _.some(f, file => photo.id == file.id)));
        onFilesChanged(f, links);
    }

    const onUpdateLinks = (e) => {
        setLinks(e);
        onFilesChanged(files, e);        
    }

    function showUploadModal(e): MouseEventHandler<HTMLAnchorElement> {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        setIsActive(true);
        return e;
    }

    return (
        <div>
            {props.discussionResult && (props.discussionResult.userRights != UserRightsType.Author) &&
                <ConfirmationModal displayModal={displayEditModal} heading="Edit discussion"
                    reasonMessage="Reason for editing this discussion"
                    confirmMessage="Are you sure you want to update this discussion?"
                    userRights={props.discussionResult?.userRights} title={props.discussionResult.headPost.subject}
                    onConfirm={onEditModalConfirm} onRequestClose={onEditModalClose} />
            }

            <div className="inline-edit form-fields create-post __active">
                <div className="fn-flex">
                    <div className="avatar fn-flex-align-center">
                        <img src={AppDelegate.config.userAvatar.urlWithSize(37, 37)} alt="My avatar" />
                    </div>
                    <div className="row full-width">
                        <div className="small-16 large-9 columns">
                            <span className="title">{AppDelegate.config.userFullName}</span>
                        </div>
                        <div className="small-16 large-7 columns">
                            <span><Clock /></span>
                        </div>
                    </div>
                </div>

                {props.topicResults && props.topicResults.length > 1 &&
                    <div className="form-field">
                        <label htmlFor="topic">Topic: </label>
                        <select name="topic" onChange={onChangeTopic} value={topicId} className="form-control">
                            <option value="">Choose a topic for your discussion</option>
                            {props.topicResults.map((item, index) => <option key={index} value={item.topic.topicId}>{item.topic.title}</option>)}
                        </select>
                    </div>
                }
                <div className="form-field">
                    <label htmlFor="subject">Subject: </label>

                    <input dir="auto"
                        name="subject"
                        placeholder="Subject is required"
                        type="text"
                        maxLength={100}
                        className="form-control"
                        onChange={onChangeSubject}
                        spellCheck={true}
                        value={subject}
                    />
                </div>
                <div className="form-field">
                    <RichTextEditor ref={rte} value={content} onValueChange={onChangeContent} userPreference={userPreference}
                        readOnly={false} spellcheck={true} mode={RichTextEditorMode.ActivityPost} />
                </div>
{/*                 {(associations && associations.length > 0) &&
                    <div className="form-field">
                        <DisplayFileList associations={associations} discussionResult={props.discussionResult}/>
                    </div>
                } */}
                {(attachedPhotos && attachedPhotos.length > 0) &&
                    <div className="form-field create-post__inputs">
                        <AttachedPhotosFiles files={attachedPhotos} onClick={showUploadModal} />
                    </div>
                }
                <div className="form-field">
                    <div className="buttons">
                        <div className="inline">
                            <BtnUpload url={AppDelegate.appSettings.fileUploadUrl} btnStyle={null}
                                                title="Add/Edit File" label="Add/Edit File" className="create-post__types-type" btnIconStyle="fn-icon fn-icon-upload"
                                                allowMultiple={true} allowRemove={true} active={isActive} amountLimit={10}
                                                category='Group'
                                                selectedGroupOrEvent={props.groupId}
                                                canAddExisting={true}
                                                showNotify={false}
                                                updateFileList={onUpdateFileList}
                                                uploadFormData={[{ name: 'Type', value: 'GroupPost' }]}
                                                links={links}
                                                files={files}
                                                onUpdateLinks={onUpdateLinks}
                                                attachedPhotos={attachedPhotos}
                                                onFileUploadCompleteImmediate={_onFileUploaded}
                                                onFileUploadClose={_onFileUploadClose}
                                                onFileUploadFailed={_onFileUploadFailed}>
                                                <span className="fn-icon fn-icon-upload"></span>Add/Edit File
                                            </BtnUpload>
                            &nbsp;
                            <button className="btn btn-default" onClick={props.onCancel}>Cancel</button>
                            &nbsp;
                            <button className="btn btn-primary" disabled={!isSaveEnabled} onClick={onSubmit}>Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

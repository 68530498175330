import React from "react"

let searching

class SearchBox extends React.Component {
    constructor(props) {
        super(props)

        this.debounceSearch = _.debounce(this.props._getSearch.bind(this), 500);
    }

    _onKey(e) {
        if (e.keyCode == 13) {
            e.preventDefault()
            return false
        }

        this.debounceSearch(e.target.value);
    }

    clear() {
        this.refs.searchBox.value = "";
        this.debounceSearch("");
    }

    render() {
        let val = this.refs.searchBox && this.refs.searchBox.value;
        
        return (
            <div className="mailbox-search">
                <div className="form-group has-feedback">
                    <input
                        id="txtMessageSearch"
                        type="text"
                        ref="searchBox"
                        placeholder="Search messages by recipient"
                        className="form-control mailbox-search-control"
                        onKeyUp={this._onKey.bind(this)}
                        onKeyDown={this._onKey.bind(this)}
                        spellCheck="true"
                    />
                    <div className="form-control-feedback">
                        <a
                            id="btnSearchClear"
                            href="#"
                            role="button"
                            aria-label="Clear"
                            style={{ display: val ? "block" : "none" }}
                            onClick={this.clear.bind(this)}>
                            <span
                                className="fn-icon fn-icon-delete fn-icon-hover-primary"
                                aria-hidden="true"
                            />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchBox


import React from "react";
import PropTypes from "prop-types";
import GroupReportActions from "./actions/GroupReportActions";
import GroupReportConstants from "./constants/GroupReportConstants";
import GroupReportStore from "./stores/GroupReportStore";
import GroupReportChart from "./components/GroupReportChart";
import GroupReportPerformance from "./components/GroupReportPerformance";
import GroupReportSummary from "./components/GroupReportSummary";
import GroupReportUsage from "./components/GroupReportUsage";
import GroupReportSignificantActivity from "./components/GroupReportSignificantActivity";
import PromptActions from "../prompt/actions/PromptActions";
import DateTimeUtils from "../../core/utils/DateTimeUtils";

const DATE_SELECTOR = 'dateSelect';

const DateSelect = {
  Week: 7,
  Fortnight: 14,
  Month: 30,
  Quarter: 90,
  Year: 365
};

const DateSelectAll = [DateSelect.Week, DateSelect.Fortnight, DateSelect.Month, DateSelect.Quarter, DateSelect.Year];

class GroupReportModule extends React.Component {

  _storeChangeBinding: () => void;
  state: any;

  constructor(props) {
    super(props);

    this._storeChangeBinding = this._onStoreChange.bind(this);

    this.state = {
      isWorking: false,
      dateSelect: 0,
      dateStart: '',
      dateEnd: '',
      reportData: {
        Dates: [],
        Posts: [],
        Comments: [],
        Acks: [],
        Events: [],
        FileDownloads: [],
        FileUploads: [],
        Members: [],
        NewMembers: [],
        JoinRequests: [],
        PageViews: [],
        UniquePageViews: [],
        TotalPageView: 0,
        TotalPageViewUnique: 0,
        UserDetail: [],
        DeviceDetail: []
      }
    };
  }

  componentDidMount() {
    GroupReportStore.addChangeListener(this._storeChangeBinding);
    this._loadReport(DateSelect.Year);
  }

  componentWillUnmount() {
    GroupReportStore.removeChangeListener(this._storeChangeBinding);
  }

  _onStoreChange() {
    this.setState(GroupReportStore.getState());
  }

  _handleDateChange(e) {
    if (e) e.preventDefault();
    this._loadReport(parseInt(this.refs[DATE_SELECTOR].value));
  }

  _loadReport(dateSelect) {
    if (dateSelect == this.state.dateSelect) return;

    var today = new Date();
    var start = new Date(today.getFullYear(), today.getMonth(), 1 + -1 * dateSelect + today.getDate());
    var end = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    var fmt = 'DD/MM/YYYY';
    var startDate = DateTimeUtils.stringFromDate(start, fmt);
    var endDate = DateTimeUtils.stringFromDate(end, fmt);

    this.setState({
      isWorking: true,
      dateSelect: dateSelect,
      dateStart: startDate,
      dateEnd: endDate
    });

    GroupReportActions.loadReport(this.props.groupId, startDate, endDate).then(() => {
      this.setState({ isWorking: false });
    }).catch(err => {
      this.setState({ isWorking: false });
      PromptActions.displayFriendlyErrorPrompt(err);
    });
  }

  _dateSelectTitle(value: DateSelect) {
    switch (value) {
      case DateSelect.Week:
        return 'Last week';
      case DateSelect.Fortnight:
        return 'Last 14 days';
      case DateSelect.Month:
        return 'Last month';
      case DateSelect.Quarter:
        return 'Last 90 days';
      case DateSelect.Year:
        return 'Last year';

    }
  }

  _renderDateSelectOption(value: DateSelect) {
    var title = this._dateSelectTitle(value);

    return <option value={value}>{title}</option>;
  }

  _renderExportButton() {
    let reportUrl = '/Services/DownloadReport.ashx?report=groupreport&groupid=' + this.props.groupId + '&startdate=' + this.state.dateStart + '&enddate=' + this.state.dateEnd;

    return <a className="btn btn-secondary" href={reportUrl} target="_blank">Export</a>;
  }

  render() {
    return <div>
				<div className="panel">
					<div className="panel-heading-primary panel-heading panel-grid">
						<h1 className="panel-title">Group Report</h1>
						<p className="panel-title">
						This is a trial group reporting tool which is undergoing continued development.  All ideas and suggestions for change are most welcome. Please direct any feedback through your chain of command or direct to <a href="mailto:forcenet@defence.gov.au">forcenet@defence.gov.au</a>.
						</p>
					</div>
				</div>
						
				<div className="panel-floating">
					<select className='form-control short' defaultValue={DateSelect.Week} value={this.state.dateSelect} onChange={e => this._handleDateChange(e)} ref={DATE_SELECTOR}>
						{_.map(DateSelectAll, v => <option value={v}>{this._dateSelectTitle(v)}</option>)}
					</select>
					<button className="btn btn-primary" onClick={e => this._handleDateChange(e)}>Show</button>
					<span> </span>
					{this._renderExportButton()}
				</div>
				
				<GroupReportSummary reportData={this.state.reportData} />
				<GroupReportChart reportData={this.state.reportData} />
				<GroupReportSignificantActivity reportData={this.state.reportData} />
				<GroupReportUsage reportData={this.state.reportData} />
			</div>;
  }
}

GroupReportModule.defaultProps = {
  groupId: null
};

GroupReportModule.propTypes = {
  groupId: PropTypes.string.isRequired
};

export default GroupReportModule;
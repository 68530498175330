
import React, {
    FC,
    useEffect,
    useState,
} from "react";

import { 
    useMutation,
    useQuery, 
    NetworkStatus
} from "@apollo/client";

import {
    getMyBookmarks_myBookmarks,
    removeBookmarkVariables,
}                           from "../../../types/graphqlTypes";

import SvgIcon              from '../../icons';
import moment               from 'moment';
import Pluralize            from "../../vendor/typescript-pluralize/index";

import "./BookmarksModule.css";

const getMyBookmarksGql = require("../../../gql/getMyBookmarks.gql");
const removeBookmarkGql = require("../../../gql/removeBookmark.gql");

const makePlural = (word: string, count: number) => count != 1 ? Pluralize.plural(word) : word;

import { DATE_STANDARD_RENDER_FORMAT } from "../../core/utils/DateTimeUtils";
import PromptActions        from "../../modules/prompt/actions/PromptActions";


interface IBookmarksModuleProps {
    bookmarkId: string;
    activityId: number | null,
    className: string | null;
}

export const BookmarksModule : FC<IBookmarksModuleProps> = (props: IBookmarksModuleProps) => {
    const [bookmarks, setBookmarks] = useState(null);
    const { loading, data, error, networkStatus, refetch } = useQuery(getMyBookmarksGql);

    useEffect(() => {
        if(networkStatus == NetworkStatus.ready) {
            setBookmarks(data?.myBookmarks?.entries);
        }
    }, [networkStatus, data]);

    const [removeBookmark] = useMutation(removeBookmarkGql);

    const goToLink = (url) => {
        window.location.href = url;
    }
    
    const onRemoveBookmark = (e, bookmarkId) => {
        e.preventDefault(); 

        removeBookmark({
            variables: {
                id: bookmarkId,
            } as removeBookmarkVariables
        }).then((savedList) => {
            const errors = savedList?.data?.removeBookmark?.errors;

            if (errors && errors.length > 0) {
                throw errors[0];
            }

            // remove bookmark from bookmarks array
            if (bookmarks) {
                var filteredBookmarks = bookmarks.filter(b => b.userBookmarkId != bookmarkId);
                setBookmarks(filteredBookmarks);
            }

        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);

        }).finally();
        
        e.stopPropagation();
    }


    const posts = bookmarks?.filter(i => i.post).sort((l, r) => l.post.subject ?? l.post.rawMessage < r.post.subject ?? r.post.rawMessage ? -1 : 0).map(i => {
        return (<div key={i.userBookmarkId} className="fn-section-item bookmarks" onClick={() => goToLink(`/community/view-post?Id=${i.post.id}`)}>
            <div className="fn-flex-space-between">
                <div className="fn-subject">
                    {i.post.subject ?? i.post.rawMessage}
                </div>
                <div className="fn-action-link">
                    <button className="close" onClick={(e) => onRemoveBookmark(e, `${i.userBookmarkId}`)}>
                        <SvgIcon icon="icon-delete" width={8} height={8} />
                    </button>
                    <a href={`/community/view-post?Id=${i.post.id}`}><SvgIcon icon="icon-chevron-circle-right" width={16} height={16}/></a>
                </div>
            </div>
            <div className="fn-action-other">
                <a href={`/viewprofile?Id=${i.post.author.id}`}>{i.post.author.name}</a> | <a href={i.post.target.url}>{i.post.target.name}</a>
            </div>
        </div>);
    })

    const news = bookmarks?.filter(i => i.newsItem).sort((l, r) => l.newsItem.title < r.newsItem.title ? -1 : 0).map(i => {
        const date = i.newsItem.date && moment(i.newsItem.date).format(DATE_STANDARD_RENDER_FORMAT);

        return (<div key={i.userBookmarkId} className="fn-section-item bookmarks" onClick={() => goToLink(`${i.newsItem.url}`)}>
            <div className="fn-flex-space-between">
                <div className="fn-subject">
                    {i.newsItem.title}
                </div>
                <div className="fn-action-link">
                    <button className="close" onClick={(e) => onRemoveBookmark(e, `${i.userBookmarkId}`)}>
                        <SvgIcon icon="icon-delete" width={8} height={8} />
                    </button>
                    <a href={i.newsItem.url}><SvgIcon icon="icon-chevron-circle-right" width={16} height={16}/></a>
                </div>
            </div>
            <div className="fn-action-other">
                <span>{ date }</span>
            </div>
        </div>);
    })

    const jobs = bookmarks?.filter(i => i.job).sort((l, r) => l.job.title < r.job.title ? -1 : 0).map(i => {
        return (<div key={i.userBookmarkId} className="fn-section-item bookmarks" onClick={() => goToLink(`/jobs/View/${i.job.id}`)}>
            <div className="fn-flex-space-between">
                <div className="fn-subject">
                    {i.job.title}
                </div>
                <div className="fn-action-link">
                    <button className="close" onClick={(e) => onRemoveBookmark(e, `${i.userBookmarkId}`)}>
                        <SvgIcon icon="icon-delete" width={8} height={8} />
                    </button>
                    <a href={`/jobs/View/${i.job.id}`}><SvgIcon icon="icon-chevron-circle-right" width={16} height={16}/></a>
                </div>
            </div>
            <div className="fn-action-other">
                {`${i.job.unit} | ${i.job.states.join(", ")} | ${i.job.serviceTitle} | ${i.job.positions} ${makePlural("position", i.job.positions)}`}
            </div>
        </div>);
    })

    const events = bookmarks?.filter(i => i.event).sort((l, r) => l.event.name < r.event.name ? -1 : 0).map(i => {
        const startDate = moment(i.event.startDateTime);
        const endDate = moment(i.event.endDateTime);
        const timeZone = moment().tz(i.event.timeZone).format("z");
        let eventDate = "";

        if (startDate.format("YYYYMMDD") == endDate.format("YYYYMMDD")) {
            eventDate = `${moment(startDate).format("D MMM YYYY HH:mm")} - ${moment(endDate).format("HH:mm")} ${timeZone}`;
        } else {
            eventDate = `${moment(startDate).format("D MMM YYYY HH:mm")} - ${moment(endDate).format("D MMM YYYY HH:mm")} ${timeZone}`;
        }

        return (<div key={i.userBookmarkId} className="fn-section-item bookmarks" onClick={() => goToLink(`/community/viewevent?Id=${i.event.eventId}`)}>
            <div className="fn-flex-space-between">
                <div className="fn-subject">
                    {i.event.name}
                </div>
                <div className="fn-action-link">
                    <button className="close" onClick={(e) => onRemoveBookmark(e, `${i.userBookmarkId}`)}>
                        <SvgIcon icon="icon-delete" width={8} height={8} />
                    </button>
                    <a href={`/community/viewevent?Id=${i.event.eventId}`}><SvgIcon icon="icon-chevron-circle-right" width={16} height={16}/></a>
                </div>
            </div>
            <div className="fn-action-other">
                <span>
                    { i.event.hostedByType == "Group" && <a href={`/community/viewgroup?Id=${i.event.hostedById}`}>{i.event.hostName}</a>}
                    { i.event.hostedByType == "User" && <a href={`/viewprofile?Id=${i.event.hostedById}`}>{i.event.hostName}</a>}
                </span>
                <span> | { eventDate }</span>
            </div>
        </div>);
    })

    const discussions = bookmarks?.filter(i => i.discussion).sort((l, r) => l.discussion.headPost.subject< r.discussion.headPost.subject ? -1 : 0).map(i => {
        return (<div key={i.userBookmarkId} className="fn-section-item bookmarks" onClick={() => goToLink(`${i.url}`)}>
            <div className="fn-flex-space-between">
                <div className="fn-subject">
                    {i.discussion.headPost.subject}
                </div>
                <div className="fn-action-link">
                    <button className="close" onClick={(e) => onRemoveBookmark(e, `${i.userBookmarkId}`)}>
                        <SvgIcon icon="icon-delete" width={8} height={8} />
                    </button>
                    <a href={`${i.url}`}><SvgIcon icon="icon-chevron-circle-right" width={16} height={16}/></a>
                </div>
            </div>
            <div>
                <a href={`/viewprofile?Id=${i.discussion.createdBy.id}`}>{i.discussion.createdBy.name}</a>
            </div>
        </div>);
    })

    const items = <div>
        { <div className="fn-section-header">Posts</div>}
        { <div className="fn-section-body">{posts?.length > 0 ? posts : <div className="fn-section-item no-items">No bookmarks added</div>}</div>}
        { <div className="fn-section-header">Events</div>}
        { <div className="fn-section-body">{events?.length > 0 ? events : <div className="fn-section-item no-items">No bookmarks added</div>}</div>}
        { <div className="fn-section-header">News</div>}
        { <div className="fn-section-body">{news?.length > 0 ? news : <div className="fn-section-item no-items">No bookmarks added</div>}</div>}
        { <div className="fn-section-header">Jobs</div>}
        { <div className="fn-section-body">{jobs?.length > 0 ? jobs : <div className="fn-section-item no-items">No bookmarks added</div>}</div>}
        { <div className="fn-section-header">Discussions</div>}
        { <div className="fn-section-body">{discussions?.length > 0 ? discussions : <div className="fn-section-item no-items">No bookmarks added</div>}</div>}
    </div>;

    return (<div id="primary" className="fn-bookmarks">
        <div className="panel">
            <div className="panel-heading">
                <h1 className="panel-title">Bookmarks</h1>
            </div>
            <div className="panel-body">
                { loading && <div className="fn-section-body-loading">Loading...</div>}
                { !loading && items }
            </div>
        </div>
    </div>
    )
}


import _ from "lodash";
import moment from "moment";
import { CalendarEvents } from "./CalendarEvents";

export default class DayView {

  Date: Date;
  Events: Event[];
  Holidays: Event[];
  CurrentMonthYear: Date;
  IsToday: boolean;

  constructor(CurrentMonthYear: Date, thisDate: Date, allEvents: Array<CalendarEvents>, holidayEvents: Array<CalendarEvents>) {
    this.Date = thisDate || moment();
    this.CurrentMonthYear = CurrentMonthYear || moment();
    this.Events = this._getEvents(allEvents);
    this.Holidays = this._getEvents(holidayEvents);
  }

  /**
   * Returns events for the day
   * @returns {Array<Event>|Array}
   * @private
   */
  _getEvents(allEvent: Array<CalendarEvents>) {
    return allEvent ? _.filter(allEvent.Events, evt => evt.StartDateTime.format("DDMMYYYY") === this.Date.format("DDMMYYYY")) : null;
  }

  isHoliday() {
    return this.Holidays && this.Holidays.length > 0;
  }

  isToday() {
    return this.Date ? this.Date.format("DDMMYYYY") === moment().format("DDMMYYYY") : false;
  }

  isFocusMonth() {
    return (this.CurrentMonthYear && this.Date) ? (moment(this.CurrentMonthYear).month() === moment(this.Date).month() && moment(this.CurrentMonthYear).year() === moment(this.Date).year()) : false;
  }

  isWeekend() {
    return moment(this.Date).day() === 0 || moment(this.Date).day() === 6;
  }

}
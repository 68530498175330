import * as React from "react";

function SvgIconForward(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 42 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M16.399 5.251c-.219.091-.436.18-.655.278-2.893 1.293-5.257 2.97-7.051 4.959H5.236v15.705h26.182v-4.369l5.239-3.526V31.43H.001V5.251zm9.818 8.998c-3.446 0-5.624.168-7.054.338-8.751 1.04-11.04 6.531-11.04 6.531C8.123 7.739 26.211 6.35 26.211 6.35V.001l15.688 10.558-15.682 10.558z"
      />
    </svg>
  );
}

export default SvgIconForward;


import React from "react";
import "./usage.css";

class GroupReportHeatmap extends React.Component {


  row(day, heatmap, dayname) {
    return <tr>
                <th><abbr title={dayname}>{day}</abbr></th>
                {_.map(heatmap, v => {
        let opacity = v / 100;
        return <td style={{ opacity: opacity }}>{"\u2b24"}<span className="sr-only">{v}</span></td>;
      })}
            </tr>;
  }

  render() {
    return <table className='report-heatmap'>
                <caption>Heatmap of pageviews per day</caption>
                {this.row('M', this.props.reportData.HeatmapMon, 'Monday')}
                {this.row('T', this.props.reportData.HeatmapTue, 'Tuesday')}
                {this.row('W', this.props.reportData.HeatmapWed, 'Wednesday')}
                {this.row('T', this.props.reportData.HeatmapThu, 'Thursday')}
                {this.row('F', this.props.reportData.HeatmapFri, 'Friday')}
                {this.row('S', this.props.reportData.HeatmapSat, 'Saturday')}
                {this.row('S', this.props.reportData.HeatmapSun, 'Sunday')}
                <tr>
                    <td />
                    <th colSpan={6}>12am</th>
                    <th colSpan={6}>6am</th>
                    <th colSpan={6}>12pm</th>
                    <th colSpan={6}>6pm</th>
                </tr>
			</table>;
  }
}

export default GroupReportHeatmap;
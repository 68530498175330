import React, {
    FC,
    useEffect,
    useCallback,
    useState,
}                           from "react";
import _	                from 'lodash';

import { 
    useMutation,
    useLazyQuery, 
}                           from "@apollo/client";

import {
    getMyLatestBookmarks_myLatestBookmarks,
    removeBookmarkVariables,
}                           from "../../../types/graphqlTypes";

import SvgIcon              from '../../icons';
import moment               from 'moment';

import Pluralize            from "../../vendor/typescript-pluralize/index";

import { DATE_STANDARD_RENDER_FORMAT } from "../../core/utils/DateTimeUtils";
import PromptActions        from "../../modules/prompt/actions/PromptActions";

const getMyLatestBookmarksGql = require("../../../gql/getMyLatestBookmarks.gql");
const removeBookmarkGql = require("../../../gql/removeBookmark.gql");

const makePlural = (word: string, count: number) => count != 1 ? Pluralize.plural(word) : word;

import "./LatestBookmarks.css"
import GroupTabsConstants from "../../core/constants/GroupTabsConstants";
import UserDisplayPreferenceActions    from '../../core/actions/UserDisplayPreferenceActions';

interface ILatestBookmarksProps {
    groupId?: string,
    isOpen: boolean,
    isPostBookmarkOpen: boolean;
    isEventBookmarkOpen: boolean;
    isNewsBookmarkOpen: boolean;
    isJobBookmarkOpen: boolean;
    isDiscussionBookmarkOpen: boolean;    
}

export const LatestBookmarks : FC<ILatestBookmarksProps> = (props: ILatestBookmarksProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
    const [isPostBookmarkOpen, setIsPostBookmarkOpen] = useState<boolean>(props.isPostBookmarkOpen);
    const [isEventBookmarkOpen, setIsEventBookmarkOpen] = useState<boolean>(props.isEventBookmarkOpen);
    const [isNewsBookmarkOpen, setIsNewsBookmarkOpen] = useState<boolean>(props.isNewsBookmarkOpen);
    const [isJobBookmarkOpen, setIsJobBookmarkOpen] = useState<boolean>(props.isJobBookmarkOpen); 
    const [isDiscussionBookmarkOpen, setIsDiscussionBookmarkOpen] = useState<boolean>(props.isDiscussionBookmarkOpen);  
    const savedIsOpen = props.isOpen;
    const savedIsPostBookmarkOpen = props.isPostBookmarkOpen;
    const savedIsEventBookmarkOpen = props.isEventBookmarkOpen;
    const savedIsNewsBookmarkOpen = props.isNewsBookmarkOpen;
    const savedIsJobBookmarkOpen = props.isJobBookmarkOpen;
    const savedIsDiscussionBookmarkOpen = props.isDiscussionBookmarkOpen;

    const [getMyLatestBookmarks, { loading, data, error, networkStatus, refetch }] = useLazyQuery(getMyLatestBookmarksGql, { 
        fetchPolicy: "network-only" });
    const bookmarks: Array<getMyLatestBookmarks_myLatestBookmarks> = data?.myLatestBookmarks;
    const [removeBookmark] = useMutation(removeBookmarkGql);
    const refresh = useCallback(() => {
        getMyLatestBookmarks();
    }, []);

    useEffect(() => {
        getMyLatestBookmarks();
    }, []);

    useEffect(() => {
        window.addEventListener("refreshLatestBookmarks", refresh);
        return () => {
            window.removeEventListener("refreshLatestBookmarks", refresh);
        };
    });

    useEffect(() => {
        const saveBookmarkState = (e) => {
            let savedDisplayPreferences = { 
                ShowBookmarks: isOpen,
                ShowPostBookmarks: isPostBookmarkOpen,
                ShowEventBookmarks: isEventBookmarkOpen,
                ShowNewsBookmarks: isNewsBookmarkOpen,
                ShowJobBookmarks: isJobBookmarkOpen,
                ShowDiscussionBookmarks: isDiscussionBookmarkOpen
            };
            const isDirty = savedIsOpen != isOpen 
            || savedIsPostBookmarkOpen != isPostBookmarkOpen
            || savedIsEventBookmarkOpen != isEventBookmarkOpen
            || savedIsJobBookmarkOpen != isJobBookmarkOpen
            || savedIsDiscussionBookmarkOpen != isDiscussionBookmarkOpen
            || savedIsNewsBookmarkOpen !=  isNewsBookmarkOpen;
            if (isDirty){
                UserDisplayPreferenceActions.saveDisplayPreferences(savedDisplayPreferences); 
            }
        }

        window.addEventListener("beforeunload", saveBookmarkState);
        return () => {
            window.removeEventListener("beforeunload", saveBookmarkState);
        };
    }, [isOpen, isPostBookmarkOpen, isEventBookmarkOpen, isNewsBookmarkOpen, isJobBookmarkOpen, isDiscussionBookmarkOpen]);

    const goToLink = (url) => {
        window.location.href = url;
    }
    
    const onRemoveBookmark = (e, bookmarkId) => {
        e.preventDefault(); 

        removeBookmark({
            variables: {
                id: bookmarkId,
            } as removeBookmarkVariables
        }).then((savedList) => {
            const errors = savedList?.data?.createBookmark?.errors;

            if (errors && errors.length > 0) {
                throw errors[0];
            }

            window.dispatchEvent(new Event("refreshLatestBookmarks"));
        }).catch(err => {
            PromptActions.displayFriendlyErrorPrompt(err);

        }).finally();
        
        e.stopPropagation();
    }

    const posts = bookmarks?.filter(i => i.post).sort((l, r) => l.post.subject ?? l.post.rawMessage < r.post.subject ?? r.post.rawMessage ? -1 : 0).map(i => {
        return (<div key={i.userBookmarkId} className="fn-section-item latest-bookmarks" onClick={() => goToLink(`/community/view-post?Id=${i.post.id}`)}>
            <div className="fn-flex-space-between">
                <div className="fn-subject">
                    {i.post.subject ?? i.post.rawMessage}
                </div>
                <div className="fn-action-link">
                    <button className="close" onClick={(e) => onRemoveBookmark(e, `${i.userBookmarkId}`)}>
                        <SvgIcon icon="icon-delete" width={8} height={8} />
                    </button>
                    <a href={`/community/view-post?Id=${i.post.id}`}><SvgIcon icon="icon-chevron-circle-right" width={16} height={16}/></a>
                </div>
            </div>
            <div>
                <a href={`/viewprofile?Id=${i.post.author.id}`}>{i.post.author.name}</a> | <a href={i.post.target.url}>{i.post.target.name}</a>
            </div>
        </div>);
    })

    const news = bookmarks?.filter(i => i.newsItem).sort((l, r) => l.newsItem.title < r.newsItem.title ? -1 : 0).map(i => {
        const date = i.newsItem.date && moment(i.newsItem.date).format(DATE_STANDARD_RENDER_FORMAT);
        return (<div key={i.userBookmarkId} className="fn-section-item latest-bookmarks" onClick={() => goToLink(`${i.newsItem.url}`)}>
            <div className="fn-flex-space-between">
                <div className="fn-subject">
                    {i.newsItem.title}
                </div>
                <div className="fn-action-link">
                    <button className="close" onClick={(e) => onRemoveBookmark(e, `${i.userBookmarkId}`)}>
                        <SvgIcon icon="icon-delete" width={8} height={8} />
                    </button>
                    <a href={i.newsItem.url}><SvgIcon icon="icon-chevron-circle-right" width={16} height={16}/></a>
                </div>
            </div>
            <div>
                <span>{ date }</span>
            </div>
        </div>);
    })

    const jobs = bookmarks?.filter(i => i.job).sort((l, r) => l.job.title < r.job.title ? -1 : 0).map(i => {
        return (<div key={i.userBookmarkId} className="fn-section-item latest-bookmarks" onClick={() => goToLink(`/jobs/View/${i.job.id}`)}>
            <div className="fn-flex-space-between">
                <div className="fn-subject">
                    {i.job.title}
                </div>
                <div className="fn-action-link">
                    <button className="close" onClick={(e) => onRemoveBookmark(e, `${i.userBookmarkId}`)}>
                        <SvgIcon icon="icon-delete" width={8} height={8} />
                    </button>
                    <a href={`/jobs/View/${i.job.id}`}><SvgIcon icon="icon-chevron-circle-right" width={16} height={16}/></a>
                </div>
            </div>
            <div>
                {`${i.job.unit} | ${i.job.states.join(", ")} | ${i.job.serviceTitle} | ${i.job.positions} ${makePlural("position", i.job.positions)}`}
            </div>
        </div>);
    })

    const events = bookmarks?.filter(i => i.event).sort((l, r) => l.event.name < r.event.name ? -1 : 0).map(i => {
        const startDate = moment(i.event.startDateTime);
        const endDate = moment(i.event.endDateTime);
        const timeZone = moment().tz(i.event.timeZone).format("z");
        let eventDate = "";

        if (startDate.format("YYYYMMDD") == endDate.format("YYYYMMDD")) {
            eventDate = `${moment(startDate).format("D MMM YYYY HH:mm")} - ${moment(endDate).format("HH:mm")} ${timeZone}`;
        } else {
            eventDate = `${moment(startDate).format("D MMM YYYY HH:mm")} - ${moment(endDate).format("D MMM YYYY HH:mm")} ${timeZone}`;
        }

        return (<div key={i.userBookmarkId} className="fn-section-item latest-bookmarks" onClick={() => goToLink(`/community/viewevent?Id=${i.event.eventId}`)}>
            <div className="fn-flex-space-between">
                <div className="fn-subject">
                    {i.event.name}
                </div>
                <div className="fn-action-link">
                    <button className="close" onClick={(e) => onRemoveBookmark(e, `${i.userBookmarkId}`)}>
                        <SvgIcon icon="icon-delete" width={8} height={8} />
                    </button>
                    <a href={`/community/viewevent?Id=${i.event.eventId}`}><SvgIcon icon="icon-chevron-circle-right" width={16} height={16}/></a>
                </div>
            </div>
            <div>
                <span>
                    { i.event.hostedByType == "Group" && <a href={`/community/viewgroup?Id=${i.event.hostedById}`}>{i.event.hostName}</a>}
                    { i.event.hostedByType == "User" && <a href={`/viewprofile?Id=${i.event.hostedById}`}>{i.event.hostName}</a>}
                </span>
                <span> | { eventDate }</span>
            </div>
        </div>);
    })

    const discussions = bookmarks?.filter(i => i.discussion).sort((l, r) => l.discussion.headPost.subject< r.discussion.headPost.subject ? -1 : 0).map(i => {
        return (<div key={i.userBookmarkId} className="fn-section-item latest-bookmarks" onClick={() => goToLink(`${i.url}`)}>
            <div className="fn-flex-space-between">
                <div className="fn-subject">
                    {i.discussion.headPost.subject}
                </div>
                <div className="fn-action-link">
                    <button className="close" onClick={(e) => onRemoveBookmark(e, `${i.userBookmarkId}`)}>
                        <SvgIcon icon="icon-delete" width={8} height={8} />
                    </button>
                    <a href={`${i.url}`}><SvgIcon icon="icon-chevron-circle-right" width={16} height={16}/></a>
                </div>
            </div>
            <div>
                <a href={`/viewprofile?Id=${i.discussion.createdBy.id}`}>{i.discussion.createdBy.name}</a>
            </div>
        </div>);
    })

    const togglePostBookmark = () => {
        setIsPostBookmarkOpen(!isPostBookmarkOpen);
    }
    const toggleEventBookmark = () => {
        setIsEventBookmarkOpen(!isEventBookmarkOpen);
    }
    const toggleNewsBookmark = () => {
        setIsNewsBookmarkOpen(!isNewsBookmarkOpen);
    }
    const toggleJobBookmark = () => {
        setIsJobBookmarkOpen(!isJobBookmarkOpen);
    }
    const toggleDiscussionBookmark = () => {
        setIsDiscussionBookmarkOpen(!isDiscussionBookmarkOpen);
    }

    const buttonPostBookmarClassName = `fn-icon-button fn-float-right${(!isPostBookmarkOpen ? " fn-icon-rotate-180" : "")}`;
    const buttonEventBookmarClassName = `fn-icon-button fn-float-right${(!isEventBookmarkOpen ? " fn-icon-rotate-180" : "")}`;
    const buttonNewsBookmarClassName = `fn-icon-button fn-float-right${(!isNewsBookmarkOpen ? " fn-icon-rotate-180" : "")}`;
    const buttonJobBookmarClassName = `fn-icon-button fn-float-right${(!isJobBookmarkOpen ? " fn-icon-rotate-180" : "")}`;
    const buttonDiscussionBookmarClassName = `fn-icon-button fn-float-right${(!isDiscussionBookmarkOpen ? " fn-icon-rotate-180" : "")}`;

    const items =<div>
        { posts?.length > 0 && <div className="groups-list">
            <div className="fn-react-root block">
                <div className="fn-section-header">
                    Posts
                    <span className={buttonPostBookmarClassName} onClick={togglePostBookmark}>
                        <span className="fn-icon fn-icon-arrow-down">
                        </span>
                    </span>
                </div>
                { isPostBookmarkOpen && <div id="postListRight" className="fn-section-right group-list-container">
                    {posts}
                    </div>
                }                 
            </div>
        </div>
        }
        { events?.length > 0 && <div className="groups-list">
            <div className="fn-react-root block">
                <div className="fn-section-header">
                    Events
                    <span className={buttonEventBookmarClassName} onClick={toggleEventBookmark}>
                        <span className="fn-icon fn-icon-arrow-down">
                        </span>
                    </span>
                </div>
                { isEventBookmarkOpen && <div id="eventListRight" className="fn-section-right group-list-container">
                    {events}
                    </div>
                }                 
            </div>
        </div>
        }
        { news?.length > 0 && <div className="groups-list">
            <div className="fn-react-root block">
                <div className="fn-section-header">
                    News
                    <span className={buttonNewsBookmarClassName} onClick={toggleNewsBookmark}>
                        <span className="fn-icon fn-icon-arrow-down">
                        </span>
                    </span>
                </div>
                { isNewsBookmarkOpen && <div id="newsListRight" className="fn-section-right group-list-container">
                    {news}
                    </div>
                }                 
            </div>
        </div>
        }
        { jobs?.length > 0 && <div className="groups-list">
            <div className="fn-react-root block">
                <div className="fn-section-header">
                    Jobs
                    <span className={buttonJobBookmarClassName} onClick={toggleJobBookmark}>
                        <span className="fn-icon fn-icon-arrow-down">
                        </span>
                    </span>
                </div>
                { isJobBookmarkOpen && <div id="jobsListRight" className="fn-section-right group-list-container">
                    {jobs}
                    </div>
                }                 
            </div>
        </div>
        }
        { discussions?.length > 0 && <div className="groups-list">
            <div className="fn-react-root block">
                <div className="fn-section-header">
                    Discussions
                    <span className={buttonDiscussionBookmarClassName} onClick={toggleDiscussionBookmark}>
                        <span className="fn-icon fn-icon-arrow-down">
                        </span>
                    </span>
                </div>
                { isDiscussionBookmarkOpen && <div id="discussionsListRight" className="fn-section-right group-list-container">
                    {discussions}
                    </div>
                }                 
            </div>
        </div>
        }
    </div>;

    const toggle = () => {
        setIsOpen(!isOpen);
    }    

    const buttonClassName = `fn-icon-button fn-float-right${(!isOpen ? " fn-icon-rotate-180" : "")}`;
 
    return (
        <div>
            <div className="fn-header">
                <span>
                    <SvgIcon icon="icon-bookm-ark" width="13" height="20" /> &nbsp;Latest bookmarks
                </span>
                <span className={buttonClassName} onClick={toggle}>
                    <span className="fn-icon fn-icon-arrow-down fn-icon-white">
                    </span>
                </span>                
                <span className="fn-float-right">
                    <a href="/bookmarks">See all<span className="no-tablet"> bookmarks&nbsp;&nbsp;</span></a>
                </span>
            </div>
            <div className="fn-section-right">
                { loading && (bookmarks == null || bookmarks?.length == 0) && <div className="fn-section-body-loading">Loading...</div>}
                { isOpen && (!loading || bookmarks?.length > 0) && items }
            </div>
        </div>
    )
}

export default LatestBookmarks;
class DefenceVideoProvider {
    extractUrl(url) {
        if (!url){ return null; }
        let matches = url.match(DefenceVideoProvider._extract);
        let extract = null;

        if (matches !== null) {
            extract = `http://video.${matches[2]}.gov.au/play/${matches[3]}`;
        }

        return extract;
    }

    decode(oEmbedly) {
        let url = this.extractUrl(oEmbedly.originalUrl);
        let decoded = false;

        if (url) {
            let thumbnailUrl = decodeURIComponent(oEmbedly.thumbnailUrl);
            let matches = thumbnailUrl.match(DefenceVideoProvider._decode);

            if (matches !== null) {
                oEmbedly.decodedUrl = `http://player.ooyala.com/static/${DefenceVideoProvider.PlayerVersion}/skin-plugin/iframe.html?ec=${matches[1]}&pbid=${DefenceVideoProvider.PlayerBrandingId}&pcode=${DefenceVideoProvider.PlayerId}${DefenceVideoProvider.AutoPlay ? '&autoplay=true' : ''}`;
                oEmbedly.decodedThumbnailUrl = `https://cf.c.ooyala.com/${matches[1]}/${matches[2]}`;
                oEmbedly.decodedVideoId = matches[1];
                decoded = true;
            }
        }
        oEmbedly.decodedVideo = decoded;

        return decoded;
    }
}

DefenceVideoProvider._extract = new RegExp("^(http[s]?://)?video.(defence|navy|army|airforce).gov.au/play/([^#]*).*");
DefenceVideoProvider._decode = new RegExp(".*url=http://cf\.c\.ooyala\.com/(.*)/(.*)&key=(.*)");

// Optionally set in OoyalaUrlProvider to override
DefenceVideoProvider.PlayerId = 'F4ZzIyOi-LDj1f7SH2kwHBmsHxoF';
DefenceVideoProvider.PlayerBrandingId = 'f4b32ab0d0464b628e6dac4da6abf0c3';
DefenceVideoProvider.PlayerVersion = 'v4/stable/4.10.4';
DefenceVideoProvider.AutoPlay = true;

/**
 * Exports singleton
 */
export default new DefenceVideoProvider();
import * as React from "react";

function SvgIconBookmarkO(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 28"
      {...props}
    >
      <path
        fill="currentColor"
        d="M18 4H2v19.406l8-7.672 1.391 1.328L18 23.406V4zm.188-2c.234 0 .469.047.688.141a1.73 1.73 0 011.125 1.609v20.141a1.73 1.73 0 01-1.125 1.609 1.727 1.727 0 01-.688.125 1.9 1.9 0 01-1.297-.5L10 18.5l-6.891 6.625a1.914 1.914 0 01-1.297.516c-.234 0-.469-.047-.688-.141a1.73 1.73 0 01-1.125-1.609V3.75a1.73 1.73 0 011.125-1.609c.219-.094.453-.141.688-.141h16.375z"
      />
    </svg>
  );
}

export default SvgIconBookmarkO;

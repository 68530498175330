import React from 'react';
import PropTypes from 'prop-types'
import FileUtils from '../../../core/utils/FileUtils';
import FileExplorerStore from '../stores/FileExplorerStore';
import Modal from '../../../core/components/modal/Modal';
import BtnClose from '../../../core/components/BtnClose';
import FolderSelect from './FolderSelect';


class ModalFolderSelect extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			edit: { valid: true, title: '' }
		};

		this._setFolderOptions(props.folders);
	}

	_setFolderOptions(folders) {
		this.folderOptions = folders;
	}

	componentWillReceiveProps(props) {
		let title = '';

		if (props.folder != null && props.folder.name != null) {
			title = props.folder.name;
		}

		let valid = 0 != title.trim().length;

		this.setState({
			edit: { valid: valid, title: title }
		});
	}

	handleFocus(e) {
		e.target.select();
	}

	_handleModalCloseRequest(event) {
		event.preventDefault();

		this.props.onCloseClick()
	}

	_handleUpdateClick(event) {
		event.preventDefault();

		if (this.state.edit.valid) {
			this.props.onFolderSelected(
				!this.refs.folderSelect.isCreateFolder() ? this.refs.folderSelect.getFolderId() : null,
				this.refs.folderSelect.isCreateFolder() ? this.refs.folderSelect.getFolderTitle() : null,
				this.refs.folderSelect.isCreateFolder() ? this.refs.folderSelect.getParentFolderId() : null);
		}
	}

	_handleFolderChange() {
		let edit = this.state.edit;
		edit.valid = this.refs.folderSelect.isValid()

		this.setState({
			edit: edit
		});
	}

	render() {
		let name = "this folder";
		if (this.props.folder != null) {
			name = `"${this.props.folder.name}"`;
		}

		let saveButtonClassName = 'btn btn-secondary';
		if (!this.state.edit.valid) {
			saveButtonClassName += ' disabled';
		}

		return (
			<Modal onRequestsClose={this._handleModalCloseRequest.bind(this)} active={this.props.active} size='default'>
				<form className="modal-form" onSubmit={this._handleUpdateClick.bind(this)}>
					<div className='modal-header'>
						<BtnClose onClick={this._handleModalCloseRequest.bind(this)} />
						<h2 className='modal-title'>Select {this.props.folderLabel}</h2>
					</div>

					<div className='modal-content file-modal'>
						<FolderSelect ref="folderSelect" folderLabel={this.props.folderLabel} creationFolders={this.props.creationFolders} folderOptions={this.folderOptions} defaultFolderId={this.folder} onChange={this._handleFolderChange.bind(this)} showLabel={false} />
					</div>

					<div className="modal-actions">
                        <button role="button" className="btn btn-default" onClick={this._handleModalCloseRequest.bind(this)}>Cancel</button>
						&nbsp;
						<button role="button" className={saveButtonClassName} onClick={this._handleUpdateClick.bind(this)}>Upload file</button>
					</div>
				</form>
			</Modal>
		);
	}
}

ModalFolderSelect.defaultProps = {
	folderLabel: 'Folder',
	toggleUploadTypeText: ''
}

ModalFolderSelect.propTypes = {
	folder: PropTypes.object.isRequired,
	active: PropTypes.bool.isRequired,
	folderLabel: PropTypes.string,
	folders: PropTypes.array,
	toggleUploadTypeText: PropTypes.string
};

export default ModalFolderSelect;

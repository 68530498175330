import Promise from 'bluebird';
import _ from 'lodash';
import Action from '../../../core/models/Action';
import AppDispatcher from '../../../core/dispatcher/AppDispatcher';
import UserTempStoreSvc from '../../../services/UserTempStoreSvc';

class UserTempStoreActions {

    constructor() {

    }

	static async getLastSave(type) {
		var data = await UserTempStoreSvc.getLastSave(type);
		var obj = JSON.parse(data.d);		
		return obj;

	}
	static async removeLastSave(type) {
		var data = await UserTempStoreSvc.removeLastSave(type);
		var obj = JSON.parse(data.d);
		return obj;

	}	
	static async saveToTempStore(type, data) {
		var result = await UserTempStoreSvc.saveToTempStore(type, data);
		var obj = JSON.parse(result.d);
		return obj;
    }
}

export default UserTempStoreActions;
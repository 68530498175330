import React, {
    FC,
    useState,
    useEffect,
    useRef,
}                           from "react";
import _	                from 'lodash';

import { 
    useLazyQuery, 
    useMutation,
}                           from "@apollo/client";

import {
    getMyAdministrativeActions_myAdministrativeActions,
    getGroupMemberships_groupMemberships,
    AdministrativeActionType,
    GroupMembershipStatus,
    assignTaskVariables,
}                           from "../../../../types/graphqlTypes";

import SvgIcon              from '../../../icons';
import PromptActions        from "../../prompt/actions/PromptActions";
import Popover              from '../../../core/components/Popover';
import { JoinRequestFilter } from "../../groupJoinRequests/components/GroupJoinRequests";
import moment               from 'moment';

import { DATE_STANDARD_RENDER_FORMAT } from "../../../core/utils/DateTimeUtils";

import Pluralize            from "../../../vendor/typescript-pluralize/index";
import ActionsFilter        from "./ActionsFilter";

const getMyAdministrativeActionsGql = require("../../../../gql/getMyAdministrativeActions.gql");

const getGroupMembershipsGql = require("../../../../gql/getGroupMemberships.gql");
const { assignTask: assignTaskGql } = require("../../../../gql/assignTask.gql");

const DateColumn = "DATE";
const TypeColumn = "TYPE";
const ExpiryDateColumn = "EXPIRY_DATE";

const makePlural = (word: string, count: number) => count != 1 ? Pluralize.plural(word) : word;

interface IAdministrativeActionProps {
    action: getMyAdministrativeActions_myAdministrativeActions,
    refresh: any;
    sortColumn: string;
}

const AdministrativeAction : FC<IAdministrativeActionProps> = (props: IAdministrativeActionProps) => {
    const [getGroupMemberships, { loading, data, error, networkStatus, refetch }] = useLazyQuery(getGroupMembershipsGql);
    const [assignTask] = useMutation(assignTaskGql);
    const popOver = useRef(null);
    const selectedUserRef = useRef(null);
    const groupMemberships: getGroupMemberships_groupMemberships = data?.groupMemberships;
    const [selectedUser, setSelectedUser] = useState("");
    const action = props.action;
    const dt = moment(props?.action.date);
    const expiryDt = props?.action?.expiryDate && moment(props?.action.expiryDate);
    const [isOpen, setIsOpen] = useState(false);
  
    const groupMembers = groupMemberships?.groupMembers
        ? _.cloneDeep(groupMemberships.groupMembers).sort((l, r) => (l.user.fullName < r.user.fullName ? -1 : 0))
        : null;

    const toggle = (e) => {
        if (!popOver!.current.state.open) {
            getGroupMemberships({
                variables: {
                    id: props.action.group.groupId,
                    page: {
                        "pageFirst": 1000
                    },
                    queryParameters: {
                        membershipStatuses: [ GroupMembershipStatus.GROUP_COMMANDER, GroupMembershipStatus.GROUP_PRIMARY_ADMINISTRATOR, GroupMembershipStatus.GROUP_ADMINISTRATOR ]
                    }
                }
            });

            setSelectedUser("");
        }
        popOver.current._toggle(e);
    }

    const onCancel = (e) => {
        e.preventDefault();
        setSelectedUser("");
        popOver.current._toggle(e);
    }

    const onAssign = (e) => {
        e.preventDefault();
        if (selectedUser) {
            const task = {
                groupJoinRequestGroupId: action.group.groupId,
                groupJoinRequestUserId: action.user.id,
                assignToUserId: selectedUser
            };

            assignTask({
                variables: {
                    task
                } as assignTaskVariables
            }).then((savedList) => {
                const errors = savedList?.data?.saveGroupList?.errors;

                if (errors && errors.length > 0) {
                    throw errors[0];
                }

                setSelectedUser("");
                popOver.current._toggle(e);
                props.refresh();
            }).catch(err => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally();
        }
    }

    const onChangeUser = (e) => {
        setSelectedUser(e.target.value);
    }

    const onToggled = (open) => {
        setIsOpen(open);
    }

    const assignClassName = `btn btn-primary fn-margin-left-small${!selectedUser ? " disabled" : ""}`;
    var EOT = moment("9999/01/01", "YYYY/MM/DD");
    const hasEndDate= (theDate) => {
        return theDate.diff(EOT) < 0;
    }
    return (
        <tr className={isOpen ? "selected" : ""}>
            <td className={props.sortColumn == TypeColumn ? "sort-active" : ""} data-th="Type">{props.action.description}</td>
            <td className={props.sortColumn == DateColumn ? "sort-active" : ""} data-th="Date">{dt.format(DATE_STANDARD_RENDER_FORMAT)}</td>
            <td className={props.sortColumn == ExpiryDateColumn ? "sort-active" : ""} data-th="Expiry date">{expiryDt && hasEndDate(expiryDt) && expiryDt.format(DATE_STANDARD_RENDER_FORMAT)}</td>
            <td>
                <div>
                    <div className="fn-action-user">
                        <img src={`/api/v1/image?id=${action.user.profileImageId}&amp;width=30&amp;height=30`} />
                    </div>
                    <div>
                        <a href={`/viewprofile?Id=${action.user.id}`} target="_blank" rel="noreferrer">{action.user.fullName}</a>
                        <span> {props.action.title} </span>
                        { props.action.event && <a href={`/community/viewevent?Id=${action.event.eventId}`} target="_blank" rel="noreferrer">{action.event.name}</a>}
                        { !props.action.event && props.action.group && <a href={`/community/viewgroup?Id=${action.group.groupId}`} target="_blank" rel="noreferrer">{action.group.name}</a>}
                        <Popover ref={popOver} onToggled={onToggled}>
                            <label>Assign action to:</label>
                            <select ref={selectedUserRef} className="form-control" onChange={onChangeUser} value={selectedUser}>
                                <option key={0} value="">Please select a user</option>
                                {
                                    groupMembers?.map(i => {
                                        return <option key={i.user.id} value={i.user.id}>{i.user.fullName}</option>;
                                    })
                                }
                            </select>
                            <span>
                                <button className="btn btn-default" onClick={onCancel}>Cancel</button>
                                <button className={assignClassName} onClick={onAssign}>{action.taskAssignedByUser ? "Reassign" : "Assign"}</button>
                            </span>
                        </Popover>
                        { action.taskAssignedByUser && <div className="fn-action-assignment">Assigned by <a href={`/viewprofile?Id=${action.taskAssignedByUser.id}`} target="_blank" rel="noreferrer">{action.taskAssignedByUser.fullName}</a> on {moment(props?.action.taskAssignedDate).format(DATE_STANDARD_RENDER_FORMAT)}</div>}
                    </div>
                </div>
            </td>
            <td className="fn-action-links">
                <span>
                    { props.action.type == AdministrativeActionType.GROUP_JOIN_REQUEST && <a href={`/community/viewgroup?Id=${action.group?.groupId}&View=JoinRequests&filter=${action.taskAssignedToUser ? JoinRequestFilter.ME : JoinRequestFilter.UNASSIGNED}`}>View</a> }
                    { props.action.type == AdministrativeActionType.ABUSE_REPORTS && <a href={`/tools/forcenetsupport/manage-complaints?GroupId=${action.group?.groupId}`}>View</a> }
                    { props.action.type == AdministrativeActionType.GROUP_APPROVAL && <a href={`/community/creategroup?Id=${action.group?.groupId}&amp;IsManage=True&amp;ReturnPath=/actions`}>View</a> }
                    { props.action.type == AdministrativeActionType.JOB_APPROVAL && <a href={`/jobs/edit-job?Id=${action.job?.id}`}>View</a> }
                    { props.action.type == AdministrativeActionType.JOB_EXPIRY && <a href={`/jobs/edit-job?Id=${action.job?.id}`}>View</a> }
                </span>
                { action.type == AdministrativeActionType.GROUP_JOIN_REQUEST && <span><a onClick={toggle}>{action.taskAssignedByUser ? "Reassign" : "Assign"}</a></span> }
            </td>
        </tr>
    )
}

interface IAdministrativeActionsProps {
    setAdministrativeCount: any;
   
}


export const AdministrativeActions : FC<IAdministrativeActionsProps> = (props: IAdministrativeActionsProps) => {
    const [getActions, { loading, data, error, networkStatus, refetch }] = useLazyQuery(getMyAdministrativeActionsGql);
    const actions: Array<getMyAdministrativeActions_myAdministrativeActions> = data?.myAdministrativeActions;
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const SortAscending = "sort-asc";
    const SortDescending = "sort-desc";
    const [showAllRows, setShowAllRows] = useState(false);
    const [showSortByMenu, setShowSortByMenu] = useState(false);
    const [showActions, setShowActions] = useState(true);
    const [groupapproval, setgroupapproval] = useState(false);
    const [join, setjoin] = useState(false);
    const [jobapproval, setjobapproval] = useState(false);
    const [complaints, setcomplaints] = useState(false);
    const [jobexpiry, setjobexpiry] = useState(false);
    const [lastvalue, setlastvalue] = useState(null);
  

    useEffect(() => {
        const savedSort = sessionStorage.getItem("administrative-actions-sort");

        if (savedSort) {
            const typeAndOrder = savedSort.split(":");

            if (typeAndOrder.length == 2) {
                setSortColumn(typeAndOrder[0]);
                setSortOrder(typeAndOrder[1]);
            }
        }

        getActions();

    }, []);

    const refresh = () => {
        refetch();
    }

    const setSortOrderAndType = (val, order) => {
        let newSortOrder; 

        if (sortColumn == val) {
            if (!order) {
                if (sortOrder == SortDescending) {
                    newSortOrder = SortAscending;
                } else {
                    newSortOrder = SortDescending;
                }
            } else {
                newSortOrder = order;
            }
        } else {
            setSortColumn(val);
            newSortOrder = order ?? SortAscending;
        }

        setSortOrder(newSortOrder);

        sessionStorage.setItem("administrative-actions-sort", `${val}:${newSortOrder}`);

        setShowSortByMenu(false);
    }
   const togglegroupapproval = (e) => {
      //  e.preventDefault();
       setgroupapproval(e.target.checked);

    }

    const togglejoin = (e) => {
     //   e.preventDefault();
        setjoin(e.target.checked);
    }


    const togglejobapproval  = (e) => {
      //  e.preventDefault();  
       setjobapproval(e.target.checked);
    }

  const togglejobexpiry = (e) => {
    //    e.preventDefault();
    
      setjobexpiry(e.target.checked);
    }
   const  togglecomplaints = (e) => {
    //    e.preventDefault();
       setcomplaints(e.target.checked);
    }
    const sortByType = () => {
        setSortOrderAndType(TypeColumn, null);
    }

    const sortByTypeAscending = () => {
        setSortOrderAndType(TypeColumn, SortAscending);
    }

    const sortByTypeDescending = () => {
        setSortOrderAndType(TypeColumn, SortDescending);
    }

    const sortByDate = () => {
        setSortOrderAndType(DateColumn, null);
    }

    const sortByDateAscending = () => {
        setSortOrderAndType(DateColumn, SortAscending);
    }

    const sortByDateDescending = () => {
        setSortOrderAndType(DateColumn, SortDescending);
    }

    const sortByExpiryDate = () => {
        setSortOrderAndType(ExpiryDateColumn, null);
    }

    const sortByExpiryDateAscending = () => {
        setSortOrderAndType(ExpiryDateColumn, SortAscending);
    }

    const sortByExpiryDateDescending = () => {
        setSortOrderAndType(ExpiryDateColumn, SortDescending);
    }

    

    if (actions) {
        props.setAdministrativeCount(actions.length);
       
    }
    var filteraction = actions;

    if (actions && (groupapproval  || join || jobapproval  || jobexpiry || complaints))
        {
        if (!groupapproval) {
            filteraction = filteraction.filter(a => a.type !== AdministrativeActionType.GROUP_APPROVAL)
        }

        if (!join) {
            filteraction = filteraction.filter(a => a.type !== AdministrativeActionType.GROUP_JOIN_REQUEST)
        }

        if (!jobapproval) {
            filteraction = filteraction.filter(a => a.type !== AdministrativeActionType.JOB_APPROVAL)
        }
        if (!jobexpiry) {
            filteraction = filteraction.filter(a => a.type !== AdministrativeActionType.JOB_EXPIRY)
        }
        if (!complaints) {
            filteraction = filteraction.filter(a => a.type !== AdministrativeActionType.ABUSE_REPORTS)
        }

       
       }
    const sortedActions = sortColumn && filteraction
        ? _.cloneDeep(filteraction).sort((l, r) => {
            if (sortColumn == TypeColumn) {
                return sortOrder == SortAscending
                    ? l.description < r.description ? -1 : 1
                    : r.description < l.description ? -1 : 1;
            } else if (sortColumn == DateColumn) {
                const ldate = moment(l.date);
                const rdate = moment(r.date);
                
                return (sortOrder == SortAscending ? ldate.isBefore(rdate) : ldate.isAfter(rdate)) ? -1 : 1;
            } else if (sortColumn == ExpiryDateColumn) {
                if (!l.expiryDate || !r.expiryDate) {
                    if (l.expiryDate && !r.expiryDate) {
                        return sortOrder == SortAscending ? -1 : 1;
                    }
                    if (r.expiryDate) {
                        return sortOrder == SortDescending ? -1 : 1;
                    }

                    return 0;
                }

                const ldate = moment(l.expiryDate);
                const rdate = moment(r.expiryDate);
                
                return (sortOrder == SortAscending ? ldate.isBefore(rdate) : ldate.isAfter(rdate)) ? -1 : 1;
            }
        })
        : filteraction;

    const toggleShowAll = () => {
        if (showAllRows) {
            window.scrollTo({top: 0});
        }

        setShowAllRows(!showAllRows)
    }

    const toggleSortByMenu = (e) => {
        e.preventDefault();
        setShowSortByMenu(!showSortByMenu);
    }

    const toggleShowActions = (e) => {
        e.preventDefault();
        setShowActions(!showActions);
    }
    
    /*
     ABUSE_REPORTS
GROUP_APPROVAL
JOB_APPROVAL
JOB_EXPIRY
GROUP_JOIN_REQUEST

    */
   
    const maxRows = 2;
    const maxRowsExceeded = sortedActions?.length > maxRows;
    const tbodyClass = maxRowsExceeded && !showAllRows ? "mobile-max" : "";

    let sortText;

    switch (sortColumn) {
        case TypeColumn:
            sortText = `: Type (${sortOrder !== SortDescending ? "A-Z" : "Z-A"})`;
            break;
        case DateColumn:
            sortText = `: Date (${sortOrder !== SortDescending ? "most recent first" : "least recent first"})`;
            break;
        case ExpiryDateColumn:
            sortText = `: Expiry date (${sortOrder !== SortDescending ? "most recent first" : "least recent first"})`;
            break;
    }

    return (
        <div className="fn-administrative-actions">
            <ActionsFilter className="no-mobile" togglegroupapproval={togglegroupapproval} groupapproval={groupapproval} togglejoin={togglejoin} join={join} togglejobapproval={togglejobapproval} jobapproval={jobapproval} togglejobexpiry={togglejobexpiry} jobexpiry={jobexpiry} togglecomplaints={togglecomplaints} complaints={complaints} />
           
           
            <div className="heading" onClick={toggleShowActions}>
                <h3>Admin</h3>
                { loading ? <span>Loading…</span> : null}
                {!loading && sortedActions ? <span>{sortedActions.length} {makePlural("action", sortedActions.length)}</span> : null}

               
              
                <SvgIcon icon={`${showActions ? "icon-arrow-up" : "icon-arrow-down"}`}/>
            </div>
            <div>

                <ActionsFilter className="mobile-only" togglegroupapproval={togglegroupapproval} groupapproval={groupapproval} togglejoin={togglejoin} join={join} togglejobapproval={togglejobapproval} jobapproval={jobapproval} togglejobexpiry={togglejobexpiry} jobexpiry={jobexpiry} togglecomplaints={togglecomplaints} complaints={complaints} />
              
            </div>
            { !loading && showActions && sortedActions?.length > 0 && <div className="no-desktop fn-actions-menu">
                <a onClick={toggleSortByMenu}>Sort by{sortText}<SvgIcon icon={`${showSortByMenu ? "icon-arrow-up" : "icon-arrow-down"}`}/></a>
            </div>
            }
            { !loading && showActions && sortedActions?.length > 0 && <div className={`no-desktop fn-actions-menu-links${showSortByMenu ? " open" : ""}`}>
                <div className={sortColumn == TypeColumn && sortOrder == SortAscending ? "sort-active" : ""}><a className={`sort ${sortColumn == TypeColumn ? sortOrder : ""}`} onClick={sortByTypeAscending}>Type A-Z</a></div>
                <div className={sortColumn == TypeColumn && sortOrder == SortDescending ? "sort-active" : ""}><a className={`sort ${sortColumn == TypeColumn ? sortOrder : ""}`} onClick={sortByTypeDescending}>Type Z-A</a></div>
                <div className={sortColumn == DateColumn && sortOrder == SortAscending ? "sort-active" : ""}><a className={`sort ${sortColumn == DateColumn ? sortOrder : ""}`} onClick={sortByDateAscending}>Date most recent first</a></div>
                <div className={sortColumn == DateColumn && sortOrder == SortDescending ? "sort-active" : ""}><a className={`sort ${sortColumn == DateColumn ? sortOrder : ""}`} onClick={sortByDateDescending}>Date least recent first</a></div>
                <div className={sortColumn == ExpiryDateColumn && sortOrder == SortAscending ? "sort-active" : ""}><a className={`sort ${sortColumn == ExpiryDateColumn ? sortOrder : ""}`} onClick={sortByExpiryDateAscending}>Expiry date most recent first</a></div>
                <div className={sortColumn == ExpiryDateColumn && sortOrder == SortDescending ? "sort-active" : ""}><a className={`sort ${sortColumn == ExpiryDateColumn ? sortOrder : ""}`} onClick={sortByExpiryDateDescending}>Expiry date least recent first</a></div>
            </div>

            }

            { !loading && showActions && sortedActions?.length > 0 && <table className="table table-data sort u-align-center">
                <thead>
                    <tr>
                        <th className={`type ${sortColumn == TypeColumn ? "sort-active" : ""}`}><a className={`sort ${sortColumn == TypeColumn ? sortOrder : ""}`} onClick={sortByType}>Type</a></th>
                        <th className={`date ${sortColumn == DateColumn ? "sort-active" : ""}`}><a className={`sort ${sortColumn == DateColumn ? sortOrder : ""}`} onClick={sortByDate}>Date</a></th>
                        <th className={`date ${sortColumn == ExpiryDateColumn ? "sort-active" : ""}`}><a className={`sort ${sortColumn == ExpiryDateColumn ? sortOrder : ""}`} onClick={sortByExpiryDate}>Expiry date</a></th>
                        <th colSpan={2}>Action</th>
                    </tr>
                </thead>
                <tbody className={tbodyClass}>
                    {sortedActions?.map((action, index) => {
                        return <AdministrativeAction action={action} key={index} sortColumn={sortColumn} refresh={refresh}/>
                    })}
                </tbody>
            </table>
            }
            { showActions && maxRowsExceeded && <div className="no-desktop fn-actions-show-all">
                <a role="button" className="btn btn-secondary" onClick={toggleShowAll}>See {!showAllRows ? "more" : "less"} admin actions {!showAllRows ? "v" : "^"}</a>
            </div> }
        </div>
    );
}

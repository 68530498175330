import React from "react";
import shaka from "shaka-player";
import folderSvc from "../../../services/FolderSvc"


interface StreamPlayerProps {
    autoplay: boolean,
    containerId: string,
    id: string,
    onError: (string) => void,
    poster: string,
    url: string,
}

class StreamPlayer extends React.Component<StreamPlayerProps, any> {

  constructor(props) {
    super(props);

    if (!window.fnshaka) {
      shaka.polyfill.installAll();
      window.fnshaka = shaka;
    }

    this.player = new shaka.Player();
    var config = this.player.getConfiguration()
    config.streaming = {
      bufferingGoal: 25, 
      rebufferingGoal: 3, 
      bufferBehind: 10, 
      retryParameters: {
        timeout: 20000,
        stallTimeout: 6000, 
        connectionTimeout: 15000,       
        maxAttempts: 120,   
        baseDelay: 1000,  
        backoffFactor: 2, 
        fuzzFactor: 0.5,  
      },
      failureCallback: this.retryShakaStreaming
    }
    this.player.configure(config)
    this.player?.addEventListener("error", this.onErrorEvent);
    this.isPlayed = false;
  }

  isPlayed: boolean
  player: any
  videoRef: HTMLVideoElement|undefined = undefined


  retryShakaStreaming = (e) => {
    console.error('streaming error.  Retrying automatically...');
    console.error(e)
    this.player.retryStreaming()
  }

  onErrorEvent = error => {
    // don;t display errors in production
    // console.error("Error code", error.code, "object", error)
    this.props.onError?.(error.detail);
  };

  onPlaying = e => {
    if (this.isPlayed) return;

    return new Promise((resolve, reject) => {
      folderSvc.createVideoPlayLogEntry(this.props.id, this.props.containerId)
          .promise
          .then((response) => {
              this.isPlayed = true;
              resolve(response);
          })
    })
  };

  initPlayer = (el: HTMLVideoElement|null) => {
    if (!el) return

    this.player.attach(el).then(_ => {
      return this.player.load(this.props.url);
    }).catch(ex => {
      console.log(ex)
    });

    el.addEventListener("playing", this.onPlaying);
  };

  render() {
    if (!shaka.Player.isBrowserSupported()) {
      console.log('shaka does not support this browser')
      return null
    }

    return (
      <video key={this.props.id} ref={el => {this.videoRef = el; this.initPlayer(el);}} width="720" height="480" controls autoPlay={this.props.autoplay} poster={this.props.poster} />
    )
  }
}

export default StreamPlayer;

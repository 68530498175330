import React, {
    FC,
} from "react";
import _ from 'lodash';
import {
    useMutation,
} from "@apollo/client";
import PromptActions from "../../prompt/actions/PromptActions";
import {
    FORUM_DISCUSSION_ACK_MUTATIONVariables,
    forumDiscussionResult,
    FORUM_DISCUSSION_UNACK_MUTATIONVariables,
    forumReplyResult,
    FORUM_REPLY_ACK_MUTATIONVariables,
    FORUM_REPLY_UNACK_MUTATIONVariables,
} from "../../../../types/graphqlTypes";

const forumsGql = require('../../../../gql/forums.gql');

interface IAckButtonProps {
    discussionResult: forumDiscussionResult,
    replyResult?: forumReplyResult,
    setDiscussion: (e) => void,
    setReply?: (e) => void,
}

export const AckButton: FC<IAckButtonProps> = (props: IAckButtonProps) => {
    const [discussionAck] = useMutation(forumsGql.FORUM_DISCUSSION_ACK_MUTATION);
    const [discussionUnAck] = useMutation(forumsGql.FORUM_DISCUSSION_UNACK_MUTATION);
    const [replyAck] = useMutation(forumsGql.FORUM_REPLY_ACK_MUTATION);
    const [replyUnAck] = useMutation(forumsGql.FORUM_REPLY_UNACK_MUTATION);

    var hasUserAck:(boolean | null) = false;
    if (props.replyResult) {
        hasUserAck = props.replyResult.hasUserAck;
    } else if (props.discussionResult) {
        hasUserAck = props.discussionResult.hasUserAck;
    }

    function ackPost(e) {
        e.preventDefault();

        if (props.replyResult) {
            replyAck({
                variables: {
                    discussionId: props.replyResult.reply.discussionId,
                    replyId: props.replyResult.reply.replyId
                } as FORUM_REPLY_ACK_MUTATIONVariables
            }).then((replyAck) => {
                const errors = replyAck?.data?.replyAck?.errors;

                if (errors && errors.length > 0) {
                    throw errors[0];
                }
                props.setReply({ ...props.replyResult, hasUserAck: true, totalAcks: props.replyResult.totalAcks + 1 });
            }).catch((err) => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally()

        } else if (props.discussionResult) {
            discussionAck({
                variables: {
                    discussionId: props.discussionResult.discussion.discussionId
                } as FORUM_DISCUSSION_ACK_MUTATIONVariables
            }).then((discussionAck) => {
                const errors = discussionAck?.data?.discussionAck?.errors;

                if (errors && errors.length > 0) {
                    throw errors[0];
                }
                props.setDiscussion({ ...props.discussionResult, hasUserAck: true, totalAcks: props.discussionResult.totalAcks + 1 });
            }).catch((err) => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally()
        }
    }

    function unackPost(e) {
        e.preventDefault();

        if (props.replyResult) {
            replyUnAck({
                variables: {
                    discussionId: props.replyResult.reply.discussionId,
                    replyId: props.replyResult.reply.replyId
                } as FORUM_REPLY_UNACK_MUTATIONVariables
            }).then(() => {
                props.setReply({ ...props.replyResult, hasUserAck: false, totalAcks: props.replyResult.totalAcks - 1 });
            }).catch((err) => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally();

        } else if (props.discussionResult) {
            discussionUnAck({
                variables: {
                    discussionId: props.discussionResult.discussion.discussionId
                } as FORUM_DISCUSSION_UNACK_MUTATIONVariables
            }).then(() => {
                props.setDiscussion({ ...props.discussionResult, hasUserAck: false, totalAcks: props.discussionResult.totalAcks - 1 });
            }).catch((err) => {
                PromptActions.displayFriendlyErrorPrompt(err);
            }).finally();
        }
    }

    return (
        <div>
            {hasUserAck
                ? <a onClick={unackPost} className="strong">unACK</a>
                : <a onClick={ackPost} className="strong">ACK</a>
            }
        </div>
    )
}

export default AckButton;




export class FileFolder {

  id: string;
  name: string;
  canDelete: boolean;
  folders: Array<FileFolder | null | undefined> | null | undefined;
  isAccessRestricted: boolean;

  constructor(id: string, name: string, canDelete: boolean, folders: Array<FileFolder | null | undefined> | null | undefined, isAccessRestricted: boolean) {
    this.id = id;
    this.name = name;
    this.canDelete = canDelete;
    this.folders = folders;
    this.isAccessRestricted = isAccessRestricted;
  }
}

export class FileFolderFactory {

  static foldersFromFolder(folders: Array<any>): Array<FileFolder | null | undefined> | null | undefined {
    return folders && folders.map(obj => FileFolderFactory.fileFolderFromObject(obj)) || null;
  }

  static fileFolderFromObject(obj: any): FileFolder | null | undefined {
    if (obj == null) {return null;}

    let id: string | null | undefined = _.isString(obj.Id) ? obj.Id : null;
    let name: string | null | undefined = _.isString(obj.Name) ? obj.Name : null;
    let canDelete: boolean | null | undefined = _.isBoolean(obj.CanDelete) ? obj.CanDelete : null;
    let isAccessRestricted: boolean | null | undefined = _.isBoolean(obj.IsAccessRestricted) ? obj.IsAccessRestricted : null;
    let folders = _.isArray(obj.Folders) ? obj.Folders : null;

    if (id == null) {return null;}
    if (name == null) {return null;}
    if (canDelete == null) {return null;}

    let childFolders = folders && folders.length > 0 ? FileFolderFactory.foldersFromFolder(folders) : null;

    return new FileFolder(id, name, canDelete, childFolders, isAccessRestricted);
  }
}
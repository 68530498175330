
import _ from "lodash";
import { FileFactory } from "../../../core/models/File";
import { FileFolderFactory, FileFolder } from "../../../core/models/FileFolder";


/**
 * The folder model
 */
export class Folder {

  currentPage: number; // The item id (eg group id or event id)
  files: Array<File>; // The list of files
  folders: Array<FileFolder>;
  folderId: string;
  totalPages: number; // Total number of result pages for this query
  totalFiles: number; // Total number of files across all pages of the search
  filesPerPage: number; // Total number of files per page
  unarchivableCount: number | null;
  message: string | null | undefined; // Optional message displayed at the bottom of the search results

  constructor(currentPage: number, files: Array<File>, folders: Array<FileFolder>, folderId: string, totalPages: number, totalFiles: number, filesPerPage: number, unarchivableCount: number | null, message: string | null | undefined) {
    this.currentPage = currentPage;
    this.files = files;
    this.folders = folders;
    this.folderId = folderId;
    this.totalPages = totalPages;
    this.totalFiles = totalFiles;
    this.filesPerPage = filesPerPage;
    this.message = message;
    this.unarchivableCount = unarchivableCount;
  }

  /**
   * Removes a file from the set
   * @param {File} 			file		- The file to remove
   * @returns {Boolean} 					- True if the file removed
   */
  removeFile(file: File): boolean {
    if (_.remove(this.files, f => {return f.id === file.id;})) {
      this.totalFiles--;
      return true;
    }

    return false;
  }

  /**
   * Returns true if the folder contains files
   * @returns {boolean}
   */
  hasFiles(): boolean {
    return this.files.length > 0;
  }

  hasFolders(): boolean {
    return this.folders.length > 0;
  }
}

/**
 * File factory
 */
export class FolderFactory {

  /**
   * Creates a File from an object
   * @param {object}		o		- The object to map
   * @returns {File}
   */
  static folderFromObject(o: any): Folder | null | undefined {
    if (o == null) {return null;}

    // NOTE:
    // These property names on "o" might not be correct as im guessing since the endpoint hasnt been built yet.

    let folderId: string | null | undefined = _.isString(o.FolderId) ? o.FolderId : null;
    let currentPage: number | null | undefined = _.isNumber(o.CurrentPage) ? o.CurrentPage : null;
    let totalPages: number | null | undefined = _.isNumber(o.TotalPages) ? o.TotalPages : null;
    let totalFiles: number | null | undefined = _.isNumber(o.TotalFiles) ? o.TotalFiles : null;
    let message: string | null | undefined = _.isString(o.Message) ? o.Message : null;
    let filesPerPage: number | null | undefined = _.isNumber(o.FilesPerPage) ? o.FilesPerPage : null;
    let unarchivableCount: number | null | undefined = _.isNumber(o.UnarchivableCount) ? o.UnarchivableCount : null;
    let files: Array<File> = [];
    if (o.Files) {
      files = _.map(o.Files, fileObj => {return FileFactory.fileFromObject(fileObj);});
    }
    let folders: Array<FileFolder> = [];
    if (o.Folders) {
      folders = _.map(o.Folders, fileObj => {return FileFolderFactory.fileFolderFromObject(fileObj);});
    }

    if (folderId == null) {return null;}
    if (currentPage == null) {return null;}
    if (totalPages == null) {return null;}
    if (totalFiles == null) {return null;}
    if (filesPerPage == null) {return null;}
    if (files == null) {return null;}
    if (folders == null) {return null;}

    return new Folder(currentPage, files, folders, folderId, totalPages, totalFiles, filesPerPage, unarchivableCount, message);
  }
}
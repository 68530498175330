import React from "react"
import { Button, Message, NewMessage, NoMessage, Reply } from "./Components"

class MessagePanel extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={`columns small-16 ${this.props.initialUsers && this.props.initialUsers.length > 0 && !this.props.conversation? "large-16" : "large-9"} ${ this.props.hideMobile ? "slid-middle" : ""}`} id="msgRead">
                <div className="ui-helper-clearfix no-desktop">
                    <p>
                        <a href="#" className="link-back" onClick={this.props._showMobileMenu.bind(this)}>
                            Back to messages
                        </a>
                    </p>
                </div>
                <div
                    className={`panel messages ${this.props.loading ? "loading" : ""}`}
                    style={{ height: "876px" }}>
                    {
                        this.props.mode == "new" || this.props.mode == "draft" ? (
                        <NewMessage
                            _toggle={this.props._toggle}
                            _new={this.props._new}
                            initialUsers={this.props.initialUsers}
                            canSendSms={this.props.canSendSms}
                            isCreateSeparateConversations={this.props.isCreateSeparateConversations}
                            canCreateSeparateConversations={this.props.canCreateSeparateConversations}
                            maxSeparateConversations={this.props.maxSeparateConversations}
                            separateConversationCount={this.props.separateConversationCount} 
                            userPreference={this.props.userPreference}
                            showModal={this.props.showModal}
                            onRequestsClose={this.props.onRequestsClose}
                            body={this.props.body} 
                            conversation={this.props.conversation}
                            title={this.props.title} />
                    ) : this.props.selected && this.props.conversation ? (
                        <Message
                            message={this.props.message}
                            conversation={this.props.conversation}
                            loading={this.props.loading}
                            _reply={this.props._reply}
                            _forward={this.props._forward}
                            _getConversationMessages={this.props._getConversationMessages}
                            _conversationLoaded={this.props._conversationLoaded}
                            hasMoreMessages={this.props.hasMoreMessages}
                            infiniteScroll={this.props.infiniteScroll}
                            canSendMessage={this.props.canSendMessage}
                            userPreference={this.props.userPreference}
                            />
                    ) : (
                        <NoMessage />
                    )}
                </div>
            </div>
        )
    }
}

MessagePanel.defaultProps = {
    hideMobile: false,
    infiniteScroll: true
}

export default MessagePanel

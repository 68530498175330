import AppDelegate from "../core/AppDelegate";
import HttpAuthenticatedSvc from "./HttpAuthenticatedSvc";
import AuditLogCollection from "../core/models/AuditLogCollection";

/**
 * Audit Service
 */
class AuditSvc {
  /**
   * Find
   * Query audit service to find audits based on criteria
   *
   * @param {object}          [options]                 -Filter options
   * @param {string}          [options.username]
   * @param {string}          [options.pMKey]
   * @param {string}          [options.startDate]
   * @param {string}          [options.endDate]
   * @param {string}          [options.intent]
   * @returns {{promise: Promise, abort: Function}}
   */
  find(options) {
    var req,
      endpoint = `${
        AppDelegate.appSettings.apiUrl
      }/AuditService.asmx/FindAudits`,
      payload = {
        options: null
      };

    if (options) {
      payload.options = {
        Username: options.username || null,
        PMKey: options.pMKey || null,
        StartDate: options.startDate || null,
        EndDate: options.endDate || null,
        Intent: options.intent || null,
        KeywordSearch: options.keywordSearch || null
      };
    }

    let promise = new Promise((resolve, reject) => {
      req = HttpAuthenticatedSvc.post(
        endpoint,
        payload,
        function(response) {
          resolve(AuditLogCollection.AuditLogCollectionFromObject(response));
        },
        function(err) {
          reject({ reason: err.message });
        }
      );
    });

    return {
      promise: promise,
      abort: () => {
        req.abort();
      }
    };
  }

  /**
   * ExportAudit
   * Export current filtered data
   *
   * @param {object}          [options]                 -Filter options
   * @param {string}          [options.username]
   * @param {string}          [options.pMKey]
   * @param {string}          [options.startDate]
   * @param {string}          [options.endDate]
   * @param {string}          [options.intent]
   *
   */
  ExportAudit(options) {
    var req,
      endpoint = `${
        AppDelegate.appSettings.apiUrl
      }/AuditService.asmx/ExportAuditLog`,
      payload = {};

    if (options) {
      payload = {
        username: options.username || null,
        pMKey: options.pMKey || null,
        startDate: options.startDate || null,
        endDate: options.endDate || null,
        intent: options.intent || null,
        keywordSearch: options.keywordSearch || null
      };
    }

    var xhr = new XMLHttpRequest();
    xhr.open("POST", endpoint, true);
    xhr.responseType = "arraybuffer";
    // Append CSRF token to request header
    xhr.setRequestHeader('__RequestVerificationToken', $('input[name=__RequestVerificationToken]').val());
    xhr.onload = function() {
      if (this.status === 200) {
        var filename = "";
        var disposition = xhr.getResponseHeader("Content-Disposition");
        if (disposition && disposition.indexOf("attachment") !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, "");
        }
        var type = xhr.getResponseHeader("Content-Type");

        var blob =
          typeof File === "function"
            ? new File([this.response], filename, { type: type })
            : new Blob([this.response], { type: type });
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            // use HTML5 a[download] attribute to specify filename
            var a = document.createElement("a");
            // safari doesn't support this yet
            if (typeof a.download === "undefined") {
              window.location = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location = downloadUrl;
          }

          setTimeout(function() {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }
      }
    };
    //xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    //xhr.send($.param(payload));
    xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
    xhr.send(JSON.stringify(payload));
  }

  //Push updated data to audit trail. Test purpose only. TBD
  pushAudit() {
    var req,
      endpoint = `${
        AppDelegate.appSettings.apiUrl
      }/AuditService.asmx/SyncAudits`;

    let promise = new Promise((resolve, reject) => {
      req = HttpAuthenticatedSvc.post(
        endpoint,
        {},
        function(response) {
          resolve(response);
          alert("synchronised");
        },
        function(err) {
          reject({ reason: err.message || null });
        }
      );
    });

    return {
      promise: promise,
      abort: () => {
        req.abort();
      }
    };
  }
}

/**
 * Exports singleton
 */
export default new AuditSvc();

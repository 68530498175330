import React 				        from 'react';
import _                    from 'lodash';
import moment               from 'moment';

import FilterDate           from '../models/FilterDate';

export default class FilterDateViewer extends React.Component {
  static propTypes = {
    maxYearFilter: Number,
    minYearFilter: Number
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(){
    document.getElementById("sr-status-alert").setAttribute("role", "alert");
  }

  render() {
    const months = _.map(moment.months(), (m, index) => {
      return (
        <option value = {index}>
          {m}
        </option>
      );
    });

    let yearsList = [];
      for (var i = this.props.maxYearFilter; i >= this.props.minYearFilter; i--) {
        yearsList.push(i);
      }
    const years = _.map(yearsList, y => {
      return (
        <option value = {y}>
          {y}
        </option>
      );
    });

    return (
      <fieldset className="calendar-filter">
        <legend className="sr-only">Select a month and year to show in the calendar</legend>
        <label htmlFor="show-month" className="sr-only">Month</label>
        <select id="show-month" className="form-control"
          value={this.props.selectedMonth}
          onChange={this.props.filterMonthHandler}
        >
          {months}
        </select>
        <label htmlFor="show-year" className="sr-only">Year</label>
        <select id="show-year" className="form-control"
          value={this.props.selectedYear}
          onChange={this.props.filterYearHandler}
        >
          {years}
        </select>
      </fieldset>
    );
  }
}
import GroupReportModule from '../modules/groupreport/GroupReportModule'

export default class GroupReportButton
{
    constructor(ele, props)
    {
        this.node = ele
        this.props = props;

        ele.setAttribute('style', 'display: block !important');

        ele.addEventListener('click', this._click.bind(this), true);
    }

    _click(e)
    {
        e.preventDefault();

        this._showReport();

        return false;
    }

    _showReport()
    {
        this.node.setAttribute('style', 'display: none');

        var details = document.getElementById(this.props.detailsContainer);
        if (details != null) {
            details.setAttribute('style', 'display: none');
        }

        var feed = this.node.nextElementSibling;
        if (feed != null) {
            feed.setAttribute('style', 'display: none');
        }

        var container = document.getElementById(this.props.container);
        if (container != null) {
            container.removeAttribute('style');
            this.report = ReactDOM.render(this._renderGroupReport(), container)
            if (container.scrollIntoView) {
                container.scrollIntoView();
            }
        }
    }

    _renderGroupReport()
    {
        return (
            <GroupReportModule groupId={this.props.groupId} />
        )
    }
}
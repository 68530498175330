import {
    useEffect,
    useState,
} from "react";
import moment from 'moment';
import DateTimeUtils from "../../../core/utils/DateTimeUtils";
const renderDateTime = DateTimeUtils.renderDateTime;

export const Clock = () => {
    const [date, setDate] = useState(moment());

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(moment());
        }, 1000);
        return () => {
            clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        }
    }, []);

    return renderDateTime(date);
}
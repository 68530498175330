import React 				from 'react';
import moment               from "moment";

interface Props {
    id: string;
    name: string;
    checked: boolean;
    isOpen: boolean;
    indent: number;
    onToggleExpansion: Function;
    onToggleSelection: Function;
}

interface State {
}

export default class BtnUploadFolder extends React.Component<Props, State> {
	readonly NotFiledFolderId = "00000000-0000-0000-0000-000000000000";

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    _onClickCheckbox = () => {
        this.props?.onToggleSelection(this.props.id, this.props.name);
    }

    _onToggleExpansion = (e) => {
        e.preventDefault();
        this.props?.onToggleExpansion(this.props.id, this.props.isOpen);
    }

    _renderFolderName() {
        if (this.props.id == this.NotFiledFolderId) {
            return this.props.name
        }

        return (
            <>
                {[...Array(this.props.indent)].map(i => <span className="indent" />)}
                <label><input type="checkbox" onClick={ this._onClickCheckbox } defaultChecked={this.props.checked} /> { this.props.name }</label>
            </>
        )
    }

    render() {
		let folderClassName = "bg-primary fileupload-folder-link";

		if (!this.props.isOpen) {
			folderClassName += " closed";
		}

        return <div className={folderClassName}>
            { this._renderFolderName() }
            <a href="#" onClick={this._onToggleExpansion}><span className="arrow"/></a>
        </div>
    }
}

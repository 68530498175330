import _ 			from 'lodash';
import React 		from 'react';
import ReactDOM		from 'react-dom';
import { ApolloProvider } 	from '@apollo/client';
import AppDelegate		    from './core/AppDelegate';
import { BrowserRouter as Router } from 'react-router-dom'

import RootModule   from './core/RootModule'
import PMKeysImporterModule from  './modules/io/PMKeysImporter/PMKeysImporterModule'
import FamiliesTrialImporterModule from  './modules/io/FamiliesTrialImporter/FamiliesTrialImporterModule'
import UserSelectorModule from  './modules/form/UserSelectorModule'
import GroupSelectorModule from  './modules/form/GroupSelectorModule'
import UserInviteModule from  './modules/form/UserInviteModule'
import FileExplorerModule from  './modules/fileexplorer/FileExplorerModule'
import ActivityFeedModule from './modules/activityfeed/ActivityFeedModule'
import PostModule from  './modules/activityfeed/PostModule'
import PostAnalyticsModule from  './modules/activityfeed/PostAnalyticsModule'
import MessagesModule from  './modules/messages/MessagesModule'
import ReportAbuseModule from  './modules/reportabuse/ReportAbuseModule'
import SitewideNoticesListingModule from  './modules/notices/SitewideNoticesListingModule'
import ModalConfirmTrigger from  './dommodules/ModalConfirmTrigger'
import ModalConfirmWithReasonTrigger from './dommodules/ModalConfirmWithReasonTrigger'
import GroupReportButton from './dommodules/GroupReportButton'
import GroupInvitationSelectMember from './modules/form/GroupInvitationSelectMember'
import NotificationModule from './modules/notification/NotificationModule'
import PollModule from './modules/poll/PollModule'
import AuditLogViewerModule from './modules/supporttools/auditlogviewer/AuditLogViewerModule'
import GroupHierarchySelectorModule from  './modules/form/GroupHierarchySelectorModule';
import MultiGroupHierarchySelectorModule from  './modules/form/MultiGroupHierarchySelectorModule';
import CalenderEventModule from "./modules/event/calendar/CalendarEventModule";
import InviteUsersModule from "./modules/inviteUsers/InviteUsersModule";
import InviteMembersModule from "./modules/inviteUsers/InviteMembersModule";
import InviteFamiliesModule from "./modules/inviteUsers/InviteFamiliesModule";
import SkillFileModule from "./modules/skillFile/SkillFileModule";
import FilterModule from "./modules/form/FilterModule";
import FilterLanguageModule from "./modules/form/FilterLanguageModule";
import FilterWithGroupModule from "./modules/form/FilterWithGroupModule";
import FilterWithGroupAndStateModule from "./modules/form/FilterWithGroupAndStateModule";
import FilterWithGroupAndFlagModule from "./modules/form/FilterWithGroupAndFlagModule";
import SelectorModule from "./modules/form/SelectorModule";
import JobActivityReportModule from './modules/job/JobActivityReportModule';
import JobTypeSelectorModule from './modules/job/JobTypeSelectorModule';
import PatternsOfServiceModule from './modules/patternsOfService/PatternsOfServiceModule';
import QualificationModule from './modules/education/QualificationModule';
import SkillSelectorModule from "./modules/form/SkillSelectorModule";
import RichTextEditorModule from './core/components/richtext/RichTextEditorModule';
import ActionsRequiredModule from  './modules/actions/ActionsRequiredModule'
import { LatestBookmarksModule } from  './modules/bookmarks/LatestBookmarksModule'
import { BookmarksModule } from  './modules/bookmarks/BookmarksModule'
import ForumsModule from  './modules/forums/ForumsModule'
import MediaViewerModule from './modules/media/MediaViewerModule';
import GroupJoinRequestsModule from  './modules/groupJoinRequests/GroupJoinRequestsModule'
import GroupManageListsModule from './modules/groupManageLists/GroupManageListsModule'
import { GroupsListsModule } from './modules/groupsLists/GroupsListsModule';
import { RecentActivitiesModule } from './modules/RecentActivities/RecentActivitiesModule';


// Our default root app element id
const APP_ROOT = 'appRoot';

/**
 * Wrapper for the node 'partition-bundle` module API
 * @private
 */
//let _requireAsync = require.ensure;

/**
 * Wrapper for the node 'React.render' API
 * If the ReactElement was previously rendered into container, this will perform an update on it and only
 * mutate the DOM as necessary to reflect the latest React component.
 * @param module {object} 				- The React Module
 * @param props {object} [props={}]		- The props object to initiate the component with
 * @param ele {string|object} 			- The element 'id' string to render the component to or a document node
 * @private
 */
let _render = (module, props, ele) => {

	let target = (typeof ele === 'string') ? window.document.getElementById(ele) : ele;

	if(module.__esModule){
		module = module['default'];
	}

	if(target){
		 ReactDOM.render(
			<ApolloProvider client={AppDelegate.client}>
				<Router>
					{ React.createElement(module, props || {}) }
				</Router>
			</ApolloProvider>,
			target,
		);
	}
};

let _renderDOM = (module, props, ele) => {

	let target = (typeof ele === 'string') ? window.document.getElementById(ele) : ele;

	if(module.__esModule){
		module = module['default'];
	}

	if(target){
		new module(target, props || {})
	}
};

/**
 * Create a new node element after a target element for attaching React components.
 * @param {object} ele - The initiating dom element
 * @returns {Element}
 * @private
 */
let _portal = (ele) => {
	let node = document.createElement('div');
	let className = ele.getAttribute('data-fn-class') || '';
	node.className = `fn-react-root ${className}`;

	if (ele.id)
	{
		node.id = "react-" + ele.id;
	}

	// inject
	if(ele === document.body) {
		document.body.appendChild(node);
	}
	else {
		ele.parentNode.insertBefore(node, ele.nextSibling);
	}


	return node;
};

/**
 * Converts the first letter of a string to uppercase
 * @private
 * @param {string}		input		- The string to parse
 * @returns {string}				- Returns the parsed string
 */
function firstLetterToUpper(input) {
	return input[1].toUpperCase();
}

/**
 * Converts a habitat hyphenated attribute name into camelCase
 * @param {string}		key			- The habitat pre attr
 * @param {string}		name		- The attribute name
 * @returns {string}				- The camel case value
 */
function getNameFor(key, name) {
	return name.replace(key, '').replace(/-([a-z])/g, firstLetterToUpper);
}

/**
 * Generates a prop object from reading the element data attributes
 * @param ele
 * @returns {object}
 * @private
 */
let _getProps = (ele) => {

	// Default props with reference to the initiating node
	var _props = {
		fnNode: ele
	};

	// Populate custom props from reading any ele attributes that start with 'data-prop-'
	_.forEach(ele.attributes, (a) => {

		if(!a.specified){ return; }

		if(a.name.indexOf('data-prop-') === 0) {
			// Convert prop name from hyphens to camel case
			var name = getNameFor('data-prop-', a.name);

			let value = a.value || '';

			// Parse booleans
			if(typeof value === 'string' && value.toLocaleLowerCase() === 'false'){
				value = false;

			} else if(typeof value === 'string' && value.toLocaleLowerCase() === 'true') {
				value = true;

				// nulls
			} else if (typeof value === 'string' && value.toLowerCase() === 'null') {
				value = null;

				// JOSN strings
			} else if(typeof value === 'string' && value.length > 2 && (value[0] === '{' && value[value.length-1] === '}') || (value[0] === '[' && value[value.length-1] === ']')) {
				value = JSON.parse(value);
			}

			_props[name] = value;
		
		} else if (a.name === 'data-props') {
			// Parse all of the props as json
			Object.assign(_props, JSON.parse(a.value));

		} else if (a.name.indexOf('data-n-prop-') === 0) {
			// Number type props
			
			// Convert prop name from hyphens to camel case
			var _name = getNameFor('data-n-prop-', a.name);

			// Parse the value as a float as it handles both floats and whole int's
			// Might want to look at configuring the radix somehow in the future
			_props[_name] = parseFloat(a.value);

		}
	});


	return _props;
};

/**
 * Wire up controls
 */

let _controllerContainer = {
	root:			() => _render(RootModule, null, _portal(document.body)),
	pmkeysCtrl: 	() => _render(PMKeysImporterModule, null, APP_ROOT),
    familiesTrialCtrl: 	() => _render(FamiliesTrialImporterModule, null, APP_ROOT),
	contractorimportandexport: 	() => _render(PMKeysImporterModule, {isPermanentImport:true}, APP_ROOT),
};

let _firstClassContainer = {
	userSelector: 	(n) => _render(UserSelectorModule, _getProps(n), _portal(n)),
	groupSelector: 	(n) => _render(GroupSelectorModule, _getProps(n), _portal(n)),
	inviteSelector: (n) => _render(UserInviteModule, _getProps(n), _portal(n)),
	selector: 		(n) => _render(SelectorModule, _getProps(n), _portal(n)),
	fileExplorer: 	(n) => _render(FileExplorerModule, _getProps(n), _portal(n)),
	activityFeed:	(n) => _render(ActivityFeedModule, _getProps(n), _portal(n)),
	post:			(n) => _render(PostModule, _getProps(n), _portal(n)),
	postAnalytics:		(n) => _render(PostAnalyticsModule, _getProps(n), _portal(n)),
	messages:		(n) => _render(MessagesModule, _getProps(n), _portal(n)),
	reportComplaint:	(n) => _render(ReportAbuseModule, _getProps(n), _portal(n)),
	sitewideNotices:	(n) => _render(SitewideNoticesListingModule, _getProps(n), _portal(n)),
	groupInvitationSelectMember: (n) => _render(GroupInvitationSelectMember, _getProps(n), _portal(n)),
	poll:	(n) => _render(PollModule, _getProps(n), _portal(n)),
	calendarEvent:	(n) => _render(CalenderEventModule,_getProps(n), _portal(n)),
	auditLogViewer:	(n) => _render(AuditLogViewerModule, _getProps(n), _portal(n)),
	groupHierarchySelector:	(n) => _render(GroupHierarchySelectorModule, _getProps(n), _portal(n)),
	multiGroupHierarchySelector: 	(n) => _render(MultiGroupHierarchySelectorModule, _getProps(n), _portal(n)),
	inviteMembers:	(n) => _render(InviteMembersModule, _getProps(n), _portal(n)),
	inviteFamilies:	(n) => _render(InviteFamiliesModule, _getProps(n), _portal(n)),
	filter:	(n) => _render(FilterModule, _getProps(n), _portal(n)),
	filterLanguage:	(n) => _render(FilterLanguageModule, _getProps(n), _portal(n)),
	filterWithGroup:	(n) => _render(FilterWithGroupModule, _getProps(n), _portal(n)),
	filterWithGroupAndState:	(n) => _render(FilterWithGroupAndStateModule, _getProps(n), _portal(n)),
	filterWithGroupAndFlag:	(n) => _render(FilterWithGroupAndFlagModule, _getProps(n), _portal(n)),
	jobActivityReport:	(n) => _render(JobActivityReportModule, _getProps(n), _portal(n)),
	jobTypeSelector:	(n) => _render(JobTypeSelectorModule, _getProps(n), _portal(n)),
	//inviteUsersUpload: (n) => _render(InviteUsersModule,_getProps(n),_portal(n)),
	skillFile:	(n) => _render(SkillFileModule, _getProps(n), _portal(n)),
	notificationDeleteAll:	(n) => _render(NotificationModule, _getProps(n), _portal(n)),
	patternsOfService:  (n) => _render(PatternsOfServiceModule, _getProps(n), _portal(n)),
	qualification:  (n) => _render(QualificationModule, _getProps(n), _portal(n)),
	skillSelector: (n) => _render(SkillSelectorModule, _getProps(n), _portal(n)),
	richTextEditor: (n) => _render(RichTextEditorModule, _getProps(n), _portal(n)),
	actionsRequired: (n) => _render(ActionsRequiredModule, _getProps(n), _portal(n)),
	latestBookmarks: (n) => _render(LatestBookmarksModule, _getProps(n), _portal(n)),
	bookmarks: (n) => _render(BookmarksModule, _getProps(n), _portal(n)),
	forums: (n) => _render(ForumsModule, _getProps(n), _portal(n)),
	mediaViewer: (n) => _render(MediaViewerModule, _getProps(n), _portal(n)),
	groupJoinRequests: (n) => _render(GroupJoinRequestsModule, _getProps(n), _portal(n)),
	groupManageLists: (n) => _render(GroupManageListsModule, _getProps(n), _portal(n)),
	groupsLists: (n) => _render(GroupsListsModule, _getProps(n), _portal(n)),
	recentActivityList: (n) => _render(RecentActivitiesModule, _getProps(n), _portal(n)),
};

let _domContainer = {
	modalConfirmTrigger:			(n) => _renderDOM(ModalConfirmTrigger, _getProps(n), n),
	modalConfirmWithReasonTrigger:			(n) => _renderDOM(ModalConfirmWithReasonTrigger, _getProps(n), n),
	groupReportButton:			(n) => _renderDOM(GroupReportButton, _getProps(n), n),
};


export default {
	ControllerContainer: _controllerContainer,
	FirstClassContainer: _firstClassContainer,
	DOMContainer: _domContainer
};

import React, {
    FC,
} from "react";
import _ from 'lodash';

interface IForumTabsProps {
    newDiscussion?: () => void,
    postToDiscussion?: () => void,
    title?: string,
}

export const ForumTabs: FC<IForumTabsProps> = (props: IForumTabsProps) => {

    return (
        <div className="forum-tabs">
            <ul>
                {props.newDiscussion &&
                    <li className="active fn-header fn-service-bg-colour">
                        <a onClick={props.newDiscussion}>
                            <span className="fn-icon fn-icon-plus fn-icon-white" aria-hidden="true"></span> New Discussion
                        </a>
                    </li>
                }
                {props.postToDiscussion &&
                    <li className="active fn-header fn-service-bg-colour">
                        <a onClick={props.postToDiscussion}>Post to discussion</a>
                    </li>
                }
                {props.title &&
                    <li>{props.title}</li>
                }
            </ul>
        </div>
    )
}

export default ForumTabs;
import * as React from "react";

function SvgIconReport(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path fill="currentColor" d="M1.6115 9.4901H3.9393V34.5584H1.6115Z" stroke="currentColor" stroke-width="1"></path>
      <path fill="currentColor" d="M5.5509 9.4902 8.2368 6.4462 11.1017 5.3718 15.22 9.1321 24.352 16.1153 30.261 14.1456 23.0986 23.8148 21.4871 25.2473 9.6692 19.1593 5.5509 21.308Z" stroke="currentColor" stroke-width="1"></path>
    </svg>
  );
}

export default SvgIconReport;

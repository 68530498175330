import React from "react"
import { SearchBox } from "./Components"

class Summary extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="mailbox">
                <SearchBox _getSearch={this.props._getSearch} searchText={this.props.searchText} />
                {!this.props.loading ? (
                    <div id="mailboxFilters" className="mailbox-folders">
                        <ul>
                            <li>
                                <a
                                    data-mailbox-id="my-inbox"
                                    className={`mailbox-item ${
                                        this.props.mailbox === "my-inbox" ? "mailbox-highlight" : ""
                                    }`}
                                    onClick={this.props._selectMailBox.bind(this, "my-inbox")}
                                    data-unread={this.props.unread}>
                                    {`Inbox `}
                                    {this.props.unread > 0 ? (
                                        <span className="unread">({this.props.unread})</span>
                                    ) : null}
                                </a>
                            </li>
                            <li>
                                <ul className="ul-level2">
                                    <li>
                                        <a
                                            data-mailbox-id="my-inbox-unread"
                                            className={`mailbox-item ${
                                                this.props.mailbox === "my-inbox-unread"
                                                    ? "mailbox-highlight"
                                                    : ""
                                            }`}
                                            onClick={this.props._selectMailBox.bind(
                                                this,
                                                "my-inbox-unread",
                                            )}
                                            data-unread={this.props.unread}>
                                            {`Unread `}
                                            {this.props.unread > 0 ? (
                                                <span className="unread">
                                                    ({this.props.unread})
                                                </span>
                                            ) : null}
                                        </a>
                                    </li>                                 
                                    <li>
                                        <a
                                            data-mailbox-id="my-inbox-important"
                                            className="mailbox-item"
                                            className={`mailbox-item ${
                                                this.props.mailbox === "my-inbox-important"
                                                    ? "mailbox-highlight"
                                                    : ""
                                            }`}
                                            onClick={this.props._selectMailBox.bind(
                                                this,
                                                "my-inbox-important",
                                            )}
                                            data-unread={this.props.important}>
                                            {`Important `}
                                            {this.props.important > 0 ? (
                                                <span className="unread">
                                                    ({this.props.important})
                                                </span>
                                            ) : null}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <a
                                    data-mailbox-id="my-archived"
                                    className={`mailbox-item ${
                                        this.props.mailbox === "my-archived"
                                            ? "mailbox-highlight"
                                            : ""
                                    }`}
                                    onClick={this.props._selectMailBox.bind(this, "my-archived")}
                                    data-unread={this.props.archived}>
                                    {`Archived `}
                                    {this.props.archived > 0 ? (
                                        <span className="unread">({this.props.archived})</span>
                                    ) : null}
                                </a>
                            </li>
                            <li>
                                <a
                                    data-mailbox-id="my-inbox-draft"
                                    className={`mailbox-item ${
                                        this.props.mailbox === "my-inbox-draft"
                                            ? "mailbox-highlight"
                                            : ""
                                    }`}
                                    onClick={this.props._selectMailBox.bind(
                                        this,
                                        "my-inbox-draft",
                                    )}
                                    data-draft={this.props.draft}>
                                    {`Draft `}
                                    {this.props.draft > 0 ? (
                                        <span className="unread">
                                            ({this.props.draft})
                                        </span>
                                    ) : null}
                                </a>
                            </li>                            
                            <li>
                                <ul className="ul-level2"> </ul>
                            </li>
                        </ul>
                    </div>
                ) : null}
            </div>
        )
    }
}

export default Summary

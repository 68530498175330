import React, {
    FC,
    useState,
    useEffect,
    useRef,
} from "react";
import _	             from 'lodash';

import Modal			 from "../../core/components/modal/Modal";
import BtnClose			 from "../../core/components/BtnClose";
import Localisation		 from "../../core/resources/Localisation";

import IGroup  from "./IGroup";

type CallbackFunction = () => void;
type CallbackFunctionGroup = (group: IGroup) => void;
type CallbackFunctionString = (id: string) => void;
type CallbackFunctionArray = (selection: Array<string>, allGroups :boolean) => void;

interface IMultiGroupHierarchyGroupProps {
    group: IGroup,
    selectedGroups: Set<string>;
    openGroups: Set<string>;
    indent: number;
    onToggleSelection: CallbackFunctionGroup,
    onToggleExpansion: CallbackFunctionString,
}

const MultiGroupHierarchyGroup : FC<IMultiGroupHierarchyGroupProps> = (props: IMultiGroupHierarchyGroupProps) => {
    const isOpen = props.openGroups.has(props.group.Id);
    const groupClassName = `group${isOpen ? " expanded" : ""}`;

    const checked = props.selectedGroups.has(props.group.Id);

    const _onChangeCheckbox = (e) => {
        if (props.group.IsActive) {
            props?.onToggleSelection(props.group);
        }
    }

    const _onToggleExpansion = (e) => {
        e?.preventDefault();
        props?.onToggleExpansion(props.group.Id);
    }

    const renderGroupName = () => {
        return (
            <span>
                <input type="checkbox" disabled={!props.group.IsActive} onChange={ _onChangeCheckbox } checked={checked} /> { props.group.Name }
            </span>
        )
    }

    const rowClassName = `item${!props.group.IsActive ? " disabled" : ""} indent-${props.indent}`;

    return <div className={groupClassName}>
        <div className={rowClassName}>
            { renderGroupName() }
            { props.group.SubGroups && props.group.SubGroups.length > 0 ? <a href="#" onClick={_onToggleExpansion}><span className="arrow"/></a> : null }
        </div>
        { isOpen && props.group.SubGroups?.map(g => <MultiGroupHierarchyGroup key={g.Id} group={g} indent={props.indent + 1} selectedGroups={props.selectedGroups} openGroups={props.openGroups} onToggleSelection={props.onToggleSelection} onToggleExpansion={props.onToggleExpansion}/>)}
    </div>

}

interface IMultiGroupHierarchySelectorProps {
    active: boolean,
    groups: Array<IGroup>,
    selectedGroups: Array<string>,
    onClose: CallbackFunction,
    onSelect: CallbackFunctionArray,
}

export const MultiGroupHierarchySelector : FC<IMultiGroupHierarchySelectorProps> = (props: IMultiGroupHierarchySelectorProps) => {
    const [openGroups, setOpenGroups] = useState(new Set<string>());
    const [selectedGroups, setSelectedGroups] = useState(new Set<string>());

    useEffect(() => {
        if (props.active) {
            setOpenGroups(new Set(props.groups?.map(i => i.Id)));
            setSelectedGroups(new Set<string>(props.selectedGroups));
        }
    }, [props.active]);

    const onRequestClose = (e) => {
        props?.onClose();
    };

    const onSelect = (e) => {
        props?.onSelect(Array.from(selectedGroups));
        onRequestClose(e);
    }

    const toggleGroup = (id) => {
        if (openGroups.has(id)) {
            openGroups.delete(id);
        } else {
            openGroups.add(id);
        }

        setOpenGroups(new Set(openGroups));

        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }

    const selectDescendentGroups = (groups: Array<IGroup>, add: boolean) => {
        groups?.forEach(group => {
            if (add) {
                if (group.IsActive &&!selectedGroups.has(group.Id)) {
                    selectedGroups.add(group.Id)
                }
            } else {
                if (selectedGroups.has(group.Id)) {
                    selectedGroups.delete(group.Id)
                }
            }

            selectDescendentGroups(group.SubGroups, add);
        })
    }

    const toggleSelection = (group: IGroup) => {
        if (selectedGroups.has(group.Id)) {
            selectedGroups.delete(group.Id)
            selectDescendentGroups(group.SubGroups, false);
        } else {
            if (group.IsActive) {
                selectedGroups.add(group.Id);
            }
            selectDescendentGroups(group.SubGroups, true);
        }
        setSelectedGroups(new Set<string>(selectedGroups));
    }

    const selectButtonClass = "btn btn-primary";

    return (
        <Modal active={props.active} onRequestsClose={onRequestClose}>
            <div className="modal-header">
                <BtnClose onClick={onRequestClose} />
                <h2 className="modal-title">{Localisation.localisedStringFor("ChooseGroups")}</h2>
            </div>
            <div className="modal-content groups multigroup-list">
                { props.groups?.map(g => <MultiGroupHierarchyGroup key={g.Id} group={g} indent={0} openGroups={openGroups} selectedGroups={selectedGroups} onToggleSelection={toggleSelection} onToggleExpansion={toggleGroup}/>) }
            </div>
            <div className="modal-actions">
                <button role="button" className={selectButtonClass} onClick={onSelect}>Select</button>
                &nbsp;&nbsp;
                <button role="button" className="btn btn-default" onClick={onRequestClose}>Cancel</button>
            </div> 
        </Modal>
    );
}

import moment from "moment";
import React, {
    FC,
} from "react";

import ImageView from '../fileexplorer/components/ImageView';

interface IMediaViewerFileProps {
    id: string;
    title: string;
    url: string;
    thumbnailFileId: string;
    thumbnailUrl: string;
    fileType: string;
    containerId: string;
    dateUploaded: string;    
}

interface IMediaViewerModuleProps {
    file: IMediaViewerFileProps;
    user: any;
}

export const MediaViewerModule : FC<IMediaViewerModuleProps> = (props: IMediaViewerModuleProps) => {
    const isVideo = props.file.fileType.toLowerCase() === "video";
    const isImage = props.file.fileType.toLowerCase() === "image";
    const dateUploaded = props.file.dateUploaded && moment(props.file.dateUploaded);
    const thumbnailFileId = props.file.thumbnailFileId ?? (isImage ? props.file.id : null);
    const url = props.file.url ?? (props.file.id ? `/Services/GetImage.ashx?id=${props.file.id}` : null);
    const thumbnailUrl = props.file.thumbnailUrl ?? (thumbnailFileId ? `/Services/GetImage.ashx?id=${thumbnailFileId}` : null);

    const file = {
        id: props.file.id,
        fileType: props.file.fileType,
        thumbnail: {
            url: thumbnailUrl,
        },
        image: isImage ? {
            url: url
        } : null,
        thumbnailFileId: thumbnailFileId,
        title: props.file.title,
        url: url
    };

    const renderIcon = () => {
        let fileIcon = null;

        if (props.file) {
            return null;
        }

        switch (props.file.fileType.toLowerCase()) {
            case 'word':
                fileIcon = 'icon-file-icon-doc';
                break;
            case 'excel':
                fileIcon = 'icon-file-icon-excel';
                break;
            case 'pdf':
                fileIcon = 'icon-file-icon-pdf';
                break;
            case 'powerpoint':
                fileIcon = 'icon-file-icon-powerpoint';
                break;
            case 'image':
                fileIcon = 'icon-file-icon-image';
                break;
            case 'csv':
                fileIcon = 'icon-file-icon-excel';
                break;
            case 'video':
                fileIcon = 'icon-file-icon-video';
                break;
            case 'audio':
                fileIcon = 'icon-file-icon-audio';
                break;				
        }

        return (<span className={`file-icon ${fileIcon}`} aria-hidden="true"></span>);

    }

    if (thumbnailUrl) {
        const className = isVideo ? 'video-thumbnail' : null
        return (
            <ImageView file={file} user={props.user}
                dateUploaded={dateUploaded} title={props.file.title} className={className} 
                folderId={props.file.containerId}>
                {isVideo && <span className='icon-play-icon'></span>}
                <img alt={props.file.title} src={thumbnailUrl} />
            </ImageView>
        );
    }

    if ('audio' == props.file.fileType.toLowerCase()) {
        return (
            <React.StrictMode>
                <ImageView file={file} user={props.user} 
                    dateUploaded={dateUploaded} title={props.file.title}
                    folderId={props.file.containerId}>
                    <div className='icon-container'>
                        { renderIcon() }
                    </div>
                </ImageView>
            </React.StrictMode>
        )
    }

    return null;
}

export default MediaViewerModule;
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import "moment/locale/en-au";

import DayView from "../models/DayView";
import EventSvc from "../../../../services/EventSvc";

export default class Calendar extends React.Component {

  static propTypes = {
    CurrentMonth: PropTypes.Number,
    CurrentYear: PropTypes.Number,
    Holidays: PropTypes.Array,
  };
  static defaultProps = {
    CurrentMonth: moment().month,
    CurrentYear: moment().year
  };

  constructor(props) {
    super(props);

    this.state = {
      views: [],
      currentMonth: props.CurrentMonth,
      currentYear: props.CurrentYear,
      holidays: null,
      events: []
    };

    // Preload public holidays
    EventSvc.getPublicHolidays(this.props.australianPublicHolidays).promise.then(response => {
      this.setState({ holidays: response });
      this.SetViews();
    });

    // Load events filter by year month
    EventSvc.getCalendarEvents(moment().year(this.props.CurrentYear).month(this.props.CurrentMonth).format("MMYY")).promise.then(response => {
      this.setState({ events: response });
      this.SetViews();
    });
  }

  componentDidMount() {
    if (!history.state || !history.state.currentYear || !history.state.currentMonth) { return; }
    this.state.currentYear = history.state.currentYear;
    this.state.currentMonth = history.state.currentMonth;
  }

  /**
   * Populate calender event views
   * @private
   */
  SetViews() {
    moment.locale('en-au');
    // Total days of current month;
    const daysInMonth = moment(`${this.state.currentMonth + 1}/${this.state.currentYear}`, "M/YYYY").daysInMonth();

    let daysView = [];
    const currentMonthYear = moment().year(this.state.currentYear).month(this.state.currentMonth);
    for (var i = 0; i < daysInMonth; i++) {
      let dayView = new DayView(currentMonthYear, moment().year(this.state.currentYear).month(this.state.currentMonth).date(i + 1), this.state.events, this.state.holidays);
      daysView.push(dayView);
    }

    /**
     * Get weekdays of first and last day of current month,
     * fill rest of the days continue to focused month
     */
    const weekdaysOfFirstMonthDay = daysView[0].Date.day();
    const weekdaysOfLastMonthDay = daysView[daysView.length - 1].Date.day();
    // Append additional days of previous month
    for (var i = weekdaysOfFirstMonthDay - 1; i >= 0; i--) {
      let newDay = new DayView(currentMonthYear, moment(daysView[0].Date).add(-1, 'day'), this.state.events, this.state.holidays);
      daysView = [newDay, ...daysView];
    }
    // Append additional days of coming month
    for (var i = weekdaysOfLastMonthDay + 1; i <= 6; i++) {
      let newDay = new DayView(currentMonthYear, moment(daysView[daysView.length - 1].Date).add(1, 'day'), this.state.events, this.state.holidays);
      daysView.push(newDay);
    }

    this.setState({ views: daysView });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.currentMonth != nextProps.CurrentMonth || this.state.currentYear != nextProps.CurrentYear) {
      this.state.currentMonth = nextProps.CurrentMonth;
      this.state.currentYear = nextProps.CurrentYear;
      history.replaceState({
        currentMonth: this.state.currentMonth,
        currentYear: this.state.currentYear,
      }, "");
      this.SetViews();
    }
  }

  _renderWeekdaysHeader() {
    let headers = _.map(moment.weekdays(), wd => {
      return <div aria-hidden="true" className="month-header">
                        <span>{wd}</span>
                    </div>;
    });

    return <div className="calendar-header">
                {headers}
            </div>;
  }

  _renderDaysView() {
    if (this.state.views % 7 > 0) {
      return null;
    }

    // 7 days a row
    const viewRows = _.reduce(this.state.views, (all, one, i) => {
      const ch = Math.floor(i / 7);
      all[ch] = [].concat((all[ch] || []), one);
      return all;
    }, []);

    return _.map(viewRows, viewRow => {
      return <div className="calendar-row">
                        <div className="calendar-rowBg">
                            {_.map(viewRow, view => {
            return <div className={"calendar-bg-cell" + (!view.isFocusMonth() ? " calendar-bg-cell-light" : "") + (view.isToday() ? " calendar-bg-cell-today" : "") + (view.isHoliday() ? " calendar-bg-cell-holiday" : "")}>
                                    </div>;
          })}
                        </div>
                        <div className="calendar-row-content">
                            <div className="calendar-row">
                                {_.map(viewRow, view => {
              return <div className={"calendar-date-cell" + (!view.isFocusMonth() ? " calendar-date-cell-light" : "") + (view.isToday() ? " calendar-date-cell-today" : "") + (view.isWeekend() ? " calendar-date-cell-weekend" : "") + (view.isHoliday() ? " calendar-date-cell-holiday" : "")}>
                                            <span aria-hidden="true">{moment(view.Date).format("D")}</span>
                                        </div>;
            })}
                            </div>
                            <div className="calendar-row">
                                {_.map(viewRow, view => {
              return <div className={"calendar-event-cell" + (!view.isFocusMonth() ? " calendar-event-cell-light" : "")}>
                                            {view.Events.length > 0 || view.isHoliday() || view.isToday() ? <span className="sr-only">{moment(view.Date).format("dddd")}, {moment(view.month).format("MMMM")} {moment(view.Date).format("Do")}</span> : null}
                                            {view.isToday() ? <span className="sr-only">(Today)</span> : null}
                                            {view.Events.length == 0 && view.isToday() ? <span className="sr-only">No events</span> : null}
                                            {this._renderEventView(view)}
                                        </div>;
            })}
                            </div>
                        </div>
                    </div>;
    });
  }

  _renderEventView(view: DayView) {
    if (!view.Events && !view.isHoliday()) {
      return null;
    }

    let eventView = [];

    if (view.isHoliday()) {
      eventView = _.map(view.Holidays, holiday => {
        return <span className="calendar-event-link calendar-event-link-holiday">{holiday.getDisplayName()}</span>;
      });
    }

    let firstEventId = null;
    if (view.Events) {
      eventView = eventView.concat(_.map(view.Events, event => {
        if (!firstEventId) {
          firstEventId = event.EventId;
        }
        return <a className="calendar-event-link" href={event.EventURL}>
                        <span className={event.AttendanceStatus}>
                            <strong className="calendar-event-name">{event.Name}</strong>
                            <span className="sr-only"> at </span><span>{event.DisplayTime()}</span>
                        </span>
                    </a>;
      }));
    }

    // Display maximum 4 events, create show more anchor if it does exceed
    if (eventView.length > 4) {
      const moreEvent = eventView.length - 4;
      eventView = eventView.slice(0, 4);

      eventView.push(<a className="calendar-event-link-more" href={`./events#${firstEventId || ""}`}>+{moreEvent} more events</a>);
    }

    return eventView;
  }


  render() {
    return <div className="calendarEvent">
                <div className="calendar-view">
                    <h2 id="sr-status-alert" className="sr-only">Showing {moment().month(this.props.CurrentMonth).format("MMMM")} {this.state.currentYear}</h2>
                    {this._renderWeekdaysHeader()}
                    {this._renderDaysView()}
                </div>
            </div>;
  }


}
import React, {
    FC,
} from "react";
import _ from 'lodash';

import {
    forumResult_topics,
    forumDiscussionResult,
} from "../../../../types/graphqlTypes";

import SvgIcon from '../../../icons';
import moment from 'moment';

import ReactTooltip from 'react-tooltip'

import DateTimeUtils from "../../../core/utils/DateTimeUtils";
const renderDateTime = DateTimeUtils.renderDateTime;

interface IDiscussionProps {
    discussionResult: forumDiscussionResult,
    topicResult: forumResult_topics,
    showTopic: boolean,
    onSelectTopic: (topicResult: forumResult_topics) => void,
    onSelectDiscussion: (discussionResult: forumDiscussionResult, topicResult: forumResult_topics) => void
}

export const Discussion: FC<IDiscussionProps> = (props: IDiscussionProps) => {
    const discussionResult = props.discussionResult;
    const status = props.discussionResult.discussion.isOpen ? "open" : "closed";
    const threadClassName = props.discussionResult.discussion.isEnabled ? "thread" : "thread disabled";
    const editedText = (props.discussionResult.lastActivityDate == props.discussionResult.discussion.createdDate) ? "" : " (edited)";

    const selectDiscussion = (e) => {
        e.preventDefault();
        props.onSelectDiscussion(discussionResult, props.topicResult);
    }
    const selectTopic = (e) => {
        e.preventDefault();
        props.onSelectTopic(props.topicResult);
    }

    return (
        <div dir="auto" className={threadClassName}>                                        
        <ReactTooltip place="right" backgroundColor="#738089" className="hover-text" />
            <div className="fn-flex">
                <div className="ProfileInfo">
                    <a href={`/viewprofile?Id=${discussionResult.createdBy.avatarId}`} className="thumb">
                        <img className="thumb" alt={discussionResult.createdBy.name} src={`/api/v1/image?id=${discussionResult.createdBy.avatarId}`} width='54' height='54' />
                    </a>
                </div>
                <div className="form-horizontal">
                    <div className="row">
                        <div className="small-16 large-12 columns">
                            <div>
                                <div className="title" dir="auto">
                                    <a onClick={selectDiscussion}>
                                        {props.discussionResult.headPost.subject ?? <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />}
                                        {!props.discussionResult.discussion.isOpen &&
                                            <span> (Closed)</span>
                                        }
                                    </a>
                                </div>
                                { discussionResult.hasNewMarker &&
                                    <div>
                                        <span className="fn-service-colour new" data-tip="You have unread replies">New!</span>
                                    </div> }
                            </div>
                        </div>
                        <div className="small-16 large-4 columns end no-mobile">
                            <div className="fn-flex-space-between">
                                <div className="count" data-tip="Number of replies">
                                    <SvgIcon icon="icon-bubble" width={24} height={24}/>
                                    <span>{discussionResult?.totalRepliesCount}</span>
                                </div>
                                <div data-tip="Number of unread replies">
                                    <span className="fn-service-bg-colour circle">{discussionResult?.unreadRepliesCount}</span>
                                </div>
                            </div>
                        </div>
                        <div className="small-8 author large-5 columns">
                            <a href={`/viewprofile?Id=${discussionResult.createdBy.id}`}>
                                {discussionResult.createdBy.name}
                            </a>
                        </div>
                        <div className={`small-8 columns ${props.showTopic ? "large-5" : "large-11"}`}>
                            {renderDateTime(moment(discussionResult.discussion.lastModifiedDate))}{editedText}
                        </div>
                        {props.showTopic &&
                        <div className="large-6 columns no-mobile">
                                <a onClick={selectTopic}>{props.discussionResult.topic?.title}</a>
                        </div>
                        }
                        <div className="small-16 large-6 columns end mobile-only">
                            <div className="fn-flex-space-between">
                                <div className="count">
                                    <SvgIcon icon="icon-bubble" width={24} height={24} /><span>{discussionResult?.totalRepliesCount}</span>
                                </div>
                                <div className="unread">
                                    <span className="fn-service-bg-colour circle">{discussionResult?.unreadRepliesCount}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

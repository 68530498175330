
import _ from "lodash";
import UrlUtils from "./UrlUtils";

export default class ImgUtils {

  /**
   * Appends Sitecore image size params to a image url
   * @example
   * var url = ImgUtils.srcWithSizeParams(50,50,'http://www.site.com/img/example.png');
   *
   * >>> 'http://www.site.com/img/example.png?mw=50&mh=50'
   *
   *
   * @param {number|null}	 	width 		- The max width of the image
   * @param {number|null}		height		- The max height of the image
   * @param {string}			url			- The image src url
   * @returns {string}					- The image src url with size params
   */
  static srcWithSizeParams(width: number | null | undefined, height: number | null | undefined, url: string): string {
    var result = url;

    if (width != null && _.isNumber(width)) {
      result = UrlUtils.setParam('mw', width.toString(), result);
    }

    if (height != null && _.isNumber(height)) {
      result = UrlUtils.setParam('mh', height.toString(), result);
    }

    return result;
  }
}
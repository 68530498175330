import React, {
    FC,
}                           from "react";
import _	                from 'lodash';

import {GroupsLists, IGroupListProps }   from "./GroupsLists";

interface IGroupsListsModuleProps {
    isOpen: boolean;
    groupsLists: Array<IGroupListProps>;
}

export const GroupsListsModule : FC<IGroupsListsModuleProps> = (props: IGroupsListsModuleProps) => {
    return (
        <GroupsLists isOpen={props.isOpen} groupsLists={props.groupsLists}/>
    )
}

import React, {
    FC,
} from "react";
import _ from 'lodash';

import {
    forumDiscussionResult,
    forumReplyResult,
} from "../../../../types/graphqlTypes";

import { DATE_TIME_STANDARD_RENDER_FORMAT } from "../../../core/utils/DateTimeUtils";
import RichTextEditor from "../../../core/components/richtext/RichTextEditor";
import moment from 'moment';

interface IQuoteButtonProps {
    rte: React.RefObject<RichTextEditor>,
    discussionResult: forumDiscussionResult,
    replyResult?: forumReplyResult,
    getSelection: () => string,
}

import DateTimeUtils from "../../../core/utils/DateTimeUtils";
const renderDateTime = DateTimeUtils.renderDateTime;

export const QuoteButton: FC<IQuoteButtonProps> = (props: IQuoteButtonProps) => {
    let postId, personName, createdDate;

    if (props.replyResult) {
        postId = props.replyResult.reply.replyId;
        personName = props.replyResult.createdBy.name;
        createdDate = renderDateTime(moment(props.replyResult.reply.createdDate));
    } else {
        postId = props.discussionResult.discussion.discussionId;
        personName = props.discussionResult.createdBy.name;
        createdDate = renderDateTime(moment(props.discussionResult.discussion.createdDate));
    }

    function insertQuote(e) {
        e.preventDefault();

        let content = props.getSelection();

        if(Boolean(content))
        {
            props.rte.current.insertEmbed('quote-in-post', {
                replyId: postId,
                personName: personName,
                createdDate: createdDate,
                replyContent: content,
            });
        }
    }

    return (
        <button className="text-button" onClick={insertQuote}><span className="fn-icon fn-icon-plus fn-icon-primary" aria-hidden="true"></span> Quote in post</button>
    )
}

export default QuoteButton;

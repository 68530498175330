import AppDispatcher 	from '../../../../core/dispatcher/AppDispatcher';
import TaskSvc 			from '../../../../services/TaskSvc';
//import TaskSvc 			from '../../../../../../test/mocks/services/TaskSvc';

import FamiliesTrialConstants  from '../constants/FamiliesTrialConstants';

var _task = {};
_task.request = null;
_task.process = (s, a) => {

	if(_task.request){ throw('Cannot start task. A task job is already running.'); }

	_task.request = TaskSvc.process(s,a);

	_task.request.promise
		.then((response) => {

			_task.request = null;

      		console.log("_task.process.IsComplete");
			if(response.IsComplete) {

				// Broadcast the import complete
				AppDispatcher.dispatch({
					actionType: FamiliesTrialConstants.FAMILIES_TRIAL_IMPORT_FINISHED,
					chunk: response
				});

			}

		})
		.catch(() => {
			// Request failed or was aborted
			_task.request = null;
		});
};

class FamiliesTrialImporterActions {

	/**
	 * Start a new import
	 * @param {string} data - The task response
	 */
	static startImport(data) {
		// Broadcast the import start
		AppDispatcher.dispatch({
			actionType: FamiliesTrialConstants.FAMILIES_TRIAL_IMPORT_FINISHED,
			state: data.state,
			chunk: data
		});

	}

	/**
	 * Stop the current import.
	 * @param {string} state - The task state token
	 */
	static stopImport(state) {
		// Broadcast the import stop
		AppDispatcher.dispatch({
			actionType: FamiliesTrialConstants.FAMILIES_TRIAL_IMPORT_STOPPED
		});
	}
}

export default FamiliesTrialImporterActions;

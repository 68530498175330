export default class TagUtils {
  /**
   * Appends input tags to plain text
   * @param {string}				text		- The text to append tags
   * @param {string}				tagStr		- Tags text
   * @returns {string}
   */
  static serializeTags(text, tagStr) {
    return `[${tagStr}]` + text + `[/${tagStr}]`;
  }

  /**
   * Strips input tags back to plain text
   * @param {string}				text		    - The text to remove tags
   * @param {string}				tagStr		    - Tags text
   * @param {int}				    occurrence		- Extract all if 0, extract occurrence index if provided. Default is 0.
   * @returns {string}
   */
  static deserializeTags(text, tagStr, occurrence = 0) {

    if (occurrence > 0 && this.occurrences(text, `[${tagStr}]`) >= occurrence && this.occurrences(text, `[/${tagStr}]`) >= occurrence) {
      let openIndex = -1,
          closeIndex = -1;
      var i;
      for (i = 0; i < occurrence; i++) {
        openIndex = text.indexOf(`[${tagStr}]`, openIndex) + `[${tagStr}]`.length;
        closeIndex = text.indexOf(`[/${tagStr}]`, closeIndex);
      }
      if (openIndex > 0 && closeIndex > 0) {
        return text.substring(openIndex, closeIndex);
      }
    }

    // unwrap any tags
    text = text.replace(new RegExp(`\[${tagStr}\b[^\[]*\]`), ''); // Remove the opening a tags
    text = text.replace(new RegExp(`\[\/${tagStr}\b[^\[]*\]`), ''); // Remove the closing a tags

    return text;
  }

  /**
   * Determine input contains tags
   * @param {string}				text		- The text
   * @param {string}				tagStr		- Tags text
   * @returns {boolean}
   */
  static containsTags(text, tagStr) {
    let regExp = new RegExp(`\[${tagStr}\b[^\[]*\]`);
    if (regExp.test(text)) {
      //regExp = new RegExp(`\[\/${tagStr}\]`);
      regExp = new RegExp(`\[\/${tagStr}\b[^\[]*\]`);
      return regExp.test(text);
    }

    return false;
  }

  /** Function that count occurrences of a substring in a string;
   * @param {String} string               The string
   * @param {String} subString            The sub string to search for
   * @param {Boolean} [allowOverlapping]  Optional. (Default:false)
   *
   * @author Vitim.us https://gist.github.com/victornpb/7736865
   * @see Unit Test https://jsfiddle.net/Victornpb/5axuh96u/
   * @see http://stackoverflow.com/questions/4009756/how-to-count-string-occurrence-in-string/7924240#7924240
   */
  static occurrences(string, subString, allowOverlapping) {

    string += "";
    subString += "";
    if (subString.length <= 0) return string.length + 1;

    var n = 0,
        pos = 0,
        step = allowOverlapping ? 1 : subString.length;

    while (true) {
      pos = string.indexOf(subString, pos);
      if (pos >= 0) {
        ++n;
        pos += step;
      } else break;
    }
    return n;
  }

  /**
   * Add non-breaking space to empty block
   * Workaround for IE9 that empty block be removed when converting from html to editor state
   * @param {string} txt      Text being serialise non-breaking space if emtpy content
   */
  static serialiseEmptyBlocksWithNonBreakingSpace(txt: string) {
    if (!txt) return txt;

    txt = txt.replace(/(\<p\>(\s*)\<\/p\>)/g, "<p>&nbsp;</p>");
    txt = txt.replace(/(\<li\>(\s*)\<\/li\>)/g, "<li>&nbsp;</li>");

    return txt;
  }

  /**
   * Get the currently selected text, and the parent container of the element where the text is selected
   * 
   * @param tagName The tagName of the parent element you want returned.  Parents will be searched until a match is found.
   * @returns The selected text and its parent element
   */
  static getSelectionTextAndContainerElement(tagName: string, className: string) {
    let text: string | null = null, containerElement: Node | null = null;
    if (typeof window.getSelection != "undefined") {
      let sel = window.getSelection();
      if (sel.rangeCount) {
        let node = sel.getRangeAt(0).commonAncestorContainer;
        containerElement = node.nodeType == 1 ? node : node.parentNode;

        var container = document.createElement("div");
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
        }
        text = container.innerHTML;        
      }
    } else if (typeof document.selection != "undefined" &&
      document.selection.type != "Control") {
      let textRange = document.selection.createRange();
      containerElement = textRange.parentElement();
      text = textRange.text;
    }
    return {
      text: text,
      containerElement: TagUtils.getParentByTag(tagName, className, containerElement)
    };
  }

  /**
   * Return the first element (or its parent) that matches the tagName and (optionally) also has a specific classname
   */
  static getParentByTag(tagName: string, className: string, element: HTMLElement | null) {
    if ((!element || !element.parentElement || element.tagName.toLowerCase() == tagName) && (!element || !element.parentElement || !className || element.className == className)) {
      return element;
    } else {
      return TagUtils.getParentByTag(tagName, className, element.parentElement);
    }
  }

  
}
// Explanation at http://billauer.co.il/blog/2021/12/quill-br-shift-enter/

import {Quill} from 'react-quill'; 

let Parchment = Quill.import('parchment');

export class ShiftEnterBlot extends Parchment.Embed {} // Actually EmbedBlot

ShiftEnterBlot.blotName = 'ShiftEnter';
ShiftEnterBlot.tagName = 'br';

import React, {
    FC,
    useEffect,
    useCallback,    
    useState,
}                           from "react";
import _	                from 'lodash';

import "./GroupsLists.css"
import UserDisplayPreferenceActions    from '../../core/actions/UserDisplayPreferenceActions';

export interface IGroupListProps {
    title: string;
    isOpen: boolean;
    groups: Array<any>;
}

const GroupList : FC<IGroupListProps> = (props: IGroupListProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);

    if (props.groups == null || props.groups.length == 0) {
        return null;
    }

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const buttonClassName = `fn-icon-button fn-float-right${(!isOpen ? " fn-icon-rotate-180" : "")}`;

    return (
        <div>
            <div className="fn-section-header">
                <span>{props.title}</span>
                <span className={buttonClassName} onClick={toggle}>
                    <span className="fn-icon fn-icon-arrow-down">
                    </span>
                </span>
            </div>
            <div className="fn-section-body">
                <ul className="group-list">
                    { isOpen && props.groups.map((group, i) => 
                        <li key={i} className="clearfix fn-section-item">
                            <div className="group-thumbnail">
                                <a href={group.url}>
                                    <img src={group.imageUrl} title={group.name}></img>
                                </a>
                            </div>
                            <div className="group-title">
                                <a href={group.url}>
                                    {group.name}
                                </a>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export interface IGroupsListsProps {
    isOpen: boolean;
    groupsLists: Array<IGroupListProps>;
}

export const GroupsLists : FC<IGroupsListsProps> = (props: IGroupsListsProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
    const socialGroups = props.groupsLists?.filter(g => g.title === 'Social Groups');
    const officialGroups = props.groupsLists?.filter(g => g.title === 'Official Groups');
    const familyGroups = props.groupsLists?.filter(g => g.title === 'Family Groups');
    const professionalGroups = props.groupsLists?.filter(g => g.title === 'Professional Groups');
    const [isSocialGroupOpen, setIsSocialGroupOpen] = useState<boolean>(socialGroups.length > 0 ? socialGroups[0].isOpen : true);
    const [isOfficialGroupOpen, setIsOfficialGroupOpen] = useState<boolean>(officialGroups.length > 0 ? officialGroups[0].isOpen : true);
    const [isFamilyGroupOpen, setIsFamilyGroupOpen] = useState<boolean>(familyGroups.length > 0 ? familyGroups[0].isOpen : true);   
    const [isProfessionalGroupOpen, setIsProfessionalGroupOpen] = useState<boolean>(professionalGroups.length > 0 ? professionalGroups[0].isOpen : true);
    const savedIsOpen = props.isOpen;
    const savedIsSocialGroupOpen = socialGroups.length > 0 ? socialGroups[0].isOpen : true;
    const savedIsOfficialGroupOpen = officialGroups.length > 0 ? officialGroups[0].isOpen : true;
    const savedIsFamilyGroupOpen = familyGroups.length > 0 ? familyGroups[0].isOpen : true;
    const saveIsProfessionalGroupOpen = professionalGroups.length > 0 ? professionalGroups[0].isOpen : true;   

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    const toggleSocialGroup = () => {
        setIsSocialGroupOpen(!isSocialGroupOpen);
    }
    const toggleOfficialGroup = () => {
        setIsOfficialGroupOpen(!isOfficialGroupOpen);
    }
    const toggleFamilyGroup = () => {
        setIsFamilyGroupOpen(!isFamilyGroupOpen);
    }
    const toggleProfessionalGroup = () => {
        setIsProfessionalGroupOpen(!isProfessionalGroupOpen);
    } 

    const buttonSocialGroupClassName = `fn-icon-button fn-float-right${(!isSocialGroupOpen ? " fn-icon-rotate-180" : "")}`;
    const buttonOfficialGroupClassName = `fn-icon-button fn-float-right${(!isOfficialGroupOpen ? " fn-icon-rotate-180" : "")}`;
    const buttonFamilyGroupClassName = `fn-icon-button fn-float-right${(!isFamilyGroupOpen ? " fn-icon-rotate-180" : "")}`;
    const buttonProfessionalGroupClassName = `fn-icon-button fn-float-right${(!isProfessionalGroupOpen ? " fn-icon-rotate-180" : "")}`;

    useEffect(() => {
        const saveGroupsState = (e) => {
            let savedDisplayPreferences = {
                ShowGroups: isOpen,
                ShowSocialGroups: isSocialGroupOpen,
                ShowOfficialGroups: isOfficialGroupOpen,
                ShowFamilyGroups: isFamilyGroupOpen,
                ShowProfessionalGroups: isProfessionalGroupOpen
            };
            const isDirty = savedIsOpen != isOpen 
                            || savedIsSocialGroupOpen != isSocialGroupOpen
                            || savedIsOfficialGroupOpen != isOfficialGroupOpen
                            || savedIsFamilyGroupOpen != isFamilyGroupOpen
                            || saveIsProfessionalGroupOpen != isProfessionalGroupOpen;
            if (isDirty) {
                UserDisplayPreferenceActions.saveDisplayPreferences(savedDisplayPreferences); 
            }             
        }

        window.addEventListener("beforeunload", saveGroupsState);
        return () => {
            window.removeEventListener("beforeunload", saveGroupsState);
        };
    }, [isOpen, isSocialGroupOpen, isOfficialGroupOpen, isFamilyGroupOpen, isProfessionalGroupOpen]);

    const buttonClassName = `fn-icon-button fn-float-right${(!isOpen ? " fn-icon-rotate-180" : "")}`;

    return (
        <div>
            <div className="groups-list">
                <div className="fn-react-root block">
                    <div className="fn-header">
                        Groups
                        <span className={buttonClassName} onClick={toggle}>
                            <span className="fn-icon fn-icon-arrow-down fn-icon-white">
                            </span>
                        </span>
                    </div>
                    { isOpen && <div id="groupsListRight" className="fn-section-right group-list-container">
                        {/* { props.groupsLists.map((groupList, index) => <GroupList key={index} title={groupList.title} groups={groupList.groups} isOpen={groupList.isOpen}/>)} */}
                        { officialGroups.length > 0 && <div>
                            <div className="fn-section-header">
                                <span>{officialGroups[0].title}</span>
                                <span className={buttonOfficialGroupClassName} onClick={toggleOfficialGroup}>
                                    <span className="fn-icon fn-icon-arrow-down">
                                    </span>
                                </span>
                            </div>
                            <div className="fn-section-body">
                                <ul className="group-list">
                                    { isOfficialGroupOpen && officialGroups[0].groups.map((group, i) => 
                                        <li key={i} className="clearfix fn-section-item">
                                            <div className="group-thumbnail">
                                                <a href={group.url}>
                                                    <img src={group.imageUrl} title={group.name}></img>
                                                </a>
                                            </div>
                                            <div className="group-title">
                                                <a href={group.url}>
                                                    {group.name}
                                                </a>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div> }  
                        { familyGroups.length > 0 && <div>
                            <div className="fn-section-header">
                                <span>{familyGroups[0].title}</span>
                                <span className={buttonFamilyGroupClassName} onClick={toggleFamilyGroup}>
                                    <span className="fn-icon fn-icon-arrow-down">
                                    </span>
                                </span>
                            </div>
                            <div className="fn-section-body">
                                <ul className="group-list">
                                    { isFamilyGroupOpen && familyGroups[0].groups.map((group, i) => 
                                        <li key={i} className="clearfix fn-section-item">
                                            <div className="group-thumbnail">
                                                <a href={group.url}>
                                                    <img src={group.imageUrl} title={group.name}></img>
                                                </a>
                                            </div>
                                            <div className="group-title">
                                                <a href={group.url}>
                                                    {group.name}
                                                </a>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div> }     
                        { professionalGroups.length > 0 && <div>
                            <div className="fn-section-header">
                                <span>{professionalGroups[0].title}</span>
                                <span className={buttonProfessionalGroupClassName} onClick={toggleProfessionalGroup}>
                                    <span className="fn-icon fn-icon-arrow-down">
                                    </span>
                                </span>
                            </div>
                            <div className="fn-section-body">
                                <ul className="group-list">
                                    { isProfessionalGroupOpen && professionalGroups[0].groups.map((group, i) => 
                                        <li key={i} className="clearfix fn-section-item">
                                            <div className="group-thumbnail">
                                                <a href={group.url}>
                                                    <img src={group.imageUrl} title={group.name}></img>
                                                </a>
                                            </div>
                                            <div className="group-title">
                                                <a href={group.url}>
                                                    {group.name}
                                                </a>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div> }  
                        { socialGroups.length > 0 && <div>
                            <div className="fn-section-header">
                                <span>{socialGroups[0].title}</span>
                                <span className={buttonSocialGroupClassName} onClick={toggleSocialGroup}>
                                    <span className="fn-icon fn-icon-arrow-down">
                                    </span>
                                </span>
                            </div>
                            <div className="fn-section-body">
                                <ul className="group-list">
                                    { isSocialGroupOpen && socialGroups[0].groups.map((group, i) => 
                                        <li key={i} className="clearfix fn-section-item">
                                            <div className="group-thumbnail">
                                                <a href={group.url}>
                                                    <img src={group.imageUrl} title={group.name}></img>
                                                </a>
                                            </div>
                                            <div className="group-title">
                                                <a href={group.url}>
                                                    {group.name}
                                                </a>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div> }                                                                                               
                        </div>
                    }
                    { <div className="fn-section-dummy">&nbsp;</div> }                    
                </div>
            </div>
        </div>
    );
}

export default GroupsLists;
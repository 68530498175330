import * as React from "react";

function SvgIconTick(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.998 0C7.164 0 0 7.163 0 16s7.164 16 15.998 16C24.834 32 32 24.837 32 16S24.834 0 15.998 0zM26.22 11.785l-11.236 11.26c-.324.324-.964.59-1.42.59h-.969c-.457 0-1.097-.267-1.419-.59l-5.291-5.316a.838.838 0 010-1.179l2.082-2.082a.838.838 0 011.179-.002l3.378 3.372a.835.835 0 001.179 0l9.264-9.284a.837.837 0 011.177-.004l2.071 2.055a.83.83 0 01.005 1.177z"
      />
    </svg>
  );
}

export default SvgIconTick;

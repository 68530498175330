import React from "react"

class Loader extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div
                id="conversationsLoader"
                className="conversations-loader"
                style={{ display: `${this.props.show ? "block" : "none"}` }}>
                <div className="spinner" />
            </div>
        )
    }
}

export default Loader

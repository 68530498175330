import _                        from 'lodash';
import React                    from 'react';
import ReactDOM					from 'react-dom';
import PropTypes from 'prop-types'
import $	 					from 'jquery';

import {UserFactory}            from '../../core/models/User';
import UserSvc                  from '../../services/UserSvc';


import FormUserSelector         from '../../core/components/form/FormUserSelector';
import FormUserInviteSelector   from '../../core/components/form/FormUserInviteSelector';
import CsvUserSelector          from './CsvUserSelector';
import AppDelegate		        from '../../core/AppDelegate';

const fileUpload = require('blueimp-file-upload');

class GroupInvitationSelectMember extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            includes: {},
            initialUsers: [],
            key: 0,
            filterCategories: [],
            uploadType: "manual",
            loadingFile:false,
            inviteMode: 'individuals',
            showFileUpload: this.props.showFileUpload
        };

        // Iterate the includes string and populate an object with true values.
        if(props.includes.length) {
            _.forEach(props.includes.split(','), (n)=> {
                if(n !== '') {
                    this.state.includes[n] = true;
                }
            });
        }

        if(props.pre) {
            // Map to state
            this.state.initialUsers = _.map(props.pre, (u) => {
                return UserFactory.userFromObject(u);
            });

            // Set initial value of native element
            this._setValue(this.state.initialUsers);
        }

        if(this.props.fnNode) {
            this.props.fnNode.reset = this.reset.bind(this);
        }
        
        if (props.filterCategories != null && props.filterCategories.length) {
            _.forEach(props.filterCategories.split(','), (n)=> {
                if(n !== '') {
                    this.state.filterCategories.push(n);
                }
            });
        }
        this._queryOptions = FormUserInviteSelector.MapQueryOptions(props.includes, props.groupId, props.excludeGroupId, props.excludeEventId, this.state.filterCategories);
        this._csvQueryOptions = FormUserInviteSelector.MapQueryOptions(props.includes, props.groupId, props.excludeGroupId, props.excludeEventId, this.state.filterCategories, true);
        
    }

    isWorking() {
        return this.state.queryRequest !== null;
    }


    reset() {

        this.setState({
            key: this.state.key + 1,
            initialUsers: [] // This is so after sending a message the users are cleared
        });

        this._setValue(null);
    }

    _setValue(users) {
        if(this.props.fnNode) {

            // Reference native element passed in from bootstrapper
            let nativeElement = this.props.fnNode;

            if(users === null){
                nativeElement.value = '';
            } else {
                // Set value to comma seperated list of id's
                nativeElement.value = _.map(users, (u) => {return u.id;}).join(',');
            }
        }
    }

    _handleSelectorChange(users) {
        this._setValue(users);
    }

    _fileChangeHandler(event){
        const content = !_.isUndefined(event.target) ? event.target.result : event;
                var ids = content.split("\n");
                var empids=[];
                for (var i = ids.length - 1; i >= 0; i--) {
                    var id = ids[i].trim();
                    if (id){
                        var v = parseInt(id);

                        if (!isNaN(v))
                        {
                            empids.push(v);
                        }
                    }
                }
                let req = UserSvc.getByPMKey(empids, this.props.excludeGroupId ? [this.props.excludeGroupId] : null );

                this.setState({
                    loadingFile:true
                });
                req.promise.then((response) => {
                    let users = response.users;
                    this.forminput._addUsersToResults(response.users)
                    this._handleSelectorChange(users);
                    this.setState({
                        loadingFile:false
                    });
                    // Clear the file as we have finished using it.
                    this.refs.file.value = '';

                }).catch((e)=> {
                    // If not abort, then throw error
                    // else silently fail
                    if(e.reason && e.reason !== 'abort') {
                        this.setState({
                            queryRequest: null,
                            searchResults: null,
                            searchStatus: null,
                            loadingFile:false
                        });
                        throw e;
                    }
                });
    }

    _handleFileChange(event){
        /**
         * <IE10 hasn't support html 5 file input, parse value property instead
         */
        var files = !_.isUndefined(event.target.files) ?
                    event.target.files :
                    event.target.value;
        for (var i = 0, f; f = files[i]; i++) {
            var file = files[i];
            if (!_.isUndefined(window.FileReader)){
                var reader = new FileReader();
                const MAX_EMPIDS=500;
                reader.onload = (event) => {
                    this._fileChangeHandler(event);
                };            
                reader.readAsText(file);
            }
        }
    }

    componentDidMount(){        
        //Read from server
        if (this.refs.file && _.isUndefined(window.FileReader)){
            var $inpFile = $(ReactDOM.findDOMNode(this.refs.file));
            // append CSRF token in the form data
            var formData = [{
                name: '__RequestVerificationToken',
                value: $('input[name=__RequestVerificationToken]').val()
            }];
            
            // Set up the jquery plugin
            $inpFile.fileupload({
                url: AppDelegate.appSettings.readInviteFileUrl,
                dataType: 'json',
                formData: formData,
                autoUpload: true,
                dropZone: null
            })

            .bind('fileuploaddone', (e, data) => {
                //Skip if error returned from server
                if (!data.result.d.ErrorMessage){
                    this._onInviteFileRead(data.result.d.Html.Message || null);
                }
            }); 
        }      
    }

    // Invite details read from server
    _onInviteFileRead(data){
        this._fileChangeHandler(data);
    }

    _handleInviteModeChange(e) {
        this.setState({
            inviteMode: e.target.value
        });

        // Hide upload if not individuals
        this.setState({showFileUpload: this.props.showFileUpload && e.target.value == 'individuals'});
    }

    _processingFile() {
        return (
            <span className='spinner spinner-sm'></span>
        );
    }
    render() {
        return (
            <div>
                { this.props.allowInviteAll ?
                    <div>
                        <div className="radio-inline"><label htmlFor="invite-group"><input type="radio" name="inviteMode" id="invite-group" value="group" checked={this.state.inviteMode === 'group'} onChange={this._handleInviteModeChange.bind(this)}></input>Invite all in host group</label></div>
                        <div className="radio-inline"><label htmlFor="invite-individuals"><input type="radio" name="inviteMode" id="invite-individuals" value="individuals" checked={this.state.inviteMode === 'individuals'} onChange={this._handleInviteModeChange.bind(this)}></input>Invite individual members</label></div>
                    </div>
                    : null
                }
                { this.state.showFileUpload ? 
                    <div id="usersfile">
                        <div className="btn btn-primary btn-file">
                            <label htmlFor="userfileupload">Upload</label>
                            <input id="userfileupload" type="file" ref="file" onChange={this._handleFileChange.bind(this)} />
                        </div>
                        <div className="small-1 column end">
                            <span className="tooltip tooltip-hover no-mobile" tabIndex="0" rel="The upload file must be in CSV format and contain only one column with a list of Defence Employee IDs (PMKeyS numbers) - max 500 at a time.">?</span>
                        </div>
                    </div>
                    : null
                }
                {this.state.loadingFile ? this._processingFile() : null}
                <div className="invite-mode-content">
                { this.state.inviteMode === 'individuals' ?
                    <FormUserInviteSelector
                        key={this.state.key}
                        name={this.props.name}
                        onChange={this._handleSelectorChange.bind(this)}
                        includes={this.state.includes}
                        groupId={this.props.groupId}
                        excludeGroupId={this.props.excludeGroupId}
                        excludeEventId={this.props.excludeEventId}
                        initialUsers={this.state.initialUsers}
                        className={this.props.className}
                        filterCategories={this.state.filterCategories}
                        ref={ (control) => {this.forminput = control; } }
                    />
                    :
                    <div className="invite-all">
                        <p>
                            <strong className="italicized">Sending invitations to:</strong><br/>
                            All members in <strong>{this.props.groupName}</strong>
                        </p>
                    </div>
                }
                </div>
            </div>
        );        
    }
}

GroupInvitationSelectMember.defaultProps = {
    selectType: 'tags',
    className: '',
    allowMultiple: true,
    includes: '',
    groupId: null,
    excludeGroupId: null,
    excludeEventId: null,
    filterCategories: '',
    showFileUpload: false,
    allowInviteAll: false,
    groupName: ''
};

GroupInvitationSelectMember.propTypes = {
    selectType:         PropTypes.oneOf(['tags', 'invite']),
    className:          PropTypes.string,
    allowMultiple:      PropTypes.bool,
    includes:           PropTypes.string,
    groupId:            PropTypes.string,
    excludeGroupId:     PropTypes.string,
    excludeEventId:     PropTypes.string,
    filterCategories:   PropTypes.string,
    showFileUpload:     PropTypes.bool,
    allowInviteAll:     PropTypes.bool,
    groupName:          PropTypes.string,
};

export default GroupInvitationSelectMember;


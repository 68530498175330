
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { AuditLog } from "../../../../core/models/AuditLog";
import AuditLogViewerExport from "./AuditLogViewerExport";
import Alert from "../../../../core/components/Alert";

export default class AuditLogViewerResult extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      resultList: this.props.resultList,
      searchStatus: this.props.searchStatus,
      searchQuery: this.props.searchQuery
    };
  }

  static getDerivedStateFromProps(props, state) {
    var hasDerived = false
    var derived = {}
    if (state.resultList != props.resultList) {
      derived = {
        ...derived,
        resultList: props.resultList,
      }
      hasDerived = true
    }
    
    if (state.searchStatus != props.searchStatus) {
      derived = {
        ...derived,
        searchStatus: props.searchStatus,
        searchQuery: props.searchQuery
      }
      hasDerived = true
    }

    return hasDerived ? derived : null
  }

  //Change details in a table at new row
  _renderChangeDetails(auditChangeColumns: Array<AuditChangeColumn>, className: string) {
    let details = null;
    if (auditChangeColumns) {
      details = <tr className={className}>
          <td className="details-cell" colSpan="4">
            <table>
              <thead>
                <th>Column name</th>
                <th>Action</th>
                <th>Table</th>
                <th>Old Value</th>
                <th>New Value</th>
              </thead>
                {_.map(auditChangeColumns, row => {
              return <tr>
                      <td>{row.columnName}</td>
                      <td>{row.action}</td>
                      <td>{row.tableName}</td>
                      <td>{row.oldValue}</td>
                      <td>{row.newValue}</td>
                    </tr>;
            })}
            </table>
          </td>
        </tr>;
    }
    return details;
  }

  _renderParameters(params) {
    return <ol className="parameters">
        {_.map(Object.keys(params), p => <li><span className="type">{params[p]}</span> <span className="name">{p}</span></li>)}
      </ol>;
  }

  _renderMethodName(name) {
    let matches = name.match(AuditLogViewerResult._methodNameRe);

    return <span><span className="namespace">{matches[1]}</span>.<span className="class">{matches[2]}</span>.<span className="method">{matches[3]}</span></span>;
  }

  _renderStackTrace(stacktrace) {
    return <ol className="stacktrace">
        {_.map(stacktrace, i => <li>{this._renderMethodName(i.Name)}({this._renderParameters(i.Parameters)})</li>)}
      </ol>;
  }

  _onResultClick(result) {
    result.showDetail = !result.showDetail;
    this.setState(this.state.resultList);
  }

  render() {
    //Do nothing if initial state
    if (this.state.resultList.length == 0 && !this.state.searchStatus) {
      return null;
    }

    //Prepare alert if message exists
    let alert = null;
    if (this.state.searchStatus) {
      alert = <Alert type="success">
          {this.state.searchStatus} Rendered on {new Date().toLocaleString()}.
        </Alert>;
    }

    let renderResult = <div>
        <div>{alert}</div>
        {this.state.resultList && this.state.resultList.length > 0 ? <div>
            <table className="table table-selectable">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Username</th>
                  <th>PMKeyS</th>
                  <th>Activity</th>
                </tr>
              </thead>
              {_.map(this.state.resultList, result => {
            let detailClassName = "audit-detail";

            if (!result.showDetail) {
              detailClassName += " invisible";
            }

            return <tbody key={result.auditLogId}>
                    <tr onClick={this._onResultClick.bind(this, result)}>
                      <td data-th="Date">{result.dateCreated}</td>
                      <td data-th="Username">{result.userName}</td>
                      <td data-th="PMKeyS">{result.pMKey}</td>
                      <td data-th="Activity">{result.intentsDescription}</td>
                    </tr>
                    {this._renderChangeDetails(result.auditChangeColumns, detailClassName)}
                    <tr className={detailClassName}>
                      <td colSpan="4">
                        <div className="data">
                          <p>MachineName: {result.data.MachineName}</p>
                          <p>URL: {result.data.Url}</p>
                          <p>StackTrace:
                            {this._renderStackTrace(result.data.StackTrace)}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>;
          })}
            </table>
          </div> : null}
        <div className="panel-footer panel-grid">
          <div className="row">
            <div className="small-16 columns large-align-r">
              <AuditLogViewerExport searchQuery={this.state.searchQuery} />
            </div>
          </div>
        </div>
      </div>;

    return renderResult;
  }
}

AuditLogViewerResult._methodNameRe = new RegExp("(.*)\\.(.*)\\.(.*)$");
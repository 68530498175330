import React, {
    FC,
} from "react";
import _ from 'lodash';
import { ForumSortOrder } from "../model/ForumSortOrder";

interface SortProps {
    sortList: any,
    sortOrder: any,
    topicsEnabled: boolean,
    onChangeSortOrder: (e) => void,
}

export const Sort: FC<SortProps> = (props: SortProps) => {
    return (
        <div className="sort">
            <label htmlFor="sort">Sort by: </label>
            <select id="sort" onChange={props.onChangeSortOrder} value={props.sortOrder}>
                {Object.keys(props.sortList).map((key, index) =>
                    (props.topicsEnabled || props.sortList[key] != ForumSortOrder.Topic) &&
                    <option key={index}>{props.sortList[key]}</option>
                )}
            </select>
        </div>
    )
}

export default Sort;

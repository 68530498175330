import React 				from 'react';
import PropTypes from 'prop-types'
import FileUtils 			from '../../../core/utils/FileUtils';
import FileExplorerStore 	from '../stores/FileExplorerStore';
import Modal        		from '../../../core/components/modal/Modal';
import BtnClose     		from '../../../core/components/BtnClose';
import DropDown				from '../../../core/components/DropDown';
import { UserSelector }     from "./UserSelector";
import AppDelegate            from '../../../core/AppDelegate';

import {
    getFolderAccess,
    getFolderAccess_folderAccess
} from "../../../../types/graphqlTypes";


const folderAccessGql = require('../../../../gql/getFolderAccess.gql')
class ModalFolderEdit extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
            edit: { valid: false, title: props.folder?.name ?? '' },
            parentFolderId: props.parentFolderId,
            showAccessRestriction: false,
            accessRestrictionsApply: props.folder.isAccessRestricted,
            selectedUsers: [],
        };
    }

    async componentDidMount() {
        if (this.props.canManageAccessRestriction) {
            const selectedUsers = await AppDelegate.client.query({
                query: folderAccessGql,
                variables: {
                    folderId: this.props.folder.id
                },
                fetchPolicy: "no-cache"
            }).then(({data}) => data?.folderAccess) ?? [];

            this.setState({
				selectedUsers: selectedUsers.sort((l, r) => l.fullName < r.fullName ? -1 : 0)
            })
        }
    }

    componentWillReceiveProps(props)
    {
        let title = '';

        if (props.folder != null && props.folder.name != null) {
            title = props.folder.name;
        }

        let valid = 0 != title.trim().length;

		this.setState({
            edit: { valid: valid, title: title },
            parentFolderId: props.parentFolderId
        });
    }

    handleFocus(e) {
        e.target.select();
    }

    _handleModalCloseRequest(event) {
        event.preventDefault();

        this.props.onCloseClick()
    }

    _handleUpdateClick(event) {
        event.preventDefault();
        
		if (!this.state.edit.valid) {
			return;
		}

        this.props.onUpdateRequest(this.props.folder, this.state.parentFolderId, this.state.edit.title,
            this.props.canManageAccessRestriction ? this.state.accessRestrictionsApply : null,
            this.props.canManageAccessRestriction ? this.state.selectedUsers.map(i => i.id) : null);
    }

    checkValid() {
        let valid = 0 < this.state.edit.title.trim().length && this.state.parentFolderId && this.state.parentFolderId !== this.props.folder.id;

        this.setState({
            edit: {
                title: this.state.edit.title,
                valid
            }
        });
    }

    _handleFolderChange(option) {
        this.setState({
        	parentFolderId: option.value
        }, () => this.checkValid());
    }

    _handleEditChange(event)
	{
		const target = event.target;
   		let value = target.type === 'checkbox' ? target.checked : target.value;

		let valid = this.state.edit.valid;

        if (value.length > 50) {
            value = value.substring(0, 50);
        }
        valid = 0 < value.trim().length;

		let edit = this.state.edit;
		edit.title = value;
		edit.valid = valid;

		this.setState({
			edit: edit
		});
	}

	blur() {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
	}

    _setShowAccessRestriction = (e) => {
        e?.preventDefault();
        this.blur();
        this.setState({
            showAccessRestriction: true
        });
    }
    
    _setHideAccessRestriction = (e) => {
        e?.preventDefault();
        this.blur();
        this.setState({
            showAccessRestriction: false
        });
    }

    _onSetSelectedUsers = (users) => {
        this.checkValid();
        this.setState({
            selectedUsers: users
        });
    }

    _toggleAccess = (e) => {
        this.checkValid();
        this.setState({
            accessRestrictionsApply: !this.state.accessRestrictionsApply
        })
    }

    render() {
        const tabClassName = this.props.canManageAccessRestriction ? "tabs tabs-access" : "";
        const filesClassName = `files ${this.state.showAccessRestriction ? "hidden" : ""}`;

        let name = "this folder";
        if (this.props.folder != null) {
            name = `"${this.props.folder.name}"`;
        }

		return (
            <Modal onRequestsClose={this._handleModalCloseRequest.bind(this)} active={this.props.active} size='default'>
                <form onSubmit={this._handleUpdateClick.bind(this)}>
                    <div className='modal-header'>
                        <BtnClose onClick={this._handleModalCloseRequest.bind(this)} />
                        <h2 className='modal-title'>Update {name}</h2>
                    </div>
                    <div className='modal-content file-modal' style={{ display: "flex", flexDirection: "column", paddingTop: "10px", paddingBottom: "5px", overflowY: "Auto"}}>
                        <div className={tabClassName}>
                            { this.props.canManageAccessRestriction && 
                                [ <div key={0} className="tab-header">
                                    <a href="#" role="button" className={!this.state.showAccessRestriction ? 'active' : ''} onClick={this._setHideAccessRestriction}><span>Folder details</span></a>
                                    <a href="#" role="button" className={this.state.showAccessRestriction ? 'active' : ''} onClick={this._setShowAccessRestriction}><span>Access restrictions</span></a>
                                </div>,
                                <br key={1}/>
                                ]
                            }

                            <div className={filesClassName}>
                                { this.props.folders.length > 0 && <div>
                                    <div>
                                        <label htmlFor="folder-parent">Parent {this.props.folderLabel.toLowerCase()}</label>
                                    </div>
                                    <div>
                                        <DropDown folderDropdown={true} options={this.props.folders} ref="folderId" defaultValue={this.state.parentFolderId} disabled={false} onChange={this._handleFolderChange.bind(this)}></DropDown>
                                    </div>
                                </div>}
                            <label htmlFor="folder-title">Enter the name of your {this.props.folderLabel.toLowerCase()}</label>
                            <input autoFocus onFocus={this.handleFocus} type="text" id="folder-title" value={this.state.edit.title} className="form-control" name="title" placeholder="Title" onChange={this._handleEditChange.bind(this)} spellCheck="true" />
                        </div>
                        { this.state.showAccessRestriction && <div className="access">
                                <div className="checkbox-inline">
                                    <label><input type="checkbox" onClick={this._toggleAccess} defaultChecked={this.state.accessRestrictionsApply}/>Limit access only to administrators</label>
                            </div>

                            { this.state.accessRestrictionsApply && <div>
                                <div>Also, allow access to the following members</div>
                                <UserSelector eventId={this.props.eventId} groupId={this.props.groupId} selectedUsers={this.state.selectedUsers} onEmit={this._onSetSelectedUsers} label="Members" placeholder="Search for a member" />
                            </div>}
                        </div>}                            
                        <div className="modal-actions">
                            <button role="button" className="btn btn-primary" onClick={this._handleUpdateClick.bind(this)} disabled={!this.state.edit.valid}>OK</button>
                            &nbsp;
                            <button role="button" className="btn btn-default" onClick={this._handleModalCloseRequest.bind(this)}>Cancel</button>
                        </div>
                        </div>
                    </div>
                </form>
            </Modal>
		);
	}
}

ModalFolderEdit.defaultProps = {
    folderLabel: 'Folder',
    canManageAccessRestriction: true
}

ModalFolderEdit.propTypes = {
	folder: PropTypes.object.isRequired,
	active: PropTypes.bool.isRequired,
    folderLabel: PropTypes.string,
    folders: PropTypes.array.isRequired,
    canManageAccessRestriction: PropTypes.bool,
    eventId: PropTypes.number,
    groupId: PropTypes.string,
};

export default ModalFolderEdit;


import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import AuditSvc from "../../../../services/AuditSvc";
import moment from "moment";

//Dynamically generate input controls
//Display name, state name, input type
const createControlsList = [[["Username", "userName", "text"]], [["PMKey", "pMKey", "number"]], [["Start date", "startDate", "date"], ["Start time", "startTime", "time"]], [["End date", "endDate", "date"], ["End time", "endTime", "time"]], [["Activity", "intents", "text"]], [["Keyword search", "keywordSearch", "text"]]];

class AuditLogViewerSearch extends React.Component {

  constructor(props) {
    super(props);

    this._queryOptions = null;

    this.state = {
      searchCriteria: {
        userName: "",
        pMKey: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        intents: "",
        keywordSearch: ""
      }
    };
  }

  _resetFilter(e) {
    this.setState({
      searchCriteria: {
        userName: "",
        pMKey: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        intents: "",
        keywordSearch: ""
      }
    });
    e.preventDefault();
  }

  //Testing purpose(TBD): Sync audit trail
  _handlePushUpdatedAudit(e) {
    AuditSvc.pushAudit();

    e.preventDefault();
  }

  search() {
    let criteria = {};
    criteria.username = this.state.searchCriteria.userName;
    criteria.pMKey = this.state.searchCriteria.pMKey;

    //Convert date time format to match web service
    criteria.startDate = this._reformatDateTime(this.state.searchCriteria.startDate, this.state.searchCriteria.startTime);
    criteria.endDate = this._reformatDateTime(this.state.searchCriteria.endDate, this.state.searchCriteria.endTime);

    criteria.intent = this.state.searchCriteria.intents;
    criteria.keywordSearch = this.state.searchCriteria.keywordSearch.split(" ");

    let req = AuditSvc.find(criteria);

    req.promise.then(response => {
      this.props.callbackAuditSearch(response.auditLogs, response.auditLogs.length + " results returned.", criteria);
    }).catch(e => {
      if (e.reason && e.reason !== "abort") {
        this.props.callbackAuditSearch([], e.reason || "", criteria);
      }
    });
  }

  _handleSearch(e) {
    e.preventDefault();

    this.search();
  }

  _reformatDateTime(dateValue, timeValue) {
    let sd = dateValue || null;
    if (sd) {
      let sdNewFormat = moment(sd, "YYYY-MM-DD").format("YYYYMMDD");
      if (dateValue) {
        let stNewFormat = timeValue !== null && timeValue.length > 0 ? moment(timeValue, "HH:SS").format("HHSS") : "0000";
        sd = sdNewFormat + " " + stNewFormat;
      }
    }
    return sd;
  }

  _handleChange(propertyName, event) {
    let searchCriteria = this.state.searchCriteria;
    searchCriteria[propertyName] = event.target.value;
    this.setState({ searchCriteria: searchCriteria });
  }

  _handlePanelKeyPressed(e) {
    if (e.charCode === 13) {
      e.preventDefault();
      this.search();
    }
  }

  render() {
    return <div onKeyPress={this._handlePanelKeyPressed.bind(this)}>
        <div className="panel-grid">
          <div className="large-align-r form-inline">
            <a href="#" className="btn btn-primary" onClick={this._handlePushUpdatedAudit.bind(this)}>
              Sync Audit(Test)
            </a>
            <a href="#" className="btn btn-tertiary" onClick={this._resetFilter.bind(this)}>
              Clear filters
            </a>
            <a href="#" className="btn btn-primary" onClick={this._handleSearch.bind(this)}>
              Search
            </a>
          </div>
        </div>
        <div className="panel-grid">
          <div className="form-horizontal">
            {_.map(createControlsList, createControls => {
            return <div className="row">
                  {_.map(createControls, createControl => {
                return <div className="small-16 large-8 columns">
                        <div className="form-group">
                          <div className="small-16 large-4 columns">
                            <label className="control-label">
                              {createControl[0]}
                            </label>
                          </div>
                          <div className="small-16 large-12 columns">
                            <input type={createControl[2]} value={this.state.searchCriteria[createControl[1]]} onChange={this._handleChange.bind(this, createControl[1])} className="form-control" />
                          </div>
                        </div>
                      </div>;
              })}
                </div>;
          })}
          </div>
        </div>
      </div>;
  }
}

AuditLogViewerSearch.defaultProps = {
  callbackAuditSearch: () => {}
};

AuditLogViewerSearch.propTypes = {
  callbackAuditSearch: PropTypes.func
};

export default AuditLogViewerSearch;
import React 	from 'react';
import StreamPlayer from './StreamPlayer'

class ViewMediaItemVideo extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
            videoError: null
		};

        this.isMSIE = false;
        if (navigator.appName == 'Microsoft Internet Explorer') {
          this.isMSIE = true;
        } else if (navigator.appName == 'Netscape' && -1 != navigator.userAgent.indexOf('Trident/')) {
          this.isMSIE = true;
        }
    }

    isMSIE: boolean

    isPlaying() {
        let videoElement = document.getElementsByTagName('video')[0]
        return videoElement?.paused == false ? true : false
    }

    _renderVideoError() {
        if (!this.state.videoError) return null

        return (
            <div style={{padding: "40px", position: "absolute", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.7)", display: "flex", justifyContent: "center", alignItems:"center"}} aria-hidden="true">
                <p style={{color: "#fff"}}>There was a problem playing your video.</p>
                <p style={{color: "#fff"}}>{ this.state.videoError }</p>
            </div>
        )
    }

    _onVideoError = (err) => {
        this.setState({
            videoError: err
        })
    }
	
	_imageUrl(photo) {
        if (photo.image && photo.image.url) {
            return photo.image.url
        } else if (photo.fileType == 'VIDEO') {
			return '/Services/GetImage.ashx?id=' + photo.thumbnailFileId
        }
		return photo.url
    }
    
    render() {
        const item = this.props.file
        if (item.fileType != 'VIDEO') return null

        // If the backend has sent a CMFV file, display it in a video tag.
        if (true == item.videoStreamUrl?.endsWith(".cmfv")){
            return  (
                <div style={{width: 720, height: 480}}>
                    <video width="720" height="480" controls poster={this._imageUrl(item)} autoPlay={this.props.autoplay}>
                        <source src={item.videoStreamUrl} type="video/mp4" />
                    </video> 
                </div>
            )
        }

        // On MSIE, display it in a video tag.
        if (this.isMSIE){
            return  (
                <div style={{width: 720, height: 480}}>
                    <video width="720" height="480" controls poster={this._imageUrl(item)} autoPlay={this.props.autoplay}>
                        <source src={`/Services/GetImage.ashx?id=${item.id}`} type="video/mp4" />
                    </video> 
                </div>
            )
        } 

        if (!item.videoStreamUrl) {
            var checkAgain = !this.props.loadingVideo
            return (
                <div style={{width: 720, height: 480}}>
                    <div style={{width: 720, height: 456}}>
                        { checkAgain && <img alt="Video is being prepared. Check again soon." src={this._imageUrl(item)} style={{height: "auto", width:"90%"}} /> }
                        <div style={{padding: "40px", position: "relative", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.7)", display: "flex", justifyContent: "center", alignItems:"center"}} aria-hidden="true">
                            <p style={{color: "#fff"}}>We are preparing your video. Check again soon.</p>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div style={{width: 720, height: 480}}>
                <StreamPlayer
                    url={item.videoStreamUrl}
                    poster={this._imageUrl(item)}
                    key={item.id}
                    id={item.id}
                    autoplay={this.props.autoplay}
                    onError={this._onVideoError}
                    containerId={this.props.containerId}
                    />
                { this._renderVideoError() }
            </div>
        )
    }
}

export default ViewMediaItemVideo


import _ from "lodash";

export default class FilesLinksUtils {

    static renderFileClass(fileType) {
        let fileIcon = null;
    
        switch (fileType.toLowerCase()) {
          case 'word':
            fileIcon = 'icon-file-icon-doc';
            break;
          case 'excel':
            fileIcon = 'icon-file-icon-excel';
            break;
          case 'pdf':
            fileIcon = 'icon-file-icon-pdf';
            break;
          case 'powerpoint':
            fileIcon = 'icon-file-icon-powerpoint';
            break;
          case 'image':
            fileIcon = 'icon-file-icon-image';
            break;
          case 'csv':
            fileIcon = 'icon-file-icon-excel';
            break;
          case 'video':
            fileIcon = 'icon-file-icon-video';
            break;
          case 'audio':
            fileIcon = 'icon-file-icon-audio';
            break;
    
          default:
            fileIcon = 'icon-file-icon-doc';
            break;
    
        }
    
        return `file-icon ${fileIcon}`;
      }
    
      static renderFileType(fileType) {
        let desc;
    
        switch (fileType.toLowerCase()) {
          case 'image':
            desc = "Image";
            break;
          case 'video':
            desc = "Video";
            break;
          case 'word':case 'excel':case 'pdf':case 'powerpoint':case 'csv':case 'audio':default:
            desc = "File";
            break;
    
        }
    
        return desc;
      }


}
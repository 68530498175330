import React 				from 'react';
import PropTypes from 'prop-types'
import DropDown				from '../../../core/components/DropDown';

class FolderSelect extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			edit: { valid: true, title: ''},
			createFolder: false
		};
	}

	_handleEditChange(event)
	{
		const target = event.target;
   		let value = target.type === 'checkbox' ? target.checked : target.value;

		if (value.length > 50) {
			value = value.substring(0, 50);
		}

		let edit = this.state.edit;

		edit.title = value;
		edit.valid = 0 < value.trim().length;

		this.setState({
			edit: edit
		});

		this._onStateChange();
	}

	_onStateChange() {
		this.props.onChange();
	}

	_existingFolderLabel() {
		return "Existing " + this.props.folderLabel.toLowerCase();
	}

	_createNewFolderLabel() {
		return "Create new " + this.props.folderLabel.toLowerCase();
	}
	
	setCreateFolderState(createFolder)
	{
		this.setState({
			createFolder: createFolder
		});

		let edit = this.state.edit;

		edit.valid = (!createFolder || this.state.edit.title.length > 0);

		this.setState({
			edit: edit
		});

		this._onStateChange();
	}

	getFolderId()
	{
		return this.refs.folderId.getValue();
	}

	isValid()
	{
		return this.state.edit.valid;
	}

	isCreateFolder()
	{
		return this.state.createFolder;
	}

	getParentFolderId() {
		return this.state.edit.parentFolderId || "00000000-0000-0000-0000-000000000000";
	}

	getFolderTitle()
	{
		return this.state.edit.title;
	}

	_handleFolderOptionClick(e)
	{
		if (e.target.id == 'newFolderDiv')
		{
			this.setCreateFolderState(true);
		}
		if (e.target.id == 'existingFolderDiv')
		{
			this.setCreateFolderState(false);
		}
	}

	_handleFolderOptionChange(createFolder, e)
	{
		this.setCreateFolderState(createFolder);
	}

    _handleFolderChange(option) {
        this.setState({
        	edit: {
				title: this.state.edit.title,
				valid: this.state.edit.valid,
				parentFolderId: option.value
			}
        });
    }

	render() {
		let existingFolderDiv = "radio" + (!this.state.createFolder ? " active" : "");
		let newFolderDiv = "radio" + (this.state.createFolder ? " active" : "");

		return (
		<span>
			{this.props.showLabel ? <label>Select {this.props.folderLabel}</label> : null}
			<div className="form-horizontal">
				<div className="form-group">
					<div className="small-16 columns">
						<div className={existingFolderDiv} id="existingFolderDiv" onClick={this._handleFolderOptionClick.bind(this)}>
							<label htmlFor="existingFolderOption">
								<input type="radio" id="existingFolderOption"
									checked={!this.state.createFolder} 
									onChange={this._handleFolderOptionChange.bind(this, false)} />
									{ this._existingFolderLabel() }
							</label>
							<div>
								<DropDown folderDropdown={true} options={this.props.folderOptions} ref="folderId" defaultValue={this.props.defaultFolderId} disabled={this.state.createFolder} onChange={this._handleFolderOptionChange.bind(this, false)}></DropDown>
							</div>
						</div>
						<div className={newFolderDiv} id="newFolderDiv" onClick={this._handleFolderOptionClick.bind(this)}>
							<label htmlFor="newFolderOption">
								<input type="radio" id="newFolderOption"
									checked={this.state.createFolder}
									onChange={this._handleFolderOptionChange.bind(this, true)} />
									{ this._createNewFolderLabel() }
							</label>
							<div>
								{ this.props.creationFolders.length > 0 && [
								<span key="label" htmlFor="folder-parent">Parent {this.props.folderLabel.toLowerCase()}</span>,
								<div key="dropdown">
									<DropDown folderDropdown={true} options={this.props.creationFolders} ref="parentFolderId" defaultValue={this.state.parentFolderId} disabled={!this.state.createFolder} onChange={this._handleFolderChange.bind(this)}></DropDown>
								</div>]}
								{ this.state.createFolder ?
									( <input key="input" type="text" name="foldertitle" id="folder-title" className="form-control" placeholder={"Enter the name of your " + this.props.folderLabel.toLowerCase()} onChange={this._handleEditChange.bind(this)} spellCheck="true" /> )
									: 
									( <input key="input" type="text" name="foldertitle" id="folder-title" className="form-control" placeholder={"Enter the name of your " + this.props.folderLabel.toLowerCase()} onChange={this._handleEditChange.bind(this)} disabled spellCheck="true"/> )
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</span>
		);
	}
}

FolderSelect.defaultProps = {
	folderLabel: 'Folder',
	showLabel: true
}

FolderSelect.propTypes = {
	folderLabel:		PropTypes.string,
	defaultFolderId:	PropTypes.string,
	onChange:		PropTypes.func.isRequired,
	showLabel:		PropTypes.bool
	//onDeleteClick: PropTypes.function.isRequired,
    //onSelectClick: PropTypes.function.isRequired,
};

export default FolderSelect;

import {
    useRef,
    useState,
} from 'react'
import Modal from '../../core/components/modal/Modal';
import BtnClose from '../../core/components/BtnClose';
import NotificationSvc from '../../services/NotificationService'

const NotificationModule  = (_) => {

    const [active, setActive] = useState(false)

    const showModal = (e) => {
        e?.preventDefault()
        setActive(true)
    }
    const closeModal = () => {
        setActive(false)
    }
    const deleteAll = () => {
        NotificationSvc.deleteAll().promise.then(_ => {
            window.location.reload()
        })
        closeModal()
    }

    return (
        <div>
            <button role="button" className="btn btn-secondary confirm" onClick={showModal}>Delete all</button>        
            <Modal active={active} onRequestsClose={closeModal}>
                <div className='modal-header'>
                    <BtnClose onClick={closeModal} />
                    <h2 className='modal-title'>Delete all notifications?</h2>
                </div>
				<div className='modal-content'>Delete all notifications?</div>
                <div className='modal-actions'>
                    <button role="button" className="btn btn-secondary confirm" onClick={deleteAll}>OK</button>
                    <button role="button" className="btn btn-tertiary cancel" onClick={closeModal}>Cancel</button>
                </div>
            </Modal>
        </div>
    )
}

export default NotificationModule

import React 				from 'react';
import FileUtils 			from '../../../core/utils/FileUtils';
import Modal        		from '../../../core/components/modal/Modal';
import BtnClose     		from '../../../core/components/BtnClose';
import DateTimeUtils 		from '../../../core/utils/DateTimeUtils';
import PromptActions		from '../../prompt/actions/PromptActions';
import FolderSvc            from '../../../services/FolderSvc';

const maxTry = 5;
class MediaAnalytics extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
            active: false,            
            file: props.file,
            isError: false,
            tryCount: 0
		};
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    clearTimer(){        
        if (this.timer) {
			clearInterval(this.timer);
            this.timer = null;
            this.setState({
                tryCount: 0
            })
		}
    }

    refreshInfo(e){
        //Refresh media info if modal opened and user has permission
        if (this.state.active && !this.state.isError && this.state.tryCount <= maxTry){
            FolderSvc
				.getMediaInfo(this.props.file.id, this.props.relatedItemType, this.props.relatedItemId, this.props.folderId, this.props.category)
                .promise
				.then((response) => {
                    if (response)
					{
                        this.setState({
						    file: response,
                            isError: false
					    });
                    }else{
                        let tryCount = this.state.tryCount + 1
                        this.setState({
                            tryCount: tryCount
                        })
                    }
				})
				.catch(err => {
					PromptActions.displayFriendlyErrorPrompt(err);
					this.setState({
						isError: true
					});                    
				});
        }
    }

    _handleModalCloseRequest(event) {
        event.preventDefault();

        if (this.state.active) {
			this.setState({ active: false });
		}
        //this.props.onCloseClick()

        this.clearTimer();
    }

    _handleModalOpenRequest(event) {
        event.preventDefault();

        if (!this.state.active) {
			this.setState({ active: true });
		}

        if (!this.timer)
        {
            this.timer = setInterval( this.refreshInfo.bind(this), 3000);
        }
    }

    _renderSeconds(t) {
		if (t == null) return ''
		if (isNaN(t) || t == Infinity) return '-:--';
		t = Math.floor(t)
		t = Math.max(0, t)
		var h = Math.floor(t / 3600);
		var m = Math.floor(t / 60) % 60;
		var s = (t % 60);
		return (h ? h + ':' : '') + (h ? ('0' + m).slice(-2) : m) + ':' + ('0' + s).slice(-2);
	}

    _viewsTextForItem(item) : string[] {
        switch (item?.fileType?.toUpperCase()) {
            case 'AUDIO':
                return ['listens', 'Listens']
            case 'VIDEO':
                return ['plays', 'Plays']
            default:
                return ['views', 'Views']
        }
    }

    _modalView() {
        const [_views, _Views] = this._viewsTextForItem(this.state.file)
        const duration = this._renderSeconds(this.state.file.duration);
        return(
            <Modal onRequestsClose={this._handleModalCloseRequest.bind(this)} active={this.state.active} size='default'>
                <div className='modal-header'>
                    <BtnClose onClick={this._handleModalCloseRequest.bind(this)} />
                    <h2 className='modal-title'>Media Details</h2>
                </div>
                <div className='modal-content'>
                    <div className="row">
                        <div className="small-16 large-8 columns">
                            { this.state.file.fileType == 'Audio' ?
                                <div className="icon-container">
                                    <span style={{display: "block", height: "200px"}} className="file-icon icon-file-icon-audio" aria-hidden="true" />
                                </div>
                                :
                                <p>
                                    <img alt={this.state.file.title} src={this.state.file?.image?.urlWithMaxWidth(250)} />
                                </p>
                            }
                            { this.state.file.duration &&
                                <span className="analytics-duration">{duration}</span>
                            }
                        </div>
                        <div className="small-16 large-8 columns">
                            <div className="stats-row">
                                <h4>Title</h4>
                                <p>{this.state.file.title}</p>
                            </div>
                            <div className="stats-row">
                                <h4>Added on</h4>
                                <p>{DateTimeUtils.stringFromDate(this.state.file.dateUploaded, "D MMM YYYY HH:mm")}</p>
                            </div>
                            <div className="stats-row">
                                <h4>Added by</h4>
                                <a href={this.state.file.uploader.url}>{this.state.file.uploader.name}</a>
                            </div>
                            <div className="stats-row split">
                                <div className="split">
                                    <h4>{_Views} in last 30 days</h4>
                                    <p>{this.state.file.thirtyDaysViews}</p>
                                </div>
                                <div className="split">
                                    <h4>All {_views}</h4>
                                    <p>{this.state.file.allViews}</p>
                                </div>
                            </div>
                        </div>
                        <div className="small-16 large-16 columns">
                            <p dangerouslySetInnerHTML={{__html:this.state.file.description}}></p>
                        </div>
                    </div>
                </div>
                <div className="modal-actions">
                    <button role="button" className="btn btn-default" onClick={this._handleModalCloseRequest.bind(this)}>Close</button>
                </div>
            </Modal>
        );
    }


    render() {
		return (
            <div>
                <a href="#" onClick={this._handleModalOpenRequest.bind(this)}><span className="fn-icon fn-icon-primary fn-analytics-info theme-background"></span><span className="sr-only">Info</span></a>
                <span> {this.state.file.allViews} {this._viewsTextForItem(this.state.file)[0]}</span>
                {this.state.active ? this._modalView() : null} 
            </div>
		);
	}
}

export default MediaAnalytics;

import React, {
    FC,
    useEffect,
    useState,
} from "react";
import SvgIcon              from '../../../icons';
type CallbackFunctionRemove = (id: string) => void
interface IGroupSummaryProps {
    group: object,
    id: string,
    removegroup: CallbackFunctionRemove
}

export const GroupSummary : FC<IGroupSummaryProps> = (props: IGroupSummaryProps) => {
    const onClickRemove = (e) => {
        e.preventDefault();
        props?.removegroup(props.id);
        blur();
    }
    const blur = () => {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }
    return (
        <li key={ props.id } className="not-selected">
            <div>
                <div><strong>{ props.group.name }</strong></div>
                <div><strong>Primary admin: </strong>{props.group.primaryAdmin.fullName  }</div>
                <div className="description">{props.group.rawDescription }</div>
                <div><strong>{props.group.numberOfMembers  } members</strong></div>
            </div>
            <a href="#" onClick={onClickRemove } className="close"><SvgIcon icon="icon-cross" width={20} height={20} /></a>
        </li>
    )
}
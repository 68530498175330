
import Promise from "bluebird";
import Action from "../../../core/models/Action";
import AppDispatcher from "../../../core/dispatcher/AppDispatcher";
import GroupSvc from "../../../services/GroupSvc";
import GroupReportConstants from "../constants/GroupReportConstants";


class GroupReportActions {

  /**
   * Load an activity feed
   * @param {string} 	state	- The server state token if any
   */
  static loadReport(groupid: String, startDate: String, endDate: String) {


    return new Promise((resolve, reject) => {
      GroupSvc.loadReport(groupid, startDate, endDate).promise.then(response => {

        // Broadcast the feed loaded event
        AppDispatcher.dispatch(new Action(GroupReportConstants.GROUP_REPORT_LOADED, response));

        resolve();

      }).catch(reject);

    });

  }
}

export default GroupReportActions;
import React 						from 'react';
import _ 							from 'lodash';

import AppDelegate					from '../../../core/AppDelegate';


import FileUpload 					from '../../../core/components/FileUpload';
import FormProgressBar 				from '../../../core/components/form/FormProgressBar';
import Alert						from '../../../core/components/Alert';
import Modal						from '../../../core/components/modal/Modal';
import BtnClose						from '../../../core/components/BtnClose';


import FamiliesTrialImporterActions 		from './actions/FamiliesTrialImporterActions';
import FamiliesTrialImporterStore			from './stores/FamiliesTrialImporterStore';
import FamiliesTrialImporterStatuses  		from './models/FamiliesTrialImporterStatuses';



/**
 * FamiliesTrialImporterModule
 * @public
 * @module
 */
export default class FamiliesTrialImporterModule extends React.Component {

	/**
	 * Constructor
	 * @param {Object} props The component props
	 */
	constructor(props)
	{
		super(props);

		this.state = {
			uploadAttempts: 0,
			status: FamiliesTrialImporterStatuses.Ready,
			currentTask: FamiliesTrialImporterStore.currentTask,
			uploadValueNow: 0,
			step: 0,
			uploadRequest: null,
			modalActive: false
		};
	}

	/**
	 * The component did mount event
	 */
	componentDidMount() {

		// Listen for store changes
		FamiliesTrialImporterStore.addChangeListener(this._onStoreChange.bind(this));

		// Bind to the window unload event to show any warnings
		window.onbeforeunload = () => {
			if(	this.state.modalActive &&
				this.state.status !== FamiliesTrialImporterStatuses.Completed &&
				this.state.status !== FamiliesTrialImporterStatuses.Ready
			) {
				return 'There is still an import in progress.';
			}
		};
	}

	/**
	 * The component will mount event
	 */
	componentWillUnmount() {
		FamiliesTrialImporter.removeChangeListener(this._onStoreChange.bind(this));
	}


	/**
	 * Builds a component state object from the store state
	 * @returns {{status: (number|*), currentTask: (Object|null)}}
	 * @private
	 */
	_getStateFromStore() {
		return {
			status: FamiliesTrialImporterStore.status,
			currentTask: FamiliesTrialImporterStore.currentTask
		};
	}

	/**
	 * Store change handler
	 * @private
	 */
	_onStoreChange() {
		this.setState(this._getStateFromStore());
	}

	/**
	 * Upload complete handler
	 * @param {object} data The upload result data
	 * @private
	 */
	_onUploadComplete (data) {
		FamiliesTrialImporterActions.startImport(data);
	}

	/**
	 * File Queued handler
	 * @param {object} file - The file queued
	 * @param {object} xhr - The xhr object
	 * @private
	 */
	_onFileQueued (file, xhr) {
		if(xhr){
			this.setState({
				uploadRequest: xhr
			});
		}
	}

	/**
	 * Task action handler
	 * @param {string} actionValue - The action value
	 * @private
	 */
	_handleAction (actionValue) {
		FamiliesTrialImporterActions.sendTaskAction(FamiliesTrialImporterStore.currentState, actionValue);
	}

	/**
	 * Upload failed handler
	 * @private
	 */
	_onUploadFailed () {
		this.setState({
			uploadAttempts: this.state.uploadAttempts + 1
		});
	}

	/**
	 * Show the import modal window
	 * @param {object} [e=null] - The event object or null
	 */
	showModal(e) {
		this.setState({modalActive: true});
		if(e){
			e.preventDefault();
		}
	}

	/**
	 * Close the import modal window
	 */
	closeModal() {
		FamiliesTrialImporterActions.stopImport(FamiliesTrialImporterStore.currentState)

		this.state.status = FamiliesTrialImporterStatuses.Ready;
		this.setState({modalActive: false});
	}

	/**
	 * Handle close requests from the modal instance
	 * @param {object} e - The event
	 * @private
	 */
	_handleModalCloseRequest (e) {
		e.preventDefault();
		this.closeModal();
	}

	/**
	 * The render method
	 */
	render() {


		var summary = _.map(FamiliesTrialImporterStore.currentSummary, (s, i) => {
			var className = '';
			switch (s.Type) {
				case 1:
					className = 'text-warning';
					break;
				case 2:
					className = 'text-danger';
					break;
			}
			return (
				<li className={className} key={i}>{s.Label}</li>
			);
		});

		var view;
		var actions = <button type="button" className='btn btn-default' onClick={this.closeModal.bind(this)}>Cancel</button>;
		switch (this.state.status) {
			// Complete
			case FamiliesTrialImporterStatuses.Completed:
				actions = <button type="button" className='btn btn-primary' onClick={this.closeModal.bind(this)}>Finish</button>;
				view = (
					<div>
						<p>{this.state.currentTask.task.status}</p>
						<ul>{summary}</ul>
					</div>
				);
				break;

			// Browse (or Uploading)
			case FamiliesTrialImporterStatuses.Ready:
			default:
				view = (
					<FileUpload
						key={this.state.uploadAttempts}
						uploadUrl={AppDelegate.appSettings.fileUploadUrl}
						formData={[{ name: 'Type', value: 'FamiliesTrial'}]}
						onFileQueued={this._onFileQueued.bind(this)}
						onUploadComplete={this._onUploadComplete.bind(this)}
						onUploadFailed={this._onUploadFailed.bind(this)}
						/>
				);
				break;

		}

		return (
			<div>
				<a role="button" className='btn btn-primary' onClick={this.showModal.bind(this)}>Import Trial PMKeyS</a>
				<Modal active={this.state.modalActive} onRequestsClose={this._handleModalCloseRequest.bind(this)}>
					<div className='modal-header'>
						<BtnClose onClick={this.closeModal.bind(this)} />
						<h2 className='modal-title'>Import Trial PMKeyS</h2>
					</div>
					<div className='modal-content'>
						{view}
					</div>
					<div className="modal-actions">{actions}</div>
				</Modal>
			</div>
		);

	}


}

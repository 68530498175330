import * as React from "react";

function SvgIconArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 63 32"
      {...props}
    >
      <path
        transform="rotate(270 10 10)"
        fill="currentColor"
        d="M30.233 0L0 30.233l1.732 1.732L30.3 3.397l30.984 28.601 1.66-1.799z"
        stroke="currentColor"
        strokeWidth={4}
      />
    </svg>
  );
}

export default SvgIconArrowRight;
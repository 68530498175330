import React from "react"
import FormUserSelector from "../../../core/components/form/FormUserSelector"
import BtnClose from "../../../core/components/BtnClose"
import Modal from "../../../core/components/modal/Modal"
import SmsPriceAlert from '../../../core/components/SmsPriceAlert'
import RichTextEditor from "../../../core/components/richtext/RichTextEditor"
import { UserFactory } from '../../../core/models/User';
import { MessagePostAction } from "../models/MessageActions";
import { toInteger } from "lodash"
import HtmlUtils from '../../../core/utils/HtmlUtils';
import PromptActions from "../../prompt/actions/PromptActions";
import Localisation from "../../../core/resources/Localisation";

class NewMessage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            body: this.props.conversation ? this.props.conversation.Body : this.props.body,
            subject: this.props.conversation ? this.props.conversation.Subject : "",
            recipientList: !this.props.isCreateSeparateConversations && this.props.initialUsers || [],
            isImportant: this.props.conversation ? this.props.conversation.IsImportant : false,
            isReplyable: true,
            sendSms: false,
            submitted: false,
            isCreateSeparateConversations: this.props.isCreateSeparateConversations,
            canSend: this.props.conversation && this.props.conversation.Body ? true : false,
            isDraft: this.props.conversation ? this.props.conversation.IsDraft : false,
            title: this.props.title,
            conversation: this.props.conversation,
            initialBccUsers: this.props.conversation ? this.props.conversation.BccParticipants.map((u) => {
                return UserFactory.userFromObject(u);
            }) : [],
            bccRecipientList: this.props.conversation ? this.props.conversation.BccParticipants.map((u) => {
                return UserFactory.userFromObject(u);
            }) : [],
            lastBody: this.props.conversation ? this.props.conversation.Body : this.props.body,
            lastSubject: this.props.conversation ? this.props.conversation.Subject : "",
            lastRecipientList: !this.props.isCreateSeparateConversations && this.props.initialUsers || [],
            lastBccRecipientList: this.props.conversation ? this.props.conversation.BccParticipants.map((u) => {
                return UserFactory.userFromObject(u);
            }) : [],
        }
        this.onSave = this.onSave.bind(this);
        this.onSend = this.onSend.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    smsPriceAlertRef = React.createRef<SmsPriceAlert>()

    onSend = (e): void => {
        e.preventDefault();
        //this.setState({ isDraft: false })    
        this._handleSubmit(false);
    }

    onSave = (e): void => {
        e.preventDefault();  
        //this.setState({ isDraft: true })      
        this._handleSubmit(true)
    }

    onSubmit = (): void => {
        this._onKeyDown.bind(this);
    }

    onDelete = (e): void => {
        e.preventDefault(); 
        if (this.props.conversation && this.props.conversation.IsDraft){
            var line1 =  `Are you sure you want to delete this draft message?`;
            var line2 =  ` This will permanently delete the draft message.`;
            PromptActions.displayPromptWithActions(Localisation.localisedStringFor("MessageDraftDelete"), (<div><p>{line1}</p><p>{line2}</p></div>) , [{
                label: Localisation.localisedStringFor('Cancel'),
                className: 'btn btn-default'
            }, {
                label: Localisation.localisedStringFor('Confirm'),
                handler: () => this.props?._toggle(MessagePostAction.DELETE) ,
                className: 'btn btn-primary'
            }]);  
        } else {
            this.props?._toggle(MessagePostAction.DELETE);
        }                    
    }

    _onKeyDown(e) {
        if (e.keyCode == 13) {
            e.preventDefault();
            this.onSend(e);
            return false;
        }
    }
    
    _setRecipients = (recipientList) => {
        this.setState({ recipientList });
    }
    
    setBccRecipients = (bccRecipientList) => {
        this.setState({ bccRecipientList });
    }

    onEditorChange = (value: string, empty: boolean) => {
        this.setState({
            body: value,
            canSend: !empty,
        });
    }

    _setSubject(e) {
        this.setState({ subject: e.target.value });
    }

    _setIsImportant(e) {
        this.setState({ isImportant: e.target.checked })
    }

    _setIsReplyable(e) {
        this.setState({ isReplyable: e.target.checked })
    }

    _setSendSms = () => {
        this.setState({ sendSms: true })
    }

    _setSendSmsConfirm = (e) => {
        if (e.target.checked) {
            this.smsPriceAlertRef.current?.show()
        } else {
            this.setState({ sendSms: false })
        }
    }

    get hasRecipients(): boolean {
        return this.state.recipientList.length > 0 || this.state.bccRecipientList.length > 0;
    }

    get isSendable(): boolean {
        return this.state.canSend && this.state.subject != "" && this.state.body && this.hasRecipients;
    }

    get isDraftable(): boolean {
        let recipientList = Array.from(new Set(this.state.recipientList.flatMap(e => e.id, [])));
        let lastRecipientList = Array.from(new Set(this.state.lastRecipientList.flatMap(e => e.id, []))); 
        let bccRecipientList = Array.from(new Set(this.state.bccRecipientList.flatMap(e => e.id, [])));
        let lastBccRecipientList = Array.from(new Set(this.state.lastBccRecipientList.flatMap(e => e.id, [])));
        let lastBody = HtmlUtils.removeRichEditTextParagraphTags(this.state.lastBody);
        let body = HtmlUtils.removeRichEditTextParagraphTags(this.state.body);
       
        return this.state.isDraft 
            ? (this.state.lastSubject != this.state.subject) || (lastBody != body) || (recipientList.toString() != lastRecipientList.toString()) || (bccRecipientList.toString() != lastBccRecipientList.toString()) && this.isSendable
            : this.isSendable ;
    }    

    _handleSubmit(isDraft): void {
        if (this.isSendable) {
            if ((this.props.maxSeparateConversations === 0 || this.state.bccRecipientList.length <= this.props.maxSeparateConversations)) {
                const draftConversationId = this.props.conversation ? this.props.conversation.Id : null;
                if (isDraft && draftConversationId){
                    var line1 =  `Are you sure you want to save this draft message?`;
                    var line2 =  ` This will overwrite the existing draft message.`;
                    PromptActions.displayPromptWithActions(Localisation.localisedStringFor("MessageDraftSave"), (<div><p>{line1}</p><p>{line2}</p></div>) , [{
                        label: Localisation.localisedStringFor('Cancel'),
                        className: 'btn btn-default'
                    }, {
                        label: Localisation.localisedStringFor('Confirm'),
                        handler: () => {
                            this.props._new(
                                true,
                                this.state.body,
                                this.state.subject,
                                this.state.recipientList,
                                this.state.bccRecipientList,
                                this.state.isImportant,
                                this.state.isReplyable,
                                this.state.sendSms,
                                isDraft,
                                draftConversationId
                            )
                        },
                        className: 'btn btn-primary'
                    }]);                     
                } else {
                    this.props._new(
                        true,
                        this.state.body,
                        this.state.subject,
                        this.state.recipientList,
                        this.state.bccRecipientList,
                        this.state.isImportant,
                        this.state.isReplyable,
                        this.state.sendSms,
                        isDraft,
                        draftConversationId
                    );
                }
            }
            this.setState({submitted:true})
        }
    }
    renderCore() {
        return (          
                    <div className="form-horizontal">
                        { this.props.isCreateSeparateConversations && this.props.maxSeparateConversations !== 0 && this.props.separateConversationCount > this.props.maxSeparateConversations && 
                            <div className="row">
                                <div className="warning">
                                    <span className="fn-icon fn-icon-warning fn-icon-primary" aria-hidden="true"></span> Sending individual messages has been limited to { this.props.maxSeparateConversations } recipients. The first { this.props.maxSeparateConversations } recipients have been selected.
                                </div>
                            </div>
                        }
                        { this.state.submitted && ((this.state.recipientList.length == 0 && this.state.bccRecipientList.length == 0) || !this.state.body || !this.state.subject || (this.state.bccRecipientList.length > this.props.maxSeparateConversations && this.props.maxSeparateConversations !== 0)) ?
                            <div className="row">
                                <div className="alert alert-danger errorMessage js-hidden" style={{display:"block"}}>
                                    { this.state.recipientList.length == 0 && this.state.bccRecipientList.length == 0 ?
                                        <span>Please select one or more recipients.<br/></span>
                                    : null }
                                    { this.state.bccRecipientList.length > this.props.maxSeparateConversations && this.props.maxSeparateConversations !== 0
                                        ? <span>Please select { this.props.maxSeparateConversations } bcc recipients or less .<br/></span>
                                        : null
                                    }
                                    { !this.state.subject ?
                                        <span>Please enter a subject.<br/></span>
                                    : null }
                                    { !this.state.body ?
                                        <span>Please enter a value for message.<br/></span>
                                    : null }
                                </div>
                            </div>
                        : null }
                        <div className="row">
                            <div className="small-16 columns">
                                <p className="req-info">
                                    <abbr className="req" title="required">
                                        *
                                    </abbr>
                                    Required Fields
                                </p>
                            </div>
                        </div>

                        <div className="alert alert-danger errorMessage js-hidden">
                            <span />
                        </div>

                        <div className="form-group">
                            <label
                                className="small-16 large-3 columns control-label"
                                htmlFor="message_to">
                                To
                                <abbr className="req" title="required">
                                    *
                                </abbr>
                            </label>
                            <div className="fn-react-root small-16 large-13 columns end">
                            <FormUserSelector
                                id={"message_to"}
                                className={""}
                                includes={{
                                    level:true, 
                                    primaryGroup:true,
                                    rankOrService:true
                                }}
                                placeholder={"Start typing the name of a member to add them"}
                                onChange={this._setRecipients}
                                initialUsers={!this.props.isCreateSeparateConversations ? this.props.initialUsers : []}
                                onSubmit={this.onSubmit}
                            />
                            </div>
                        </div>
                        { this.props.canCreateSeparateConversations && <div className="form-group">
                            <label
                                className="small-16 large-3 columns control-label"
                                htmlFor="message_to">
                                Bcc
                            </label>
                            <div className="fn-react-root small-16 large-13 columns end">
                                <FormUserSelector
                                    id={"message_bcc"}
                                    className={""}
                                    includes={{
                                        level: true, 
                                        primaryGroup: true,
                                        rankOrService: true
                                    }}
                                    placeholder={"Start typing the name of a member to add them"}
                                    onChange={this.setBccRecipients}
                                    initialUsers={this.props.isCreateSeparateConversations ? this.props.initialUsers : this.props.conversation ? this.state.initialBccUsers : []}
                                    onSubmit={this.onSubmit}
                                />
                            </div>
                        </div>
                        }
                        <div className="form-group">
                            <label
                                className="small-16 large-3 columns control-label"
                                htmlFor="message_subject">
                                Subject
                                <abbr className="req" title="required">
                                    *
                                </abbr>
                            </label>

                            <div className="small-16 large-13 end columns end">
                                <input dir="auto"
                                    name="phbody_0$phcontent_0$message_subject"
                                    type="text"
                                    maxLength="100"
                                    id="message_subject"
                                    className="form-control input_createMessageSubject"
                                    onChange={this._setSubject.bind(this)}
                                    onKeyDown={this.onSubmit}
                                    spellCheck="true"
                                    value={this.state.subject}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label
                                className="small-16 large-3 columns control-label"
                                htmlFor="message_body">
                                Message
                                <abbr className="req" title="required">
                                    *
                                </abbr>
                            </label>
                            <div className="small-16 large-13 end columns">
                                <RichTextEditor
                                    maxLength={2000}
                                    value={this.state.body}
                                    onValueChange={this.onEditorChange}
                                    spellCheck="true"
                                    userPreference={this.props.userPreference}
                                    mode="Message"
                                    />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="small-16 large-13 large-offset-3 columns end">
                                <div className="checkbox">
                                    <label>
                                        <input
                                            name="phbody_0$phcontent_0$input_flag"
                                            type="checkbox"
                                            id="input_flag"
                                            className="input_isImportantAndReadReceipts"
                                            onChange={this._setIsImportant.bind(this)}
                                            onKeyDown={this.onSubmit}
                                            spellCheck="true"
                                            defaultChecked={this.state.isImportant}
                                        />
                                        Flag message as
                                        <strong>{" "}important</strong> and show read receipt
                                    </label>
                                </div>
                                { this.props.canSendSms && 
                                <div className="checkbox">
                                    <label>
                                        <input
                                            name="phbody_0$phcontent_0$input_SMS"
                                            type="checkbox"
                                            id="input_SMS"
                                            checked={this.state.sendSms}
                                            onChange={this._setSendSmsConfirm}
                                            onKeyDown={this.onSubmit}
                                            spellCheck="true"
                                        />
                                        Notify recipients by SMS 
                                    </label>
                                </div>
                                }
                            </div>
                        </div>
                        <br/>
                        <div className="form-group">
                            <div className="small-16 large-13 large-offset-3 columns end">
                                <input
                                    type="submit"
                                    name="phbody_0$phcontent_0$SendMessageNew"
                                    value="Send"
                                    disabled={!this.isSendable}
                                    onClick={this.onSend}
                                    id="phbody_0_phcontent_0_SendMessageNew"
                                    className="btn btn-secondary buttonMed"
                                    data-confirm-header=""
                                    rel="Confirm SMS"
                                    data-confirm-message=""
                                    data-confirm-sub-message=""
                                    onKeyDown={this.onSubmit}
                                    spellCheck="true"
                                /> <a
                                    className="btn btn-default"
                                    role="button"
                                    disabled={!this.isDraftable}
                                    onClick={this.onSave}>
                                    Save draft
                                </a>
                                <a
                                    className="btn btn-default btn-cancel"
                                    role="button"
                                    onClick={this.onDelete}>
                                    {this.props.conversation && this.props.conversation.IsDraft ? "Delete" : "Cancel"}
                                </a>
                            </div>
                        </div>
                    </div>          
        )
    }
    renderNewMessage() {
        let coreDiv = this.renderCore();
        return (           
            <div className="create" id="createMessage" style={{ display: "block" }}>
                <div className="header">
                    <h2>{this.props.conversation && this.props.conversation.IsDraft ? "Draft" : "New Message"}</h2>
                </div>
                <div className="panel-body">
                    {coreDiv}
                </div>
                <SmsPriceAlert onConfirm={this._setSendSms} ref={this.smsPriceAlertRef} />
            </div>           
            )
    }
    renderNewMessageModal() {
        let coreDiv = this.renderCore();
        return (
            <div className="create panel-body" id="createMessage" style={{ display: "block" }}  >        
                {coreDiv}
                <SmsPriceAlert onConfirm={this._setSendSms} ref={this.smsPriceAlertRef} />
            </div>
        )
    }
    render() {
        let body = this.props.showModal ? this.renderNewMessageModal() : this.renderNewMessage();
        if (this.props.showModal) {
            return (             
                <Modal active={true} onRequestsClose={this.props.onRequestsClose} forceMaxHeight={false} >
                    <div className='modal-content' style={{ display: "flex", flexDirection: "column", paddingTop: "0px", overflowY: "auto", paddingBottom: "5px" }} >
                        <div className='modal-header'>
                            <h2 className='modal-title'>New message</h2>
                        </div>
                        {body}
                    </div>
                </Modal>
            )
        }
        return (
            body
        )
    }
}
NewMessage.defaultProps = {
    canSendSms: false
}

export default NewMessage

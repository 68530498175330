import React 				from 'react';
import _		from 'lodash';
import { MultiGroupHierarchySelector } from './MultiGroupHierarchySelector';
import { JsxElement } from 'typescript';
import IGroup  from "./IGroup";
import Pluralize         from "../../vendor/typescript-pluralize/index";

import FormGroupSelector from '../../core/components/form/FormGroupSelector';
import {GroupFactory} 	from '../../core/models/Group';

const makePlural = (word: string, count: number) => count != 1 ? Pluralize.plural(word) : word;

interface Props {
	className: string;
	allowMultiple: boolean;
    groups: Array<IGroup>;
    fnNode: HTMLInputElement;
    postLabel: string;
	includes: string;
    filterCategories: string;
    groupTypes: string;
    services: string;
    onChange: string;
	hasMemberOnly: string;
	searchWordsOnNameOnly: boolean;
	commanderOnly: boolean;
    showHierarchy: boolean;
    showGroups: boolean;
}

interface State {
    active: boolean;
    selectedHierarchyGroups: Array<string>;
    groups: Array<string>;
    allGroupIds: Array<string>;
	includes: any;
    filterCategories: Array<string>;
    groupTypes: Array<string>;
    services: Array<string>;
    initialGroups: Array<string>;
}

function getAllGroupIds(groups: Array<IGroup>) : Array<string> {
    let groupIds: Array<string> = [];

    if (groups) {
        groups.forEach(g => {
            if (g.IsActive) {
                groupIds.push(g.Id);
            }

            const subGroupIds = getAllGroupIds(g.SubGroups);

            if (subGroupIds) {
                groupIds = [...groupIds, ...subGroupIds];
            }
        });
    }

    return groupIds;
}

class MultiGroupHierarchySelectorModule extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        let groups = [];
        let selectedHierarchyGroups: Array<string> = [];
        const allGroupIds: Array<string> = getAllGroupIds(props.groups);

        if (this.props.fnNode) {
            const nativeElement = this.props.fnNode;

            if (nativeElement.value) {
                const filter = JSON.parse(nativeElement.value);

                selectedHierarchyGroups = filter.hierarchyGroups ?? [];
                groups = filter.groups ?? [];
            }
        }

        const includes : any = {};
        props.includes?.split(',').forEach((n)=> {
            if(n !== '') {
                includes[n] = true;
            }
        });

        const filterCategories = [];
        props.filterCategories?.split(',')?.forEach((n)=> {
            if (n !== '') {
                filterCategories.push(n);
            }
        });

        const groupTypes = [];
        props.groupTypes?.split(',').forEach((n)=> {
            if (n !== '') {
                groupTypes.push(parseInt(n));
            }
        });

        const services = [];
        props.services?.split(',').forEach((n)=> {
            if(n !== '') {
                services.push(n);
            }
        });

        this.state = {
            active: false,
            selectedHierarchyGroups,
            groups,
            allGroupIds,
            includes: includes,
            filterCategories: filterCategories,
            groupTypes: groupTypes,
            services: services,
            initialGroups: props.pre?.map((g) => {
                return GroupFactory.GroupFromObject(g);
            })
        };
    }

    reset() {
        this.setState({
        });

        this._setValue(null);
    }

    _setValue(value) {
        if (this.props.fnNode) {
            const nativeElement = this.props.fnNode;

            nativeElement.value = value;
        }
    }

    _show = (e) => {
        e?.preventDefault();

        if (this.canChangeHierarchy) {
            this.setState({
                active: true
            });
        }
    }

    _onClose = () => {
        this.setState({
            active: false
        });
    }
    
    emit() {
        this._setValue(JSON.stringify({
            hierarchyGroups: this.state.selectedHierarchyGroups,
            groups: this.state.groups,
        }));
    }

    _onSelect = (selectedHierarchyGroups) => {
        this.setState({
            selectedHierarchyGroups,
        }, this.emit);
    }

    _handleSelectorChange = (groups) => {
        this.setState({
            groups: groups?.map(i => i.id)
        }, this.emit);
    }

    get label() : React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
        if (this.props.showGroups) {
            if (this.state.groups.length == 0 && this.state.selectedHierarchyGroups.length == 0) {
                return <span>Search <strong>all</strong> groups</span>;
            }

            if (this.state.groups.length != 0) {
                return <span>Search <strong>{this.state.groups.length}</strong> {makePlural("group", this.state.groups.length)}</span>;
            }
        }

        if (this.state.selectedHierarchyGroups.length == this.state.allGroupIds.length || this.state.selectedHierarchyGroups.length == 0) {
            return <span>Search all groups { this.props.postLabel}</span>;
        }

        return <span>Search <strong>{this.state.selectedHierarchyGroups.length}</strong> {makePlural("group", this.state.selectedHierarchyGroups.length)} { this.props.postLabel}</span>;
    }

    get canChangeHierarchy() : boolean {
        return this.state.groups.length == 0;
    }

    render() {
        const groupDisabled = this.state.selectedHierarchyGroups.length > 0;
        const hierarchyLinkClassName = this.state.groups.length > 0 ? "disabled": "";

        return (<div className="fn-margin-bottom-small">
            { this.props.showGroups && 
                <div className="form-group">
                    <label className="small-16 large-16 columns control-label" style={{paddingLeft: 0}}>Groups</label>
                    <FormGroupSelector
                        onChange={this._handleSelectorChange}
                        includes={this.state.includes}
                        allowMultiple={this.props.allowMultiple}
                        filterCategories={this.state.filterCategories}
                        groupTypes={this.state.groupTypes}
                        services={this.state.services}
                        initialUsers={[]}
                        initialGroups={this.state.initialGroups}
                        hasMemberOnly={this.props.hasMemberOnly}
                        searchWordsOnNameOnly={this.props.searchWordsOnNameOnly}
                        commanderOnly={this.props.commanderOnly}
                        className={this.props.className} disabled={groupDisabled} />
                </div>
            }
            { this.props.showHierarchy && 
                <div className="fn-flex-space-between fn-margin-top-small">
                    { this.label }
                    { this.canChangeHierarchy && <a className={hierarchyLinkClassName} role="button" href="#" onClick={this._show}>Change</a>}
                    <MultiGroupHierarchySelector groups={this.props.groups} active={this.state.active} selectedGroups={this.state.selectedHierarchyGroups} onClose={this._onClose} onSelect={this._onSelect}/>
                </div>
            }
        </div>);
    }
}

export default MultiGroupHierarchySelectorModule;

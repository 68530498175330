// See ForceNet.Business.Model.Forums.AssociationType
export enum AssociationType {
    GroupDocumentFolder = 10,
    GroupDocument = 20,
    GroupMediaFolder = 30,
    GroupMedia = 40,
    AttachedFile = 50,
    AttachedPhoto = 60,
    EmbedlyResult = 70,
};

// See ForceNet.Business.Model.Files.FileCategory
export enum FileCategory {
    Document = 'Document',
    Media = 'Media',
};

//See ForceNet.Business.Model.Files.FileType
export enum FileType {
    Unknown = 0,
    Word = 1,
    Excel = 2,
    PDF = 3,
    PowerPoint = 4,
    Image = 5,
    CSV = 6,
    Video = 7,
    Url = 8,
    Audio = 9,
};

// See ForceNet.Business.Model.Forums.UserRightsType
export enum UserRightsType {
    None = 0,
    Author = 10,
    Moderator = 20,
};